import { DSP } from 'constantsBase';
import { Pixel } from 'utils/types';

export type DspToPixel = {
  [DSP.APN.id]?: Array<Pixel>,
  [DSP.TTD.id]?: Array<Pixel>,
  [DSP.DBM.id]?: Array<Pixel>,
  [DSP.AMZN.id]?: Array<Pixel>,
};

// type is used for QS to prevent conversion into an array due to numeric keys
export type DspCodeToPixel = {
  [DSP.APN.code]?: Array<Pixel>
  [DSP.TTD.code]?: Array<Pixel>
  [DSP.DBM.code]?: Array<Pixel>
  [DSP.AMZN.code]?: Array<Pixel>
};

type PixelToWeight = { [id: string]: number };

export type DspToPixelToWeightsType = {
  [DSP.APN.id]?: PixelToWeight
  [DSP.TTD.id]?: PixelToWeight
  [DSP.DBM.id]?: PixelToWeight
  [DSP.AMZN.id]?: PixelToWeight
};

export enum PixelStates {
  initial = 'initial',
  loading = 'loading',
  unsupported = 'unsupported',
  success = 'success',
}

export type PixelLoadingState =
  | { kind: PixelStates.initial }
  | { kind: PixelStates.loading }
  | { kind: PixelStates.unsupported }
  | { kind: PixelStates.success, pixels: DspToPixel, defaultWeights: DspToPixelToWeightsType };

export enum PixelCategories {
  all = 'all',
  selected = 'selected',
}
