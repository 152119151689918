/* eslint-disable react-hooks/exhaustive-deps */
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useFormContext } from 'react-hook-form';
import { WppGrid, WppInlineMessage } from 'buildingBlocks';
import { GlobalState } from 'reducers';
import { BudgetSetting, StrategyConfigurationStep } from 'containers/StrategyWizard/types';
import { DEFAULT_GROUP_ID, DEFAULT_PG_GROUP_ID } from 'containers/StrategyWizard/ConfigurationByStrategyType/BudgetOptimization/constants';
import {
  BudgetGroupOptions,
  ChildOptions,
  BudgetGroup as BudgetGroupType,
  TooltipsDescriptions,
  BudgetInterval,
  BudgetGroupLineItemMapping,
  GroupSettings,
} from 'containers/StrategyWizard/ConfigurationByStrategyType/BudgetOptimization/types';
import { displayCurrencyOrImps } from 'containers/StrategyWizard/ConfigurationByStrategyType/BudgetOptimization/utils';
import { Currency } from 'utils/types';
import { COPILOT_LAYOUT } from 'globalStyles';
import BudgetGroup from './BudgetGroup/BudgetGroup';
import DefaultGroupBanner from './BudgetGroup/DefaultGroupBanner';
import BudgetParentObject from './BudgetParent/BudgetParentObject';
import { getAllNonAttachedLineItems, getAttachedLineItems } from './utils';
import { BUDGET_MANAGEMENT_STYLES } from './style';

const { budgetGroup: { budgetGroupContainer, bannerMessage, container } } = BUDGET_MANAGEMENT_STYLES;

type BudgetGroupsProps = {
  groupedOptions: BudgetGroupOptions
  lifeTimeBudget: number
  requiredDailyValue: number
  tooltips: TooltipsDescriptions
  interval: BudgetInterval | BudgetSetting
  strategyName: string
  isCrossPlatformOptimization: boolean
  defaultCurrency: Currency
  remainingSpendDays: number
  allActiveChildOptions: ChildOptions
  budgetGroups: GroupSettings
  flightExtType: number
  flightTimezone: string
  hasNMSPermission: boolean
};

const BudgetGroups = ({
  groupedOptions,
  lifeTimeBudget,
  requiredDailyValue,
  tooltips,
  interval,
  strategyName,
  isCrossPlatformOptimization,
  defaultCurrency,
  remainingSpendDays,
  allActiveChildOptions,
  budgetGroups,
  flightExtType,
  flightTimezone,
  hasNMSPermission,
}: BudgetGroupsProps) => {
  const [budgetGroupToLineItems, setBudgetGroupToLineItems] = useState<BudgetGroupLineItemMapping>({});

  const strategyConfigurationStep = useSelector<GlobalState>((state) => state.strategyWizard.strategyConfigurationStep) as StrategyConfigurationStep;
  const reduxGroupSettings = _.get(strategyConfigurationStep, 'groupSettings');
  const { formState: { errors } } = useFormContext<StrategyConfigurationStep>();

  const filteredGroupSettings = _.omit(budgetGroups, [DEFAULT_GROUP_ID, DEFAULT_PG_GROUP_ID]);
  const generalAllocationRangeError = _.get(errors, 'groupSettings.minMaxAggregateValidation.message');
  const budgetMetric = isCrossPlatformOptimization
    ? defaultCurrency.code
    : displayCurrencyOrImps((interval as BudgetInterval));

  const { nonAttachedReg, nonAttachedPG } = getAllNonAttachedLineItems(filteredGroupSettings, allActiveChildOptions);

  useEffect(() => {
    // only want this running in edit flow to populate previous budgetGroupToLineItem mapping
    if (_.size(allActiveChildOptions) && _.size(reduxGroupSettings)) {
      const prevBudgetGroup = {};
      _.forEach(filteredGroupSettings, (budgetGroup: BudgetGroupType, groupKey: string) => {
        const groupsAttachedLineItem = getAttachedLineItems(budgetGroup.childExtIds, allActiveChildOptions);
        prevBudgetGroup[groupKey] = groupsAttachedLineItem;
      });
      setBudgetGroupToLineItems(prevBudgetGroup);
    }
  }, [budgetGroups, allActiveChildOptions, reduxGroupSettings]);

  return (
    <WppGrid container item all={24} style={{ padding: 0 }}>
      <BudgetParentObject
        interval={interval}
        tooltips={tooltips}
        strategyName={strategyName}
        lifeTimeBudget={lifeTimeBudget}
        requiredDailyValue={requiredDailyValue}
        budgetMetric={budgetMetric}
        flightExtType={flightExtType}
        flightTimezone={flightTimezone}
      />
      {!!_.size(filteredGroupSettings) && (
        <div style={{ ...container, marginBottom: COPILOT_LAYOUT.SPACING[12] }}>
          <WppGrid style={budgetGroupContainer} container fullWidth all={24}>
            {_.map(filteredGroupSettings, (budgetGroup: BudgetGroupType, key: string) => (
              <BudgetGroup
                key={key}
                groupedOptions={groupedOptions}
                lifeTimeBudget={lifeTimeBudget}
                budgetGroup={budgetGroup}
                budgetGroupKey={key}
                budgetGroupToLineItems={budgetGroupToLineItems}
                setBudgetGroupToLineItems={setBudgetGroupToLineItems}
                requiredDailyValue={requiredDailyValue}
                budgetMetric={budgetMetric}
                remainingSpendDays={remainingSpendDays}
                interval={interval}
                allActiveChildOptions={allActiveChildOptions}
                budgetGroups={budgetGroups}
                hasNMSPermission={hasNMSPermission}
              />
            ))}
          </WppGrid>
          <div style={container}>
            {generalAllocationRangeError && (
            <WppGrid container item all={24} fullWidth>
              <WppInlineMessage
                size="m"
                style={bannerMessage}
                message={generalAllocationRangeError}
                type="error"
                showTooltipFrom={100}
              />
            </WppGrid>
            )}
            {!!_.size(nonAttachedReg) && (
            <WppGrid container item all={24} fullWidth>
              <DefaultGroupBanner nonAttachedLineItemsCount={_.size(nonAttachedReg)} />
            </WppGrid>
            )}
            {(!!_.size(nonAttachedPG) && hasNMSPermission) && (
            <WppGrid container item all={24} fullWidth>
              <DefaultGroupBanner nonAttachedLineItemsCount={_.size(nonAttachedPG)} isPGBanner />
            </WppGrid>
            )}

          </div>
        </div>
      )}
    </WppGrid>
  );
};

export default BudgetGroups;
