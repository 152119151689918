import React, { CSSProperties } from 'react';
import { WppTypography, WppInlineMessage } from 'buildingBlocks';
import Tooltip from 'containers/Tooltip';
import { BUDGET_MANAGEMENT_STYLES } from '../style';

const { budgetParent } = BUDGET_MANAGEMENT_STYLES;

type BudgetParentObjectFieldsProps = {
  name: string
  content: string
  tooltip?: string
  customStyle?: CSSProperties
};

const BudgetParentObjectFields = ({
  name,
  content,
  tooltip,
  customStyle,
}: BudgetParentObjectFieldsProps) => (
  <div style={customStyle ?? budgetParent.textSection}>
    <div style={budgetParent.budgetParenttextTooltip}>
      <WppTypography type="s-strong">{name}</WppTypography>
      {tooltip && (
        <Tooltip
          content={tooltip}
        >
          <WppInlineMessage
            size="s"
            type="information"
          />
        </Tooltip>
      )}
    </div>
    <WppTypography style={budgetParent.subHeaderText} type="s-body">{content}</WppTypography>
  </div>
);

export default BudgetParentObjectFields;
