import _ from 'lodash';
import React from 'react';
import { FieldError } from 'react-hook-form';
import { BidTypeValue } from 'containers/StrategyWizard/ConfigurationByStrategyType/Helios/type';
import { ButtonClickHandler, Currency } from 'utils/types';
import EditGroupList from './EditGroupList';
import SGTopBar from './SGTopBar';
import ReorderGroupList from './ReorderGroupList';
import { bidFormKeys } from '../constants';
import { dragAndDropStyles } from '../style';
import { Item, GroupItem } from '../SegmentPickerTypes';

export type DraggableGroupListProps = {
  itemsMap: { [key: string]: Array<Item> },
  groupContainerEndRef: React.RefObject<HTMLInputElement>,
  groups: { [key: string]: GroupItem },
  groupOrder: Array<string>,
  removeFromGroup: Function,
  removeGroup: Function,
  includeExcludeToggle: Function,
  setGroupFormChange: Function,
  enableGroupReordering: boolean,
  isReorderGroupsMode: boolean,
  toggleReorderGroupsMode: Function,
  showIncludeExcludeSelector: boolean,
  enableItemReordering: boolean,
  errors: FieldError,
  currency: Currency,
  onTargetBetweenSegmentsClick: Function,
  cannotDeleteAllSegmentGroups: boolean,
  overrideBidValues: ButtonClickHandler,
  showConfigureDefaultsBar: boolean,
  bidType: BidTypeValue,
};

const SegPickerRightView = ({
  itemsMap,
  groups,
  groupOrder,
  groupContainerEndRef,
  removeFromGroup,
  removeGroup,
  includeExcludeToggle,
  setGroupFormChange,
  enableGroupReordering,
  isReorderGroupsMode,
  toggleReorderGroupsMode,
  showIncludeExcludeSelector,
  enableItemReordering,
  errors,
  currency,
  onTargetBetweenSegmentsClick,
  cannotDeleteAllSegmentGroups,
  overrideBidValues,
  showConfigureDefaultsBar,
  bidType,
}: DraggableGroupListProps) => (
  <div style={{ border: '1px solid rgba(34,36,38,.15)' }}>
    <SGTopBar
      enableGroupReordering={enableGroupReordering}
      isReorderGroupsMode={isReorderGroupsMode}
      toggleReorderGroupsMode={toggleReorderGroupsMode}
      currency={currency}
      overrideBidValues={overrideBidValues}
      showConfigureDefaultsBar={showConfigureDefaultsBar}
      bidType={bidType}
    />
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <div style={{ flex: 1 }}>
        <div style={dragAndDropStyles.scrollSGContainer}>
          {!isReorderGroupsMode
            ? (
              <div style={{ marginBottom: '100px' }}>
                {
                  _.map(groupOrder, (groupKeyId, index: number) => {
                    const group = groups[groupKeyId];
                    const groupBidValues = _.pick(groups[groupKeyId], bidFormKeys);
                    const items = group.dragIds.map(
                      (id) => _.find(itemsMap[groupKeyId], ['dragId', id]),
                    );
                    return (
                      <EditGroupList
                        groupKeyId={groupKeyId}
                        key={group.uuid}
                        group={group}
                        items={items}
                        removeFromGroup={removeFromGroup}
                        removeGroup={removeGroup}
                        includeExcludeToggle={includeExcludeToggle}
                        setGroupFormChange={setGroupFormChange}
                        showIncludeExcludeSelector={showIncludeExcludeSelector}
                        enableItemReordering={enableItemReordering}
                        errors={errors}
                        index={index}
                        currency={currency}
                        groupBidValues={groupBidValues}
                        bidType={bidType}
                        onTargetBetweenSegmentsClick={onTargetBetweenSegmentsClick}
                        cannotDeleteAllSegmentGroups={
                          !_.isEqual(cannotDeleteAllSegmentGroups, false) && groupOrder.length <= 1
}
                      />
                    );
                  })
}
              </div>
            )
            : (
              <ReorderGroupList
                itemsMap={itemsMap}
                groups={groups}
                groupOrder={groupOrder}
                enableGroupReordering={enableGroupReordering}
              />
            )}
          <div ref={groupContainerEndRef} />
        </div>
      </div>
    </div>
  </div>
);

export default SegPickerRightView;
