import React from 'react';
import { Button } from 'buildingBlocks';
import { dragAndDropStyles } from '../style';
import { Item } from '../SegmentPickerTypes';

const getDragDropItemStyle = (isExcluded, isDeleted) => (isExcluded ? {
  color: `${isDeleted ? '#e0b4b4' : dragAndDropStyles.color.copilotGreen}`,
  border: `1px solid ${isDeleted ? '#e0b4b4' : dragAndDropStyles.color.copilotGreen}`,
  backgroundColor: isDeleted ? '#fff6f6' : 'white',
} : {
  color: `${isDeleted ? '#e0b4b4' : dragAndDropStyles.color.copilotRed}`,
  border: `1px solid ${isDeleted ? '#e0b4b4' : dragAndDropStyles.color.copilotRed}`,
  backgroundColor: isDeleted ? '#fff6f6' : 'white',
});

type DragDropItemProps = {
  groupKeyId: string,
  item: Item,
  removeFromGroup: Function,
  includeExcludeToggle: Function,
  showIncludeExcludeSelector: boolean,
};

const DragDropItem = ({
  groupKeyId, item, removeFromGroup, includeExcludeToggle, showIncludeExcludeSelector,
}: DragDropItemProps) => (
  <div
    style={{
      ...dragAndDropStyles.dragDropItem,
      ...getDragDropItemStyle(!item.isExcluded, item.isDeleted),
    }}
  >
    {showIncludeExcludeSelector && (
      <div style={{ minWidth: '50px' }}>
        <Button
          basic
          circular
          icon="check"
          style={{ width: '22px' }}
          className={`segment-picker ${!item.isExcluded ? 'copilot-green' : 'grey'}`}
          size="mini"
          onClick={() => { includeExcludeToggle(groupKeyId, item.dragId, false); }}
          type="button"
        />
        <Button
          basic
          circular
          icon="minus"
          style={{ width: '22px' }}
          className={`segment-picker ${item.isExcluded ? 'copilot-red' : 'grey'}`}
          size="mini"
          onClick={() => { includeExcludeToggle(groupKeyId, item.dragId, true); }}
          type="button"
        />
      </div>
    )}

    <span style={dragAndDropStyles.segmentName} title={item.text}>{item.text}</span>
    <Button
      basic
      circular
      icon="x"
      size="mini"
      className="segment-picker"
      style={{ marginLeft: 'auto', maxHeight: '23px', width: '22px' }}
      onClick={() => {
        removeFromGroup(groupKeyId, item.dragId, item.id);
      }}
    />
  </div>
);

export default DragDropItem;
