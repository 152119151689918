import _ from 'lodash';
import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Controller, useFormContext, useWatch } from 'react-hook-form';
import { Grid, Dimmer, Loader, Divider } from 'buildingBlocks';
import { GOAL_TYPES } from 'constantsBase';
import { GlobalState } from 'reducers';
import SegmentPicker from 'containers/StrategyWizard/components/SegmentPicker';
import { createDraggableItems } from 'containers/StrategyWizard/components/SegmentPicker/utils';
import APNSegmentDataProvider from 'containers/StrategyWizard/ConfigurationByStrategyType/dataProvider/APNSegmentDataProvider';
import { ModuleProps } from 'containers/StrategyWizard/steps/StrategyConfiguration/components/Loader';
import Viewability from 'containers/StrategyWizard/components/Viewability';
import strategyWizardStyles from 'containers/StrategyWizard/styles';
import { HeliosForm, WizardFormValues } from 'containers/StrategyWizard/types';
import OptionalSettings from './components/OptionalSettings';
import BidSettingsComponent from './components/BidSettingsComponent';
import { formatBudgetIntervals, getBidsAndBidType } from './utils';
import { useHeliosDataFetcher } from './hooks';
import { BidTypeValue } from './type';

const { column, row, divider } = strategyWizardStyles;

const getSegments = (
  memberId: number,
  advertiserId: string,
  includeNetworkSegments: boolean,
) => new APNSegmentDataProvider(
  memberId,
  advertiserId,
  includeNetworkSegments,
  (segments) => createDraggableItems(_.map(segments, (segment) => ({
    id: _.toNumber(segment.externalId),
    shortName: segment.shortName,
  }))),
);

export type HeliosState = {
  isFilterExcludedIds: boolean,
};

const Helios = (props: ModuleProps) => {
  const { initialValues, strategyId } = props;
  const {
    attachFlightsStep: { member, advertiser, attachedFlights, defaultCurrency },
    goalSelectionStep: { goal: { type } },
    strategyTypeSelectionStep: { strategyType },
  } = useSelector<GlobalState>((state) => state.strategyWizard) as WizardFormValues;
  const { setValue } = useFormContext<HeliosForm>();
  // if in edit mode with previously set viewability goals and user changes goalType to ivrMeasured, viewability field is reset
  if (type === GOAL_TYPES.ivrMeasured.value) {
    setValue('viewability', { enabled: false });
  }

  const baseBid = useWatch({ name: 'baseBid' });
  const minBid = useWatch({ name: 'minBid' });
  const maxBid = useWatch({ name: 'maxBid' });

  const [includeNetworkSegments, setIncludeNetworkSegments] = useState(true);
  const [count, setCount] = useState<number>(_.size(initialValues.segmentGroups) - 1);
  const {
    segments,
    selectedLineItem,
    lineItems,
    loading,
  } = useHeliosDataFetcher(initialValues, member, advertiser, attachedFlights, strategyType, strategyId, setValue);

  const { bids, bidType } = getBidsAndBidType(attachedFlights, strategyType.id);
  const lineItemsByExtId = _.keyBy(lineItems, 'externalId');
  const attachedLIs = !_.isEmpty(lineItemsByExtId) && _.map(attachedFlights, (f) => lineItemsByExtId[f.externalId]);

  // always set baseBid to 1 to avoid any edge cases where it gets removed from the form
  if (!baseBid) {
    setValue('baseBid', 1);
  }

  useEffect(() => {
    // We pull LI from our db, therefore we use externalId, but then we check if lineitem exist in apn...
    // apn doesn't have externalId, and our externalId match apn id
    if (selectedLineItem) {
      setValue('lineItem', `${_.get(selectedLineItem, 'externalId', selectedLineItem.id)}`);
      setValue(
        'budgetIntervals',
        formatBudgetIntervals(
          _.get(initialValues, 'budgetIntervals'),
          _.get(selectedLineItem, 'budgetIntervals'),
        ),
      );
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedLineItem]);

  const segmentLookup = _.keyBy(segments, 'externalId');

  return (
    <Grid stackable celled="internally">
      {
        (!initialValues || loading)
        && (
          <Dimmer active page>
            <Loader>Helios module is loading.</Loader>
          </Dimmer>
        )
      }
      {type !== GOAL_TYPES.ivrMeasured.value
        && (
          <>
            <Viewability />
            <Divider style={divider} />
          </>
        )}
      <Grid.Row style={row}>
        <Grid.Column style={column}>
          {!loading && (
            <Controller
              name="segmentGroups"
              render={({ field, fieldState }) => (
                <SegmentPicker
                  dataProvider={getSegments(member.id, advertiser.externalId, includeNetworkSegments)}
                  preventCloning
                  enableGroupReordering
                  showIncludeExcludeSelector
                  segmentLookup={segmentLookup}
                  onFilterExcludedIds={setIncludeNetworkSegments}
                  filterAllSegments={includeNetworkSegments}
                  fieldState={fieldState}
                  field={field}
                  value={field.value}
                  count={count}
                  setCount={setCount}
                  bidType={bidType as BidTypeValue}
                />
              )}
            />
          )}
        </Grid.Column>
      </Grid.Row>
      <Divider style={divider} />
      {
        !_.isEmpty(attachedFlights)
        && (
          <BidSettingsComponent
            currency={defaultCurrency}
            bidType={bidType as BidTypeValue}
            bids={bids}
            attachedLIs={attachedLIs}
            minBidMultiplier={minBid}
            maxBidMultiplier={maxBid}
          />
        )
      }
      <Divider style={divider} />
      <Grid.Row style={row}>
        <OptionalSettings />
      </Grid.Row>
    </Grid>
  );
};

export default Helios;
