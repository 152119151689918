import _ from 'lodash';
import { applyAllValidators } from 'utils/formValidators';
import { StrategyConfigurationStep } from 'containers/StrategyWizard/types';
import {
  BID_LIST_STRING,
  VOLUME_CONTROL_BID_LIST_STRING,
  BidListErrorArray,
  PageStatusType,
  BidListStatus,
  VolumeControlStatus,
} from './constants';

const generateCopyForField = (fieldName: string) => (
  `${fieldName === BID_LIST_STRING ? 'Bid List' : 'Volume Control Bid List'} must be valid to continue`
);

const checkIfBidListIsValid = (errors: BidListErrorArray) => (_formValues: StrategyConfigurationStep, fieldName: string) => (
  _.isEmpty(errors) ? {} : { [fieldName]: generateCopyForField(fieldName) }
);

const MUST_VALIDATE_COPY_BID_LIST = 'Bid list(s) must be valid to continue';
const checkPageStatus = (pageStatus: PageStatusType) => (_fv: StrategyConfigurationStep, fieldName: string) => {
  if (fieldName === BID_LIST_STRING) {
    return _.get(pageStatus, 'bidListStatus') === BidListStatus.BidListsAreValid ? {} : { [fieldName]: MUST_VALIDATE_COPY_BID_LIST };
  }
  return _.get(pageStatus, 'volumeControlStatus') === VolumeControlStatus.VolumeControlAreValid ? {} : { [fieldName]: MUST_VALIDATE_COPY_BID_LIST };
};

// eslint-disable-next-line max-len
const BOTH_FIELDS_CANT_BE_EMPTY_COPY = 'Both the Bid List and Volume Control Bid List are currently blank - at least one of the fields must contain at least one bid line in order to continue';
const checkEmpty = (fv: StrategyConfigurationStep, fieldName: string) => (
  _.get(fv, BID_LIST_STRING) === '' && _.get(fv, VOLUME_CONTROL_BID_LIST_STRING) === ''
    ? { [fieldName]: BOTH_FIELDS_CANT_BE_EMPTY_COPY }
    : {}
);

const validate = (values: any) => {
  const validators = {
    [BID_LIST_STRING]: [checkIfBidListIsValid(values.bidListErrorsArray), checkEmpty],
    [VOLUME_CONTROL_BID_LIST_STRING]: [checkIfBidListIsValid(values.volumeControlErrorsArray), checkEmpty],
    // @ts-ignore
    [BID_LIST_STRING]: [checkPageStatus(values.pageValidator)],
    // @ts-ignore
    [VOLUME_CONTROL_BID_LIST_STRING]: [checkPageStatus(values.pageValidator)],
  };
  return { values, errors: applyAllValidators(values, validators) };
};

export default validate;
