import React from 'react';
import { DraggableProvided } from '@hello-pangea/dnd';
import DragDropItem from './DragDropItem';
import { Item } from '../SegmentPickerTypes';

type EditGroupListContentProps = {
  dragProvided: DraggableProvided,
  index: number,
  groupKeyId: string,
  item: Item,
  removeFromGroup: Function,
  includeExcludeToggle: Function,
  targetBetweenSegmentsValue: string,
  showIncludeExcludeSelector: boolean,
};

const EditGroupListContent = ({
  dragProvided,
  index,
  targetBetweenSegmentsValue,
  groupKeyId,
  item,
  removeFromGroup,
  includeExcludeToggle,
  showIncludeExcludeSelector,
} : EditGroupListContentProps) => (
  <div className="segment" style={{ display: 'flex' }}>
    {index !== 0
      && (
      <span
        style={{ lineHeight: '38px', paddingRight: '10px' }}
      >
        {targetBetweenSegmentsValue}
      </span>
      )}
    <div
      className="draggable-item"
      ref={dragProvided.innerRef}
      {...dragProvided.draggableProps}
      {...dragProvided.dragHandleProps}
    >
      <DragDropItem
        item={item}
        groupKeyId={groupKeyId}
        removeFromGroup={removeFromGroup}
        includeExcludeToggle={includeExcludeToggle}
        showIncludeExcludeSelector={showIncludeExcludeSelector}
      />
    </div>
  </div>
);

export default EditGroupListContent;
