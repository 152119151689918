import React, { Dispatch, SetStateAction } from 'react';
import { WppButton, WppInlineMessage } from 'buildingBlocks';
import { pluralizer } from 'utils/formattingUtils';

type Props = {
  numberSelected: number
  onClick: Dispatch<SetStateAction<boolean>>
  modalOpen: boolean
};

const CustomButton = ({ numberSelected, onClick, modalOpen }: Props) => {
  const pixelText = pluralizer('Pixel', 'Pixels')(numberSelected);

  return (
    <>
      {!modalOpen && (
        <WppInlineMessage
          size="s"
          message={`${numberSelected} Conversion ${pixelText} Selected`}
          type={numberSelected === 0 ? 'error' : 'information'}
        />
      )}
      <WppButton
        variant="secondary"
        // @ts-ignore
        onClick={onClick}
        size="s"
        className="configureButton"
      >
        Configure
      </WppButton>
    </>
  );
};

export default CustomButton;
