import _ from 'lodash';
import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { Message, WppButton, WppInlineMessage } from 'buildingBlocks';
import strategyWizardStyles from 'containers/StrategyWizard/styles';
import CustomTextArea from 'components/CustomTextArea';
import { COPILOT_LAYOUT } from 'globalStyles';
import { formatListAsString } from 'utils/formattingUtils';
import { BidListValidity, BidListErrorArray, MAX_ERRORS_TO_SHOW, BID_LIST_STRING } from './constants';
import { TranslationErrorsByField } from './context';

const { SPACING } = COPILOT_LAYOUT;

const ValidBidListMessage = ({ bidListName }: { bidListName: string }) => (
  <WppInlineMessage
    size="s"
    message={`${bidListName} is valid`}
    type="success"
  />
);

const ErrorsList = ({ errors }: { errors: BidListErrorArray }) => {
  const errorListItems = _(errors).slice(0, MAX_ERRORS_TO_SHOW).map((error, idx) => (
    // eslint-disable-next-line react/no-array-index-key
    <li key={idx} style={{ marginBottom: '5px' }}>
      <WppInlineMessage
        size="s"
        message={JSON.stringify(error)}
        type="error"
      />
    </li>
  )).value();

  return (
    <ul style={{ listStyleType: 'none' }}>
      {errorListItems}
    </ul>
  );
};

type ConditionalComponentProps = {
  id: 'bidListString' | 'volumeControlBidListString'
  bidListValidity: BidListValidity,
  errors: BidListErrorArray,
};

const ConditionalBidListStatusComponent = ({ bidListValidity, errors, id }: ConditionalComponentProps) => {
  switch (bidListValidity) {
    case BidListValidity.Valid:
      return <ValidBidListMessage bidListName={id === BID_LIST_STRING ? 'Bid List' : 'Volume Control'} />;
    case BidListValidity.Invalid:
      return <ErrorsList errors={errors} />;
    case BidListValidity.NotYetValidated:
      if (errors) {
        return <ErrorsList errors={errors} />;
      }
      return null;
    default:
      return null;
  }
};

type TabComponentProps = {
  onValidateBidListClick: () => void,
  onChange: (event: React.ChangeEvent, val: string) => void,
  errors: BidListErrorArray,
  id: 'bidListString' | 'volumeControlBidListString',
  bidListString: string,
  bidListValidity: BidListValidity,
  shouldDisableValidateBidList: boolean,
  shouldDisableBidListInput: boolean,
  shouldDisplayValidateButtonLoader: boolean,
  notGeneratedWarning: string | null,
  translationErrorsByField: TranslationErrorsByField,
};

const TabComponent = ({
  onValidateBidListClick,
  onChange,
  errors,
  id,
  bidListString,
  bidListValidity,
  shouldDisableValidateBidList,
  shouldDisableBidListInput,
  shouldDisplayValidateButtonLoader,
  notGeneratedWarning,
  translationErrorsByField,
}: TabComponentProps) => {
  const { control } = useForm();
  const csvInputValue = !!bidListString;

  const adjustedShouldDisableValidate = shouldDisableValidateBidList || bidListString.length === 0;
  return (
    <>
      {notGeneratedWarning && (
        <Message size="mini" info style={{ marginBottom: '21px' }}>
          <Message.Content style={{ textAlign: 'center' }}>{notGeneratedWarning}</Message.Content>
        </Message>
      )}
      {!_.isEmpty(translationErrorsByField) && (
        <Message size="mini" style={{ marginBottom: '21px' }}>
          <Message.Content>
            Error retrieving translation maps the following dimensions: {`${formatListAsString(_.keys(translationErrorsByField))}`}. If you were expecting translation to occur please try again later.
          </Message.Content>
        </Message>
      )}
      <Controller
        name="customBidFileInput"
        control={control}
        render={({ ...field }) => (
          <CustomTextArea
            name={id}
            value={bidListString}
            disabled={shouldDisableBidListInput}
            onChange={onChange}
            height={500}
            mode="application/json"
            csvInputValue={csvInputValue}
            {...field}
          />
        )}
      />
      <div style={strategyWizardStyles.configureCustomStyle}>
        <Controller
          name="validateBidLists"
          control={control}
          render={({ field, ...restFormProps }) => (
            <WppButton
              size="m"
              variant="secondary"
              className="custom-configure-tree-btn"
              loading={shouldDisplayValidateButtonLoader}
              onClick={onValidateBidListClick}
              disabled={adjustedShouldDisableValidate}
              {...field}
              style={{ width: '100%' }}
              {..._.omit(restFormProps, ['formState', 'fieldState'])}
            >
              Validate
            </WppButton>
          )}
        />
      </div>
      <div style={{ marginTop: SPACING[12] }}>
        <ConditionalBidListStatusComponent id={id} bidListValidity={bidListValidity} errors={errors} />
      </div>
    </>
  );
};

export default TabComponent;
