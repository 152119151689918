import _ from 'lodash';

export type APNSegment = { id: number, shortName?: string, externalId?: string, name?: string };
export type SegmentOrSegmentIdentifier = APNSegment | number | string;

export type SegmentLookup = { [key: string]: APNSegment };

export const getSegmentIdentifier = (segment: SegmentOrSegmentIdentifier) => {
  if (typeof segment === 'number') {
    return segment;
  }
  let id;
  if (typeof segment === 'string') {
    id = segment;
  } else {
    // We need to get the externalId of the segment
    // In the case it's an already saved segment, there won't be any externalId
    // because we are saving the externalId as 'id' therefore we should take
    // the id if externalId is undefined.
    id = _.get(segment, 'externalId', _.get(segment, 'id'));
  }
  return parseInt(id, 10);
};

export const segmentFormatLabel = (segment: APNSegment) => (
  `[${getSegmentIdentifier(segment)}] - ${segment.shortName || segment.name}`
);

export const segmentFormatOption = (segment: APNSegment) => ({
  // Option formatting for modal selection
  text: segmentFormatLabel(segment),
  value: getSegmentIdentifier(segment),
});

export const segmentMultiModalSelectInMapper = (
  segmentLookup: SegmentLookup,
  seg: SegmentOrSegmentIdentifier,
) => {
  const externalId = getSegmentIdentifier(seg);
  let metaSegment = _.get(segmentLookup, `${externalId}`);

  if (_.isEmpty(metaSegment)) {
    metaSegment = {
      id: externalId,
      shortName: 'Segment Not Found',
    };
  }

  return {
    ...metaSegment,
    ...segmentFormatOption(metaSegment),
    ...(typeof seg === 'number' || typeof seg === 'string' ? {} : seg),
  };
};
