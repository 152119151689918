import React, { ChangeEventHandler } from 'react';
import { Controller } from 'react-hook-form';
import { Input } from 'buildingBlocks';
import { validatedFieldWrapper } from 'utils/wrapperComponentsReactHookForms';

const ValidatedInput = validatedFieldWrapper(Input);

type SegmentGroupFormComponentProps = {
  name: string,
  onChange?: ChangeEventHandler<HTMLTextAreaElement>,
};

const SegmentGroupFormComponent = ({ name, onChange } :SegmentGroupFormComponentProps) => (
  <Controller
    rules={{ required: true }}
    name={name}
    render={({ field, fieldState }) => (
      <ValidatedInput
        label="Name"
        type="text"
        onChange={(v) => {
          field.onChange(v);
          onChange(v);
        }}
        field={field}
        fieldState={fieldState}
        skipIsDirtyCheck
      />
    )}
  />
);

export default SegmentGroupFormComponent;
