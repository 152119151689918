import _ from 'lodash';
import { checkDupes, checkSegments } from 'containers/StrategyWizard/steps/StrategyConfiguration/validate';
import { checkDuplicate } from 'utils/functionHelpers';
import {
  applyAllValidators,
  isNumber,
  required,
  isLess,
  isLessOrEqual,
  isGreater,
  crossValidate,
  mapValidator,
  isGreaterOrEqual,
} from 'utils/reactHookFormValidators';
import { SegmentGroup } from 'utils/types';
import { MAX_DAYS } from './constants';

export const validators = {
  maxBid: [isGreater(0), crossValidate(isGreater, 'minBid'), required],
  minBid: [isGreater(0), crossValidate(isLess, 'maxBid'), required],
};

export default (values: { segmentGroups: Array<SegmentGroup> }) => {
  const allNames = _.map(values.segmentGroups, 'name');
  const dupesNames = checkDuplicate(allNames);

  const segmentGroupValidators = {
    name: [required, checkDupes(dupesNames)],
    boolOperator: [required],
    segments: [checkSegments],
    startingMaxBid: [isGreater(0), crossValidate(isGreater, 'startingMinBid'), required],
    startingMinBid: [isGreater(0), crossValidate(isLess, 'startingMaxBid'), required],
    maxDays: [isGreaterOrEqual(1), isLessOrEqual(MAX_DAYS), isNumber, required],
  };

  const restValidators = {
    segmentGroups: [mapValidator(segmentGroupValidators), required],
  };

  return { values, errors: applyAllValidators(values, { ...validators, ...restValidators }) };
};
