import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { FieldError } from 'react-hook-form';
import { Droppable, Draggable } from '@hello-pangea/dnd';
import { Button, Divider } from 'buildingBlocks';
import { BOOLEAN_OPERATORS } from 'constantsBase';
import SegmentGroupFormComponent from 'containers/StrategyWizard/ConfigurationByStrategyType/Helios/components/SegmentGroupFormComponent';
import { BidTypeValue } from 'containers/StrategyWizard/ConfigurationByStrategyType/Helios/type';
import SegmentGroupBidFormComponent from 'containers/StrategyWizard/ConfigurationByStrategyType/SegmentRecency/components/SegmentGroupBidFormComponent';
import { Currency } from 'utils/types';
import TargetGroupBar from './TargetGroupBar';
import EditGroupListContent from './EditGroupListContent';
import NonEditModeBar from './NonEditModeBar';
import { dragAndDropStyles } from '../style';
import { GroupItem, GroupBidValues } from '../SegmentPickerTypes';

type EditGroupListProps<T> = {
  groupKeyId: string
  items: Array<T>
  group: GroupItem
  removeFromGroup: Function
  removeGroup: Function
  includeExcludeToggle: Function
  setGroupFormChange: Function
  showIncludeExcludeSelector: boolean
  enableItemReordering: boolean
  errors: FieldError
  index: number
  currency: Currency
  groupBidValues: GroupBidValues
  onTargetBetweenSegmentsClick: Function
  cannotDeleteAllSegmentGroups: boolean
  bidType: BidTypeValue
};

type EditGroupListState = {
  displayEditGroupFields: boolean
  disableDeleteGroup: boolean
};

const hasErrorCheck = (errors, index) => !_.isEmpty(_.get(errors, `[${index}]`));

const EditGroupList = (props: EditGroupListProps<any>) => {
  const {
    groupKeyId,
    items,
    group,
    removeFromGroup,
    removeGroup,
    includeExcludeToggle,
    setGroupFormChange,
    showIncludeExcludeSelector,
    enableItemReordering,
    errors,
    index,
    currency,
    groupBidValues,
    onTargetBetweenSegmentsClick,
    cannotDeleteAllSegmentGroups,
    bidType,
  } = props;

  const [editGroupListState, setEditGroupListState] = useState<EditGroupListState>({
    displayEditGroupFields: hasErrorCheck(errors, index),
    disableDeleteGroup: cannotDeleteAllSegmentGroups,
  });

  const createFieldName = (name: string, idx: number) => `segmentGroups[${idx}].${name}`;

  useEffect(() => {
    let updatedEditGroupListState = { ...editGroupListState };
    if (hasErrorCheck(errors, index)) {
      setEditGroupListState({ ...editGroupListState, displayEditGroupFields: true });
      updatedEditGroupListState = { ...editGroupListState, displayEditGroupFields: true };
    }
    if (cannotDeleteAllSegmentGroups) {
      setEditGroupListState({
        ...updatedEditGroupListState,
        disableDeleteGroup: true,
      });
    } else {
      setEditGroupListState({
        ...updatedEditGroupListState,
        disableDeleteGroup: false,
      });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errors, setEditGroupListState]);

  const onInputChange = (key: string) => (event: any) => {
    setGroupFormChange(groupKeyId, { [key]: event.target.value });
  };

  const toggleEditGroupFields = () => setEditGroupListState((prevState) => ({
    ...prevState,
    displayEditGroupFields: !prevState.displayEditGroupFields,
  }));

  const { displayEditGroupFields, disableDeleteGroup } = editGroupListState;

  return (
    <div id={`group-${groupKeyId}`} style={{ marginTop: '20px' }}>
      <Droppable droppableId={groupKeyId}>
        {(dropProvided, dropSnapshot) => (
          <div
            ref={dropProvided.innerRef}
            style={{
              ...dragAndDropStyles.segmentGroup,
              backgroundColor: dropSnapshot.isDraggingOver ? '#d8d8d8' : '#F0F1F0',
              padding: '10px',
            }}
            {...dropProvided.droppableProps}
          >
            <div>
              { displayEditGroupFields
                ? (
                  <div style={{ display: 'flex' }}>
                    <div style={{ flex: 1 }}>
                      <div className="ui form">
                        <div className="field">
                          <SegmentGroupFormComponent
                            name={createFieldName('name', index)}
                            onChange={onInputChange('name')}
                          />
                          {showIncludeExcludeSelector && (
                            <TargetGroupBar
                              groupKeyId={groupKeyId}
                              booleanOptions={BOOLEAN_OPERATORS}
                              selectedValue={group.boolOperator.value}
                              onClick={onTargetBetweenSegmentsClick}
                            />
                          )}
                          { !showIncludeExcludeSelector && _.isUndefined(group.bids) && (
                            <SegmentGroupBidFormComponent
                              currency={currency}
                              groupIndex={index}
                              createFieldName={createFieldName}
                              onChange={onInputChange}
                              bidType={bidType}
                            />
                          )}
                          <Divider style={{ marginTop: 0 }} />
                        </div>
                      </div>
                    </div>
                    <div style={{ ...dragAndDropStyles.segmentGroup.btnContainer, right: '-10px', bottom: '13px' }}>
                      <Button
                        disabled={hasErrorCheck(errors, index)}
                        style={{ ...dragAndDropStyles.segmentGroup.btnIcon, color: '#00b4e3' }}
                        icon="checkmark"
                        size="large"
                        onClick={toggleEditGroupFields}
                      />
                    </div>
                  </div>
                )
                : (
                  <NonEditModeBar
                    toggleEditGroupFields={toggleEditGroupFields}
                    enableItemReordering={enableItemReordering}
                    groupName={group.name}
                    groupBidValues={groupBidValues}
                    bidType={bidType}
                  />
                )}
            </div>
            <div style={{ display: 'flex' }}>
              <div style={{ flex: 1 }}>
                {_.isEmpty(items)
                && <small style={{ color: '#9f3a38', fontSize: '12px' }}>Please add a segment</small>}
                {
                  _.map(items, (item, idx: number) => (
                    <Draggable
                      draggableId={item.dragId}
                      index={idx}
                      key={item.dragId}
                      isDragDisabled={!enableItemReordering}
                    >
                      {(dragProvided) => (
                        <EditGroupListContent
                          item={item}
                          dragProvided={dragProvided}
                          index={idx}
                          groupKeyId={groupKeyId}
                          targetBetweenSegmentsValue={group.boolOperator.value}
                          removeFromGroup={removeFromGroup}
                          includeExcludeToggle={includeExcludeToggle}
                          showIncludeExcludeSelector={showIncludeExcludeSelector}
                        />
                      )}
                    </Draggable>
                  ))
                }
                {dropSnapshot.isDraggingOver && (
                  <div style={{ height: '25px', position: 'relative' }} />
                )}

              </div>
              <div style={dragAndDropStyles.segmentGroup.btnContainer}>
                <Button
                  disabled={disableDeleteGroup}
                  style={dragAndDropStyles.segmentGroup.btnIcon}
                  icon="x"
                  size="large"
                  floated="right"
                  onClick={() => removeGroup(groupKeyId)}
                />
              </div>
            </div>
            {dropProvided.placeholder}
          </div>
        )}
      </Droppable>
    </div>
  );
};

export default EditGroupList;
