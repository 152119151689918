import _ from 'lodash';
import React, { useState, SetStateAction, Dispatch } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router';
import { useFormContext } from 'react-hook-form';
import { WppButton } from 'buildingBlocks';
import { WizardFormAttachFlights } from 'containers/StrategyWizard/types';
import { RESET_GOALS_AND_STRATEGY_TYPE, WizardSteps, WIZARD_STEPS, WIZARD_BASE } from 'containers/StrategyWizard/constants';
import { STRATEGY_WIZARD_BUTTON_TAG } from 'cssTagConstants';
import { OptimizationType, OPTIMIZATION_TYPE_OPTIONS, ATTACH_FLIGHTS_STEP_INITIAL_VALUES, attachFlightsInfoInitialState } from '../steps/AttachFlights/constants';
import strategyWizardStyles from '../styles';
import ConfirmationPopup from './ConfirmationPopup';

type Props = {
  onNextClick: () => void
  onBackClick: () => void
  showBackButton?: boolean
  backButtonDisabled?: boolean
  showNextButton?: boolean
  nextButtonDisabled?: boolean
  strategyId?: number
  nextButtonText?: string
  backButtonText?: string
  selectedOptType?: OptimizationType
  canAccessCrossPlatform?: boolean
  optTypeSelectionOpen?: boolean
  setOptTypeSelectionOpen?: Dispatch<SetStateAction<boolean>>
  setAttachFlightsInfo?: Dispatch<SetStateAction<string>>
  setSelectedOptType?: Dispatch<SetStateAction<string>>
  style?: {}
};

type NavFooterOptTypeProps = {
  togglePopup: () => void
  setAttachFlightsInfo: Dispatch<SetStateAction<any>>
  setOptTypeSelectionOpen: Dispatch<SetStateAction<boolean>>
  setSelectedOptType: Dispatch<SetStateAction<string>>
  dispatch: (x: any) => void
  navigate: (x: any) => void
  modalSelectedOptType?: string
  selectedOptType?: string
};

const NavFooterOptType = ({ togglePopup, modalSelectedOptType, selectedOptType, setAttachFlightsInfo, setOptTypeSelectionOpen, setSelectedOptType, dispatch, navigate }: NavFooterOptTypeProps) => {
  const { reset } = useFormContext<WizardFormAttachFlights>();
  const ATTACH_FLIGHTS_OBJECT_LINK = `${WIZARD_BASE}${WIZARD_STEPS[WizardSteps.attachFlightsStep].subSteps.objects.id}`;
  const handleConfirmClick = () => {
    setSelectedOptType(_.isNull(selectedOptType) ? modalSelectedOptType : selectedOptType);
    reset(ATTACH_FLIGHTS_STEP_INITIAL_VALUES);
    setAttachFlightsInfo(attachFlightsInfoInitialState);
    dispatch({ type: RESET_GOALS_AND_STRATEGY_TYPE });
    setOptTypeSelectionOpen(false);
    navigate(ATTACH_FLIGHTS_OBJECT_LINK);
  };
  return (
    <>
      <WppButton
        variant="secondary"
        size="m"
        onClick={togglePopup}
        style={strategyWizardStyles.optTypeCancelButton}
      >
        Cancel
      </WppButton>
      <WppButton
        variant="primary"
        size="m"
        onClick={handleConfirmClick}
        disabled={!modalSelectedOptType}
      >
        Next
      </WppButton>
    </>
  );
};

const NavFooter = ({
  onNextClick,
  onBackClick,
  showBackButton,
  backButtonDisabled,
  showNextButton = true,
  nextButtonDisabled,
  strategyId,
  nextButtonText = 'Next',
  backButtonText = 'Back',
  selectedOptType,
  canAccessCrossPlatform,
  optTypeSelectionOpen,
  setOptTypeSelectionOpen,
  setAttachFlightsInfo,
  setSelectedOptType,
  style = {},
}: Props) => {
  const dispatch = useDispatch();
  const optTypeOptions = canAccessCrossPlatform ? OPTIMIZATION_TYPE_OPTIONS : _.omit(OPTIMIZATION_TYPE_OPTIONS, [OptimizationType.crossPlatform]);
  const defaultOptType = selectedOptType ?? _.head(_.keys(optTypeOptions)) as OptimizationType;
  const modalSelectedOptType = _.isNull(selectedOptType) ? defaultOptType : selectedOptType;
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const togglePopup = () => {
    setIsOpen(!isOpen);
  };
  const cancelBtnPath = document.referrer === `${window.location.origin}/strategies/${strategyId}` ? `/strategies/${strategyId}` : '/';

  return (
    <div style={{ ...strategyWizardStyles.navButtonContainerStyle, ...style }}>
      {!optTypeSelectionOpen && (
        <>
          {showBackButton && (
            <WppButton
              type="submit"
              style={strategyWizardStyles.backButtonStyle}
              id={strategyId ? STRATEGY_WIZARD_BUTTON_TAG.backButtonForEditStrategy : STRATEGY_WIZARD_BUTTON_TAG.backButtonForNewStrategy}
              disabled={backButtonDisabled}
              className="wizardNavBack"
              variant="secondary"
              onClick={onBackClick}
            >
              {backButtonText}
            </WppButton>
          )}
          {showNextButton && (
            <WppButton
              type="submit"
              id={strategyId ? STRATEGY_WIZARD_BUTTON_TAG.nextButtonForEditStrategy : STRATEGY_WIZARD_BUTTON_TAG.nextButtonForNewStrategy}
              disabled={nextButtonDisabled}
              onClick={onNextClick}
              style={{ ...strategyWizardStyles.nextButtonStyle, ...(nextButtonDisabled && { pointerEvents: 'none' }) }}
            >
              {nextButtonText}
            </WppButton>
          )}
        </>
      )}
      {optTypeSelectionOpen && (
        <>
          <NavFooterOptType
            togglePopup={togglePopup}
            modalSelectedOptType={modalSelectedOptType}
            selectedOptType={selectedOptType}
            setSelectedOptType={setSelectedOptType}
            setAttachFlightsInfo={setAttachFlightsInfo}
            setOptTypeSelectionOpen={setOptTypeSelectionOpen}
            dispatch={dispatch}
            navigate={navigate}
          />
          <ConfirmationPopup
            isOpen={isOpen}
            togglePopup={togglePopup}
            cancelBtnPath={cancelBtnPath}
          />
        </>
      )}
    </div>
  );
};

export default NavFooter;
