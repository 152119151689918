import _ from 'lodash';
import React from 'react';
import { Button, Divider, List } from 'buildingBlocks';
import { GenericMouseEventHandler } from 'utils/types';
import { BidTypeValue } from 'containers/StrategyWizard/ConfigurationByStrategyType/Helios/type';
import { BID_TYPES } from 'containers/StrategyWizard/ConfigurationByStrategyType/Helios/constants';
import { dragAndDropStyles } from '../style';
import { GroupBidValues } from '../SegmentPickerTypes';

type NonEditModeBarProps = {
  toggleEditGroupFields: GenericMouseEventHandler,
  enableItemReordering: boolean,
  groupName: string,
  groupBidValues: GroupBidValues,
  bidType: BidTypeValue,
};

const getKeyText = (key: string, bidType: BidTypeValue) => {
  if (key === 'maxDays') {
    return 'Attribution Window';
  }
  if (bidType === BID_TYPES.BID_MULTIPLIER) {
    return _.replace(key, 'Bid', 'Multiplier');
  }
  return key;
};

const NonEditModeBar = ({
  toggleEditGroupFields,
  enableItemReordering,
  groupName,
  groupBidValues,
  bidType,
}: NonEditModeBarProps) => (
  <List divided verticalAlign="middle">
    <List.Item>
      <List.Content floated="right">
        <Button
          style={{
            ...dragAndDropStyles.segmentGroup.btnIcon,
            position: 'relative',
            left: '17px',
            bottom: '11px',
          }}
          icon="edit"
          size="large"
          onClick={toggleEditGroupFields}
        />
      </List.Content>
      <List.Content>
        <div
          title={groupName}
          style={{
            ...dragAndDropStyles.groupName,
          }}
        >
          {groupName}
        </div>
        {enableItemReordering && (
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            {_.map(groupBidValues, (value: number, key: string) => {
              const keyValue = getKeyText(key, bidType);
              return (
                <small key={keyValue} style={dragAndDropStyles.segmentGroup.nonEditModeList}>
                  {`${_.startCase(keyValue)}:`} {value}
                </small>
              );
            })}
          </div>
        )}
      </List.Content>
      <Divider />
    </List.Item>
  </List>
);

export default NonEditModeBar;
