import React from 'react';
import { Controller } from 'react-hook-form';
import { WppSlider, WppTypography } from 'buildingBlocks';
import { PACING_MARKS, PACING_LABELS } from 'containers/StrategyWizard/ConfigurationByStrategyType/BudgetOptimization/constants';
import BAStyle from 'containers/StrategyWizard/ConfigurationByStrategyType/BudgetOptimization/style';

const { pacingSliderStyle, pacingSliderLableTextStyle } = BAStyle;

const PacingSlider = () => (
  <>
    <Controller
      name="dailyParentBudgetInflationRatio"
      render={({ field: { onChange, value = [0, 1.0] } }) => (
        <WppSlider
          type="single"
          className="slider-configure-step"
          style={pacingSliderStyle}
          continuous={false}
          step={0.1}
          min={0.5}
          max={1.5}
          value={value}
          marks={PACING_MARKS}
          onWppChange={onChange}
        />
      )}
    />
    <div style={pacingSliderLableTextStyle}>
      {PACING_LABELS.map((label) => (
        <WppTypography key={label} type="xs-body">
          {label}
        </WppTypography>
      ))}
    </div>
  </>
);

export default PacingSlider;
