import _ from 'lodash';
import React, { Dispatch, SetStateAction, useState, useEffect } from 'react';
import { useWatch } from 'react-hook-form';
import { createPortal } from 'react-dom';
import { WppSideModal, WppButton, WppInput, WppTypography, WppInlineMessage } from 'buildingBlocks';
import { WizardFormGoalSelection } from 'containers/StrategyWizard/types';
import { isEngScoreGoalType } from 'containers/StrategyWizard/ConfigurationByStrategyType/utils';
import { InputChangeEventDetail, Pixel, WppInputCustomEvent } from 'utils/types';
import CustomButton from './CustomButton';
import CategorySwitcher from './PixelPickerModal/CategorySwitcher';
import PixelTable from './PixelPickerModal/PixelTable';
import { DspToPixel, DspToPixelToWeightsType, PixelCategories } from './types';
import { PIXEL_PICKER_STYLES } from './styles';
import { getHeaderText, getTotalMatchingPixelCount } from './utils';

const { errorMessage } = PIXEL_PICKER_STYLES;

type PixelPickerModalProps = {
  pixels: DspToPixel
  defaultDspToPixelToWeights: DspToPixelToWeightsType
  goalType: string
  modalOpen: boolean
  setModalOpen: Dispatch<SetStateAction<boolean>>
  onChange: Function
};

const PixelPickerModal = (props: PixelPickerModalProps) => {
  const { pixels, defaultDspToPixelToWeights, goalType, modalOpen, setModalOpen, onChange } = props;
  const impValueFilters = useWatch<WizardFormGoalSelection>({ name: 'goal.impValueFilters' }) as DspToPixel; // pixels currently saved on the form

  const [selectedModalPixels, setSelectedModalPixels] = useState<DspToPixel>(impValueFilters);
  const [searchStr, setSearchStr] = useState<string>('');
  const [pixelCategory, setPixelCategory] = useState<PixelCategories>(PixelCategories.all);
  const [error, setError] = useState<string>('');

  useEffect(() => {
    // clear error every time user makes any changes in the modal
    setError('');
  }, [selectedModalPixels]);

  const isEngScore = isEngScoreGoalType(goalType);
  const numberSelected = _
    .chain(impValueFilters)
    .values()
    .flatten()
    .size()
    .value();

  const selectionValidated = () => {
    // check that at least one pixel is selected per dsp
    if (!_.every(_.keys(pixels), (dsp: string) => (selectedModalPixels[dsp] && (_.size(selectedModalPixels[dsp]) >= 1)))) {
      setError('You must select at least one pixel per platform.');
      return false;
    }
    // check that weights are configured for all selected pixels when goal type is engagement score
    if (isEngScore && !_.every(_.flatten(_.values(selectedModalPixels)), (p: Pixel) => _.isNumber(p.weight))) {
      setError('Please enter weightings for all selected pixels.');
      return false;
    }
    // check that all configured weights are greater than or equal to 0 for selected pixels when goal type is engagement score
    if (isEngScore && !_.every(_.flatten(_.values(selectedModalPixels)), (p: Pixel) => _.isNumber(p.weight) && p.weight >= 0)) {
      setError('Please enter a minimum weighting of 0 for all selected pixels.');
      return false;
    }
    return true;
  };

  const handleModalClose = () => {
    setSelectedModalPixels(impValueFilters);
    setSearchStr('');
    setModalOpen(false);
  };

  const handleConfirmClick = () => {
    if (selectionValidated()) {
      onChange(selectedModalPixels);
      setModalOpen(false);
    }
  };

  return (
    <>
      <CustomButton
        onClick={() => setModalOpen(true)}
        numberSelected={numberSelected}
        modalOpen={modalOpen}
      />
      {modalOpen
        && createPortal(
          <WppSideModal
            size="l"
            open={modalOpen}
            onWppSideModalClose={handleModalClose}
            className="configure-goal-side-model"
            disableOutsideClick
          >
            <WppTypography type="2xl-heading" tag="h3" slot="header">{getHeaderText(goalType)}</WppTypography>
            <div slot="body">
              <WppTypography className="wppTypogrphySegmentColor" type="s-body" tag="p" style={{ marginBottom: '10px' }}>Select and assign weights to the conversion pixels which contribute towards the strategy goal.</WppTypography>
              <WppInput
                id="pixel-picker-search"
                placeholder="Search by pixel name or ID"
                value={searchStr}
                type="search"
                size="s"
                onWppChange={(event: WppInputCustomEvent<InputChangeEventDetail>) => setSearchStr(event.detail.value)}
              />
              <CategorySwitcher
                pixelCategory={pixelCategory}
                setPixelCategory={setPixelCategory}
                allCount={getTotalMatchingPixelCount(pixels, searchStr)}
                selectedCount={getTotalMatchingPixelCount(selectedModalPixels, searchStr)}
              />
              <PixelTable
                isEngScore={isEngScore}
                pixelCategory={pixelCategory}
                searchStr={searchStr}
                pixels={pixels}
                selectedModalPixels={selectedModalPixels}
                setSelectedModalPixels={setSelectedModalPixels}
                defaultDspToPixelToWeights={defaultDspToPixelToWeights}
              />
              {error && (
                <WppInlineMessage size="s" message={error} type="error" style={errorMessage} />
              )}
            </div>
            <div slot="actions" style={{ display: 'flex', justifyContent: 'end' }}>
              <div>
                <WppButton
                  variant="secondary"
                  size="m"
                  style={{ marginRight: '10px' }}
                  onClick={handleModalClose}
                >
                  Cancel
                </WppButton>
                <WppButton
                  variant="primary"
                  size="m"
                  type="submit"
                  onClick={handleConfirmClick}
                >
                  Confirm
                </WppButton>
              </div>
            </div>
          </WppSideModal>,
          document.body,
        )}
    </>
  );
};

export default PixelPickerModal;
