import React, { useState } from 'react';
import _ from 'lodash';
import { WppSegmentedControl, WppSegmentedControlItem } from 'buildingBlocks';
import { COPILOT_LAYOUT } from 'globalStyles';
import {
  BID_LIST_STRING,
  VOLUME_CONTROL_BID_LIST_STRING,
  BidListValidity,
  BidListErrorArray,
  BidListStatus,
  PageStatusType,
  PageStatus,
  FromTabType,
  TabType,
  TabState,
  VolumeControlStatus,
} from './constants';
import { TranslationErrorsByField } from './context';
import TabComponent from './TabComponent';

const { SPACING } = COPILOT_LAYOUT;

const determineValidity = (pageStatus: PageStatusType, fromTabType: FromTabType, bidListErrors: BidListErrorArray) => {
  const flattenedPageStatus = fromTabType === TabType.BID_LIST_TAB
    ? _.get(pageStatus, 'bidListStatus', pageStatus)
    : _.get(pageStatus, 'volumeControlStatus', pageStatus);

  switch (flattenedPageStatus) {
    case BidListStatus.BidListsAreValid:
    case VolumeControlStatus.VolumeControlAreValid:
      return BidListValidity.Valid;
    case BidListStatus.NotYetValidated:
    case VolumeControlStatus.NotYetValidated:
      return BidListValidity.NotYetValidated;
    case BidListStatus.BidListsError:
    case VolumeControlStatus.VolumeControlError:
      return _.isEmpty(bidListErrors) ? BidListValidity.Valid : BidListValidity.Invalid;
    default:
      return BidListValidity.NotYetValidated;
  }
};

type TabsProps = {
  onValidateBidListClick: () => void,
  onBidListChange: (event: React.ChangeEvent, val: string) => void,
  onVolumeControlBidListChange: (event: React.ChangeEvent, val: string) => void,
  bidListString: string,
  volumeControlBidListString: string,
  pageStatus: PageStatusType,
  bidListErrors: BidListErrorArray,
  volumeControlErrors: BidListErrorArray,
  noBidListGeneratedWarning: string | null,
  noVcBidListGeneratedWarning: string | null,
  translationErrorsByField: TranslationErrorsByField,
};

const Tabs = ({
  onValidateBidListClick,
  onBidListChange,
  onVolumeControlBidListChange,
  bidListString,
  volumeControlBidListString,
  pageStatus,
  bidListErrors,
  volumeControlErrors,
  noBidListGeneratedWarning,
  noVcBidListGeneratedWarning,
  translationErrorsByField,
}: TabsProps) => {
  const bidListValidity = determineValidity(pageStatus, TabType.BID_LIST_TAB, bidListErrors);
  const vcBidListValidity = determineValidity(pageStatus, TabType.VOLUME_CONTROL_TAB, volumeControlErrors);
  const [tabState, setTabState] = useState<string>(TabState.BID_LIST_STATE);

  const shouldDisableBidListValidate = (
    pageStatus.bidListStatus === PageStatus.InitialPageStatus
      || pageStatus.bidListStatus === PageStatus.CsvHasError
      || pageStatus.bidListStatus === PageStatus.BidListsGenerating
      || pageStatus.bidListStatus === BidListStatus.BidListsAreValid
  );

  const shouldDisableVolumeControlValidate = (
    pageStatus.bidListStatus === PageStatus.InitialPageStatus
      || pageStatus.volumeControlStatus === PageStatus.CsvHasError
      || pageStatus.volumeControlStatus === PageStatus.VolumeControlGenerating
      || pageStatus.volumeControlStatus === VolumeControlStatus.VolumeControlAreValid
  );

  const shouldDisableBidListInput = (
    pageStatus.bidListStatus === PageStatus.CsvHasError
      || pageStatus.bidListStatus === PageStatus.BidListsGenerating
      || pageStatus.bidListStatus === BidListStatus.BidListsValidating
  );

  const shouldDisableVolumeControlInput = (
    pageStatus.volumeControlStatus === PageStatus.CsvHasError
      || pageStatus.volumeControlStatus === PageStatus.VolumeControlGenerating
      || pageStatus.volumeControlStatus === VolumeControlStatus.VolumeControlValidating
  );

  const shouldDisplayValidateButtonLoader = pageStatus.bidListStatus === BidListStatus.BidListsValidating;

  const shouldDisableValidateBidList = shouldDisableBidListValidate || bidListString.length === 0;
  const shouldDisableValidateVolumeControl = shouldDisableVolumeControlValidate || volumeControlBidListString.length === 0;

  return (
    <>
      <WppSegmentedControl style={{ marginBottom: SPACING[12] }} size="s" hugContentOff value={tabState}>
        <WppSegmentedControlItem
          value="BidList"
          onClick={() => setTabState(TabState.BID_LIST_STATE)}
        >
          Bid List
        </WppSegmentedControlItem>
        <WppSegmentedControlItem
          value="VolumeControl"
          onClick={() => setTabState(TabState.VOLUME_CONTROL_STATE)}
        >
          Volume Control
        </WppSegmentedControlItem>
      </WppSegmentedControl>
      {
        tabState === 'BidList' && (
          <TabComponent
            onChange={onBidListChange}
            onValidateBidListClick={onValidateBidListClick}
            bidListString={bidListString}
            errors={bidListErrors}
            id={BID_LIST_STRING}
            bidListValidity={bidListValidity}
            shouldDisableValidateBidList={shouldDisableValidateBidList}
            shouldDisableBidListInput={shouldDisableBidListInput}
            shouldDisplayValidateButtonLoader={shouldDisplayValidateButtonLoader}
            notGeneratedWarning={noBidListGeneratedWarning}
            translationErrorsByField={translationErrorsByField}
          />
        )
      }
      {
        tabState === 'VolumeControl' && (
          <TabComponent
            onChange={onVolumeControlBidListChange}
            onValidateBidListClick={onValidateBidListClick}
            bidListString={volumeControlBidListString}
            errors={volumeControlErrors}
            id={VOLUME_CONTROL_BID_LIST_STRING}
            bidListValidity={vcBidListValidity}
            shouldDisableValidateBidList={shouldDisableValidateVolumeControl}
            shouldDisableBidListInput={shouldDisableVolumeControlInput}
            shouldDisplayValidateButtonLoader={shouldDisplayValidateButtonLoader}
            notGeneratedWarning={noVcBidListGeneratedWarning}
            translationErrorsByField={translationErrorsByField}
          />
        )
      }
    </>
  );
};

export default Tabs;
