/* eslint-disable react-hooks/exhaustive-deps */
import _ from 'lodash';
import React, { Dispatch, SetStateAction, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useWatch, useFormContext } from 'react-hook-form';
import { GlobalState } from 'reducers';
import {
  WppActionButton, WppModal, WppButton, WppTypography,
  WppCard, WppIconEdit, WppIconTrash, WppDivider, WppGrid,
} from 'buildingBlocks';
import {
  BudgetGroupOptions,
  BudgetGroup as BudgetGroupType,
  ChildOptions,
  BudgetGroupLineItemMapping,
  BudgetInterval,
  GroupSettings,
} from 'containers/StrategyWizard/ConfigurationByStrategyType/BudgetOptimization/types';
import PopUpForm from 'containers/StrategyWizard/ConfigurationByStrategyType/BudgetOptimization/components/GroupSettings/BudgetGroup/components/PopUpForm/PopUpForm';
import { BudgetSetting, StrategyConfigurationStep, WizardFormValues } from 'containers/StrategyWizard/types';
import { getTotalDelivered } from 'containers/StrategyWizard/ConfigurationByStrategyType/BudgetOptimization/utils';
import { DEFAULT_MAX_ALLOCATION, DEFAULT_MIN_ALLOCATION } from 'containers/StrategyWizard/ConfigurationByStrategyType/BudgetOptimization/constants';
import { configuringCampaignStratCheck } from 'containers/StrategyWizard/steps/AttachFlights/utils';
import { BUDGET_MANAGEMENT_STYLES } from '../style';
import { displayDailyDelivery, displayLifetimeDelivery, getAllBudgetGroupLineItems, getAttachedLineItems, getFieldErrors } from '../utils';
import { AllocationRangeInput } from './components/AllocationRangeInput';
import BudgetOptimizationToggle from './BudgetOptimizationToggle';
import ObjectsAttachedCard from './components/ObjectsAttachedCard';

const {
  budgetGroup: {
    budgetGroupCardContainer, budgetGroupCard, cardInfoSection, deliverySection,
    individualDeliveryColumn, deleteModalFooterContainer, budgetGroupName,
  },
} = BUDGET_MANAGEMENT_STYLES;

type BudgetGroupProps = {
  groupedOptions: BudgetGroupOptions
  lifeTimeBudget: number
  budgetGroup: BudgetGroupType
  budgetGroupKey: string
  budgetGroupToLineItems: BudgetGroupLineItemMapping
  setBudgetGroupToLineItems: Dispatch<SetStateAction<BudgetGroupLineItemMapping>>
  requiredDailyValue: number
  budgetMetric: string
  remainingSpendDays: number
  interval: BudgetInterval | BudgetSetting
  allActiveChildOptions: ChildOptions
  budgetGroups: GroupSettings
  hasNMSPermission: boolean
};

const BudgetGroup = ({
  groupedOptions,
  lifeTimeBudget,
  budgetGroup,
  budgetGroupKey,
  budgetGroupToLineItems,
  setBudgetGroupToLineItems,
  requiredDailyValue,
  budgetMetric,
  remainingSpendDays,
  interval,
  allActiveChildOptions,
  budgetGroups,
  hasNMSPermission,
}: BudgetGroupProps) => {
  const { strategyConfigurationStep, attachFlightsStep: { attachedFlights } } = useSelector((state: GlobalState) => state.strategyWizard);

  const [bGroup, setBGroup] = useState<BudgetGroupType>(budgetGroup);
  const [deleteModelOpen, setDeleteModelOpen] = useState<boolean>(false);
  const [selectedLineItems, setSelectedLineItems] = useState<ChildOptions>({});
  const [lineItemsToDisplay, setLineItemsToDisplay] = useState<ChildOptions>({});
  const [isModalOpen, setModalStatus] = useState<boolean>(false);
  const { setValue } = useFormContext<StrategyConfigurationStep>();
  const intelligentChildObjectsEnabled = useWatch({ name: 'intelligentChildObjects' });

  const hasObjectsAttached = useRef<boolean>(!!_.size(budgetGroup.childExtIds));
  const lineItemFieldErrors = getFieldErrors(budgetGroupKey, 'childExtIds');
  const {
    attachFlightsStep: { selectedOptType },
    goalSelectionStep,
    budgetAllocationState,
  } = useSelector<GlobalState>((state) => state.strategyWizard) as WizardFormValues;

  const budgetData = _.get(budgetAllocationState, 'data');
  const budget = _.get(goalSelectionStep, 'budget');
  const isSinglePlatformStrategy = configuringCampaignStratCheck(selectedOptType);
  const shouldShowBudgetOptimizationToggle = isSinglePlatformStrategy && hasNMSPermission;

  const prevBudgetGroupSettings = _.get(strategyConfigurationStep, `groupSettings[${budgetGroupKey}]`);

  const defaultMinValue = _.get(prevBudgetGroupSettings, 'min', DEFAULT_MIN_ALLOCATION);
  const defaultMaxValue = _.get(prevBudgetGroupSettings, 'max', DEFAULT_MAX_ALLOCATION);
  const aggDelivery = _.get(budgetGroup, 'aggDelivery', 0);

  const watchForMinVal = useWatch({ name: (`groupSettings[${budgetGroupKey}].min` as const), defaultValue: defaultMinValue });
  const watchForMaxVal = useWatch({ name: (`groupSettings[${budgetGroupKey}].max` as const), defaultValue: defaultMaxValue });

  // in edge case when there is no cumData and less than 1 day remaining, use the lifetimeBudget
  const dailyValue = requiredDailyValue > lifeTimeBudget ? lifeTimeBudget : requiredDailyValue;
  const dailyDeliveryValue = displayDailyDelivery(dailyValue, watchForMinVal, watchForMaxVal, budgetMetric);
  const lifetimeDeliveryValue = displayLifetimeDelivery(requiredDailyValue, watchForMinVal, watchForMaxVal, budgetMetric, remainingSpendDays, aggDelivery);

  const handleDeleteBudgetGroup = () => {
    setBudgetGroupToLineItems(_.omit(budgetGroupToLineItems, budgetGroupKey));
    setValue('groupSettings', _.omit(budgetGroups, budgetGroupKey));
    setDeleteModelOpen(false);
  };

  const handleDeleteBudgetGroupModel = () => {
    setDeleteModelOpen(true);
  };

  const handleCloseDeleteModal = () => {
    setDeleteModelOpen(false);
  };

  const handleCloseModal = () => setModalStatus(false);
  const handleOpenModal = () => setModalStatus(true);

  useEffect(() => {
    // creates clone for each attached line item for single DSP flow
    if (_.size(allActiveChildOptions)) {
      const prevAttachedLineItems = getAttachedLineItems(budgetGroup.childExtIds, allActiveChildOptions);
      const lineItemObjsToDisplay = (intelligentChildObjectsEnabled && bGroup?.budgetOptimization) ? getAllBudgetGroupLineItems(attachedFlights, prevAttachedLineItems, budgetData) : prevAttachedLineItems;
      setSelectedLineItems(prevAttachedLineItems);
      setLineItemsToDisplay(lineItemObjsToDisplay);
    }
  }, [budgetGroup.budgetOptimization, budgetGroup.childExtIds, allActiveChildOptions, intelligentChildObjectsEnabled]);

  const saveBudgetGroupToGroupSettings = (updatedLineItems: ChildOptions, groupName: string, budgetOptimizationEnabled: boolean, min: number, max: number, allocationStrategy: string = null) => {
    const lineItemsToCalculateSpend = intelligentChildObjectsEnabled
      ? getAllBudgetGroupLineItems(attachedFlights, updatedLineItems, budgetData)
      : updatedLineItems;
    const aggDeliveryObj = getTotalDelivered(budgetData, [interval], budget, lineItemsToCalculateSpend);
    const aggregateDelivery = _.head(_.values(aggDeliveryObj)) ?? 0;
    const updatedBudgetGroups = {
      ...budgetGroups,
      [budgetGroupKey]: {
        ...budgetGroups[budgetGroupKey],
        childExtIds: _.map(updatedLineItems, 'extId'),
        aggDelivery: budgetOptimizationEnabled ? aggregateDelivery : 0, // manually set aggDelivery to 0 if bugetOpt is off
        budgetOptimization: budgetOptimizationEnabled,
        allocationStrategy,
        groupName,
        min,
        max,
      },
    };
    setValue('groupSettings', updatedBudgetGroups);
    setBudgetGroupToLineItems({ ...budgetGroupToLineItems, [budgetGroupKey]: updatedLineItems });
    setSelectedLineItems(updatedLineItems);
  };

  useEffect(() => {
    setBGroup({ ...budgetGroup });
  }, [budgetGroup]);

  return (
    <WppGrid item fullWidth>
      <WppCard style={budgetGroupCardContainer}>
        <div slot="header">
          <WppTypography type="m-strong"><h3 style={budgetGroupName} title={budgetGroup.groupName}>{budgetGroup.groupName}</h3></WppTypography>
        </div>
        <div style={budgetGroupCard}>
          <ObjectsAttachedCard
            lineItemsToDisplay={lineItemsToDisplay}
            handleOpenModal={handleOpenModal}
            hasObjectsAttached={hasObjectsAttached}
            lineItemFieldErrors={lineItemFieldErrors}
          />
          <div style={cardInfoSection}>
            <WppTypography type="s-strong">Allocation Range</WppTypography>
            <AllocationRangeInput budgetGroupKey={budgetGroupKey} budgetGroup={bGroup} disabled={!budgetGroup.budgetOptimization} />
          </div>
          <div style={deliverySection}>
            <div style={individualDeliveryColumn}>
              <WppTypography type="s-strong">Daily Delivery</WppTypography>
              <WppTypography type="s-body">{dailyDeliveryValue}</WppTypography>
            </div>
            <div style={individualDeliveryColumn}>
              <WppTypography type="s-strong">Lifetime Delivery</WppTypography>
              <WppTypography type="s-body">{lifetimeDeliveryValue}</WppTypography>
            </div>
          </div>
          {(shouldShowBudgetOptimizationToggle) && (
            <>
              <WppDivider alignment="horizontal" />
              <BudgetOptimizationToggle
                selectedLineItems={selectedLineItems}
                budgetGroup={budgetGroup}
                budgetGroupKey={budgetGroupKey}
              />
            </>
          )}
        </div>
        <div slot="actions">
          <WppActionButton>
            <WppIconEdit onClick={handleOpenModal} />
          </WppActionButton>
          <WppActionButton>
            <WppIconTrash onClick={handleDeleteBudgetGroupModel} />
          </WppActionButton>
          <PopUpForm
            groupedOptions={groupedOptions}
            selectedLineItems={selectedLineItems}
            budgetGroupToLineItems={budgetGroupToLineItems}
            budgetGroupKey={budgetGroupKey}
            allActiveChildOptions={allActiveChildOptions}
            hasObjectsAttached={hasObjectsAttached}
            saveBudgetGroupToGroupSettings={saveBudgetGroupToGroupSettings}
            isModalOpen={isModalOpen}
            handleCloseModal={handleCloseModal}
            shouldShowBudgetOptimizationToggle={shouldShowBudgetOptimizationToggle}
          />
          <WppModal
            size="s"
            open={deleteModelOpen}
          >
            <WppTypography type="xl-heading" slot="header">Deleting Budget Management Group</WppTypography>
            <WppTypography type="s-body" slot="body">Are you sure you want to delete this Budget Management group? This action cannot be undone.</WppTypography>
            <div slot="actions" style={deleteModalFooterContainer}>
              <WppButton variant="secondary" size="s" onClick={handleCloseDeleteModal}>Cancel</WppButton>
              <WppButton variant="destructive" size="s" onClick={handleDeleteBudgetGroup}>Delete</WppButton>
            </div>
          </WppModal>
        </div>
      </WppCard>
    </WppGrid>
  );
};

export default BudgetGroup;
