import { ALGORITHM_TYPE } from 'constantsBase';
import { BASE_MAPPER, baseBudgetOptInitializer } from '../mapper';

const algoTypeId = ALGORITHM_TYPE.dbmBudgetOptimization.id;

export const TASKS = [algoTypeId, ALGORITHM_TYPE.dbmTargetingPlus.id];

export const MAPPER = BASE_MAPPER(algoTypeId);

export const initializer = baseBudgetOptInitializer;
