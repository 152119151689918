import { DSP, STRATEGY_TYPE } from 'constantsBase';

/**
 * @type {string} The key in the airflow config at which we place Xandr
 * custom models.
 */
export const APN_CUSTOM_MODEL_HANDLER = 'apn_custom_model_handler';

/**
 * @type {string} The top-level key in the airflow config. All other mapped keys
 * are subkeys of this entry.
 */
export const DAG = 'global_dag_conf';

/**
 * @type {string} The key in the airflow config at which we place values meant
 * only for the UI. Airflow and the backend will completely ignore these values.
 */
export const DISPLAY_ONLY = 'display_only';

/**
 * @type {string} The redux-form name for the module's internal form. This MUST
 * be the same for all modules.
 */
export const FORM_NAME = 'module';

/**
 * @type {string} the key to get the advertiser external id from the mapping config
 */
export const ADVERTISER_EXTERNAL_ID_KEY = 'advertiserExternalId';

/**
 * @type {string} the key to get the member external id from the mapping config
 */
export const MEMBER_EXTERNAL_ID_KEY = 'memberExternalId';

export const VIEWABILITY_COMPATIBLE_STRATEGIES = [
  STRATEGY_TYPE.apnBudgetOptimization.id,
  STRATEGY_TYPE.ttdBudgetOptimization.id,
  STRATEGY_TYPE.dbmBudgetOptimization.id,
  STRATEGY_TYPE.helios.id,
  STRATEGY_TYPE.amznBudgetOptimization.id,
  STRATEGY_TYPE.wmtBudgetOptimization.id,
];

export const STRATEGY_TO_DSP_FOR_ATTRIBUTES = {
  [STRATEGY_TYPE.apnBudgetOptimization.id]: DSP.APN.id,
  [STRATEGY_TYPE.amznBudgetOptimization.id]: DSP.AMZN.id,
};

export const DEFAULT_WEIGHT = 10;

// supports the new conversion based goal infrastructure
export const weightsTableSupportedDsps = [
  DSP.TTD.id,
  DSP.DBM.id,
  DSP.WALMART.id,
  DSP.AMZN.id,
];

// supports the new conversion based goal infrastructure
export const newGoalInfrastructureStrategyTypes = [
  STRATEGY_TYPE.crossPlatformOptimization.id,
  STRATEGY_TYPE.ttdBudgetOptimization.id,
  STRATEGY_TYPE.dbmBudgetOptimization.id,
  STRATEGY_TYPE.dbmCustomSDF.id,
  STRATEGY_TYPE.wmtBudgetOptimization.id,
  STRATEGY_TYPE.amznBudgetOptimization.id,
];
