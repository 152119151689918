import _ from 'lodash';
import { DSP, GOAL_TYPES, STRATEGY_TYPE, XANDR_DISPLAY_NAME } from 'constantsBase';
import { FlightInfo, GoalType, LineItem, StrategyGoal, StrategyType, StrategyTypeEnumIdType } from 'utils/types';
import { STRATEGY_CONFIG_MAPPER } from './autoGeneratedFiles/strategyConfigMapper';

export const REMAINING_DAYS_DIVIDER = 12;
const RETARGETING_INDEX = 2;
const REMAINING_DAYS_INDEX = 7;
const DEFAULT_RETARGETING = '';

const TTD_DISPLAY_NAME = 'TTD';
const DBM_DISPLAY_NAME = 'DV360';
const AMZN_DISPLAY_NAME = 'AMZN';

const STRATEGY_TYPES_WITH_WILD_CARD_GOAL_TYPES = [
  'apnBudgetOptimization',
  'ttdBudgetOptimization',
  'dbmBudgetOptimization',
];

export enum OptimizationLevelInCSV {
  lineItem = 'lineItem',
  insertionOrder = 'insertionOrder',
  campaign = 'campaign',
  adGroup = 'adGroup',
  order = 'order',
}

export const getDisplayNameForDsp = (dsp?: number) => {
  if (dsp === DSP.TTD.id) {
    return TTD_DISPLAY_NAME;
  }
  if (dsp === DSP.DBM.id) {
    return DBM_DISPLAY_NAME;
  }
  if (dsp === DSP.AMZN.id) {
    return AMZN_DISPLAY_NAME;
  }
  return XANDR_DISPLAY_NAME;
};

export const buildWildCardStratConfigKey = (strategyTypeKey: string, strategyType: StrategyType) => {
  if (strategyType.dsp === DSP.APN.id) {
    if (strategyType.id === STRATEGY_TYPE.apnBudgetOptimization.id) {
      return `${XANDR_DISPLAY_NAME},${OptimizationLevelInCSV.insertionOrder},*,*,*,*,${strategyTypeKey}`;
    }
    return `${XANDR_DISPLAY_NAME},${OptimizationLevelInCSV.lineItem},*,*,*,*,${strategyTypeKey}`;
  }
  if (strategyType.dsp === DSP.TTD.id) {
    if (strategyType.id === STRATEGY_TYPE.ttdBudgetOptimization.id) {
      return `${TTD_DISPLAY_NAME},${OptimizationLevelInCSV.campaign},*,*,*,*,${strategyTypeKey}`;
    }
    return `${TTD_DISPLAY_NAME},${OptimizationLevelInCSV.adGroup},*,*,*,*,${strategyTypeKey}`;
  }
  if (strategyType.dsp === DSP.AMZN.id) {
    return `${AMZN_DISPLAY_NAME},${OptimizationLevelInCSV.order},*,*,*,*,${strategyTypeKey}`;
  }
  return `${DBM_DISPLAY_NAME},${OptimizationLevelInCSV.insertionOrder},*,*,*,*,${strategyTypeKey}`;
};

export const buildStratRecommendationKey = (
  goalTypeValue: string,
  retargeting: string,
  flight: FlightInfo,
  strategyTypeKey?: string,
  dsp: number = DSP.APN.id,
  optimizationLevel: string = OptimizationLevelInCSV.lineItem,
) => {
  let goalTypeDisplayName: string = _.get(GOAL_TYPES, `${goalTypeValue}.shortText`);

  if (_.includes(STRATEGY_TYPES_WITH_WILD_CARD_GOAL_TYPES, strategyTypeKey)) {
    goalTypeDisplayName = '*';
  }

  const {
    revenueType,
    revenueAuctionEventType,
  } = flight;

  const adType = flight.adTypes ? _.head(flight.adTypes) : null;

  let remainingDays = flight.remainingDays >= REMAINING_DAYS_DIVIDER
    ? `>=${REMAINING_DAYS_DIVIDER}`
    : `<${REMAINING_DAYS_DIVIDER}`;
  if (optimizationLevel === OptimizationLevelInCSV.insertionOrder
    || optimizationLevel === OptimizationLevelInCSV.campaign
    || optimizationLevel === OptimizationLevelInCSV.adGroup) {
    remainingDays = '';
  }

  const valuesToUseInStratTypeKey = [
    getDisplayNameForDsp(dsp),
    optimizationLevel,
    retargeting,
    goalTypeDisplayName,
    adType,
    revenueType,
    revenueAuctionEventType,
    remainingDays,
  ];

  if (strategyTypeKey) {
    const valuesToUseInConfigKey = valuesToUseInStratTypeKey.filter((_value, index) => (index !== RETARGETING_INDEX && index !== REMAINING_DAYS_INDEX));
    valuesToUseInConfigKey.push(strategyTypeKey);
    return _.join(valuesToUseInConfigKey, ',');
  }

  return _.join(valuesToUseInStratTypeKey, ',');
};

export const extractTextRightOfLastPeriod = (str: string) => _.last(_.split(str, '.'));

const formFieldsByKey = (config, flight) => (
  _(config)
    .mapValues((fn) => fn(flight))
    .mapKeys((_val, key) => extractTextRightOfLastPeriod(key))
    .value()
);

export const getStrategyConfigsForFlights = (strategyType: StrategyType, goalType: GoalType, flights: Array<FlightInfo>) => {
  const strategyTypeKey = _.findKey(STRATEGY_TYPE, { id: strategyType.id as StrategyTypeEnumIdType });
  const flightKeyObjs = _.map(flights, (f) => {
    const stratConfigKey = buildStratRecommendationKey(goalType.value, DEFAULT_RETARGETING, f, strategyTypeKey, strategyType.dsp);
    const wildCardStratConfigKey = buildWildCardStratConfigKey(strategyTypeKey, strategyType);
    return { stratConfigKey, wildCardStratConfigKey, externalId: f.externalId };
  });

  const flightKeyObjsByExtId = _.keyBy(flightKeyObjs, 'externalId');
  const configObjs = _.map(flightKeyObjsByExtId, (v, k) => {
    const stratConfig = STRATEGY_CONFIG_MAPPER[v.stratConfigKey];
    const config = !_.isEmpty(stratConfig) ? stratConfig : STRATEGY_CONFIG_MAPPER[v.wildCardStratConfigKey];
    return { config, externalId: k };
  });

  const flightsByExtId = _.keyBy(flights, 'externalId');
  return _.map(configObjs, (c) => formFieldsByKey(c.config, flightsByExtId[c.externalId]));
};

export const getMinConfiguration = (configs: Array<{}>) => _.mergeWith({}, ...configs, (x, y) => (x < y ? x : y));

export type GetStrategyConfigValuesType = (
  strategyGoals: Array<StrategyGoal>,
  strategyType: StrategyType,
  attachedFlights: Array<LineItem>,
  change: (k, v) => void,
) => void;

export const getStrategyConfigValues: GetStrategyConfigValuesType = (strategyGoals, strategyType, attachedFlights, change) => {
  // @ts-ignore
  const configs = getStrategyConfigsForFlights(strategyType, strategyGoals[0].type, attachedFlights);
  const minConfiguration = getMinConfiguration(configs);
  _.forEach(minConfiguration, (value, key) => change(key, value));
};
