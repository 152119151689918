import _ from 'lodash';
import React, { Dispatch, SetStateAction, useEffect } from 'react';
import { useWatch, useFormContext, Controller } from 'react-hook-form';
import { StrategyConfigurationStep } from 'containers/StrategyWizard/types';
import { BudgetGroup } from 'containers/StrategyWizard/ConfigurationByStrategyType/BudgetOptimization/types';
import { BUDGET_MANAGEMENT_STYLES } from 'containers/StrategyWizard/ConfigurationByStrategyType/BudgetOptimization/components/GroupSettings/style';
import WppInputWithLabel from 'buildingBlocks/WppInputWithLabel';
import { InputChangeEventDetail, WppInputCustomEvent } from 'utils/types';
import { setNumberValue } from 'buildingBlocks/WppInputWithLabel/utils';
import { getRealValue } from 'buildingBlocks/ReactHookFormComponents/PercentageInput';
import { POP_UP_FORM } from './PopUpForm/style';

const { budgetGroup: { allocationRangeContainer, dailyAllocationRangeStyle, allocationRangeDivider, minLabelWidth, maxLabelWidth } } = BUDGET_MANAGEMENT_STYLES;
const { allocationRangeContainer: popUpContainer } = POP_UP_FORM;

type AllocationRangeInputProps = {
  budgetGroupKey: string
  budgetGroup?: BudgetGroup
  isSideModalField?: boolean
  minValue?: number | string
  maxValue?: number | string
  setMinValue?: Dispatch<SetStateAction<number | string>>
  setMaxValue?: Dispatch<SetStateAction<number | string>>
  disabled?: boolean
};

export const AllocationRangeInput = (props: AllocationRangeInputProps) => {
  const {
    budgetGroupKey, isSideModalField = false, budgetGroup,
    minValue, maxValue, setMinValue, setMaxValue, disabled,
  } = props;
  const groupSettings = useWatch({ name: 'groupSettings' });
  const { trigger, control } = useFormContext<StrategyConfigurationStep>();
  const budgetGroupMin = _.get(groupSettings, `${budgetGroupKey}.min`);
  const budgetGroupMax = _.get(groupSettings, `${budgetGroupKey}.max`);

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      e.target.blur();
    }
  };

  useEffect(() => {
    trigger('groupSettings');
  }, [budgetGroupMin, budgetGroupMax, budgetGroup?.min, budgetGroup?.max, trigger]);

  return (
    <div style={isSideModalField ? _.omit({ ...allocationRangeContainer, width: '45%' }, 'justifyContent') : allocationRangeContainer}>
      {isSideModalField
        ? (
          <>
            <WppInputWithLabel
              label="Min (%)"
              type="decimal"
              style={popUpContainer}
              // need this to prevent new budget group from being added when user presses enter in AllocationRangeInput
              onKeyDown={handleKeyDown}
              onWppChange={(e: WppInputCustomEvent<InputChangeEventDetail>) => setMinValue(setNumberValue(e.detail.value, true))}
              value={_.isNumber(minValue) ? _.toString(getRealValue(minValue, 2)) : minValue}
              disabled={disabled}
              labelWidth={minLabelWidth}
              skipIsDirtyCheck
            />
            <div style={allocationRangeDivider}>&#8212;</div>
            <WppInputWithLabel
              label="Max (%)"
              type="decimal"
              style={popUpContainer}
              // need this to prevent new budget group from being added when user presses enter in AllocationRangeInput
              onKeyDown={handleKeyDown}
              onWppChange={(e: WppInputCustomEvent<InputChangeEventDetail>) => setMaxValue(setNumberValue(e.detail.value, true))}
              value={_.isNumber(maxValue) ? _.toString(getRealValue(maxValue, 2)) : maxValue}
              disabled={disabled}
              labelWidth={maxLabelWidth}
              skipIsDirtyCheck
            />
          </>
        )
        : (
          <>
            <Controller
              name={`groupSettings[${budgetGroupKey}].min` as `groupSettings.${string}.min`}
              control={control}
              render={({ field, fieldState }) => (
                <WppInputWithLabel
                  label="Min (%)"
                  type="decimal"
                  style={dailyAllocationRangeStyle}
                  // need this to prevent new budget group from being added when user presses enter in AllocationRangeInput
                  onKeyDown={handleKeyDown}
                  onWppChange={(e: WppInputCustomEvent<InputChangeEventDetail>) => field.onChange(setNumberValue(e.detail.value, true))}
                  value={_.isNumber(budgetGroup.min) ? _.toString(getRealValue(budgetGroup.min, 2)) : budgetGroup.min}
                  field={field}
                  fieldState={fieldState}
                  disabled={disabled}
                  labelWidth={minLabelWidth}
                  skipIsDirtyCheck
                />
              )}
            />
            <div style={{ ...allocationRangeDivider, fontSize: 24 }}>&#8212;</div>
            <Controller
              name={`groupSettings[${budgetGroupKey}].max` as `groupSettings.${string}.min`}
              control={control}
              render={({ field, fieldState }) => (
                <WppInputWithLabel
                  label="Max (%)"
                  type="decimal"
                  style={dailyAllocationRangeStyle}
                  // need this to prevent new budget group from being added when user presses enter in AllocationRangeInput
                  onKeyDown={handleKeyDown}
                  onWppChange={(e: WppInputCustomEvent<InputChangeEventDetail>) => field.onChange(setNumberValue(e.detail.value, true))}
                  value={_.isNumber(budgetGroup.max) ? _.toString(getRealValue(budgetGroup.max, 2)) : budgetGroup.max}
                  field={field}
                  fieldState={fieldState}
                  disabled={disabled}
                  labelWidth={maxLabelWidth}
                  skipIsDirtyCheck
                />
              )}
            />
          </>
        )}
    </div>
  );
};

export default AllocationRangeInput;
