import React from 'react';
import { WppTypography, Menu } from 'buildingBlocks';

type Props = {
  strategyName?: string
  isNewStrategy: boolean
};

const StrategyWizardHeader = ({ strategyName = '', isNewStrategy }: Props) => {
  const title = `Edit Strategy | ${strategyName}`;
  return (
    <Menu.Item fitted style={{ margin: 0, minWidth: 0, flexBasis: '85%' }}>
      <WppTypography tag="p" type="3xl-heading">{isNewStrategy ? 'Create Strategy' : title}</WppTypography>
    </Menu.Item>
  );
};

export default StrategyWizardHeader;
