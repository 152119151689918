/* eslint-disable no-param-reassign */
import _ from 'lodash';
import moment from 'moment';
import { AmznConversionEvents, STRATEGY_TYPE, ALGORITHM_TYPE, GOAL_TYPES, DSP, FLIGHT_EXTERNAL_TYPE } from 'constantsBase';
import { toCamelCase, toSnakeCase } from 'utils/formattingUtils';
import { numberOrUndefined } from 'utils/functionHelpers';
import {
  isSimplePassthrough,
  remap,
  reverseMappings,
  MappingConfigObj,
  PlainKeyMapping,
  StringMapping,
} from 'utils/airflow/nameTranslator';
import {
  Advertiser,
  Member,
  StrategyGoalDB,
  StrategyGoals,
  StrategyGoalsDB,
  Flight,
  StrategyConfig,
  GoalType,
  Pixel,
  Brand,
} from 'utils/types';
import { AppNexus, DBMLineItem, TTD, WMT } from 'utils/copilotAPI';
import { StrategyConfigurationStep, WizardFormGoalSelection, FlightCandidate, BudgetSetting, BudgetSettingDB, RevenueTypeConfig } from 'containers/StrategyWizard/types';
import { NCS_GOALS } from 'containers/StrategyWizard/constants';
import { DAG, DEFAULT_WEIGHT, newGoalInfrastructureStrategyTypes, STRATEGY_TO_DSP_FOR_ATTRIBUTES, VIEWABILITY_COMPATIBLE_STRATEGIES, weightsTableSupportedDsps } from 'containers/StrategyWizard/ConfigurationByStrategyType/constants';
import { CONVERSION_REVENUE_IMP_VALUE_BY_DSP } from 'containers/StrategyWizard/ConfigurationByStrategyType/roasUtils';
import { dateTimeFormatter } from 'utils/dateTime';
import {
  initializer as customTreeInitializer,
  MAPPER as ORIG_CUSTOM_TREE_MAPPER,
  EXCLUDED_FIELDS as CUSTOM_TREE_EXCLUDED_FIELDS,
  TASKS as CUSTOM_TREE_TASKS,
} from './CustomTree/mapper';
import {
  initializer as customBidListInitializer,
  MAPPER as ORIG_CUSTOM_BID_LIST_MAPPER,
  TASKS as CUSTOM_BID_LIST_TASKS,
} from './CustomBidList/mapper';
import {
  initializer as dbmCustomSDFInitializer,
  MAPPER as ORIG_DBM_CUSTOM_SDF_MAPPER,
  TASKS as DBM_CUSTOM_SDF_TASKS,
} from './CustomSDF/mapper';
import { initializer as heliosInitializer, TASKS as HELIOS_TASKS, MAPPER as ORIG_HELIOS_MAPPER } from './Helios/mapper';
import {
  initializer as apnBudgetOptimizationInitializer,
  MAPPER as ORIG_APN_BUDGET_OPTIMIZATION_MAPPER,
  TASKS as APN_BUDGET_OPTIMIZATION_TASKS,
} from './BudgetOptimization/ApnBudgetOptimization/mapper';
import {
  initializer as ttdBudgetOptimizationInitializer,
  MAPPER as ORIG_TTD_BUDGET_OPTIMIZATION_MAPPER,
  TASKS as TTD_BUDGET_OPTIMIZATION_TASKS,
} from './BudgetOptimization/TTDBudgetOptimization/mapper';
import {
  initializer as wmtBudgetOptimizationInitializer,
  MAPPER as ORIG_WMT_BUDGET_OPTIMIZATION_MAPPER,
  TASKS as WMT_BUDGET_OPTIMIZATION_TASKS,
} from './BudgetOptimization/WMTBudgetOptimization/mapper';
import {
  initializer as dbmBudgetOptimizationInitializer,
  MAPPER as ORIG_DBM_BUDGET_OPTIMIZATION_MAPPER,
  TASKS as DBM_BUDGET_OPTIMIZATION_TASKS,
} from './BudgetOptimization/DBMBudgetOptimization/mapper';
import {
  initializer as amznBudgetOptimizationInitializer,
  MAPPER as ORIG_AMZN_BUDGET_OPTIMIZATION_MAPPER,
  TASKS as AMZN_BUDGET_OPTIMIZATION_TASKS,
} from './BudgetOptimization/AMZNBudgetOptimization/mapper';
import {
  initializer as hsegrecInitializer,
  MAPPER as ORIG_HSEG_REC_MAPPER,
  EXCLUDED_FIELDS as HSEG_REC_EXCLUDED_FIELDS,
  TASKS as HELIOS_SEG_REC_TASKS,
} from './SegmentRecency/mapper';
import {
  initializer as crossPlatformOptimizationInitializer,
  MAPPER as ORIG_CROSS_PLATFORM_OPTIMIZATION_MAPPER,
  TASKS as CROSS_PLATFORM_OPTIMIZATION_TASKS,
  EXCLUDED_FIELDS as CROSS_PLATFORM_EXCLUDED_FIELDS,
} from './CrossPlatform/mapper';
import { ImpValueFiltersDb } from './Helios/type';
import { GroupSettings } from './BudgetOptimization/types';
import { DspToPixel } from '../components/PixelPicker/types';
import { CONVERSION_BASED_GOALS, RevenueTypeOutcomeOptions } from '../steps/GoalSelection/constants';
import { cyodIsEnabledCheck } from '../steps/StrategyConfirmation/utils';
import { OptimizationType } from '../steps/AttachFlights/constants';
import { configuringCrossPlatformStratCheck } from '../steps/AttachFlights/utils';
import { getSingleRevTypeAndValue, isNCSGoal } from '../utils';
import { isAWGOrImpact } from '../steps/GoalSelection/utils';

const TASKS = [DAG, 'id', 'name'];

const MODULE_PASSTHROUGH: [string, string] = ['(.*)', '(.*)'];
export const mergeConfig = (config: MappingConfigObj): MappingConfigObj => {
  const passthroughFromConfig: PlainKeyMapping = _.get(config, 'passthrough');
  const passthrough: PlainKeyMapping = [MODULE_PASSTHROUGH];
  if (_.isNil(passthroughFromConfig)) {
    return { ...config, passthrough };
  }

  // dirty casts to get this to work. Something is out of sync with the
  // isSimplePassthrough and the union of possible types from PlainKeyMapping.
  if (isSimplePassthrough(passthroughFromConfig)) {
    passthrough.unshift(passthroughFromConfig as StringMapping);
  } else {
    passthrough.unshift(...(passthroughFromConfig as Array<StringMapping>));
  }

  const newConfig = {
    ...config,
    passthrough: _.uniqBy(passthrough, (p) => p && p[0]),
  };

  return newConfig;
};

type Mapper = {
  config?: MappingConfigObj;
  mapping: Array<StringMapping>;
  createConfig: Function;
  analyzeMapping?: Array<StringMapping>;
};

export const DEFAULT_MAPPER = {
  config: {} as MappingConfigObj,
  mapping: [] as Array<StringMapping>,
  createConfig: () => ({}),
};

export const baseInitializer = (config, goalTypes: typeof GOAL_TYPES = GOAL_TYPES) => {
  const updatedConfig = { ...config };
  if (config.strategyGoals) {
    updatedConfig.strategyGoals = _.map(config.strategyGoals, (goal) => ({
      type: goalTypes[toCamelCase(goal.type)],
      target: goal.target,
      attributes: goal.attributes,
    }));
  }
  return updatedConfig;
};

const isXandr = (dspId: number) => (dspId === DSP.APN.id);

export const isEngScoreGoalType = (goalType: string) => _.includes([GOAL_TYPES.engagementScore.value, GOAL_TYPES.ncsNbEngagementScore.value], _.camelCase(goalType));

export const convertImpValueFiltersToDBForEngScore = (impValueFilters: Array<Pixel>, dspId?: number) => {
  if (!_.isEmpty(impValueFilters)) {
    const isXandrDSP = isXandr(dspId);
    return _.map(impValueFilters, (ivf) => ({
      filters: { pixel_id: [isXandrDSP ? _.toNumber(ivf.id) : ivf.id] },
      impression_value: ivf.weight,
    }));
  }
  return undefined;
};

const convertImpValueFiltersToDBForConvRev = (impValueFilters: Array<Pixel>, dspId: number) => {
  const impValue = CONVERSION_REVENUE_IMP_VALUE_BY_DSP[dspId];
  if (!_.isEmpty(impValueFilters)) {
    const isXandrDSP = isXandr(dspId);
    return _.map(impValueFilters, (ivf) => ({
      filters: { pixel_id: [isXandrDSP ? _.toNumber(ivf.id) : ivf.id] },
      impression_value: impValue[0].impression_value,
    }));
  }
  return impValue;
};

// some goal types store pixels in the strategyGoals table rather than in the DAG config
const storesPixelsOnStrategyGoals = (goalType: string, strategyTypeId: number) => _.includes([GOAL_TYPES.cpa.value, GOAL_TYPES.conversionRate.value], goalType)
  && _.includes(
    [
      STRATEGY_TYPE.helios.id,
      STRATEGY_TYPE.heliosSegmentRecency.id,
      STRATEGY_TYPE.customTree.id,
      STRATEGY_TYPE.customBidList.id,
    ],
    strategyTypeId,
  );

const shouldStorePixelsOnStrategyGoal = (goalType: string) => _.includes(
  [
    GOAL_TYPES.cpa.value,
    GOAL_TYPES.conversionRate.value,
    GOAL_TYPES.engagementScore.value,
    GOAL_TYPES.conversionRevenue.value,
    GOAL_TYPES.ncsNbCpa.value,
    GOAL_TYPES.ncsNbCvr.value,
    GOAL_TYPES.ncsNbEngagementScore.value,
    GOAL_TYPES.ncsNbRoas.value,
  ],
  goalType,
);

const getConversionPixelIds = (selectedPixels: Array<Pixel>, dsp: number) => _.map(selectedPixels, (px) => (isXandr(dsp) ? _.toNumber(px.id) : px.id));

export const updateAttributesOnStrategyGoalsForXNDRAndAMZN = (attributes, goalType, selectedPixels, dspId) => {
  if (!_.isEmpty(selectedPixels)) {
    switch (goalType) {
      case GOAL_TYPES.engagementScore.value:
        {
          const updatedAttributes = convertImpValueFiltersToDBForEngScore(selectedPixels, dspId);
          _.set(attributes, 'impValueFilters', updatedAttributes);
        }
        break;
      case GOAL_TYPES.conversionRevenue.value:
        _.set(attributes, 'impValueFilters', convertImpValueFiltersToDBForConvRev(selectedPixels, dspId));
        break;
      default:
      {
        const conversionPixelIds = getConversionPixelIds(selectedPixels, dspId);
        _.set(attributes, 'conversionPixelIds', conversionPixelIds);
      }
    }
    return attributes;
  }
  return attributes;
};

// populate the 'attributes' field in strategyGoals before saving a strategy to the database
const getAttributes = (attributes, goalType, selectedPixels: DspToPixel, strategyTypeId?: number) => {
  // configuration for cross-platform, ttd, dv360, and walmart campaign level opt strategies
  if (_.includes(newGoalInfrastructureStrategyTypes, strategyTypeId) || isNCSGoal(goalType)) {
    const dbFormatPixels = _.mapValues(selectedPixels, (p) => _.map(p, 'id'));
    const formatedPixels = isEngScoreGoalType(goalType)
      ? (
        _.mapValues(selectedPixels, (dspArray) => (
          _.chain(dspArray)
            .keyBy('id')
            .mapValues('weight')
            .value()
        ))
      ) : dbFormatPixels;
    return _.set(attributes, 'conversionPixelIds', formatedPixels);
  }
  // single platform campaign level opt strategy for xandr or amazon
  const dspId = STRATEGY_TO_DSP_FOR_ATTRIBUTES[strategyTypeId];
  if (_.includes(_.keys(STRATEGY_TO_DSP_FOR_ATTRIBUTES), _.toString(strategyTypeId))) {
    return updateAttributesOnStrategyGoalsForXNDRAndAMZN(attributes, goalType, _.get(selectedPixels, dspId), dspId);
  }
  // xandr helio/seg rec & ttd custom bid list
  if (storesPixelsOnStrategyGoals(goalType, strategyTypeId)) {
    const dsp = _.get(STRATEGY_TYPE.getById(strategyTypeId), 'dsp');
    const conversionPixelIds = getConversionPixelIds(_.get(selectedPixels, dsp), dsp);
    if (!_.isEmpty(conversionPixelIds)) {
      _.set(attributes, 'conversionPixelIds', conversionPixelIds);
    }
  }
  return attributes;
};

const getRevTypeStandardGoal = (goal: string, revenueOutcomeType: RevenueTypeOutcomeOptions, revTypeConfig: RevenueTypeConfig) => {
  if (!_.isEmpty(revTypeConfig) && !isAWGOrImpact(goal)) {
    return _.isEqual(revenueOutcomeType, RevenueTypeOutcomeOptions.single) ? `net_${getSingleRevTypeAndValue(revTypeConfig).revenueType}` : GOAL_TYPES.margin.value;
  }
  return goal;
};

// transform strategy goal values from what the form expects to what the database expects
export const transformStrategyGoals = (formValues: StrategyConfigurationStep, goalSelectionValues: WizardFormGoalSelection, strategyTypeId?: number) => {
  const { goal, budget, revenueOutcomeType } = goalSelectionValues;
  const type = goal.type;

  const primaryGoalObj = {
    type: getRevTypeStandardGoal(type, revenueOutcomeType, budget),
    target: numberOrUndefined(goal, 'target'),
  };

  let strategyGoals = [
    primaryGoalObj,
  ];
  if (_.get(formValues, 'viewability.enabled')) {
    strategyGoals = [...strategyGoals, {
      type: GOAL_TYPES.ivrMeasured.value,
      target: _.get(formValues, 'viewability.target'),
    }];
  }

  return _.map(strategyGoals, (g) => {
    const attributes = shouldStorePixelsOnStrategyGoal(g.type) && getAttributes(_.get(g, 'attributes', {}), g.type, goal.impValueFilters, strategyTypeId);
    return !_.isEmpty(attributes) ? { ...g, type: toSnakeCase(g.type), attributes } : { ...g, type: toSnakeCase(g.type) };
  });
};

// transform strategy budget settings from what the form expects to what the database expects
export const transformBudgetSettings = (budgetSettings: Array<BudgetSetting>) => (
  _.map(budgetSettings, (b) => _.transform(b, (result, value, key) => {
    if (key === 'budget') {
      result[key] = +value;
    } else if (key === 'type') {
      result[key] = 'amount';
    } else if (key === 'startDate' || key === 'endDate') {
      result[toSnakeCase(key)] = dateTimeFormatter.isoDate(value as Date);
    }
  }))
);

// transform strategy budget settings from DB to what the form expects
export const transformBudgetSettingsDB = (budgetSettings: Array<BudgetSettingDB>) => (
  _.map(budgetSettings, (b) => _.transform(b, (result, value, key) => {
    if (key === 'budget') {
      result[key] = +value;
    } else if (key === 'type') {
      result[key] = 'amount';
    } else if (key === 'start_date' || key === 'end_date') {
      result[toCamelCase(key)] = moment(value).toDate();
    } else if (key === 'startDate' || key === 'endDate') {
      // Querystring initialization
      result[key] = moment(value).toDate();
    }
  }))
);

const getAWGOrImpactGoal = (customGoal) => {
  if (!_.isEqual(customGoal.value, GOAL_TYPES.impactOutcome.value)) {
    return GOAL_TYPES.awgCreateYourOwn.value;
  }
  return customGoal.value;
};

export const getGoalTypeDB = (strategyStateData) => {
  const { strategyGoals, customGoal } = strategyStateData;
  const revTypeConfig = _.get(strategyStateData, 'revTypeConfig');
  const goalType = _.get(_.head(strategyGoals), 'type');

  if (revTypeConfig) {
    return _.size(customGoal) ? getAWGOrImpactGoal(customGoal) : GOAL_TYPES.margin.value;
  }

  return _.camelCase(goalType);
};

export const feedbackControlEnabledStrategies = [STRATEGY_TYPE.predictorALI.id];

export const isFeedbackControlEnabled = (formValues) => {
  const strategyGoals = _.get(formValues, 'strategyGoals');
  const goals = _.map(strategyGoals, 'type.value');
  const ivrMeasured = GOAL_TYPES.ivrMeasured.value;
  return _.includes(goals, ivrMeasured);
};

const modifyFormValues = (formValues) => {
  const { clientEventRevenueValue, revenueType, strategyGoals } = formValues;
  const goalTypeVal = _.get(_.first(strategyGoals), 'type');
  const isCyodEnabled = cyodIsEnabledCheck(goalTypeVal, formValues);
  const fieldsToOmit = [
    ...((_.isNil(clientEventRevenueValue) || !revenueType) ? ['clientEventRevenueValue', 'revenueType'] : []),
    ...(!isCyodEnabled ? ['cyodEnabled'] : []),
  ];
  return _.omit(formValues, fieldsToOmit);
};

export const getModifiedFormValuesForConfig = (formValues) => {
  const algoKeys = _.keys(formValues);
  let modifiedFormValues = modifyFormValues(formValues);
  const incIntelligentChildObj = _.get(modifiedFormValues, 'intelligentChildObjects');
  if (_.includes(algoKeys, `${ALGORITHM_TYPE.feedbackControl.id}`) && !isFeedbackControlEnabled(modifiedFormValues)) {
    modifiedFormValues = _.omit(modifiedFormValues, ALGORITHM_TYPE.feedbackControl.id);
  }
  if (_.includes(algoKeys, `${ALGORITHM_TYPE.xndrTargetingPlus.id}`) && !incIntelligentChildObj) {
    modifiedFormValues = _.omit(modifiedFormValues, ALGORITHM_TYPE.xndrTargetingPlus.id);
  }
  if (_.includes(algoKeys, `${ALGORITHM_TYPE.ttdTargetingPlus.id}`) && !incIntelligentChildObj) {
    modifiedFormValues = _.omit(modifiedFormValues, ALGORITHM_TYPE.ttdTargetingPlus.id);
  }
  if (_.includes(algoKeys, `${ALGORITHM_TYPE.wmtTargetingPlus.id}`) && !incIntelligentChildObj) {
    modifiedFormValues = _.omit(modifiedFormValues, ALGORITHM_TYPE.wmtTargetingPlus.id);
  }
  if (_.includes(algoKeys, `${ALGORITHM_TYPE.dbmTargetingPlus.id}`) && !incIntelligentChildObj) {
    const omitKeys = [
      ALGORITHM_TYPE.dbmTargetingPlus.id,
      'cpmLever',
      'cpcvLever',
      'minDeliveryFracLever',
      'thresholdBoundsLever',
    ];
    modifiedFormValues = _.omit(modifiedFormValues, omitKeys);
  }
  return _.omit(modifiedFormValues, 'prevSetIntelChildObj');
};

export type CreateConfigType<T> = (formValues: T, advertiser: Advertiser, member: { dsp: number }, brand: Brand, defaultCurrency: string, attachedFlights: Array<Flight>, updateAdminConfig: boolean, hasRevenueType?: boolean, hasExternalCustomValue?: boolean) => {};

export const createMapper = (mapper: Mapper = DEFAULT_MAPPER) => ({
  ...mapper,
  config: mergeConfig(mapper.config),
  createConfig: (formValues: StrategyConfigurationStep | StrategyConfig, advertiser: Advertiser, member: Member, brand: Brand, defaultCurrency: string, attachedFlights: Array<Flight>, updateAdminConfig: boolean, hasRevenueType?: boolean, hasExternalCustomValue?: boolean) => {
    const values = getModifiedFormValuesForConfig(formValues);
    return {
      // config values to transform for all strategy types
      ...values,
      currencyCode: defaultCurrency,
      ...(!_.isNil(advertiser) && { advertiserExternalId: advertiser.externalId }),
      ...(!_.isNil(member) && { memberExternalId: member.externalId }),
      ...(!_.isNil(brand) && { brandId: brand.id }),
      ...({ hasCustomRevenueType: hasRevenueType }),

      // additional config value transformations per strategy type
      ...mapper.createConfig(values, advertiser, member, brand, defaultCurrency, attachedFlights, updateAdminConfig, hasRevenueType, hasExternalCustomValue),
    };
  },
});

const HSEG_REC_MAPPER = createMapper(ORIG_HSEG_REC_MAPPER);
const CUSTOM_TREE_MAPPER = createMapper(ORIG_CUSTOM_TREE_MAPPER);
const HELIOS_MAPPER = createMapper(ORIG_HELIOS_MAPPER);
const CUSTOM_BID_LIST_MAPPER = createMapper(ORIG_CUSTOM_BID_LIST_MAPPER);
const APN_BUDGET_OPTIMIZATION_MAPPER = createMapper(ORIG_APN_BUDGET_OPTIMIZATION_MAPPER);
const TTD_BUDGET_OPTIMIZATION_MAPPER = createMapper(ORIG_TTD_BUDGET_OPTIMIZATION_MAPPER);
const WMT_BUDGET_OPTIMIZATION_MAPPER = createMapper(ORIG_WMT_BUDGET_OPTIMIZATION_MAPPER);
const DBM_BUDGET_OPTIMIZATION_MAPPER = createMapper(ORIG_DBM_BUDGET_OPTIMIZATION_MAPPER);
const AMZN_BUDGET_OPTIMIZATION_MAPPER = createMapper(ORIG_AMZN_BUDGET_OPTIMIZATION_MAPPER);
const DBM_CUSTOM_SDF_MAPPER = createMapper(ORIG_DBM_CUSTOM_SDF_MAPPER);
const CROSS_PLATFORM_OPTIMIZATION_MAPPER = createMapper(ORIG_CROSS_PLATFORM_OPTIMIZATION_MAPPER);

const stratTypeToInitializer = {
  [STRATEGY_TYPE.heliosSegmentRecency.id]: hsegrecInitializer,
  [STRATEGY_TYPE.customTree.id]: customTreeInitializer,
  [STRATEGY_TYPE.helios.id]: heliosInitializer,
  [STRATEGY_TYPE.customBidList.id]: customBidListInitializer,
  [STRATEGY_TYPE.apnBudgetOptimization.id]: apnBudgetOptimizationInitializer,
  [STRATEGY_TYPE.ttdBudgetOptimization.id]: ttdBudgetOptimizationInitializer,
  [STRATEGY_TYPE.wmtBudgetOptimization.id]: wmtBudgetOptimizationInitializer,
  [STRATEGY_TYPE.dbmBudgetOptimization.id]: dbmBudgetOptimizationInitializer,
  [STRATEGY_TYPE.amznBudgetOptimization.id]: amznBudgetOptimizationInitializer,
  [STRATEGY_TYPE.dbmCustomSDF.id]: dbmCustomSDFInitializer,
  [STRATEGY_TYPE.crossPlatformOptimization.id]: crossPlatformOptimizationInitializer,
} as const;

const TARGETING_PLUS_ALGO_IDS = [
  ALGORITHM_TYPE.xndrTargetingPlus.id,
  ALGORITHM_TYPE.dbmTargetingPlus.id,
  ALGORITHM_TYPE.ttdTargetingPlus.id,
  ALGORITHM_TYPE.wmtTargetingPlus.id,
];

/**
 * Checks the config to see if intelligentChildObjects is true or if
 * targeting plug algo id objects have enabled: true
 * @param config config from Strategy.configuration call
 * @returns boolean of whether strategy has intelligentChildObjects
 */
const hasIntelligentChildObjects = (config) => {
  const hasILI = _.get(config, 'intelligentChildObjects', false);
  const enabledKeys = _.pick(config, TARGETING_PLUS_ALGO_IDS);
  return _.some(enabledKeys, 'enabled') || hasILI;
};

/**
 * This is a helper for formatting groupSettings keys to format of second argument
 * @param groupSettings object of group settings
 * @returns object of group settings with formatted keys
 */
export const formatGroupSettings = (groupSettings: GroupSettings, formatFunc: Function) => _.mapValues(groupSettings, (budgetGroup) => _.mapKeys(budgetGroup, (_group, key) => formatFunc(key)));

/**
 * Takes groupSettings and inserts key of deactivateOptimization based on budgetOptimization. Also removes key of budgetOptimization
 * so the Admin Config and DB does not retain that key
 * @param groupSettings object of group settings
 * @returns object of group settings with key deactivateOptimization to be saved to DB
 */
export const convertGroupSettingsUiToDb = (groupSettings: GroupSettings) => _.mapValues(groupSettings, (budgetGroup) => {
  const newBudgetGroup = {
    ...budgetGroup,
    deactivateOptimization: Number(!budgetGroup.budgetOptimization),
  };
  _.unset(newBudgetGroup, 'budgetOptimization');
  return newBudgetGroup;
});

/**
 * Takes groupSettings and inserts key of budgetOptimization based on deactivateOptimization. Also removes key of deactivateOptimization
 * so the form on UI does not retain that key
 * @param groupSettings object of group settings
 * @returns object of group settings with key budgetOptimization to be used to populate form
 */
const convertGroupSettingsDbToUi = (groupSettings: GroupSettings) => _.mapValues(groupSettings, (budgetGroup) => {
  _.set(budgetGroup, 'budgetOptimization', !budgetGroup.deactivateOptimization);
  _.unset(budgetGroup, 'deactivateOptimization');
  return budgetGroup;
});

const MAIN_STRATEGY_GOAL_PRIORITY = 1;

/**
 * This is a helper for strategy wizard to be able to re-hydrate the config properly when the module is using an enum
 * as dropdown value
 * @param strategyTypeId
 * @param config
 * @returns {{}}
 */
export const advancedInitializedConfig = (strategyTypeId: number, config, dspId: number, strategyGoals?: StrategyGoalsDB) => {
  const isViewabiltyCompatibleStrat = _.includes(VIEWABILITY_COMPATIBLE_STRATEGIES, strategyTypeId);
  const hasViewabilityGoal = _.find(strategyGoals, { type: toSnakeCase(GOAL_TYPES.ivrMeasured.value) });
  const advancedConfig = _.get(stratTypeToInitializer, strategyTypeId, (cfg) => cfg)(config, strategyGoals, dspId);
  const groupSettings = _.get(advancedConfig, 'groupSettings');
  const hasMultiOutcomesAndValues = _.some(strategyGoals, ['type', GOAL_TYPES.margin.value]);
  const primaryGoal = _.size(strategyGoals) === 1 ? _.head(strategyGoals) : _.find(strategyGoals, ['priority', 1]);
  const goalType = primaryGoal && _.camelCase(primaryGoal.type);
  const isConvBasedGoal = _.includes(CONVERSION_BASED_GOALS, goalType);
  const isEngScore = isEngScoreGoalType(goalType);
  const isNcsGoal = _.includes(NCS_GOALS, goalType);
  if (groupSettings) {
    advancedConfig.groupSettings = convertGroupSettingsDbToUi(formatGroupSettings(groupSettings, toCamelCase) as GroupSettings);
    _.unset(advancedConfig, 'targetingPlusGroupSettings'); // will be reset when initializing admin config
  }
  // update impValueFilters for cross-platfrom, ttd, dv360, or walmart strategies with conversion based goals
  if (isConvBasedGoal && (_.includes(newGoalInfrastructureStrategyTypes, strategyTypeId) || isNcsGoal)) {
    const attributes = _(strategyGoals)
      .chain()
      .find(({ goal, priority }) => (!_.isNil(goal) && _.isEqual(priority, MAIN_STRATEGY_GOAL_PRIORITY)))
      .get('attributes')
      .value();
    advancedConfig.impValueFilters = !isEngScore
      ? _.mapValues(attributes.conversionPixelIds, (ids) => _.map(ids, (id) => ({ id })))
      : attributes;
  }

  if (isViewabiltyCompatibleStrat && hasViewabilityGoal) {
    return {
      ...advancedConfig,
      intelligentChildObjects: !hasMultiOutcomesAndValues && hasIntelligentChildObjects(advancedConfig),
      viewability: {
        enabled: true,
        type: GOAL_TYPES.ivrMeasured,
        target: hasViewabilityGoal.target,
      },
    };
  }

  return {
    ...advancedConfig,
    intelligentChildObjects: !hasMultiOutcomesAndValues && hasIntelligentChildObjects(advancedConfig),
    viewability: {
      enabled: false,
    },
  };
};

/**
 * get the airflow mapper for the strategy type id specified
 */
export const getStrategyTypeMapperById = (strategyTypeId: number) => {
  switch (strategyTypeId) {
    case STRATEGY_TYPE.heliosSegmentRecency.id:
      return HSEG_REC_MAPPER;
    case STRATEGY_TYPE.customTree.id:
      return CUSTOM_TREE_MAPPER;
    case STRATEGY_TYPE.helios.id:
      return HELIOS_MAPPER;
    case STRATEGY_TYPE.customBidList.id:
      return CUSTOM_BID_LIST_MAPPER;
    case STRATEGY_TYPE.apnBudgetOptimization.id:
      return APN_BUDGET_OPTIMIZATION_MAPPER;
    case STRATEGY_TYPE.ttdBudgetOptimization.id:
      return TTD_BUDGET_OPTIMIZATION_MAPPER;
    case STRATEGY_TYPE.wmtBudgetOptimization.id:
      return WMT_BUDGET_OPTIMIZATION_MAPPER;
    case STRATEGY_TYPE.dbmBudgetOptimization.id:
      return DBM_BUDGET_OPTIMIZATION_MAPPER;
    case STRATEGY_TYPE.amznBudgetOptimization.id:
      return AMZN_BUDGET_OPTIMIZATION_MAPPER;
    case STRATEGY_TYPE.dbmCustomSDF.id:
      return DBM_CUSTOM_SDF_MAPPER;
    case STRATEGY_TYPE.crossPlatformOptimization.id:
      return CROSS_PLATFORM_OPTIMIZATION_MAPPER;
    default:
      return null;
  }
};

// Prevent the mapper to send them to the backend / admin tools won't show it either
export const getStrategyTypeExcludedFieldsById = (strategyTypeId) => {
  switch (strategyTypeId) {
    case STRATEGY_TYPE.heliosSegmentRecency.id:
      return HSEG_REC_EXCLUDED_FIELDS;
    case STRATEGY_TYPE.customTree.id:
      return CUSTOM_TREE_EXCLUDED_FIELDS;
    case STRATEGY_TYPE.crossPlatformOptimization.id:
      return CROSS_PLATFORM_EXCLUDED_FIELDS;
    default:
      return [];
  }
};

/**
 * get the tasks (aka algo's) associated with each strategy type for the config that gets saved to the DB.
 * each strategy type has a primary task and may define additional tasks, ie for strategy types which run multiple algos
 * @param strategyTypeId number
 */
export const getStrategyTypeTasksById = (strategyTypeId: number) => {
  const strategyTypeName = STRATEGY_TYPE.getNameById(strategyTypeId);
  const algorithmType = ALGORITHM_TYPE[strategyTypeName];
  switch (strategyTypeId) {
    case STRATEGY_TYPE.helios.id:
      return HELIOS_TASKS;
    case STRATEGY_TYPE.heliosSegmentRecency.id:
      return HELIOS_SEG_REC_TASKS;
    case STRATEGY_TYPE.customTree.id:
      return CUSTOM_TREE_TASKS;
    case STRATEGY_TYPE.customBidList.id:
      return CUSTOM_BID_LIST_TASKS;
    case STRATEGY_TYPE.apnBudgetOptimization.id:
      return APN_BUDGET_OPTIMIZATION_TASKS;
    case STRATEGY_TYPE.ttdBudgetOptimization.id:
      return TTD_BUDGET_OPTIMIZATION_TASKS;
    case STRATEGY_TYPE.wmtBudgetOptimization.id:
      return WMT_BUDGET_OPTIMIZATION_TASKS;
    case STRATEGY_TYPE.dbmBudgetOptimization.id:
      return DBM_BUDGET_OPTIMIZATION_TASKS;
    case STRATEGY_TYPE.amznBudgetOptimization.id:
      return AMZN_BUDGET_OPTIMIZATION_TASKS;
    case STRATEGY_TYPE.dbmCustomSDF.id:
      return DBM_CUSTOM_SDF_TASKS;
    case STRATEGY_TYPE.crossPlatformOptimization.id:
      return CROSS_PLATFORM_OPTIMIZATION_TASKS;
    default:
      if (algorithmType) {
        return _.map(TASKS, (task) => `${algorithmType.id}.${task}`);
      }
      return TASKS;
  }
};

/**
 * maps keys and values of the config retrieved from the database into the form expected by the UI.
 * @param strategyConfig the config from the database
 * @param strategyTypeId the id of the strategy type
 * @param strategyGoals the strategyGoals value from the database
 */
export const mapConfigFromDbToUi = (strategyConfig, strategyTypeId: number, dspId: number, strategyGoals?: Array<StrategyGoalDB>) => {
  const mappedConfig = remap(reverseMappings(getStrategyTypeMapperById(strategyTypeId)), strategyConfig);
  if (!_.isEmpty(strategyGoals)) {
    const updatedStrategyGoals = _(strategyGoals)
      .sortBy('priority')
      .map((goal) => _.pick(goal, ['type', 'target', 'attributes']))
      .value();
    _.set(mappedConfig, 'strategyGoals', updatedStrategyGoals);
  }
  return advancedInitializedConfig(strategyTypeId, mappedConfig, dspId, strategyGoals);
};

const pixelSupportedDSPs = [
  DSP.APN.id,
  DSP.TTD.id,
  DSP.DBM.id,
  DSP.WALMART.id,
  DSP.AMZN.id,
];

export const supportsPixelSelect = (goalType: string, selectedOptType: OptimizationType, dsp: number) => (
  _.includes(CONVERSION_BASED_GOALS, goalType) && (configuringCrossPlatformStratCheck(selectedOptType) || _.includes(pixelSupportedDSPs, dsp))
);

const getLineItemExtIds = async (attachedFlights: Array<FlightCandidate>): Promise<Array<string | number>> => {
  if (!attachedFlights.length) {
    return [];
  }
  const { externalType, member } = _.first(attachedFlights);
  if (externalType === FLIGHT_EXTERNAL_TYPE.apnInsertionOrder.id) {
    const ioData = await AppNexus.ioById(member, _.map(attachedFlights, 'externalId'));
    return _.flatMap(ioData.data.response, ({ line_items: lis }) => _.map(lis, 'id'));
  }
  return _.map(attachedFlights, 'externalId');
};

// load pixels directly from appnexus line item service for a given list of line item ext ids
export const loadPixelsAPN = async (
  memberId: number,
  attachedFlights: Array<FlightCandidate>,
): Promise<Array<Pixel>> => {
  const lineItemExtIds = await getLineItemExtIds(attachedFlights);
  // this guard is necessary because if you supply an empty list to APN, they attempt to return ALL lineitems
  if (_.isEmpty(lineItemExtIds)) {
    // eslint-disable-next-line no-promise-executor-return
    return new Promise((r) => r([]));
  }
  const aliRes = await AppNexus.lineItem(memberId, lineItemExtIds);
  return _.chain(aliRes)
    .get('data.response.lineItem')
    // @ts-ignore - lineItem response type is Array<{ pixel, goal_pixels }>
    .flatMapDeep(({ pixels: p, goal_pixels: gp }) => [p, gp])
    .compact()
    .uniqBy('id')
    .map((p: { id: string, name: string }) => ({ id: p.id, name: p.name }))
    .value();
};

// get pixels (tracking tags, as they are known in TTD land) by TTDCampaign. As per product, we want only tracking tags
// with CrossDeviceAttributionModelId == NONE and (TrackingTagCategory == 'StaticTag' or TrackingTagCategory == 'UniversalPixelTag')
export const loadPixelsTTD = async (memberExtId: string, attachedFlights: Array<Flight>): Promise<Array<Pixel>> => {
  const campaignExtIds = _.map(attachedFlights, ({ externalType, parentExternalId, externalId }) => (externalType === FLIGHT_EXTERNAL_TYPE.ttdCampaign.id ? externalId : parentExternalId));
  const cpns = await TTD.campaign(memberExtId, campaignExtIds);
  const trackingTagIds = _(cpns).flatMap('CampaignConversionReportingColumns').map('TrackingTagId').value();
  const tts = await TTD.trackingTag(memberExtId, trackingTagIds);
  return _.map(tts, (p) => ({ id: p.TrackingTagId, name: p.TrackingTagName }));
};

export const loadXandrPixels = async (attachedXandrFlights: Array<FlightCandidate>) => {
  try {
    const memberIdToFlights = _.groupBy(attachedXandrFlights, 'member');
    const promises = _.map(
      memberIdToFlights,
      (attachedFlights: Array<FlightCandidate>, memIdStr: string) => loadPixelsAPN(_.toNumber(memIdStr), attachedFlights),
    );
    const promisesResp = await Promise.all(promises);
    // ensure that pixel ids are always strings
    return _.chain(promisesResp)
      .flatten()
      .uniqBy('id')
      .map((p: Pixel) => ({ ...p, id: _.toString(p.id) }))
      .sortBy('id')
      .value();
  } catch (e) {
    console.error(e);
    return 'Error fetching Xandr Pixels';
  }
};

export const loadTTDPixels = async (attachedTTDFlights: Array<Flight>) => {
  try {
    const memberExtIdToFlights = _.groupBy(attachedTTDFlights, 'memExtId');
    const promises = _.map(
      memberExtIdToFlights,
      (attachedFlights: Array<Flight>, memExtId: string) => loadPixelsTTD(memExtId, attachedFlights),
    );
    const promisesResp = await Promise.all(promises);
    return _.chain(promisesResp)
      .flatten()
      .uniqBy('id')
      .sortBy('id')
      .value();
  } catch (e) {
    console.error(e);
    return 'Error fetching TTD Pixels';
  }
};

export const loadDV360Pixels = async (attachedDV360Flights: Array<Flight>) => {
  try {
    const uniqAdvAndMemIdsWithExtIds = _(attachedDV360Flights)
      .groupBy(({ member, advertiser }) => `${member}-${advertiser}`)
      .map((flights: Array<Flight>) => {
        const firstFlight = _.head(flights);
        return {
          member: firstFlight.member,
          advertiser: firstFlight.advertiser,
          externalIds: _.map(flights, 'externalId'),
        };
      })
      .value();

    const promises = _.map(
      uniqAdvAndMemIdsWithExtIds,
      async (memAdvExtIdsObj) => {
        const floodlightActivityIds = await DBMLineItem.conversionFloodlightActivityIds(memAdvExtIdsObj);
        const pixels = _.map(floodlightActivityIds.data, (p) => JSON.parse(p.conversionFloodlightActivityIds));
        return _.flatten(pixels);
      },
    );
    const promisesResp = await Promise.all(promises); // numbered pixelIds are returned as strings
    return _.chain(promisesResp)
      .flatten()
      .uniq()
      .map((pixel: string) => ({ id: pixel }))
      .sortBy('id')
      .value();
  } catch (e) {
    console.error(e);
    return 'Error fetching DV360 Pixels';
  }
};

export const loadAmznConversionEvents = () => _.sortBy(_.map(AmznConversionEvents, (id) => ({ id })));

export const loadPixelsWMT = async (memberExtId: string, attachedFlights: Array<Flight>): Promise<Array<Pixel>> => {
  const campaignExtIds = _.map(attachedFlights, ({ externalType, parentExternalId, externalId }) => (externalType === FLIGHT_EXTERNAL_TYPE.wmtCampaign.id ? externalId : parentExternalId));
  const cpns = await WMT.campaign(memberExtId, campaignExtIds);
  const trackingTagIds = _(cpns).flatMap('CampaignConversionReportingColumns').map('TrackingTagId').value();
  const tts = await WMT.trackingTag(memberExtId, trackingTagIds);
  return _.map(tts, (p) => ({ id: p.TrackingTagId, name: p.TrackingTagName }));
};

export const loadWMTPixels = async (attachedWMTFlights: Array<Flight>) => {
  try {
    const memberExtIdToFlights = _.groupBy(attachedWMTFlights, 'memExtId');
    const promises = _.map(
      memberExtIdToFlights,
      (attachedFlights: Array<Flight>, memExtId: string) => loadPixelsWMT(memExtId, attachedFlights),
    );
    const promisesResp = await Promise.all(promises);
    return _.chain(promisesResp)
      .flatten()
      .uniqBy('id')
      .sortBy('id')
      .value();
  } catch (e) {
    console.error(e);
    return 'Error fetching WALMART Pixels';
  }
};

const dspToLoadPixelEndpoints = {
  [DSP.APN.id]: loadXandrPixels,
  [DSP.TTD.id]: loadTTDPixels,
  [DSP.DBM.id]: loadDV360Pixels,
  [DSP.AMZN.id]: loadAmznConversionEvents,
  [DSP.WALMART.id]: loadWMTPixels,
};

export const loadDspPixels = async (attachedFlights: Array<Flight>) => {
  const dspToFlights = _.groupBy(attachedFlights, 'dsp');
  const dspToPixels = {};
  const promises = _.map(
    dspToFlights,
    async (flights: Array<Flight>, dsp: number) => {
      const pixels = await dspToLoadPixelEndpoints[dsp](flights);
      dspToPixels[dsp] = pixels;
    },
  );
  await Promise.all(promises);
  return dspToPixels;
};

const getGoalTypeValue = (goal: { type: string | GoalType }) => _.camelCase(_.get(goal, 'type.value', goal.type) as string);

export const getPrimaryGoalType = (strategyGoals: StrategyGoals | StrategyGoalsDB) => {
  if (!strategyGoals) {
    return undefined;
  }
  const goal = strategyGoals[0];
  return getGoalTypeValue(goal);
};

export const getNonIVRGoalType = (strategyGoals: Array<{ type: string | GoalType }>) => {
  if (!strategyGoals) {
    return undefined;
  }
  return _.chain(strategyGoals)
    .map((sg) => ({ ...sg, type: getGoalTypeValue(sg) }))
    .filter((sg) => sg.type !== GOAL_TYPES.ivrMeasured.value)
    .map('type')
    .first()
    .value();
};

export const isGoalTypeEngagementScore = (strategyGoals: StrategyGoals) => getNonIVRGoalType(strategyGoals) === GOAL_TYPES.engagementScore.value;

export const convertImpValueFiltersToPixelIdsForEngScore = (ivfs: ImpValueFiltersDb, dspId: number) => (
  { [dspId]: _.map(ivfs, ({ filters, impression_value: iv }) => ({ id: _.toString(_.head(_.get(filters, 'pixel_id'))), weight: iv })) }
);

export const convertImpValueFiltersToPixelIdsForConvRev = (ivfs: ImpValueFiltersDb, dspId: number) => {
  const filters = _.map(ivfs, 'filters');
  const pixelIds = _.flatMap(filters, (f) => _.get(f, 'pixel_id', []));
  return { [dspId]: _.map(pixelIds, (id) => ({ id: _.toString(id) })) };
};

// format pixels from [pixelId1,...] to { dspId: [{ id: pixelId }] }
const formatPixelsForUI = (pixels: Array<any>, isCrossPlatform: boolean, dspId: number) => {
  if (isCrossPlatform) {
    return _.reduce(_.head(pixels), (acc, pxls, dsp) => {
      acc[dsp] = _.map(pxls, (p) => ({ id: _.toString(p) }));
      return acc;
    }, {});
  }
  return { [dspId]: _.map(pixels, (p) => ({ id: _.toString(p) })) };
};

// get pixel ids from either the dag config or the strategyGoals depending on the goal type
export const initializePixels = (config: { impValueFilters?: ImpValueFiltersDb }, strategyGoals: StrategyGoalsDB, dspId: number) => {
  const isCrossPlatform = _.has(config, _.toString(STRATEGY_TYPE.crossPlatformOptimization.id));
  const nonIVRGoal = getNonIVRGoalType(strategyGoals);
  switch (nonIVRGoal) {
    // for these goal types, pixels are set on the strategyGoals
    case GOAL_TYPES.cpa.value:
    case GOAL_TYPES.ncsNbCpa.value:
    case GOAL_TYPES.ncsNbCvr.value:
    case GOAL_TYPES.conversionRate.value: {
      const pids = _.flatMap(strategyGoals, 'attributes.conversionPixelIds');
      const impValueFilters = _.reject(pids, _.isNil);
      return {
        ...config,
        impValueFilters: formatPixelsForUI(impValueFilters, isCrossPlatform, dspId),
      };
    }
    // for these goal types, pixels are set on the dag config under 'impValueFilters'
    case GOAL_TYPES.ncsNbRoas.value:
    case GOAL_TYPES.ncsNbEngagementScore.value:
    case GOAL_TYPES.conversionRevenue.value:
    case GOAL_TYPES.engagementScore.value:
      if (config.impValueFilters && !isCrossPlatform) {
        const impValueFilters = _.isEqual(nonIVRGoal, GOAL_TYPES.conversionRevenue.value)
          ? convertImpValueFiltersToPixelIdsForConvRev(config.impValueFilters, dspId)
          : convertImpValueFiltersToPixelIdsForEngScore(config.impValueFilters, dspId);
        return {
          ...config,
          impValueFilters,
        };
      }
      return config;
    default:
      return config;
  }
};

// update the config with pixels before it gets mapped to database format
export const storePixelsOnConfig = (config, formValues, member: { dsp: number }, isCrossPlatform = false) => {
  if (isCrossPlatform || _.includes(weightsTableSupportedDsps, member.dsp)) {
    return config;
  }

  const dsp = member ? member.dsp : DSP.MULTIPLE.id;
  const { impValueFilters, strategyGoals } = formValues;
  const primaryGoalType = getNonIVRGoalType(strategyGoals);
  switch (primaryGoalType) {
    case GOAL_TYPES.ncsNbEngagementScore.value:
    case GOAL_TYPES.engagementScore.value:
      return {
        ...config,
        impValueFilters: convertImpValueFiltersToDBForEngScore(_.get(impValueFilters, dsp), dsp),
      };
    case GOAL_TYPES.ncsNbRoas.value:
    case GOAL_TYPES.conversionRevenue.value:
      return {
        ...config,
        impValueFilters: convertImpValueFiltersToDBForConvRev(_.get(impValueFilters, dsp), dsp),
      };
    default:
      return { ...config, impValueFilters: undefined };
  }
};

export const getDefaultDspToPixelWeights = (availablePixels: DspToPixel, selectedPixels: DspToPixel) => {
  const dspToPixelWeights = {};
  _.forEach(availablePixels, (pixels: Array<Pixel>, dsp) => {
    // fetching error OR no pixels available
    if (_.isString(pixels) || _.isEmpty(pixels)) {
      dspToPixelWeights[dsp] = {};
      return;
    }
    const selectedDspPixels = _.get(selectedPixels, dsp, []);
    dspToPixelWeights[dsp] = _.fromPairs(_.map(pixels, (p) => {
      const selectedPixel = _.find(selectedDspPixels, ['id', p.id]);
      // use the configured weight if the pixel is already on the form, otherwise use the default weight
      return [p.id, selectedPixel?.weight || DEFAULT_WEIGHT];
    }));
  });
  return dspToPixelWeights;
};
