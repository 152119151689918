import React from 'react';
import { WppModal, WppButton } from '@wppopen/components-library-react';
import { useNavigate } from 'react-router';
import strategyWizardStyles from '../styles';

type Props = {
  isOpen: boolean,
  togglePopup: Function,
  cancelBtnPath: string,
};

const ConfirmationPopup = (props: Props) => {
  const { isOpen, togglePopup, cancelBtnPath } = props;
  const navigate = useNavigate();
  return (
    <WppModal open={isOpen}>
      <h3 slot="header">Leave Page</h3>
      <p slot="body">Leaving now will discard all changes you have made.</p>
      <div slot="actions" style={strategyWizardStyles.cancelPopupActionContainer}>
        <WppButton variant="secondary" size="s" onClick={(() => togglePopup())}>Cancel</WppButton>
        <WppButton variant="primary" size="s" onClick={(() => navigate(cancelBtnPath))}>Leave</WppButton>
      </div>
    </WppModal>
  );
};

export default ConfirmationPopup;
