import _ from 'lodash';
import React from 'react';
import { Control, Controller } from 'react-hook-form';
import FormSectionHeader from 'components/FormSectionHeader';
import { Form, Input, WppButton, WppInlineMessage } from 'buildingBlocks';
import { validatedFieldWrapper } from 'utils/wrapperComponentsReactHookForms';
import { StrategyType } from 'utils/types';
import CustomTextArea from 'components/CustomTextArea';
import strategyWizardStyles from 'containers/StrategyWizard/styles';
import LeafNameToggle from './LeafNameToggle';

const InputWrapper = validatedFieldWrapper(Input);

type Props = {
  strategyType: StrategyType
  handleAdd: React.MouseEventHandler<HTMLWppActionButtonElement>
  handleRemove: React.MouseEventHandler<HTMLWppActionButtonElement>
  isCustomTreeValidating: boolean
  control: Control
  onValidateCustomTreeClick: (x: any) => void
  cannotValidateTree: boolean
  isCustomTreeValid: boolean
  validationOutput: string
  csvInputValue: boolean
};

const TreeText = ({
  strategyType, isCustomTreeValidating, cannotValidateTree, isCustomTreeValid, csvInputValue,
  validationOutput, control, handleAdd, handleRemove, onValidateCustomTreeClick,
}: Props) => (

  <>
    <div style={strategyWizardStyles.treeTextLeafNameParent}>
      <FormSectionHeader
        title="Tree Text"
        help="Type your Bonsai Tree text inline."
        group={strategyType.displayName}
        bottom={20}
      />
      <LeafNameToggle
        onAdd={handleAdd}
        onRemove={handleRemove}
      />
    </div>
    <Form.Field required disabled={isCustomTreeValidating}>
      <Controller
        name="customTreeInput"
        control={control}
        render={({ field }) => (
          <CustomTextArea
            name="customTreeInput"
            disabled={isCustomTreeValidating}
            height={594}
            mode="python"
            csvInputValue={csvInputValue}
            onChange={field.onChange}
            className="custom-tree"
            {..._.omit(field, 'ref')}
          />
        )}
      />
    </Form.Field>
    <div style={{ textAlign: 'center' }}>
      <Controller
        name="validateCustomTree"
        control={control}
        render={({ field }) => (
          <WppButton
            onClick={onValidateCustomTreeClick}
            disabled={cannotValidateTree}
            size="m"
            variant="secondary"
            className="custom-configure-tree-btn"
            loading={isCustomTreeValidating}
            style={{ ...strategyWizardStyles.customTreeBtn, ...(cannotValidateTree && { pointerEvents: 'none' }) }}
            {...field}
          >
            Validate tree
          </WppButton>
        )}
      />
    </div>
    {validationOutput
        && (
          <WppInlineMessage
            size="s"
            message={validationOutput}
            type={isCustomTreeValid ? 'success' : 'error'}
            style={strategyWizardStyles.validationOutputStyle}
          />
        )}
    <Form.Field required>
      {/** hidden field to track page validity */}
      <Controller
        name="pageValidator"
        control={control}
        render={({ field, fieldState }) => (
          <InputWrapper
            name="pageValidator"
            type="hidden"
            onChange={field.onChange}
            fieldState={fieldState}
            field={field}
            {..._.omit(field, ['value', 'ref'])}
          />
        )}
      />
    </Form.Field>
  </>
);

export default TreeText;
