import _ from 'lodash';
import React from 'react';
import { Navigate } from 'react-router-dom';
import { WizardSteps, WIZARD_STEPS } from '../constants';
import { WizardFormValues, StrategyConfigurationStep } from '../types';

type Props = {
  step: number
  strategyId?: number
  wizardFormValues: WizardFormValues
  children: JSX.Element
  hasFormErrors: boolean
};

const WIZARD_BASE = '/strategies/wizard/';
const ATTACH_FLIGHTS_LINK = `${WIZARD_BASE}${WIZARD_STEPS[WizardSteps.attachFlightsStep].id}`;
export const NO_REDIRECT = 'NO_REDIRECT';

export const getRedirectLink = (
  step: number,
  strategyId: number,
  wizardFormValues: WizardFormValues,
  moduleFormValues: StrategyConfigurationStep,
  hasFormErrors: boolean,
) => {
  const {
    attachFlightsStep: { member, advertiser, brand, attachedFlights, toBeDetached },
    strategyTypeSelectionStep: { strategyType },
  } = wizardFormValues;

  const basicSetupCompleted = (!_.isEmpty(member) && !_.isEmpty(advertiser)) || !_.isEmpty(brand);
  const strategyTypeCompleted = !_.isEmpty(strategyType);
  const strategyConfigCompleted = !_.isEmpty(moduleFormValues);
  const existingStratWithEligibleFlights = strategyId && !_.isEmpty(attachedFlights);
  const existingStratNoFlightsAttached = strategyId && _.isEmpty(attachedFlights);
  const updateBtnClicked = wizardFormValues.nextBtnClick;

  if (!step || _.isNaN(step) || (!strategyId && step > WIZARD_STEPS[WizardSteps.strategyConfirmationStep].id)) {
    return `${WIZARD_BASE}/${WIZARD_STEPS[WizardSteps.attachFlightsStep].id}`;
  }

  if (existingStratWithEligibleFlights && hasFormErrors && step === WIZARD_STEPS[WizardSteps.strategyConfirmationStep].id) {
    return `${WIZARD_BASE}${strategyId}/${WIZARD_STEPS[WizardSteps.strategyConfigurationStep].id}`;
  }
  if (existingStratWithEligibleFlights) {
    if (step === WIZARD_STEPS[WizardSteps.strategyTypeSelectionStep].id || (!updateBtnClicked && step === WIZARD_STEPS[WizardSteps.strategyConfirmationStep].id)) {
      return `${WIZARD_BASE}${strategyId}/${WIZARD_STEPS[WizardSteps.strategyConfigurationStep].id}`;
    }
    return NO_REDIRECT;
  }
  if (existingStratNoFlightsAttached) {
    if (!_.includes([WIZARD_STEPS[WizardSteps.attachFlightsStep].id, WIZARD_STEPS[WizardSteps.strategyConfirmationStep].id], step)) {
      return `${WIZARD_BASE}${strategyId}/${WIZARD_STEPS[WizardSteps.strategyConfirmationStep].id}`;
    }
    // handle edit flow when no flights attached
    if (step === WIZARD_STEPS[WizardSteps.strategyConfirmationStep].id && _.isEmpty(toBeDetached)) {
      return `${WIZARD_BASE}${strategyId}/${WIZARD_STEPS[WizardSteps.attachFlightsStep].id}`;
    }
    return NO_REDIRECT;
  }

  switch (step) {
    case WIZARD_STEPS[WizardSteps.strategyConfigurationStep].id:
      if (!strategyTypeCompleted) {
        return ATTACH_FLIGHTS_LINK;
      }
      break;
    case WIZARD_STEPS[WizardSteps.goalSelectionStep].id:
      if (!basicSetupCompleted) {
        return ATTACH_FLIGHTS_LINK;
      }
      break;
    case WIZARD_STEPS[WizardSteps.strategyTypeSelectionStep].id:
      if (!basicSetupCompleted) {
        return ATTACH_FLIGHTS_LINK;
      }
      break;
    case WIZARD_STEPS[WizardSteps.strategyConfirmationStep].id:
      if (!basicSetupCompleted || !strategyTypeCompleted || (!strategyId && !strategyConfigCompleted)) {
        return ATTACH_FLIGHTS_LINK;
      }
      break;
    default:
      return NO_REDIRECT;
  }
  return NO_REDIRECT;
};
// handle redirection logic for the wizard. we don't want to allow people to skip ahead in the wizard or jump back to
// change the first two pages when editing.
const StrategyWizardRedirector = ({ step, strategyId, children, wizardFormValues, hasFormErrors }: Props) => {
  const { strategyConfigurationStep } = wizardFormValues;
  const to = getRedirectLink(step, strategyId, wizardFormValues, strategyConfigurationStep, hasFormErrors);
  return to === NO_REDIRECT ? children : <Navigate to={to} />;
};

export default StrategyWizardRedirector;
