import React from 'react';
import { Controller, useWatch } from 'react-hook-form';
import Toggle from 'components/Toggle';

const BFOComponent = () => {
  const isBFO = useWatch({ name: 'isBFO' });
  return (
    <div>
      <Controller
        name="isBFO"
        render={({ field }) => (
          <Toggle
            style={{ paddingBottom: '15px' }}
            onChange={field.onChange}
            checked={isBFO}
          />
        )}
      />
    </div>
  );
};

export default BFOComponent;
