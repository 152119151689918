/* eslint-disable */
//@ts-nocheck

/**** THIS FILE IS PROGRAMMATICALLY GENERATED. DO NOT EDIT BY HAND ****/

import _ from 'lodash'

export const STRATEGY_CONFIG_MAPPER = {
	'Xandr,lineItem,CPA,banner,CPM,impression,heliosSegmentRecency': {
		'heliosSegmentRecency.startingMinBid': () => 0.5,
		'heliosSegmentRecency.startingMaxBid': () => 2,
		'heliosSegmentRecency.minBid': () => 0.01,
		'heliosSegmentRecency.maxBid': () => 3,
	},
	'Xandr,lineItem,CPA,native,CPM,impression,heliosSegmentRecency': {
		'heliosSegmentRecency.startingMinBid': () => 0.5,
		'heliosSegmentRecency.startingMaxBid': () => 2,
		'heliosSegmentRecency.minBid': () => 0.01,
		'heliosSegmentRecency.maxBid': () => 3,
	},
	'Xandr,lineItem,CPA,video,CPM,impression,heliosSegmentRecency': {
		'heliosSegmentRecency.startingMinBid': () => 0.5,
		'heliosSegmentRecency.startingMaxBid': () => 2,
		'heliosSegmentRecency.minBid': () => 0.01,
		'heliosSegmentRecency.maxBid': () => 3,
	},
	'Xandr,lineItem,CPA,audio,CPM,impression,heliosSegmentRecency': {
		'heliosSegmentRecency.startingMinBid': () => 0.5,
		'heliosSegmentRecency.startingMaxBid': () => 2,
		'heliosSegmentRecency.minBid': () => 0.01,
		'heliosSegmentRecency.maxBid': () => 3,
	},
	'Xandr,lineItem,Conversion Rate,banner,CPM,impression,heliosSegmentRecency': {
		'heliosSegmentRecency.startingMinBid': () => 0.5,
		'heliosSegmentRecency.startingMaxBid': () => 2,
		'heliosSegmentRecency.minBid': () => 0.01,
		'heliosSegmentRecency.maxBid': () => 3,
	},
	'Xandr,lineItem,Conversion Rate,native,CPM,impression,heliosSegmentRecency': {
		'heliosSegmentRecency.startingMinBid': () => 0.5,
		'heliosSegmentRecency.startingMaxBid': () => 2,
		'heliosSegmentRecency.minBid': () => 0.01,
		'heliosSegmentRecency.maxBid': () => 3,
	},
	'Xandr,lineItem,Conversion Rate,video,CPM,impression,heliosSegmentRecency': {
		'heliosSegmentRecency.startingMinBid': () => 0.5,
		'heliosSegmentRecency.startingMaxBid': () => 2,
		'heliosSegmentRecency.minBid': () => 0.01,
		'heliosSegmentRecency.maxBid': () => 3,
	},
	'Xandr,lineItem,Conversion Rate,audio,CPM,impression,heliosSegmentRecency': {
		'heliosSegmentRecency.startingMinBid': () => 0.5,
		'heliosSegmentRecency.startingMaxBid': () => 2,
		'heliosSegmentRecency.minBid': () => 0.01,
		'heliosSegmentRecency.maxBid': () => 3,
	},
	'Xandr,lineItem,CPA,banner,COST_PLUS_MARGIN,impression,heliosSegmentRecency': {
		'heliosSegmentRecency.startingMinBid': () => 0.5,
		'heliosSegmentRecency.startingMaxBid': () => 2,
		'heliosSegmentRecency.minBid': () => 0.01,
		'heliosSegmentRecency.maxBid': () => 4,
	},
	'Xandr,lineItem,CPA,banner,COST_PLUS_MARGIN,video,heliosSegmentRecency': {
		'heliosSegmentRecency.startingMinBid': () => 0.5,
		'heliosSegmentRecency.startingMaxBid': () => 2,
		'heliosSegmentRecency.minBid': () => 0.01,
		'heliosSegmentRecency.maxBid': () => 4,
	},
	'Xandr,lineItem,CPA,banner,COST_PLUS_MARGIN,view,heliosSegmentRecency': {
		'heliosSegmentRecency.startingMinBid': () => 0.5,
		'heliosSegmentRecency.startingMaxBid': () => 2,
		'heliosSegmentRecency.minBid': () => 0.01,
		'heliosSegmentRecency.maxBid': () => 4,
	},
	'Xandr,lineItem,CPA,banner,COST_PLUS_CPM,impression,heliosSegmentRecency': {
		'heliosSegmentRecency.startingMinBid': () => 0.5,
		'heliosSegmentRecency.startingMaxBid': () => 2,
		'heliosSegmentRecency.minBid': () => 0.01,
		'heliosSegmentRecency.maxBid': () => 4,
	},
	'Xandr,lineItem,CPA,banner,COST_PLUS_CPM,video,heliosSegmentRecency': {
		'heliosSegmentRecency.startingMinBid': () => 0.5,
		'heliosSegmentRecency.startingMaxBid': () => 2,
		'heliosSegmentRecency.minBid': () => 0.01,
		'heliosSegmentRecency.maxBid': () => 4,
	},
	'Xandr,lineItem,CPA,banner,COST_PLUS_CPM,view,heliosSegmentRecency': {
		'heliosSegmentRecency.startingMinBid': () => 0.5,
		'heliosSegmentRecency.startingMaxBid': () => 2,
		'heliosSegmentRecency.minBid': () => 0.01,
		'heliosSegmentRecency.maxBid': () => 4,
	},
	'Xandr,lineItem,CPA,native,COST_PLUS_MARGIN,impression,heliosSegmentRecency': {
		'heliosSegmentRecency.startingMinBid': () => 0.5,
		'heliosSegmentRecency.startingMaxBid': () => 2,
		'heliosSegmentRecency.minBid': () => 0.01,
		'heliosSegmentRecency.maxBid': () => 4,
	},
	'Xandr,lineItem,CPA,native,COST_PLUS_MARGIN,video,heliosSegmentRecency': {
		'heliosSegmentRecency.startingMinBid': () => 0.5,
		'heliosSegmentRecency.startingMaxBid': () => 2,
		'heliosSegmentRecency.minBid': () => 0.01,
		'heliosSegmentRecency.maxBid': () => 4,
	},
	'Xandr,lineItem,CPA,native,COST_PLUS_MARGIN,view,heliosSegmentRecency': {
		'heliosSegmentRecency.startingMinBid': () => 0.5,
		'heliosSegmentRecency.startingMaxBid': () => 2,
		'heliosSegmentRecency.minBid': () => 0.01,
		'heliosSegmentRecency.maxBid': () => 4,
	},
	'Xandr,lineItem,CPA,native,COST_PLUS_CPM,impression,heliosSegmentRecency': {
		'heliosSegmentRecency.startingMinBid': () => 0.5,
		'heliosSegmentRecency.startingMaxBid': () => 2,
		'heliosSegmentRecency.minBid': () => 0.01,
		'heliosSegmentRecency.maxBid': () => 4,
	},
	'Xandr,lineItem,CPA,native,COST_PLUS_CPM,video,heliosSegmentRecency': {
		'heliosSegmentRecency.startingMinBid': () => 0.5,
		'heliosSegmentRecency.startingMaxBid': () => 2,
		'heliosSegmentRecency.minBid': () => 0.01,
		'heliosSegmentRecency.maxBid': () => 4,
	},
	'Xandr,lineItem,CPA,native,COST_PLUS_CPM,view,heliosSegmentRecency': {
		'heliosSegmentRecency.startingMinBid': () => 0.5,
		'heliosSegmentRecency.startingMaxBid': () => 2,
		'heliosSegmentRecency.minBid': () => 0.01,
		'heliosSegmentRecency.maxBid': () => 4,
	},
	'Xandr,lineItem,CPA,video,COST_PLUS_MARGIN,impression,heliosSegmentRecency': {
		'heliosSegmentRecency.startingMinBid': () => 0.5,
		'heliosSegmentRecency.startingMaxBid': () => 2,
		'heliosSegmentRecency.minBid': () => 0.01,
		'heliosSegmentRecency.maxBid': () => 4,
	},
	'Xandr,lineItem,CPA,video,COST_PLUS_MARGIN,video,heliosSegmentRecency': {
		'heliosSegmentRecency.startingMinBid': () => 0.5,
		'heliosSegmentRecency.startingMaxBid': () => 2,
		'heliosSegmentRecency.minBid': () => 0.01,
		'heliosSegmentRecency.maxBid': () => 4,
	},
	'Xandr,lineItem,CPA,video,COST_PLUS_MARGIN,view,heliosSegmentRecency': {
		'heliosSegmentRecency.startingMinBid': () => 0.5,
		'heliosSegmentRecency.startingMaxBid': () => 2,
		'heliosSegmentRecency.minBid': () => 0.01,
		'heliosSegmentRecency.maxBid': () => 4,
	},
	'Xandr,lineItem,CPA,video,COST_PLUS_CPM,impression,heliosSegmentRecency': {
		'heliosSegmentRecency.startingMinBid': () => 0.5,
		'heliosSegmentRecency.startingMaxBid': () => 2,
		'heliosSegmentRecency.minBid': () => 0.01,
		'heliosSegmentRecency.maxBid': () => 4,
	},
	'Xandr,lineItem,CPA,video,COST_PLUS_CPM,video,heliosSegmentRecency': {
		'heliosSegmentRecency.startingMinBid': () => 0.5,
		'heliosSegmentRecency.startingMaxBid': () => 2,
		'heliosSegmentRecency.minBid': () => 0.01,
		'heliosSegmentRecency.maxBid': () => 4,
	},
	'Xandr,lineItem,CPA,video,COST_PLUS_CPM,view,heliosSegmentRecency': {
		'heliosSegmentRecency.startingMinBid': () => 0.5,
		'heliosSegmentRecency.startingMaxBid': () => 2,
		'heliosSegmentRecency.minBid': () => 0.01,
		'heliosSegmentRecency.maxBid': () => 4,
	},
	'Xandr,lineItem,CPA,audio,COST_PLUS_MARGIN,impression,heliosSegmentRecency': {
		'heliosSegmentRecency.startingMinBid': () => 0.5,
		'heliosSegmentRecency.startingMaxBid': () => 2,
		'heliosSegmentRecency.minBid': () => 0.01,
		'heliosSegmentRecency.maxBid': () => 4,
	},
	'Xandr,lineItem,CPA,audio,COST_PLUS_MARGIN,video,heliosSegmentRecency': {
		'heliosSegmentRecency.startingMinBid': () => 0.5,
		'heliosSegmentRecency.startingMaxBid': () => 2,
		'heliosSegmentRecency.minBid': () => 0.01,
		'heliosSegmentRecency.maxBid': () => 4,
	},
	'Xandr,lineItem,CPA,audio,COST_PLUS_MARGIN,view,heliosSegmentRecency': {
		'heliosSegmentRecency.startingMinBid': () => 0.5,
		'heliosSegmentRecency.startingMaxBid': () => 2,
		'heliosSegmentRecency.minBid': () => 0.01,
		'heliosSegmentRecency.maxBid': () => 4,
	},
	'Xandr,lineItem,CPA,audio,COST_PLUS_CPM,impression,heliosSegmentRecency': {
		'heliosSegmentRecency.startingMinBid': () => 0.5,
		'heliosSegmentRecency.startingMaxBid': () => 2,
		'heliosSegmentRecency.minBid': () => 0.01,
		'heliosSegmentRecency.maxBid': () => 4,
	},
	'Xandr,lineItem,CPA,audio,COST_PLUS_CPM,video,heliosSegmentRecency': {
		'heliosSegmentRecency.startingMinBid': () => 0.5,
		'heliosSegmentRecency.startingMaxBid': () => 2,
		'heliosSegmentRecency.minBid': () => 0.01,
		'heliosSegmentRecency.maxBid': () => 4,
	},
	'Xandr,lineItem,CPA,audio,COST_PLUS_CPM,view,heliosSegmentRecency': {
		'heliosSegmentRecency.startingMinBid': () => 0.5,
		'heliosSegmentRecency.startingMaxBid': () => 2,
		'heliosSegmentRecency.minBid': () => 0.01,
		'heliosSegmentRecency.maxBid': () => 4,
	},
	'Xandr,lineItem,Conversion Rate,banner,COST_PLUS_MARGIN,impression,heliosSegmentRecency': {
		'heliosSegmentRecency.startingMinBid': () => 0.5,
		'heliosSegmentRecency.startingMaxBid': () => 2,
		'heliosSegmentRecency.minBid': () => 0.01,
		'heliosSegmentRecency.maxBid': () => 4,
	},
	'Xandr,lineItem,Conversion Rate,banner,COST_PLUS_MARGIN,video,heliosSegmentRecency': {
		'heliosSegmentRecency.startingMinBid': () => 0.5,
		'heliosSegmentRecency.startingMaxBid': () => 2,
		'heliosSegmentRecency.minBid': () => 0.01,
		'heliosSegmentRecency.maxBid': () => 4,
	},
	'Xandr,lineItem,Conversion Rate,banner,COST_PLUS_MARGIN,view,heliosSegmentRecency': {
		'heliosSegmentRecency.startingMinBid': () => 0.5,
		'heliosSegmentRecency.startingMaxBid': () => 2,
		'heliosSegmentRecency.minBid': () => 0.01,
		'heliosSegmentRecency.maxBid': () => 4,
	},
	'Xandr,lineItem,Conversion Rate,banner,COST_PLUS_CPM,impression,heliosSegmentRecency': {
		'heliosSegmentRecency.startingMinBid': () => 0.5,
		'heliosSegmentRecency.startingMaxBid': () => 2,
		'heliosSegmentRecency.minBid': () => 0.01,
		'heliosSegmentRecency.maxBid': () => 4,
	},
	'Xandr,lineItem,Conversion Rate,banner,COST_PLUS_CPM,video,heliosSegmentRecency': {
		'heliosSegmentRecency.startingMinBid': () => 0.5,
		'heliosSegmentRecency.startingMaxBid': () => 2,
		'heliosSegmentRecency.minBid': () => 0.01,
		'heliosSegmentRecency.maxBid': () => 4,
	},
	'Xandr,lineItem,Conversion Rate,banner,COST_PLUS_CPM,view,heliosSegmentRecency': {
		'heliosSegmentRecency.startingMinBid': () => 0.5,
		'heliosSegmentRecency.startingMaxBid': () => 2,
		'heliosSegmentRecency.minBid': () => 0.01,
		'heliosSegmentRecency.maxBid': () => 4,
	},
	'Xandr,lineItem,Conversion Rate,native,COST_PLUS_MARGIN,impression,heliosSegmentRecency': {
		'heliosSegmentRecency.startingMinBid': () => 0.5,
		'heliosSegmentRecency.startingMaxBid': () => 2,
		'heliosSegmentRecency.minBid': () => 0.01,
		'heliosSegmentRecency.maxBid': () => 4,
	},
	'Xandr,lineItem,Conversion Rate,native,COST_PLUS_MARGIN,video,heliosSegmentRecency': {
		'heliosSegmentRecency.startingMinBid': () => 0.5,
		'heliosSegmentRecency.startingMaxBid': () => 2,
		'heliosSegmentRecency.minBid': () => 0.01,
		'heliosSegmentRecency.maxBid': () => 4,
	},
	'Xandr,lineItem,Conversion Rate,native,COST_PLUS_MARGIN,view,heliosSegmentRecency': {
		'heliosSegmentRecency.startingMinBid': () => 0.5,
		'heliosSegmentRecency.startingMaxBid': () => 2,
		'heliosSegmentRecency.minBid': () => 0.01,
		'heliosSegmentRecency.maxBid': () => 4,
	},
	'Xandr,lineItem,Conversion Rate,native,COST_PLUS_CPM,impression,heliosSegmentRecency': {
		'heliosSegmentRecency.startingMinBid': () => 0.5,
		'heliosSegmentRecency.startingMaxBid': () => 2,
		'heliosSegmentRecency.minBid': () => 0.01,
		'heliosSegmentRecency.maxBid': () => 4,
	},
	'Xandr,lineItem,Conversion Rate,native,COST_PLUS_CPM,video,heliosSegmentRecency': {
		'heliosSegmentRecency.startingMinBid': () => 0.5,
		'heliosSegmentRecency.startingMaxBid': () => 2,
		'heliosSegmentRecency.minBid': () => 0.01,
		'heliosSegmentRecency.maxBid': () => 4,
	},
	'Xandr,lineItem,Conversion Rate,native,COST_PLUS_CPM,view,heliosSegmentRecency': {
		'heliosSegmentRecency.startingMinBid': () => 0.5,
		'heliosSegmentRecency.startingMaxBid': () => 2,
		'heliosSegmentRecency.minBid': () => 0.01,
		'heliosSegmentRecency.maxBid': () => 4,
	},
	'Xandr,lineItem,Conversion Rate,video,COST_PLUS_MARGIN,impression,heliosSegmentRecency': {
		'heliosSegmentRecency.startingMinBid': () => 0.5,
		'heliosSegmentRecency.startingMaxBid': () => 2,
		'heliosSegmentRecency.minBid': () => 0.01,
		'heliosSegmentRecency.maxBid': () => 4,
	},
	'Xandr,lineItem,Conversion Rate,video,COST_PLUS_MARGIN,video,heliosSegmentRecency': {
		'heliosSegmentRecency.startingMinBid': () => 0.5,
		'heliosSegmentRecency.startingMaxBid': () => 2,
		'heliosSegmentRecency.minBid': () => 0.01,
		'heliosSegmentRecency.maxBid': () => 4,
	},
	'Xandr,lineItem,Conversion Rate,video,COST_PLUS_MARGIN,view,heliosSegmentRecency': {
		'heliosSegmentRecency.startingMinBid': () => 0.5,
		'heliosSegmentRecency.startingMaxBid': () => 2,
		'heliosSegmentRecency.minBid': () => 0.01,
		'heliosSegmentRecency.maxBid': () => 4,
	},
	'Xandr,lineItem,Conversion Rate,video,COST_PLUS_CPM,impression,heliosSegmentRecency': {
		'heliosSegmentRecency.startingMinBid': () => 0.5,
		'heliosSegmentRecency.startingMaxBid': () => 2,
		'heliosSegmentRecency.minBid': () => 0.01,
		'heliosSegmentRecency.maxBid': () => 4,
	},
	'Xandr,lineItem,Conversion Rate,video,COST_PLUS_CPM,video,heliosSegmentRecency': {
		'heliosSegmentRecency.startingMinBid': () => 0.5,
		'heliosSegmentRecency.startingMaxBid': () => 2,
		'heliosSegmentRecency.minBid': () => 0.01,
		'heliosSegmentRecency.maxBid': () => 4,
	},
	'Xandr,lineItem,Conversion Rate,video,COST_PLUS_CPM,view,heliosSegmentRecency': {
		'heliosSegmentRecency.startingMinBid': () => 0.5,
		'heliosSegmentRecency.startingMaxBid': () => 2,
		'heliosSegmentRecency.minBid': () => 0.01,
		'heliosSegmentRecency.maxBid': () => 4,
	},
	'Xandr,lineItem,Conversion Rate,audio,COST_PLUS_MARGIN,impression,heliosSegmentRecency': {
		'heliosSegmentRecency.startingMinBid': () => 0.5,
		'heliosSegmentRecency.startingMaxBid': () => 2,
		'heliosSegmentRecency.minBid': () => 0.01,
		'heliosSegmentRecency.maxBid': () => 4,
	},
	'Xandr,lineItem,Conversion Rate,audio,COST_PLUS_MARGIN,video,heliosSegmentRecency': {
		'heliosSegmentRecency.startingMinBid': () => 0.5,
		'heliosSegmentRecency.startingMaxBid': () => 2,
		'heliosSegmentRecency.minBid': () => 0.01,
		'heliosSegmentRecency.maxBid': () => 4,
	},
	'Xandr,lineItem,Conversion Rate,audio,COST_PLUS_MARGIN,view,heliosSegmentRecency': {
		'heliosSegmentRecency.startingMinBid': () => 0.5,
		'heliosSegmentRecency.startingMaxBid': () => 2,
		'heliosSegmentRecency.minBid': () => 0.01,
		'heliosSegmentRecency.maxBid': () => 4,
	},
	'Xandr,lineItem,Conversion Rate,audio,COST_PLUS_CPM,impression,heliosSegmentRecency': {
		'heliosSegmentRecency.startingMinBid': () => 0.5,
		'heliosSegmentRecency.startingMaxBid': () => 2,
		'heliosSegmentRecency.minBid': () => 0.01,
		'heliosSegmentRecency.maxBid': () => 4,
	},
	'Xandr,lineItem,Conversion Rate,audio,COST_PLUS_CPM,video,heliosSegmentRecency': {
		'heliosSegmentRecency.startingMinBid': () => 0.5,
		'heliosSegmentRecency.startingMaxBid': () => 2,
		'heliosSegmentRecency.minBid': () => 0.01,
		'heliosSegmentRecency.maxBid': () => 4,
	},
	'Xandr,lineItem,Conversion Rate,audio,COST_PLUS_CPM,view,heliosSegmentRecency': {
		'heliosSegmentRecency.startingMinBid': () => 0.5,
		'heliosSegmentRecency.startingMaxBid': () => 2,
		'heliosSegmentRecency.minBid': () => 0.01,
		'heliosSegmentRecency.maxBid': () => 4,
	},
	'Xandr,lineItem,CPA,banner,CPM,click,heliosSegmentRecency': {
		'heliosSegmentRecency.startingMinBid': () => 0.75,
		'heliosSegmentRecency.startingMaxBid': () => 1.5,
		'heliosSegmentRecency.minBid': () => 0.01,
		'heliosSegmentRecency.maxBid': () => 2,
	},
	'Xandr,lineItem,CPA,native,CPM,click,heliosSegmentRecency': {
		'heliosSegmentRecency.startingMinBid': () => 0.75,
		'heliosSegmentRecency.startingMaxBid': () => 1.5,
		'heliosSegmentRecency.minBid': () => 0.01,
		'heliosSegmentRecency.maxBid': () => 2,
	},
	'Xandr,lineItem,CPA,video,CPM,click,heliosSegmentRecency': {
		'heliosSegmentRecency.startingMinBid': () => 0.75,
		'heliosSegmentRecency.startingMaxBid': () => 1.5,
		'heliosSegmentRecency.minBid': () => 0.01,
		'heliosSegmentRecency.maxBid': () => 2,
	},
	'Xandr,lineItem,CPA,audio,CPM,click,heliosSegmentRecency': {
		'heliosSegmentRecency.startingMinBid': () => 0.75,
		'heliosSegmentRecency.startingMaxBid': () => 1.5,
		'heliosSegmentRecency.minBid': () => 0.01,
		'heliosSegmentRecency.maxBid': () => 2,
	},
	'Xandr,lineItem,Conversion Rate,banner,CPM,click,heliosSegmentRecency': {
		'heliosSegmentRecency.startingMinBid': () => 0.75,
		'heliosSegmentRecency.startingMaxBid': () => 1.5,
		'heliosSegmentRecency.minBid': () => 0.01,
		'heliosSegmentRecency.maxBid': () => 2,
	},
	'Xandr,lineItem,Conversion Rate,native,CPM,click,heliosSegmentRecency': {
		'heliosSegmentRecency.startingMinBid': () => 0.75,
		'heliosSegmentRecency.startingMaxBid': () => 1.5,
		'heliosSegmentRecency.minBid': () => 0.01,
		'heliosSegmentRecency.maxBid': () => 2,
	},
	'Xandr,lineItem,Conversion Rate,video,CPM,click,heliosSegmentRecency': {
		'heliosSegmentRecency.startingMinBid': () => 0.75,
		'heliosSegmentRecency.startingMaxBid': () => 1.5,
		'heliosSegmentRecency.minBid': () => 0.01,
		'heliosSegmentRecency.maxBid': () => 2,
	},
	'Xandr,lineItem,Conversion Rate,audio,CPM,click,heliosSegmentRecency': {
		'heliosSegmentRecency.startingMinBid': () => 0.75,
		'heliosSegmentRecency.startingMaxBid': () => 1.5,
		'heliosSegmentRecency.minBid': () => 0.01,
		'heliosSegmentRecency.maxBid': () => 2,
	},
	'Xandr,lineItem,CPA,banner,CPM,view,heliosSegmentRecency': {
		'heliosSegmentRecency.startingMinBid': () => 0.5,
		'heliosSegmentRecency.startingMaxBid': () => 2,
		'heliosSegmentRecency.minBid': () => 0.01,
		'heliosSegmentRecency.maxBid': () => 3,
	},
	'Xandr,lineItem,CPA,native,CPM,view,heliosSegmentRecency': {
		'heliosSegmentRecency.startingMinBid': () => 0.5,
		'heliosSegmentRecency.startingMaxBid': () => 2,
		'heliosSegmentRecency.minBid': () => 0.01,
		'heliosSegmentRecency.maxBid': () => 3,
	},
	'Xandr,lineItem,CPA,video,CPM,view,heliosSegmentRecency': {
		'heliosSegmentRecency.startingMinBid': () => 0.5,
		'heliosSegmentRecency.startingMaxBid': () => 2,
		'heliosSegmentRecency.minBid': () => 0.01,
		'heliosSegmentRecency.maxBid': () => 3,
	},
	'Xandr,lineItem,CPA,audio,CPM,view,heliosSegmentRecency': {
		'heliosSegmentRecency.startingMinBid': () => 0.5,
		'heliosSegmentRecency.startingMaxBid': () => 2,
		'heliosSegmentRecency.minBid': () => 0.01,
		'heliosSegmentRecency.maxBid': () => 3,
	},
	'Xandr,lineItem,Conversion Rate,banner,CPM,view,heliosSegmentRecency': {
		'heliosSegmentRecency.startingMinBid': () => 0.5,
		'heliosSegmentRecency.startingMaxBid': () => 2,
		'heliosSegmentRecency.minBid': () => 0.01,
		'heliosSegmentRecency.maxBid': () => 3,
	},
	'Xandr,lineItem,Conversion Rate,native,CPM,view,heliosSegmentRecency': {
		'heliosSegmentRecency.startingMinBid': () => 0.5,
		'heliosSegmentRecency.startingMaxBid': () => 2,
		'heliosSegmentRecency.minBid': () => 0.01,
		'heliosSegmentRecency.maxBid': () => 3,
	},
	'Xandr,lineItem,Conversion Rate,video,CPM,view,heliosSegmentRecency': {
		'heliosSegmentRecency.startingMinBid': () => 0.5,
		'heliosSegmentRecency.startingMaxBid': () => 2,
		'heliosSegmentRecency.minBid': () => 0.01,
		'heliosSegmentRecency.maxBid': () => 3,
	},
	'Xandr,lineItem,Conversion Rate,audio,CPM,view,heliosSegmentRecency': {
		'heliosSegmentRecency.startingMinBid': () => 0.5,
		'heliosSegmentRecency.startingMaxBid': () => 2,
		'heliosSegmentRecency.minBid': () => 0.01,
		'heliosSegmentRecency.maxBid': () => 3,
	},
	'Xandr,lineItem,CPA,video,CPM,video,heliosSegmentRecency': {
		'heliosSegmentRecency.startingMinBid': () => 0.75,
		'heliosSegmentRecency.startingMaxBid': () => 1.5,
		'heliosSegmentRecency.minBid': () => 0.01,
		'heliosSegmentRecency.maxBid': () => 2,
	},
	'Xandr,lineItem,CPA,audio,CPM,video,heliosSegmentRecency': {
		'heliosSegmentRecency.startingMinBid': () => 0.75,
		'heliosSegmentRecency.startingMaxBid': () => 1.5,
		'heliosSegmentRecency.minBid': () => 0.01,
		'heliosSegmentRecency.maxBid': () => 2,
	},
	'Xandr,lineItem,Conversion Rate,video,CPM,video,heliosSegmentRecency': {
		'heliosSegmentRecency.startingMinBid': () => 0.75,
		'heliosSegmentRecency.startingMaxBid': () => 1.5,
		'heliosSegmentRecency.minBid': () => 0.01,
		'heliosSegmentRecency.maxBid': () => 2,
	},
	'Xandr,lineItem,Conversion Rate,audio,CPM,video,heliosSegmentRecency': {
		'heliosSegmentRecency.startingMinBid': () => 0.75,
		'heliosSegmentRecency.startingMaxBid': () => 1.5,
		'heliosSegmentRecency.minBid': () => 0.01,
		'heliosSegmentRecency.maxBid': () => 2,
	},
	'Xandr,lineItem,Completion Rate,video,CPM,impression,helios': {
		'helios.minBid': () => 0.01,
		'helios.maxBid': () => 1.1,
	},
	'Xandr,lineItem,Completion Rate,audio,CPM,impression,helios': {
		'helios.minBid': () => 0.01,
		'helios.maxBid': () => 1.1,
	},
	'Xandr,lineItem,Completion Rate,video,COST_PLUS_MARGIN,impression,helios': {
		'helios.minBid': () => 0.01,
		'helios.maxBid': () => 4,
	},
	'Xandr,lineItem,Completion Rate,video,COST_PLUS_MARGIN,video,helios': {
		'helios.minBid': () => 0.01,
		'helios.maxBid': () => 4,
	},
	'Xandr,lineItem,Completion Rate,video,COST_PLUS_MARGIN,view,helios': {
		'helios.minBid': () => 0.01,
		'helios.maxBid': () => 4,
	},
	'Xandr,lineItem,Completion Rate,video,COST_PLUS_CPM,impression,helios': {
		'helios.minBid': () => 0.01,
		'helios.maxBid': () => 4,
	},
	'Xandr,lineItem,Completion Rate,video,COST_PLUS_CPM,video,helios': {
		'helios.minBid': () => 0.01,
		'helios.maxBid': () => 4,
	},
	'Xandr,lineItem,Completion Rate,video,COST_PLUS_CPM,view,helios': {
		'helios.minBid': () => 0.01,
		'helios.maxBid': () => 4,
	},
	'Xandr,lineItem,Completion Rate,audio,COST_PLUS_MARGIN,impression,helios': {
		'helios.minBid': () => 0.01,
		'helios.maxBid': () => 4,
	},
	'Xandr,lineItem,Completion Rate,audio,COST_PLUS_MARGIN,video,helios': {
		'helios.minBid': () => 0.01,
		'helios.maxBid': () => 4,
	},
	'Xandr,lineItem,Completion Rate,audio,COST_PLUS_MARGIN,view,helios': {
		'helios.minBid': () => 0.01,
		'helios.maxBid': () => 4,
	},
	'Xandr,lineItem,Completion Rate,audio,COST_PLUS_CPM,impression,helios': {
		'helios.minBid': () => 0.01,
		'helios.maxBid': () => 4,
	},
	'Xandr,lineItem,Completion Rate,audio,COST_PLUS_CPM,video,helios': {
		'helios.minBid': () => 0.01,
		'helios.maxBid': () => 4,
	},
	'Xandr,lineItem,Completion Rate,audio,COST_PLUS_CPM,view,helios': {
		'helios.minBid': () => 0.01,
		'helios.maxBid': () => 4,
	},
	'Xandr,lineItem,Completion Rate,video,CPM,click,helios': {
		'helios.minBid': () => 0.5,
		'helios.maxBid': () => 1.05,
	},
	'Xandr,lineItem,Completion Rate,audio,CPM,click,helios': {
		'helios.minBid': () => 0.5,
		'helios.maxBid': () => 1.05,
	},
	'Xandr,lineItem,Completion Rate,video,CPM,view,helios': {
		'helios.minBid': () => 0.01,
		'helios.maxBid': () => 1.25,
	},
	'Xandr,lineItem,Completion Rate,audio,CPM,view,helios': {
		'helios.minBid': () => 0.01,
		'helios.maxBid': () => 1.25,
	},
	'Xandr,lineItem,Completion Rate,video,CPM,video,helios': {
		'helios.minBid': () => 0.75,
		'helios.maxBid': () => 1.05,
	},
	'Xandr,lineItem,Completion Rate,audio,CPM,video,helios': {
		'helios.minBid': () => 0.75,
		'helios.maxBid': () => 1.05,
	},
	'Xandr,lineItem,CPA,video,CPM,impression,helios': {
		'helios.minBid': () => 0.01,
		'helios.maxBid': () => 1.1,
	},
	'Xandr,lineItem,CPA,audio,CPM,impression,helios': {
		'helios.minBid': () => 0.01,
		'helios.maxBid': () => 1.1,
	},
	'Xandr,lineItem,Conversion Rate,video,CPM,impression,helios': {
		'helios.minBid': () => 0.01,
		'helios.maxBid': () => 1.1,
	},
	'Xandr,lineItem,Conversion Rate,audio,CPM,impression,helios': {
		'helios.minBid': () => 0.01,
		'helios.maxBid': () => 1.1,
	},
	'Xandr,lineItem,CTR,video,CPM,impression,helios': {
		'helios.minBid': () => 0.5,
		'helios.maxBid': () => 1.1,
	},
	'Xandr,lineItem,CTR,audio,CPM,impression,helios': {
		'helios.minBid': () => 0.5,
		'helios.maxBid': () => 1.1,
	},
	'Xandr,lineItem,CPC,video,CPM,impression,helios': {
		'helios.minBid': () => 0.5,
		'helios.maxBid': () => 1.1,
	},
	'Xandr,lineItem,CPC,audio,CPM,impression,helios': {
		'helios.minBid': () => 0.5,
		'helios.maxBid': () => 1.1,
	},
	'Xandr,lineItem,Engagement Score,video,CPM,impression,helios': {
		'helios.minBid': () => 0.01,
		'helios.maxBid': () => 1.1,
	},
	'Xandr,lineItem,Engagement Score,audio,CPM,impression,helios': {
		'helios.minBid': () => 0.01,
		'helios.maxBid': () => 1.1,
	},
	'Xandr,lineItem,ROAS,video,CPM,impression,helios': {
		'helios.minBid': () => 0.01,
		'helios.maxBid': () => 1.1,
	},
	'Xandr,lineItem,ROAS,audio,CPM,impression,helios': {
		'helios.minBid': () => 0.01,
		'helios.maxBid': () => 1.1,
	},
	'Xandr,lineItem,CPCV,video,CPM,impression,helios': {
		'helios.minBid': () => 0.01,
		'helios.maxBid': () => 1.1,
	},
	'Xandr,lineItem,CPCV,audio,CPM,impression,helios': {
		'helios.minBid': () => 0.01,
		'helios.maxBid': () => 1.1,
	},
	'Xandr,lineItem,CPA,video,COST_PLUS_MARGIN,impression,helios': {
		'helios.minBid': () => 0.01,
		'helios.maxBid': () => 4,
	},
	'Xandr,lineItem,CPA,video,COST_PLUS_MARGIN,video,helios': {
		'helios.minBid': () => 0.01,
		'helios.maxBid': () => 4,
	},
	'Xandr,lineItem,CPA,video,COST_PLUS_MARGIN,view,helios': {
		'helios.minBid': () => 0.01,
		'helios.maxBid': () => 4,
	},
	'Xandr,lineItem,CPA,video,COST_PLUS_CPM,impression,helios': {
		'helios.minBid': () => 0.01,
		'helios.maxBid': () => 4,
	},
	'Xandr,lineItem,CPA,video,COST_PLUS_CPM,video,helios': {
		'helios.minBid': () => 0.01,
		'helios.maxBid': () => 4,
	},
	'Xandr,lineItem,CPA,video,COST_PLUS_CPM,view,helios': {
		'helios.minBid': () => 0.01,
		'helios.maxBid': () => 4,
	},
	'Xandr,lineItem,CPA,audio,COST_PLUS_MARGIN,impression,helios': {
		'helios.minBid': () => 0.01,
		'helios.maxBid': () => 4,
	},
	'Xandr,lineItem,CPA,audio,COST_PLUS_MARGIN,video,helios': {
		'helios.minBid': () => 0.01,
		'helios.maxBid': () => 4,
	},
	'Xandr,lineItem,CPA,audio,COST_PLUS_MARGIN,view,helios': {
		'helios.minBid': () => 0.01,
		'helios.maxBid': () => 4,
	},
	'Xandr,lineItem,CPA,audio,COST_PLUS_CPM,impression,helios': {
		'helios.minBid': () => 0.01,
		'helios.maxBid': () => 4,
	},
	'Xandr,lineItem,CPA,audio,COST_PLUS_CPM,video,helios': {
		'helios.minBid': () => 0.01,
		'helios.maxBid': () => 4,
	},
	'Xandr,lineItem,CPA,audio,COST_PLUS_CPM,view,helios': {
		'helios.minBid': () => 0.01,
		'helios.maxBid': () => 4,
	},
	'Xandr,lineItem,Conversion Rate,video,COST_PLUS_MARGIN,impression,helios': {
		'helios.minBid': () => 0.01,
		'helios.maxBid': () => 4,
	},
	'Xandr,lineItem,Conversion Rate,video,COST_PLUS_MARGIN,video,helios': {
		'helios.minBid': () => 0.01,
		'helios.maxBid': () => 4,
	},
	'Xandr,lineItem,Conversion Rate,video,COST_PLUS_MARGIN,view,helios': {
		'helios.minBid': () => 0.01,
		'helios.maxBid': () => 4,
	},
	'Xandr,lineItem,Conversion Rate,video,COST_PLUS_CPM,impression,helios': {
		'helios.minBid': () => 0.01,
		'helios.maxBid': () => 4,
	},
	'Xandr,lineItem,Conversion Rate,video,COST_PLUS_CPM,video,helios': {
		'helios.minBid': () => 0.01,
		'helios.maxBid': () => 4,
	},
	'Xandr,lineItem,Conversion Rate,video,COST_PLUS_CPM,view,helios': {
		'helios.minBid': () => 0.01,
		'helios.maxBid': () => 4,
	},
	'Xandr,lineItem,Conversion Rate,audio,COST_PLUS_MARGIN,impression,helios': {
		'helios.minBid': () => 0.01,
		'helios.maxBid': () => 4,
	},
	'Xandr,lineItem,Conversion Rate,audio,COST_PLUS_MARGIN,video,helios': {
		'helios.minBid': () => 0.01,
		'helios.maxBid': () => 4,
	},
	'Xandr,lineItem,Conversion Rate,audio,COST_PLUS_MARGIN,view,helios': {
		'helios.minBid': () => 0.01,
		'helios.maxBid': () => 4,
	},
	'Xandr,lineItem,Conversion Rate,audio,COST_PLUS_CPM,impression,helios': {
		'helios.minBid': () => 0.01,
		'helios.maxBid': () => 4,
	},
	'Xandr,lineItem,Conversion Rate,audio,COST_PLUS_CPM,video,helios': {
		'helios.minBid': () => 0.01,
		'helios.maxBid': () => 4,
	},
	'Xandr,lineItem,Conversion Rate,audio,COST_PLUS_CPM,view,helios': {
		'helios.minBid': () => 0.01,
		'helios.maxBid': () => 4,
	},
	'Xandr,lineItem,CTR,video,COST_PLUS_MARGIN,impression,helios': {
		'helios.minBid': () => 0.5,
		'helios.maxBid': () => 2,
	},
	'Xandr,lineItem,CTR,video,COST_PLUS_MARGIN,video,helios': {
		'helios.minBid': () => 0.5,
		'helios.maxBid': () => 2,
	},
	'Xandr,lineItem,CTR,video,COST_PLUS_MARGIN,view,helios': {
		'helios.minBid': () => 0.5,
		'helios.maxBid': () => 2,
	},
	'Xandr,lineItem,CTR,video,COST_PLUS_CPM,impression,helios': {
		'helios.minBid': () => 0.5,
		'helios.maxBid': () => 2,
	},
	'Xandr,lineItem,CTR,video,COST_PLUS_CPM,video,helios': {
		'helios.minBid': () => 0.5,
		'helios.maxBid': () => 2,
	},
	'Xandr,lineItem,CTR,video,COST_PLUS_CPM,view,helios': {
		'helios.minBid': () => 0.5,
		'helios.maxBid': () => 2,
	},
	'Xandr,lineItem,CTR,audio,COST_PLUS_MARGIN,impression,helios': {
		'helios.minBid': () => 0.5,
		'helios.maxBid': () => 2,
	},
	'Xandr,lineItem,CTR,audio,COST_PLUS_MARGIN,video,helios': {
		'helios.minBid': () => 0.5,
		'helios.maxBid': () => 2,
	},
	'Xandr,lineItem,CTR,audio,COST_PLUS_MARGIN,view,helios': {
		'helios.minBid': () => 0.5,
		'helios.maxBid': () => 2,
	},
	'Xandr,lineItem,CTR,audio,COST_PLUS_CPM,impression,helios': {
		'helios.minBid': () => 0.5,
		'helios.maxBid': () => 2,
	},
	'Xandr,lineItem,CTR,audio,COST_PLUS_CPM,video,helios': {
		'helios.minBid': () => 0.5,
		'helios.maxBid': () => 2,
	},
	'Xandr,lineItem,CTR,audio,COST_PLUS_CPM,view,helios': {
		'helios.minBid': () => 0.5,
		'helios.maxBid': () => 2,
	},
	'Xandr,lineItem,CPC,video,COST_PLUS_MARGIN,impression,helios': {
		'helios.minBid': () => 0.5,
		'helios.maxBid': () => 2,
	},
	'Xandr,lineItem,CPC,video,COST_PLUS_MARGIN,video,helios': {
		'helios.minBid': () => 0.5,
		'helios.maxBid': () => 2,
	},
	'Xandr,lineItem,CPC,video,COST_PLUS_MARGIN,view,helios': {
		'helios.minBid': () => 0.5,
		'helios.maxBid': () => 2,
	},
	'Xandr,lineItem,CPC,video,COST_PLUS_CPM,impression,helios': {
		'helios.minBid': () => 0.5,
		'helios.maxBid': () => 2,
	},
	'Xandr,lineItem,CPC,video,COST_PLUS_CPM,video,helios': {
		'helios.minBid': () => 0.5,
		'helios.maxBid': () => 2,
	},
	'Xandr,lineItem,CPC,video,COST_PLUS_CPM,view,helios': {
		'helios.minBid': () => 0.5,
		'helios.maxBid': () => 2,
	},
	'Xandr,lineItem,CPC,audio,COST_PLUS_MARGIN,impression,helios': {
		'helios.minBid': () => 0.5,
		'helios.maxBid': () => 2,
	},
	'Xandr,lineItem,CPC,audio,COST_PLUS_MARGIN,video,helios': {
		'helios.minBid': () => 0.5,
		'helios.maxBid': () => 2,
	},
	'Xandr,lineItem,CPC,audio,COST_PLUS_MARGIN,view,helios': {
		'helios.minBid': () => 0.5,
		'helios.maxBid': () => 2,
	},
	'Xandr,lineItem,CPC,audio,COST_PLUS_CPM,impression,helios': {
		'helios.minBid': () => 0.5,
		'helios.maxBid': () => 2,
	},
	'Xandr,lineItem,CPC,audio,COST_PLUS_CPM,video,helios': {
		'helios.minBid': () => 0.5,
		'helios.maxBid': () => 2,
	},
	'Xandr,lineItem,CPC,audio,COST_PLUS_CPM,view,helios': {
		'helios.minBid': () => 0.5,
		'helios.maxBid': () => 2,
	},
	'Xandr,lineItem,Engagement Score,video,COST_PLUS_MARGIN,impression,helios': {
		'helios.minBid': () => 0.01,
		'helios.maxBid': () => 4,
	},
	'Xandr,lineItem,Engagement Score,video,COST_PLUS_MARGIN,video,helios': {
		'helios.minBid': () => 0.01,
		'helios.maxBid': () => 4,
	},
	'Xandr,lineItem,Engagement Score,video,COST_PLUS_MARGIN,view,helios': {
		'helios.minBid': () => 0.01,
		'helios.maxBid': () => 4,
	},
	'Xandr,lineItem,Engagement Score,video,COST_PLUS_CPM,impression,helios': {
		'helios.minBid': () => 0.01,
		'helios.maxBid': () => 4,
	},
	'Xandr,lineItem,Engagement Score,video,COST_PLUS_CPM,video,helios': {
		'helios.minBid': () => 0.01,
		'helios.maxBid': () => 4,
	},
	'Xandr,lineItem,Engagement Score,video,COST_PLUS_CPM,view,helios': {
		'helios.minBid': () => 0.01,
		'helios.maxBid': () => 4,
	},
	'Xandr,lineItem,Engagement Score,audio,COST_PLUS_MARGIN,impression,helios': {
		'helios.minBid': () => 0.01,
		'helios.maxBid': () => 4,
	},
	'Xandr,lineItem,Engagement Score,audio,COST_PLUS_MARGIN,video,helios': {
		'helios.minBid': () => 0.01,
		'helios.maxBid': () => 4,
	},
	'Xandr,lineItem,Engagement Score,audio,COST_PLUS_MARGIN,view,helios': {
		'helios.minBid': () => 0.01,
		'helios.maxBid': () => 4,
	},
	'Xandr,lineItem,Engagement Score,audio,COST_PLUS_CPM,impression,helios': {
		'helios.minBid': () => 0.01,
		'helios.maxBid': () => 4,
	},
	'Xandr,lineItem,Engagement Score,audio,COST_PLUS_CPM,video,helios': {
		'helios.minBid': () => 0.01,
		'helios.maxBid': () => 4,
	},
	'Xandr,lineItem,Engagement Score,audio,COST_PLUS_CPM,view,helios': {
		'helios.minBid': () => 0.01,
		'helios.maxBid': () => 4,
	},
	'Xandr,lineItem,ROAS,video,COST_PLUS_MARGIN,impression,helios': {
		'helios.minBid': () => 0.01,
		'helios.maxBid': () => 4,
	},
	'Xandr,lineItem,ROAS,video,COST_PLUS_MARGIN,video,helios': {
		'helios.minBid': () => 0.01,
		'helios.maxBid': () => 4,
	},
	'Xandr,lineItem,ROAS,video,COST_PLUS_MARGIN,view,helios': {
		'helios.minBid': () => 0.01,
		'helios.maxBid': () => 4,
	},
	'Xandr,lineItem,ROAS,video,COST_PLUS_CPM,impression,helios': {
		'helios.minBid': () => 0.01,
		'helios.maxBid': () => 4,
	},
	'Xandr,lineItem,ROAS,video,COST_PLUS_CPM,video,helios': {
		'helios.minBid': () => 0.01,
		'helios.maxBid': () => 4,
	},
	'Xandr,lineItem,ROAS,video,COST_PLUS_CPM,view,helios': {
		'helios.minBid': () => 0.01,
		'helios.maxBid': () => 4,
	},
	'Xandr,lineItem,ROAS,audio,COST_PLUS_MARGIN,impression,helios': {
		'helios.minBid': () => 0.01,
		'helios.maxBid': () => 4,
	},
	'Xandr,lineItem,ROAS,audio,COST_PLUS_MARGIN,video,helios': {
		'helios.minBid': () => 0.01,
		'helios.maxBid': () => 4,
	},
	'Xandr,lineItem,ROAS,audio,COST_PLUS_MARGIN,view,helios': {
		'helios.minBid': () => 0.01,
		'helios.maxBid': () => 4,
	},
	'Xandr,lineItem,ROAS,audio,COST_PLUS_CPM,impression,helios': {
		'helios.minBid': () => 0.01,
		'helios.maxBid': () => 4,
	},
	'Xandr,lineItem,ROAS,audio,COST_PLUS_CPM,video,helios': {
		'helios.minBid': () => 0.01,
		'helios.maxBid': () => 4,
	},
	'Xandr,lineItem,ROAS,audio,COST_PLUS_CPM,view,helios': {
		'helios.minBid': () => 0.01,
		'helios.maxBid': () => 4,
	},
	'Xandr,lineItem,CPCV,video,COST_PLUS_MARGIN,impression,helios': {
		'helios.minBid': () => 0.01,
		'helios.maxBid': () => 4,
	},
	'Xandr,lineItem,CPCV,video,COST_PLUS_MARGIN,video,helios': {
		'helios.minBid': () => 0.01,
		'helios.maxBid': () => 4,
	},
	'Xandr,lineItem,CPCV,video,COST_PLUS_MARGIN,view,helios': {
		'helios.minBid': () => 0.01,
		'helios.maxBid': () => 4,
	},
	'Xandr,lineItem,CPCV,video,COST_PLUS_CPM,impression,helios': {
		'helios.minBid': () => 0.01,
		'helios.maxBid': () => 4,
	},
	'Xandr,lineItem,CPCV,video,COST_PLUS_CPM,video,helios': {
		'helios.minBid': () => 0.01,
		'helios.maxBid': () => 4,
	},
	'Xandr,lineItem,CPCV,video,COST_PLUS_CPM,view,helios': {
		'helios.minBid': () => 0.01,
		'helios.maxBid': () => 4,
	},
	'Xandr,lineItem,CPCV,audio,COST_PLUS_MARGIN,impression,helios': {
		'helios.minBid': () => 0.01,
		'helios.maxBid': () => 4,
	},
	'Xandr,lineItem,CPCV,audio,COST_PLUS_MARGIN,video,helios': {
		'helios.minBid': () => 0.01,
		'helios.maxBid': () => 4,
	},
	'Xandr,lineItem,CPCV,audio,COST_PLUS_MARGIN,view,helios': {
		'helios.minBid': () => 0.01,
		'helios.maxBid': () => 4,
	},
	'Xandr,lineItem,CPCV,audio,COST_PLUS_CPM,impression,helios': {
		'helios.minBid': () => 0.01,
		'helios.maxBid': () => 4,
	},
	'Xandr,lineItem,CPCV,audio,COST_PLUS_CPM,video,helios': {
		'helios.minBid': () => 0.01,
		'helios.maxBid': () => 4,
	},
	'Xandr,lineItem,CPCV,audio,COST_PLUS_CPM,view,helios': {
		'helios.minBid': () => 0.01,
		'helios.maxBid': () => 4,
	},
	'Xandr,lineItem,CPC,video,CPM,click,helios': {
		'helios.minBid': () => 0.75,
		'helios.maxBid': () => 1.05,
	},
	'Xandr,lineItem,CPC,audio,CPM,click,helios': {
		'helios.minBid': () => 0.75,
		'helios.maxBid': () => 1.05,
	},
	'Xandr,lineItem,CPA,video,CPM,click,helios': {
		'helios.minBid': () => 0.5,
		'helios.maxBid': () => 1.05,
	},
	'Xandr,lineItem,CPA,audio,CPM,click,helios': {
		'helios.minBid': () => 0.5,
		'helios.maxBid': () => 1.05,
	},
	'Xandr,lineItem,Conversion Rate,video,CPM,click,helios': {
		'helios.minBid': () => 0.5,
		'helios.maxBid': () => 1.05,
	},
	'Xandr,lineItem,Conversion Rate,audio,CPM,click,helios': {
		'helios.minBid': () => 0.5,
		'helios.maxBid': () => 1.05,
	},
	'Xandr,lineItem,CTR,video,CPM,click,helios': {
		'helios.minBid': () => 0.75,
		'helios.maxBid': () => 1.05,
	},
	'Xandr,lineItem,CTR,audio,CPM,click,helios': {
		'helios.minBid': () => 0.75,
		'helios.maxBid': () => 1.05,
	},
	'Xandr,lineItem,Engagement Score,video,CPM,click,helios': {
		'helios.minBid': () => 0.5,
		'helios.maxBid': () => 1.05,
	},
	'Xandr,lineItem,Engagement Score,audio,CPM,click,helios': {
		'helios.minBid': () => 0.5,
		'helios.maxBid': () => 1.05,
	},
	'Xandr,lineItem,ROAS,video,CPM,click,helios': {
		'helios.minBid': () => 0.5,
		'helios.maxBid': () => 1.05,
	},
	'Xandr,lineItem,ROAS,audio,CPM,click,helios': {
		'helios.minBid': () => 0.5,
		'helios.maxBid': () => 1.05,
	},
	'Xandr,lineItem,CPCV,video,CPM,click,helios': {
		'helios.minBid': () => 0.5,
		'helios.maxBid': () => 1.05,
	},
	'Xandr,lineItem,CPCV,audio,CPM,click,helios': {
		'helios.minBid': () => 0.5,
		'helios.maxBid': () => 1.05,
	},
	'Xandr,lineItem,CPCV,video,CPM,video,helios': {
		'helios.minBid': () => 0.75,
		'helios.maxBid': () => 1.05,
	},
	'Xandr,lineItem,CPCV,audio,CPM,video,helios': {
		'helios.minBid': () => 0.75,
		'helios.maxBid': () => 1.05,
	},
	'Xandr,lineItem,CPA,video,CPM,video,helios': {
		'helios.minBid': () => 0.5,
		'helios.maxBid': () => 1.05,
	},
	'Xandr,lineItem,CPA,audio,CPM,video,helios': {
		'helios.minBid': () => 0.5,
		'helios.maxBid': () => 1.05,
	},
	'Xandr,lineItem,Conversion Rate,video,CPM,video,helios': {
		'helios.minBid': () => 0.5,
		'helios.maxBid': () => 1.05,
	},
	'Xandr,lineItem,Conversion Rate,audio,CPM,video,helios': {
		'helios.minBid': () => 0.5,
		'helios.maxBid': () => 1.05,
	},
	'Xandr,lineItem,CTR,video,CPM,video,helios': {
		'helios.minBid': () => 0.5,
		'helios.maxBid': () => 1.05,
	},
	'Xandr,lineItem,CTR,audio,CPM,video,helios': {
		'helios.minBid': () => 0.5,
		'helios.maxBid': () => 1.05,
	},
	'Xandr,lineItem,Engagement Score,video,CPM,video,helios': {
		'helios.minBid': () => 0.5,
		'helios.maxBid': () => 1.05,
	},
	'Xandr,lineItem,Engagement Score,audio,CPM,video,helios': {
		'helios.minBid': () => 0.5,
		'helios.maxBid': () => 1.05,
	},
	'Xandr,lineItem,ROAS,video,CPM,video,helios': {
		'helios.minBid': () => 0.5,
		'helios.maxBid': () => 1.05,
	},
	'Xandr,lineItem,ROAS,audio,CPM,video,helios': {
		'helios.minBid': () => 0.5,
		'helios.maxBid': () => 1.05,
	},
	'Xandr,lineItem,CPC,video,CPM,video,helios': {
		'helios.minBid': () => 0.5,
		'helios.maxBid': () => 1.05,
	},
	'Xandr,lineItem,CPC,audio,CPM,video,helios': {
		'helios.minBid': () => 0.5,
		'helios.maxBid': () => 1.05,
	},
	'Xandr,lineItem,CPA,video,CPM,view,helios': {
		'helios.minBid': () => 0.01,
		'helios.maxBid': () => 1.25,
	},
	'Xandr,lineItem,CPA,audio,CPM,view,helios': {
		'helios.minBid': () => 0.01,
		'helios.maxBid': () => 1.25,
	},
	'Xandr,lineItem,Conversion Rate,video,CPM,view,helios': {
		'helios.minBid': () => 0.01,
		'helios.maxBid': () => 1.25,
	},
	'Xandr,lineItem,Conversion Rate,audio,CPM,view,helios': {
		'helios.minBid': () => 0.01,
		'helios.maxBid': () => 1.25,
	},
	'Xandr,lineItem,CTR,video,CPM,view,helios': {
		'helios.minBid': () => 0.5,
		'helios.maxBid': () => 1.1,
	},
	'Xandr,lineItem,CTR,audio,CPM,view,helios': {
		'helios.minBid': () => 0.5,
		'helios.maxBid': () => 1.1,
	},
	'Xandr,lineItem,CPC,video,CPM,view,helios': {
		'helios.minBid': () => 0.5,
		'helios.maxBid': () => 1.1,
	},
	'Xandr,lineItem,CPC,audio,CPM,view,helios': {
		'helios.minBid': () => 0.5,
		'helios.maxBid': () => 1.1,
	},
	'Xandr,lineItem,Engagement Score,video,CPM,view,helios': {
		'helios.minBid': () => 0.01,
		'helios.maxBid': () => 1.25,
	},
	'Xandr,lineItem,Engagement Score,audio,CPM,view,helios': {
		'helios.minBid': () => 0.01,
		'helios.maxBid': () => 1.25,
	},
	'Xandr,lineItem,ROAS,video,CPM,view,helios': {
		'helios.minBid': () => 0.01,
		'helios.maxBid': () => 1.25,
	},
	'Xandr,lineItem,ROAS,audio,CPM,view,helios': {
		'helios.minBid': () => 0.01,
		'helios.maxBid': () => 1.25,
	},
	'Xandr,lineItem,CPCV,video,CPM,view,helios': {
		'helios.minBid': () => 0.01,
		'helios.maxBid': () => 1.25,
	},
	'Xandr,lineItem,CPCV,audio,CPM,view,helios': {
		'helios.minBid': () => 0.01,
		'helios.maxBid': () => 1.25,
	},
	'Xandr,lineItem,CPC,banner,CPM,click,helios': {
		'helios.minBid': () => 0.75,
		'helios.maxBid': () => 1.05,
	},
	'Xandr,lineItem,CPC,native,CPM,click,helios': {
		'helios.minBid': () => 0.75,
		'helios.maxBid': () => 1.05,
	},
	'Xandr,lineItem,CPA,banner,CPM,click,helios': {
		'helios.minBid': () => 0.5,
		'helios.maxBid': () => 1.05,
	},
	'Xandr,lineItem,CPA,native,CPM,click,helios': {
		'helios.minBid': () => 0.5,
		'helios.maxBid': () => 1.05,
	},
	'Xandr,lineItem,Conversion Rate,banner,CPM,click,helios': {
		'helios.minBid': () => 0.5,
		'helios.maxBid': () => 1.05,
	},
	'Xandr,lineItem,Conversion Rate,native,CPM,click,helios': {
		'helios.minBid': () => 0.5,
		'helios.maxBid': () => 1.05,
	},
	'Xandr,lineItem,CTR,banner,CPM,click,helios': {
		'helios.minBid': () => 0.75,
		'helios.maxBid': () => 1.05,
	},
	'Xandr,lineItem,CTR,native,CPM,click,helios': {
		'helios.minBid': () => 0.75,
		'helios.maxBid': () => 1.05,
	},
	'Xandr,lineItem,Engagement Score,banner,CPM,click,helios': {
		'helios.minBid': () => 0.5,
		'helios.maxBid': () => 1.05,
	},
	'Xandr,lineItem,Engagement Score,native,CPM,click,helios': {
		'helios.minBid': () => 0.5,
		'helios.maxBid': () => 1.05,
	},
	'Xandr,lineItem,ROAS,banner,CPM,click,helios': {
		'helios.minBid': () => 0.5,
		'helios.maxBid': () => 1.05,
	},
	'Xandr,lineItem,ROAS,native,CPM,click,helios': {
		'helios.minBid': () => 0.5,
		'helios.maxBid': () => 1.05,
	},
	'Xandr,lineItem,CPA,banner,CPM,view,helios': {
		'helios.minBid': () => 0.01,
		'helios.maxBid': () => 1.25,
	},
	'Xandr,lineItem,CPA,native,CPM,view,helios': {
		'helios.minBid': () => 0.01,
		'helios.maxBid': () => 1.25,
	},
	'Xandr,lineItem,Conversion Rate,banner,CPM,view,helios': {
		'helios.minBid': () => 0.01,
		'helios.maxBid': () => 1.25,
	},
	'Xandr,lineItem,Conversion Rate,native,CPM,view,helios': {
		'helios.minBid': () => 0.01,
		'helios.maxBid': () => 1.25,
	},
	'Xandr,lineItem,CTR,banner,CPM,view,helios': {
		'helios.minBid': () => 0.5,
		'helios.maxBid': () => 1.1,
	},
	'Xandr,lineItem,CTR,native,CPM,view,helios': {
		'helios.minBid': () => 0.5,
		'helios.maxBid': () => 1.1,
	},
	'Xandr,lineItem,CPC,banner,CPM,view,helios': {
		'helios.minBid': () => 0.5,
		'helios.maxBid': () => 1.1,
	},
	'Xandr,lineItem,CPC,native,CPM,view,helios': {
		'helios.minBid': () => 0.5,
		'helios.maxBid': () => 1.1,
	},
	'Xandr,lineItem,Engagement Score,banner,CPM,view,helios': {
		'helios.minBid': () => 0.01,
		'helios.maxBid': () => 1.25,
	},
	'Xandr,lineItem,Engagement Score,native,CPM,view,helios': {
		'helios.minBid': () => 0.01,
		'helios.maxBid': () => 1.25,
	},
	'Xandr,lineItem,ROAS,banner,CPM,view,helios': {
		'helios.minBid': () => 0.01,
		'helios.maxBid': () => 1.25,
	},
	'Xandr,lineItem,ROAS,native,CPM,view,helios': {
		'helios.minBid': () => 0.01,
		'helios.maxBid': () => 1.25,
	},
	'Xandr,lineItem,CPA,banner,CPM,impression,helios': {
		'helios.minBid': () => 0.01,
		'helios.maxBid': () => 1.1,
	},
	'Xandr,lineItem,CPA,native,CPM,impression,helios': {
		'helios.minBid': () => 0.01,
		'helios.maxBid': () => 1.1,
	},
	'Xandr,lineItem,Conversion Rate,banner,CPM,impression,helios': {
		'helios.minBid': () => 0.01,
		'helios.maxBid': () => 1.1,
	},
	'Xandr,lineItem,Conversion Rate,native,CPM,impression,helios': {
		'helios.minBid': () => 0.01,
		'helios.maxBid': () => 1.1,
	},
	'Xandr,lineItem,CTR,banner,CPM,impression,helios': {
		'helios.minBid': () => 0.5,
		'helios.maxBid': () => 1.1,
	},
	'Xandr,lineItem,CTR,native,CPM,impression,helios': {
		'helios.minBid': () => 0.5,
		'helios.maxBid': () => 1.1,
	},
	'Xandr,lineItem,CPC,banner,CPM,impression,helios': {
		'helios.minBid': () => 0.5,
		'helios.maxBid': () => 1.1,
	},
	'Xandr,lineItem,CPC,native,CPM,impression,helios': {
		'helios.minBid': () => 0.5,
		'helios.maxBid': () => 1.1,
	},
	'Xandr,lineItem,Engagement Score,banner,CPM,impression,helios': {
		'helios.minBid': () => 0.01,
		'helios.maxBid': () => 1.1,
	},
	'Xandr,lineItem,Engagement Score,native,CPM,impression,helios': {
		'helios.minBid': () => 0.01,
		'helios.maxBid': () => 1.1,
	},
	'Xandr,lineItem,ROAS,banner,CPM,impression,helios': {
		'helios.minBid': () => 0.01,
		'helios.maxBid': () => 1.1,
	},
	'Xandr,lineItem,ROAS,native,CPM,impression,helios': {
		'helios.minBid': () => 0.01,
		'helios.maxBid': () => 1.1,
	},
	'Xandr,lineItem,CPA,banner,COST_PLUS_MARGIN,impression,helios': {
		'helios.minBid': () => 0.01,
		'helios.maxBid': () => 4,
	},
	'Xandr,lineItem,CPA,banner,COST_PLUS_MARGIN,video,helios': {
		'helios.minBid': () => 0.01,
		'helios.maxBid': () => 4,
	},
	'Xandr,lineItem,CPA,banner,COST_PLUS_MARGIN,view,helios': {
		'helios.minBid': () => 0.01,
		'helios.maxBid': () => 4,
	},
	'Xandr,lineItem,CPA,banner,COST_PLUS_CPM,impression,helios': {
		'helios.minBid': () => 0.01,
		'helios.maxBid': () => 4,
	},
	'Xandr,lineItem,CPA,banner,COST_PLUS_CPM,video,helios': {
		'helios.minBid': () => 0.01,
		'helios.maxBid': () => 4,
	},
	'Xandr,lineItem,CPA,banner,COST_PLUS_CPM,view,helios': {
		'helios.minBid': () => 0.01,
		'helios.maxBid': () => 4,
	},
	'Xandr,lineItem,CPA,native,COST_PLUS_MARGIN,impression,helios': {
		'helios.minBid': () => 0.01,
		'helios.maxBid': () => 4,
	},
	'Xandr,lineItem,CPA,native,COST_PLUS_MARGIN,video,helios': {
		'helios.minBid': () => 0.01,
		'helios.maxBid': () => 4,
	},
	'Xandr,lineItem,CPA,native,COST_PLUS_MARGIN,view,helios': {
		'helios.minBid': () => 0.01,
		'helios.maxBid': () => 4,
	},
	'Xandr,lineItem,CPA,native,COST_PLUS_CPM,impression,helios': {
		'helios.minBid': () => 0.01,
		'helios.maxBid': () => 4,
	},
	'Xandr,lineItem,CPA,native,COST_PLUS_CPM,video,helios': {
		'helios.minBid': () => 0.01,
		'helios.maxBid': () => 4,
	},
	'Xandr,lineItem,CPA,native,COST_PLUS_CPM,view,helios': {
		'helios.minBid': () => 0.01,
		'helios.maxBid': () => 4,
	},
	'Xandr,lineItem,Conversion Rate,banner,COST_PLUS_MARGIN,impression,helios': {
		'helios.minBid': () => 0.01,
		'helios.maxBid': () => 4,
	},
	'Xandr,lineItem,Conversion Rate,banner,COST_PLUS_MARGIN,video,helios': {
		'helios.minBid': () => 0.01,
		'helios.maxBid': () => 4,
	},
	'Xandr,lineItem,Conversion Rate,banner,COST_PLUS_MARGIN,view,helios': {
		'helios.minBid': () => 0.01,
		'helios.maxBid': () => 4,
	},
	'Xandr,lineItem,Conversion Rate,banner,COST_PLUS_CPM,impression,helios': {
		'helios.minBid': () => 0.01,
		'helios.maxBid': () => 4,
	},
	'Xandr,lineItem,Conversion Rate,banner,COST_PLUS_CPM,video,helios': {
		'helios.minBid': () => 0.01,
		'helios.maxBid': () => 4,
	},
	'Xandr,lineItem,Conversion Rate,banner,COST_PLUS_CPM,view,helios': {
		'helios.minBid': () => 0.01,
		'helios.maxBid': () => 4,
	},
	'Xandr,lineItem,Conversion Rate,native,COST_PLUS_MARGIN,impression,helios': {
		'helios.minBid': () => 0.01,
		'helios.maxBid': () => 4,
	},
	'Xandr,lineItem,Conversion Rate,native,COST_PLUS_MARGIN,video,helios': {
		'helios.minBid': () => 0.01,
		'helios.maxBid': () => 4,
	},
	'Xandr,lineItem,Conversion Rate,native,COST_PLUS_MARGIN,view,helios': {
		'helios.minBid': () => 0.01,
		'helios.maxBid': () => 4,
	},
	'Xandr,lineItem,Conversion Rate,native,COST_PLUS_CPM,impression,helios': {
		'helios.minBid': () => 0.01,
		'helios.maxBid': () => 4,
	},
	'Xandr,lineItem,Conversion Rate,native,COST_PLUS_CPM,video,helios': {
		'helios.minBid': () => 0.01,
		'helios.maxBid': () => 4,
	},
	'Xandr,lineItem,Conversion Rate,native,COST_PLUS_CPM,view,helios': {
		'helios.minBid': () => 0.01,
		'helios.maxBid': () => 4,
	},
	'Xandr,lineItem,CTR,banner,COST_PLUS_MARGIN,impression,helios': {
		'helios.minBid': () => 0.5,
		'helios.maxBid': () => 2,
	},
	'Xandr,lineItem,CTR,banner,COST_PLUS_MARGIN,video,helios': {
		'helios.minBid': () => 0.5,
		'helios.maxBid': () => 2,
	},
	'Xandr,lineItem,CTR,banner,COST_PLUS_MARGIN,view,helios': {
		'helios.minBid': () => 0.5,
		'helios.maxBid': () => 2,
	},
	'Xandr,lineItem,CTR,banner,COST_PLUS_CPM,impression,helios': {
		'helios.minBid': () => 0.5,
		'helios.maxBid': () => 2,
	},
	'Xandr,lineItem,CTR,banner,COST_PLUS_CPM,video,helios': {
		'helios.minBid': () => 0.5,
		'helios.maxBid': () => 2,
	},
	'Xandr,lineItem,CTR,banner,COST_PLUS_CPM,view,helios': {
		'helios.minBid': () => 0.5,
		'helios.maxBid': () => 2,
	},
	'Xandr,lineItem,CTR,native,COST_PLUS_MARGIN,impression,helios': {
		'helios.minBid': () => 0.5,
		'helios.maxBid': () => 2,
	},
	'Xandr,lineItem,CTR,native,COST_PLUS_MARGIN,video,helios': {
		'helios.minBid': () => 0.5,
		'helios.maxBid': () => 2,
	},
	'Xandr,lineItem,CTR,native,COST_PLUS_MARGIN,view,helios': {
		'helios.minBid': () => 0.5,
		'helios.maxBid': () => 2,
	},
	'Xandr,lineItem,CTR,native,COST_PLUS_CPM,impression,helios': {
		'helios.minBid': () => 0.5,
		'helios.maxBid': () => 2,
	},
	'Xandr,lineItem,CTR,native,COST_PLUS_CPM,video,helios': {
		'helios.minBid': () => 0.5,
		'helios.maxBid': () => 2,
	},
	'Xandr,lineItem,CTR,native,COST_PLUS_CPM,view,helios': {
		'helios.minBid': () => 0.5,
		'helios.maxBid': () => 2,
	},
	'Xandr,lineItem,CPC,banner,COST_PLUS_MARGIN,impression,helios': {
		'helios.minBid': () => 0.5,
		'helios.maxBid': () => 2,
	},
	'Xandr,lineItem,CPC,banner,COST_PLUS_MARGIN,video,helios': {
		'helios.minBid': () => 0.5,
		'helios.maxBid': () => 2,
	},
	'Xandr,lineItem,CPC,banner,COST_PLUS_MARGIN,view,helios': {
		'helios.minBid': () => 0.5,
		'helios.maxBid': () => 2,
	},
	'Xandr,lineItem,CPC,banner,COST_PLUS_CPM,impression,helios': {
		'helios.minBid': () => 0.5,
		'helios.maxBid': () => 2,
	},
	'Xandr,lineItem,CPC,banner,COST_PLUS_CPM,video,helios': {
		'helios.minBid': () => 0.5,
		'helios.maxBid': () => 2,
	},
	'Xandr,lineItem,CPC,banner,COST_PLUS_CPM,view,helios': {
		'helios.minBid': () => 0.5,
		'helios.maxBid': () => 2,
	},
	'Xandr,lineItem,CPC,native,COST_PLUS_MARGIN,impression,helios': {
		'helios.minBid': () => 0.5,
		'helios.maxBid': () => 2,
	},
	'Xandr,lineItem,CPC,native,COST_PLUS_MARGIN,video,helios': {
		'helios.minBid': () => 0.5,
		'helios.maxBid': () => 2,
	},
	'Xandr,lineItem,CPC,native,COST_PLUS_MARGIN,view,helios': {
		'helios.minBid': () => 0.5,
		'helios.maxBid': () => 2,
	},
	'Xandr,lineItem,CPC,native,COST_PLUS_CPM,impression,helios': {
		'helios.minBid': () => 0.5,
		'helios.maxBid': () => 2,
	},
	'Xandr,lineItem,CPC,native,COST_PLUS_CPM,video,helios': {
		'helios.minBid': () => 0.5,
		'helios.maxBid': () => 2,
	},
	'Xandr,lineItem,CPC,native,COST_PLUS_CPM,view,helios': {
		'helios.minBid': () => 0.5,
		'helios.maxBid': () => 2,
	},
	'Xandr,lineItem,Engagement Score,banner,COST_PLUS_MARGIN,impression,helios': {
		'helios.minBid': () => 0.01,
		'helios.maxBid': () => 4,
	},
	'Xandr,lineItem,Engagement Score,banner,COST_PLUS_MARGIN,video,helios': {
		'helios.minBid': () => 0.01,
		'helios.maxBid': () => 4,
	},
	'Xandr,lineItem,Engagement Score,banner,COST_PLUS_MARGIN,view,helios': {
		'helios.minBid': () => 0.01,
		'helios.maxBid': () => 4,
	},
	'Xandr,lineItem,Engagement Score,banner,COST_PLUS_CPM,impression,helios': {
		'helios.minBid': () => 0.01,
		'helios.maxBid': () => 4,
	},
	'Xandr,lineItem,Engagement Score,banner,COST_PLUS_CPM,video,helios': {
		'helios.minBid': () => 0.01,
		'helios.maxBid': () => 4,
	},
	'Xandr,lineItem,Engagement Score,banner,COST_PLUS_CPM,view,helios': {
		'helios.minBid': () => 0.01,
		'helios.maxBid': () => 4,
	},
	'Xandr,lineItem,Engagement Score,native,COST_PLUS_MARGIN,impression,helios': {
		'helios.minBid': () => 0.01,
		'helios.maxBid': () => 4,
	},
	'Xandr,lineItem,Engagement Score,native,COST_PLUS_MARGIN,video,helios': {
		'helios.minBid': () => 0.01,
		'helios.maxBid': () => 4,
	},
	'Xandr,lineItem,Engagement Score,native,COST_PLUS_MARGIN,view,helios': {
		'helios.minBid': () => 0.01,
		'helios.maxBid': () => 4,
	},
	'Xandr,lineItem,Engagement Score,native,COST_PLUS_CPM,impression,helios': {
		'helios.minBid': () => 0.01,
		'helios.maxBid': () => 4,
	},
	'Xandr,lineItem,Engagement Score,native,COST_PLUS_CPM,video,helios': {
		'helios.minBid': () => 0.01,
		'helios.maxBid': () => 4,
	},
	'Xandr,lineItem,Engagement Score,native,COST_PLUS_CPM,view,helios': {
		'helios.minBid': () => 0.01,
		'helios.maxBid': () => 4,
	},
	'Xandr,lineItem,ROAS,banner,COST_PLUS_MARGIN,impression,helios': {
		'helios.minBid': () => 0.01,
		'helios.maxBid': () => 4,
	},
	'Xandr,lineItem,ROAS,banner,COST_PLUS_MARGIN,video,helios': {
		'helios.minBid': () => 0.01,
		'helios.maxBid': () => 4,
	},
	'Xandr,lineItem,ROAS,banner,COST_PLUS_MARGIN,view,helios': {
		'helios.minBid': () => 0.01,
		'helios.maxBid': () => 4,
	},
	'Xandr,lineItem,ROAS,banner,COST_PLUS_CPM,impression,helios': {
		'helios.minBid': () => 0.01,
		'helios.maxBid': () => 4,
	},
	'Xandr,lineItem,ROAS,banner,COST_PLUS_CPM,video,helios': {
		'helios.minBid': () => 0.01,
		'helios.maxBid': () => 4,
	},
	'Xandr,lineItem,ROAS,banner,COST_PLUS_CPM,view,helios': {
		'helios.minBid': () => 0.01,
		'helios.maxBid': () => 4,
	},
	'Xandr,lineItem,ROAS,native,COST_PLUS_MARGIN,impression,helios': {
		'helios.minBid': () => 0.01,
		'helios.maxBid': () => 4,
	},
	'Xandr,lineItem,ROAS,native,COST_PLUS_MARGIN,video,helios': {
		'helios.minBid': () => 0.01,
		'helios.maxBid': () => 4,
	},
	'Xandr,lineItem,ROAS,native,COST_PLUS_MARGIN,view,helios': {
		'helios.minBid': () => 0.01,
		'helios.maxBid': () => 4,
	},
	'Xandr,lineItem,ROAS,native,COST_PLUS_CPM,impression,helios': {
		'helios.minBid': () => 0.01,
		'helios.maxBid': () => 4,
	},
	'Xandr,lineItem,ROAS,native,COST_PLUS_CPM,video,helios': {
		'helios.minBid': () => 0.01,
		'helios.maxBid': () => 4,
	},
	'Xandr,lineItem,ROAS,native,COST_PLUS_CPM,view,helios': {
		'helios.minBid': () => 0.01,
		'helios.maxBid': () => 4,
	},
	'Xandr,lineItem,CPA,banner,VCPM,impression,heliosSegmentRecency': {
		'heliosSegmentRecency.startingMinBid': () => 0.5,
		'heliosSegmentRecency.startingMaxBid': () => 2,
		'heliosSegmentRecency.minBid': () => 0.01,
		'heliosSegmentRecency.maxBid': () => 3,
	},
	'Xandr,lineItem,CPA,native,VCPM,impression,heliosSegmentRecency': {
		'heliosSegmentRecency.startingMinBid': () => 0.5,
		'heliosSegmentRecency.startingMaxBid': () => 2,
		'heliosSegmentRecency.minBid': () => 0.01,
		'heliosSegmentRecency.maxBid': () => 3,
	},
	'Xandr,lineItem,CPA,video,VCPM,impression,heliosSegmentRecency': {
		'heliosSegmentRecency.startingMinBid': () => 0.5,
		'heliosSegmentRecency.startingMaxBid': () => 2,
		'heliosSegmentRecency.minBid': () => 0.01,
		'heliosSegmentRecency.maxBid': () => 3,
	},
	'Xandr,lineItem,CPA,audio,VCPM,impression,heliosSegmentRecency': {
		'heliosSegmentRecency.startingMinBid': () => 0.5,
		'heliosSegmentRecency.startingMaxBid': () => 2,
		'heliosSegmentRecency.minBid': () => 0.01,
		'heliosSegmentRecency.maxBid': () => 3,
	},
	'Xandr,lineItem,Conversion Rate,banner,VCPM,impression,heliosSegmentRecency': {
		'heliosSegmentRecency.startingMinBid': () => 0.5,
		'heliosSegmentRecency.startingMaxBid': () => 2,
		'heliosSegmentRecency.minBid': () => 0.01,
		'heliosSegmentRecency.maxBid': () => 3,
	},
	'Xandr,lineItem,Conversion Rate,native,VCPM,impression,heliosSegmentRecency': {
		'heliosSegmentRecency.startingMinBid': () => 0.5,
		'heliosSegmentRecency.startingMaxBid': () => 2,
		'heliosSegmentRecency.minBid': () => 0.01,
		'heliosSegmentRecency.maxBid': () => 3,
	},
	'Xandr,lineItem,Conversion Rate,video,VCPM,impression,heliosSegmentRecency': {
		'heliosSegmentRecency.startingMinBid': () => 0.5,
		'heliosSegmentRecency.startingMaxBid': () => 2,
		'heliosSegmentRecency.minBid': () => 0.01,
		'heliosSegmentRecency.maxBid': () => 3,
	},
	'Xandr,lineItem,Conversion Rate,audio,VCPM,impression,heliosSegmentRecency': {
		'heliosSegmentRecency.startingMinBid': () => 0.5,
		'heliosSegmentRecency.startingMaxBid': () => 2,
		'heliosSegmentRecency.minBid': () => 0.01,
		'heliosSegmentRecency.maxBid': () => 3,
	},
	'Xandr,lineItem,Completion Rate,video,VCPM,impression,helios': {
		'helios.minBid': () => 0.01,
		'helios.maxBid': () => 1.25,
	},
	'Xandr,lineItem,Completion Rate,audio,VCPM,impression,helios': {
		'helios.minBid': () => 0.01,
		'helios.maxBid': () => 1.25,
	},
	'Xandr,lineItem,CPA,video,VCPM,impression,helios': {
		'helios.minBid': () => 0.01,
		'helios.maxBid': () => 1.25,
	},
	'Xandr,lineItem,CPA,audio,VCPM,impression,helios': {
		'helios.minBid': () => 0.01,
		'helios.maxBid': () => 1.25,
	},
	'Xandr,lineItem,Conversion Rate,video,VCPM,impression,helios': {
		'helios.minBid': () => 0.01,
		'helios.maxBid': () => 1.25,
	},
	'Xandr,lineItem,Conversion Rate,audio,VCPM,impression,helios': {
		'helios.minBid': () => 0.01,
		'helios.maxBid': () => 1.25,
	},
	'Xandr,lineItem,CTR,video,VCPM,impression,helios': {
		'helios.minBid': () => 0.5,
		'helios.maxBid': () => 1.1,
	},
	'Xandr,lineItem,CTR,audio,VCPM,impression,helios': {
		'helios.minBid': () => 0.5,
		'helios.maxBid': () => 1.1,
	},
	'Xandr,lineItem,CPC,video,VCPM,impression,helios': {
		'helios.minBid': () => 0.5,
		'helios.maxBid': () => 1.1,
	},
	'Xandr,lineItem,CPC,audio,VCPM,impression,helios': {
		'helios.minBid': () => 0.5,
		'helios.maxBid': () => 1.1,
	},
	'Xandr,lineItem,Engagement Score,video,VCPM,impression,helios': {
		'helios.minBid': () => 0.01,
		'helios.maxBid': () => 1.25,
	},
	'Xandr,lineItem,Engagement Score,audio,VCPM,impression,helios': {
		'helios.minBid': () => 0.01,
		'helios.maxBid': () => 1.25,
	},
	'Xandr,lineItem,ROAS,video,VCPM,impression,helios': {
		'helios.minBid': () => 0.01,
		'helios.maxBid': () => 1.25,
	},
	'Xandr,lineItem,ROAS,audio,VCPM,impression,helios': {
		'helios.minBid': () => 0.01,
		'helios.maxBid': () => 1.25,
	},
	'Xandr,lineItem,CPCV,video,VCPM,impression,helios': {
		'helios.minBid': () => 0.01,
		'helios.maxBid': () => 1.25,
	},
	'Xandr,lineItem,CPCV,audio,VCPM,impression,helios': {
		'helios.minBid': () => 0.01,
		'helios.maxBid': () => 1.25,
	},
	'Xandr,lineItem,CPA,banner,VCPM,impression,helios': {
		'helios.minBid': () => 0.01,
		'helios.maxBid': () => 1.25,
	},
	'Xandr,lineItem,CPA,native,VCPM,impression,helios': {
		'helios.minBid': () => 0.01,
		'helios.maxBid': () => 1.25,
	},
	'Xandr,lineItem,Conversion Rate,banner,VCPM,impression,helios': {
		'helios.minBid': () => 0.01,
		'helios.maxBid': () => 1.25,
	},
	'Xandr,lineItem,Conversion Rate,native,VCPM,impression,helios': {
		'helios.minBid': () => 0.01,
		'helios.maxBid': () => 1.25,
	},
	'Xandr,lineItem,CTR,banner,VCPM,impression,helios': {
		'helios.minBid': () => 0.5,
		'helios.maxBid': () => 1.1,
	},
	'Xandr,lineItem,CTR,native,VCPM,impression,helios': {
		'helios.minBid': () => 0.5,
		'helios.maxBid': () => 1.1,
	},
	'Xandr,lineItem,CPC,banner,VCPM,impression,helios': {
		'helios.minBid': () => 0.5,
		'helios.maxBid': () => 1.1,
	},
	'Xandr,lineItem,CPC,native,VCPM,impression,helios': {
		'helios.minBid': () => 0.5,
		'helios.maxBid': () => 1.1,
	},
	'Xandr,lineItem,Engagement Score,banner,VCPM,impression,helios': {
		'helios.minBid': () => 0.01,
		'helios.maxBid': () => 1.25,
	},
	'Xandr,lineItem,Engagement Score,native,VCPM,impression,helios': {
		'helios.minBid': () => 0.01,
		'helios.maxBid': () => 1.25,
	},
	'Xandr,lineItem,ROAS,banner,VCPM,impression,helios': {
		'helios.minBid': () => 0.01,
		'helios.maxBid': () => 1.25,
	},
	'Xandr,lineItem,ROAS,native,VCPM,impression,helios': {
		'helios.minBid': () => 0.01,
		'helios.maxBid': () => 1.25,
	},
	'Xandr,lineItem,ROAS,banner,CPM,impression,heliosSegmentRecency': {
		'heliosSegmentRecency.startingMinBid': () => 0.5,
		'heliosSegmentRecency.startingMaxBid': () => 2,
		'heliosSegmentRecency.minBid': () => 0.01,
		'heliosSegmentRecency.maxBid': () => 3,
	},
	'Xandr,lineItem,ROAS,native,CPM,impression,heliosSegmentRecency': {
		'heliosSegmentRecency.startingMinBid': () => 0.5,
		'heliosSegmentRecency.startingMaxBid': () => 2,
		'heliosSegmentRecency.minBid': () => 0.01,
		'heliosSegmentRecency.maxBid': () => 3,
	},
	'Xandr,lineItem,ROAS,video,CPM,impression,heliosSegmentRecency': {
		'heliosSegmentRecency.startingMinBid': () => 0.5,
		'heliosSegmentRecency.startingMaxBid': () => 2,
		'heliosSegmentRecency.minBid': () => 0.01,
		'heliosSegmentRecency.maxBid': () => 3,
	},
	'Xandr,lineItem,ROAS,audio,CPM,impression,heliosSegmentRecency': {
		'heliosSegmentRecency.startingMinBid': () => 0.5,
		'heliosSegmentRecency.startingMaxBid': () => 2,
		'heliosSegmentRecency.minBid': () => 0.01,
		'heliosSegmentRecency.maxBid': () => 3,
	},
	'Xandr,lineItem,ROAS,banner,COST_PLUS_MARGIN,impression,heliosSegmentRecency': {
		'heliosSegmentRecency.startingMinBid': () => 0.5,
		'heliosSegmentRecency.startingMaxBid': () => 2,
		'heliosSegmentRecency.minBid': () => 0.01,
		'heliosSegmentRecency.maxBid': () => 4,
	},
	'Xandr,lineItem,ROAS,banner,COST_PLUS_MARGIN,video,heliosSegmentRecency': {
		'heliosSegmentRecency.startingMinBid': () => 0.5,
		'heliosSegmentRecency.startingMaxBid': () => 2,
		'heliosSegmentRecency.minBid': () => 0.01,
		'heliosSegmentRecency.maxBid': () => 4,
	},
	'Xandr,lineItem,ROAS,banner,COST_PLUS_MARGIN,view,heliosSegmentRecency': {
		'heliosSegmentRecency.startingMinBid': () => 0.5,
		'heliosSegmentRecency.startingMaxBid': () => 2,
		'heliosSegmentRecency.minBid': () => 0.01,
		'heliosSegmentRecency.maxBid': () => 4,
	},
	'Xandr,lineItem,ROAS,banner,COST_PLUS_CPM,impression,heliosSegmentRecency': {
		'heliosSegmentRecency.startingMinBid': () => 0.5,
		'heliosSegmentRecency.startingMaxBid': () => 2,
		'heliosSegmentRecency.minBid': () => 0.01,
		'heliosSegmentRecency.maxBid': () => 4,
	},
	'Xandr,lineItem,ROAS,banner,COST_PLUS_CPM,video,heliosSegmentRecency': {
		'heliosSegmentRecency.startingMinBid': () => 0.5,
		'heliosSegmentRecency.startingMaxBid': () => 2,
		'heliosSegmentRecency.minBid': () => 0.01,
		'heliosSegmentRecency.maxBid': () => 4,
	},
	'Xandr,lineItem,ROAS,banner,COST_PLUS_CPM,view,heliosSegmentRecency': {
		'heliosSegmentRecency.startingMinBid': () => 0.5,
		'heliosSegmentRecency.startingMaxBid': () => 2,
		'heliosSegmentRecency.minBid': () => 0.01,
		'heliosSegmentRecency.maxBid': () => 4,
	},
	'Xandr,lineItem,ROAS,native,COST_PLUS_MARGIN,impression,heliosSegmentRecency': {
		'heliosSegmentRecency.startingMinBid': () => 0.5,
		'heliosSegmentRecency.startingMaxBid': () => 2,
		'heliosSegmentRecency.minBid': () => 0.01,
		'heliosSegmentRecency.maxBid': () => 4,
	},
	'Xandr,lineItem,ROAS,native,COST_PLUS_MARGIN,video,heliosSegmentRecency': {
		'heliosSegmentRecency.startingMinBid': () => 0.5,
		'heliosSegmentRecency.startingMaxBid': () => 2,
		'heliosSegmentRecency.minBid': () => 0.01,
		'heliosSegmentRecency.maxBid': () => 4,
	},
	'Xandr,lineItem,ROAS,native,COST_PLUS_MARGIN,view,heliosSegmentRecency': {
		'heliosSegmentRecency.startingMinBid': () => 0.5,
		'heliosSegmentRecency.startingMaxBid': () => 2,
		'heliosSegmentRecency.minBid': () => 0.01,
		'heliosSegmentRecency.maxBid': () => 4,
	},
	'Xandr,lineItem,ROAS,native,COST_PLUS_CPM,impression,heliosSegmentRecency': {
		'heliosSegmentRecency.startingMinBid': () => 0.5,
		'heliosSegmentRecency.startingMaxBid': () => 2,
		'heliosSegmentRecency.minBid': () => 0.01,
		'heliosSegmentRecency.maxBid': () => 4,
	},
	'Xandr,lineItem,ROAS,native,COST_PLUS_CPM,video,heliosSegmentRecency': {
		'heliosSegmentRecency.startingMinBid': () => 0.5,
		'heliosSegmentRecency.startingMaxBid': () => 2,
		'heliosSegmentRecency.minBid': () => 0.01,
		'heliosSegmentRecency.maxBid': () => 4,
	},
	'Xandr,lineItem,ROAS,native,COST_PLUS_CPM,view,heliosSegmentRecency': {
		'heliosSegmentRecency.startingMinBid': () => 0.5,
		'heliosSegmentRecency.startingMaxBid': () => 2,
		'heliosSegmentRecency.minBid': () => 0.01,
		'heliosSegmentRecency.maxBid': () => 4,
	},
	'Xandr,lineItem,ROAS,video,COST_PLUS_MARGIN,impression,heliosSegmentRecency': {
		'heliosSegmentRecency.startingMinBid': () => 0.5,
		'heliosSegmentRecency.startingMaxBid': () => 2,
		'heliosSegmentRecency.minBid': () => 0.01,
		'heliosSegmentRecency.maxBid': () => 4,
	},
	'Xandr,lineItem,ROAS,video,COST_PLUS_MARGIN,video,heliosSegmentRecency': {
		'heliosSegmentRecency.startingMinBid': () => 0.5,
		'heliosSegmentRecency.startingMaxBid': () => 2,
		'heliosSegmentRecency.minBid': () => 0.01,
		'heliosSegmentRecency.maxBid': () => 4,
	},
	'Xandr,lineItem,ROAS,video,COST_PLUS_MARGIN,view,heliosSegmentRecency': {
		'heliosSegmentRecency.startingMinBid': () => 0.5,
		'heliosSegmentRecency.startingMaxBid': () => 2,
		'heliosSegmentRecency.minBid': () => 0.01,
		'heliosSegmentRecency.maxBid': () => 4,
	},
	'Xandr,lineItem,ROAS,video,COST_PLUS_CPM,impression,heliosSegmentRecency': {
		'heliosSegmentRecency.startingMinBid': () => 0.5,
		'heliosSegmentRecency.startingMaxBid': () => 2,
		'heliosSegmentRecency.minBid': () => 0.01,
		'heliosSegmentRecency.maxBid': () => 4,
	},
	'Xandr,lineItem,ROAS,video,COST_PLUS_CPM,video,heliosSegmentRecency': {
		'heliosSegmentRecency.startingMinBid': () => 0.5,
		'heliosSegmentRecency.startingMaxBid': () => 2,
		'heliosSegmentRecency.minBid': () => 0.01,
		'heliosSegmentRecency.maxBid': () => 4,
	},
	'Xandr,lineItem,ROAS,video,COST_PLUS_CPM,view,heliosSegmentRecency': {
		'heliosSegmentRecency.startingMinBid': () => 0.5,
		'heliosSegmentRecency.startingMaxBid': () => 2,
		'heliosSegmentRecency.minBid': () => 0.01,
		'heliosSegmentRecency.maxBid': () => 4,
	},
	'Xandr,lineItem,ROAS,audio,COST_PLUS_MARGIN,impression,heliosSegmentRecency': {
		'heliosSegmentRecency.startingMinBid': () => 0.5,
		'heliosSegmentRecency.startingMaxBid': () => 2,
		'heliosSegmentRecency.minBid': () => 0.01,
		'heliosSegmentRecency.maxBid': () => 4,
	},
	'Xandr,lineItem,ROAS,audio,COST_PLUS_MARGIN,video,heliosSegmentRecency': {
		'heliosSegmentRecency.startingMinBid': () => 0.5,
		'heliosSegmentRecency.startingMaxBid': () => 2,
		'heliosSegmentRecency.minBid': () => 0.01,
		'heliosSegmentRecency.maxBid': () => 4,
	},
	'Xandr,lineItem,ROAS,audio,COST_PLUS_MARGIN,view,heliosSegmentRecency': {
		'heliosSegmentRecency.startingMinBid': () => 0.5,
		'heliosSegmentRecency.startingMaxBid': () => 2,
		'heliosSegmentRecency.minBid': () => 0.01,
		'heliosSegmentRecency.maxBid': () => 4,
	},
	'Xandr,lineItem,ROAS,audio,COST_PLUS_CPM,impression,heliosSegmentRecency': {
		'heliosSegmentRecency.startingMinBid': () => 0.5,
		'heliosSegmentRecency.startingMaxBid': () => 2,
		'heliosSegmentRecency.minBid': () => 0.01,
		'heliosSegmentRecency.maxBid': () => 4,
	},
	'Xandr,lineItem,ROAS,audio,COST_PLUS_CPM,video,heliosSegmentRecency': {
		'heliosSegmentRecency.startingMinBid': () => 0.5,
		'heliosSegmentRecency.startingMaxBid': () => 2,
		'heliosSegmentRecency.minBid': () => 0.01,
		'heliosSegmentRecency.maxBid': () => 4,
	},
	'Xandr,lineItem,ROAS,audio,COST_PLUS_CPM,view,heliosSegmentRecency': {
		'heliosSegmentRecency.startingMinBid': () => 0.5,
		'heliosSegmentRecency.startingMaxBid': () => 2,
		'heliosSegmentRecency.minBid': () => 0.01,
		'heliosSegmentRecency.maxBid': () => 4,
	},
	'Xandr,lineItem,ROAS,banner,CPM,click,heliosSegmentRecency': {
		'heliosSegmentRecency.startingMinBid': () => 0.75,
		'heliosSegmentRecency.startingMaxBid': () => 1.5,
		'heliosSegmentRecency.minBid': () => 0.01,
		'heliosSegmentRecency.maxBid': () => 2,
	},
	'Xandr,lineItem,ROAS,native,CPM,click,heliosSegmentRecency': {
		'heliosSegmentRecency.startingMinBid': () => 0.75,
		'heliosSegmentRecency.startingMaxBid': () => 1.5,
		'heliosSegmentRecency.minBid': () => 0.01,
		'heliosSegmentRecency.maxBid': () => 2,
	},
	'Xandr,lineItem,ROAS,video,CPM,click,heliosSegmentRecency': {
		'heliosSegmentRecency.startingMinBid': () => 0.75,
		'heliosSegmentRecency.startingMaxBid': () => 1.5,
		'heliosSegmentRecency.minBid': () => 0.01,
		'heliosSegmentRecency.maxBid': () => 2,
	},
	'Xandr,lineItem,ROAS,audio,CPM,click,heliosSegmentRecency': {
		'heliosSegmentRecency.startingMinBid': () => 0.75,
		'heliosSegmentRecency.startingMaxBid': () => 1.5,
		'heliosSegmentRecency.minBid': () => 0.01,
		'heliosSegmentRecency.maxBid': () => 2,
	},
	'Xandr,lineItem,ROAS,banner,CPM,view,heliosSegmentRecency': {
		'heliosSegmentRecency.startingMinBid': () => 0.5,
		'heliosSegmentRecency.startingMaxBid': () => 2,
		'heliosSegmentRecency.minBid': () => 0.01,
		'heliosSegmentRecency.maxBid': () => 3,
	},
	'Xandr,lineItem,ROAS,native,CPM,view,heliosSegmentRecency': {
		'heliosSegmentRecency.startingMinBid': () => 0.5,
		'heliosSegmentRecency.startingMaxBid': () => 2,
		'heliosSegmentRecency.minBid': () => 0.01,
		'heliosSegmentRecency.maxBid': () => 3,
	},
	'Xandr,lineItem,ROAS,video,CPM,view,heliosSegmentRecency': {
		'heliosSegmentRecency.startingMinBid': () => 0.5,
		'heliosSegmentRecency.startingMaxBid': () => 2,
		'heliosSegmentRecency.minBid': () => 0.01,
		'heliosSegmentRecency.maxBid': () => 3,
	},
	'Xandr,lineItem,ROAS,audio,CPM,view,heliosSegmentRecency': {
		'heliosSegmentRecency.startingMinBid': () => 0.5,
		'heliosSegmentRecency.startingMaxBid': () => 2,
		'heliosSegmentRecency.minBid': () => 0.01,
		'heliosSegmentRecency.maxBid': () => 3,
	},
	'Xandr,lineItem,ROAS,video,CPM,video,heliosSegmentRecency': {
		'heliosSegmentRecency.startingMinBid': () => 0.75,
		'heliosSegmentRecency.startingMaxBid': () => 1.5,
		'heliosSegmentRecency.minBid': () => 0.01,
		'heliosSegmentRecency.maxBid': () => 2,
	},
	'Xandr,lineItem,ROAS,audio,CPM,video,heliosSegmentRecency': {
		'heliosSegmentRecency.startingMinBid': () => 0.75,
		'heliosSegmentRecency.startingMaxBid': () => 1.5,
		'heliosSegmentRecency.minBid': () => 0.01,
		'heliosSegmentRecency.maxBid': () => 2,
	},
	'Xandr,lineItem,ROAS,banner,VCPM,impression,heliosSegmentRecency': {
		'heliosSegmentRecency.startingMinBid': () => 0.5,
		'heliosSegmentRecency.startingMaxBid': () => 2,
		'heliosSegmentRecency.minBid': () => 0.01,
		'heliosSegmentRecency.maxBid': () => 3,
	},
	'Xandr,lineItem,ROAS,native,VCPM,impression,heliosSegmentRecency': {
		'heliosSegmentRecency.startingMinBid': () => 0.5,
		'heliosSegmentRecency.startingMaxBid': () => 2,
		'heliosSegmentRecency.minBid': () => 0.01,
		'heliosSegmentRecency.maxBid': () => 3,
	},
	'Xandr,lineItem,ROAS,video,VCPM,impression,heliosSegmentRecency': {
		'heliosSegmentRecency.startingMinBid': () => 0.5,
		'heliosSegmentRecency.startingMaxBid': () => 2,
		'heliosSegmentRecency.minBid': () => 0.01,
		'heliosSegmentRecency.maxBid': () => 3,
	},
	'Xandr,lineItem,ROAS,audio,VCPM,impression,heliosSegmentRecency': {
		'heliosSegmentRecency.startingMinBid': () => 0.5,
		'heliosSegmentRecency.startingMaxBid': () => 2,
		'heliosSegmentRecency.minBid': () => 0.01,
		'heliosSegmentRecency.maxBid': () => 3,
	},
	'Xandr,lineItem,Engagement Score,banner,CPM,impression,heliosSegmentRecency': {
		'heliosSegmentRecency.startingMinBid': () => 0.5,
		'heliosSegmentRecency.startingMaxBid': () => 2,
		'heliosSegmentRecency.minBid': () => 0.01,
		'heliosSegmentRecency.maxBid': () => 3,
	},
	'Xandr,lineItem,Engagement Score,native,CPM,impression,heliosSegmentRecency': {
		'heliosSegmentRecency.startingMinBid': () => 0.5,
		'heliosSegmentRecency.startingMaxBid': () => 2,
		'heliosSegmentRecency.minBid': () => 0.01,
		'heliosSegmentRecency.maxBid': () => 3,
	},
	'Xandr,lineItem,Engagement Score,video,CPM,impression,heliosSegmentRecency': {
		'heliosSegmentRecency.startingMinBid': () => 0.5,
		'heliosSegmentRecency.startingMaxBid': () => 2,
		'heliosSegmentRecency.minBid': () => 0.01,
		'heliosSegmentRecency.maxBid': () => 3,
	},
	'Xandr,lineItem,Engagement Score,audio,CPM,impression,heliosSegmentRecency': {
		'heliosSegmentRecency.startingMinBid': () => 0.5,
		'heliosSegmentRecency.startingMaxBid': () => 2,
		'heliosSegmentRecency.minBid': () => 0.01,
		'heliosSegmentRecency.maxBid': () => 3,
	},
	'Xandr,lineItem,Engagement Score,banner,COST_PLUS_MARGIN,impression,heliosSegmentRecency': {
		'heliosSegmentRecency.startingMinBid': () => 0.5,
		'heliosSegmentRecency.startingMaxBid': () => 2,
		'heliosSegmentRecency.minBid': () => 0.01,
		'heliosSegmentRecency.maxBid': () => 4,
	},
	'Xandr,lineItem,Engagement Score,banner,COST_PLUS_MARGIN,video,heliosSegmentRecency': {
		'heliosSegmentRecency.startingMinBid': () => 0.5,
		'heliosSegmentRecency.startingMaxBid': () => 2,
		'heliosSegmentRecency.minBid': () => 0.01,
		'heliosSegmentRecency.maxBid': () => 4,
	},
	'Xandr,lineItem,Engagement Score,banner,COST_PLUS_MARGIN,view,heliosSegmentRecency': {
		'heliosSegmentRecency.startingMinBid': () => 0.5,
		'heliosSegmentRecency.startingMaxBid': () => 2,
		'heliosSegmentRecency.minBid': () => 0.01,
		'heliosSegmentRecency.maxBid': () => 4,
	},
	'Xandr,lineItem,Engagement Score,banner,COST_PLUS_CPM,impression,heliosSegmentRecency': {
		'heliosSegmentRecency.startingMinBid': () => 0.5,
		'heliosSegmentRecency.startingMaxBid': () => 2,
		'heliosSegmentRecency.minBid': () => 0.01,
		'heliosSegmentRecency.maxBid': () => 4,
	},
	'Xandr,lineItem,Engagement Score,banner,COST_PLUS_CPM,video,heliosSegmentRecency': {
		'heliosSegmentRecency.startingMinBid': () => 0.5,
		'heliosSegmentRecency.startingMaxBid': () => 2,
		'heliosSegmentRecency.minBid': () => 0.01,
		'heliosSegmentRecency.maxBid': () => 4,
	},
	'Xandr,lineItem,Engagement Score,banner,COST_PLUS_CPM,view,heliosSegmentRecency': {
		'heliosSegmentRecency.startingMinBid': () => 0.5,
		'heliosSegmentRecency.startingMaxBid': () => 2,
		'heliosSegmentRecency.minBid': () => 0.01,
		'heliosSegmentRecency.maxBid': () => 4,
	},
	'Xandr,lineItem,Engagement Score,native,COST_PLUS_MARGIN,impression,heliosSegmentRecency': {
		'heliosSegmentRecency.startingMinBid': () => 0.5,
		'heliosSegmentRecency.startingMaxBid': () => 2,
		'heliosSegmentRecency.minBid': () => 0.01,
		'heliosSegmentRecency.maxBid': () => 4,
	},
	'Xandr,lineItem,Engagement Score,native,COST_PLUS_MARGIN,video,heliosSegmentRecency': {
		'heliosSegmentRecency.startingMinBid': () => 0.5,
		'heliosSegmentRecency.startingMaxBid': () => 2,
		'heliosSegmentRecency.minBid': () => 0.01,
		'heliosSegmentRecency.maxBid': () => 4,
	},
	'Xandr,lineItem,Engagement Score,native,COST_PLUS_MARGIN,view,heliosSegmentRecency': {
		'heliosSegmentRecency.startingMinBid': () => 0.5,
		'heliosSegmentRecency.startingMaxBid': () => 2,
		'heliosSegmentRecency.minBid': () => 0.01,
		'heliosSegmentRecency.maxBid': () => 4,
	},
	'Xandr,lineItem,Engagement Score,native,COST_PLUS_CPM,impression,heliosSegmentRecency': {
		'heliosSegmentRecency.startingMinBid': () => 0.5,
		'heliosSegmentRecency.startingMaxBid': () => 2,
		'heliosSegmentRecency.minBid': () => 0.01,
		'heliosSegmentRecency.maxBid': () => 4,
	},
	'Xandr,lineItem,Engagement Score,native,COST_PLUS_CPM,video,heliosSegmentRecency': {
		'heliosSegmentRecency.startingMinBid': () => 0.5,
		'heliosSegmentRecency.startingMaxBid': () => 2,
		'heliosSegmentRecency.minBid': () => 0.01,
		'heliosSegmentRecency.maxBid': () => 4,
	},
	'Xandr,lineItem,Engagement Score,native,COST_PLUS_CPM,view,heliosSegmentRecency': {
		'heliosSegmentRecency.startingMinBid': () => 0.5,
		'heliosSegmentRecency.startingMaxBid': () => 2,
		'heliosSegmentRecency.minBid': () => 0.01,
		'heliosSegmentRecency.maxBid': () => 4,
	},
	'Xandr,lineItem,Engagement Score,video,COST_PLUS_MARGIN,impression,heliosSegmentRecency': {
		'heliosSegmentRecency.startingMinBid': () => 0.5,
		'heliosSegmentRecency.startingMaxBid': () => 2,
		'heliosSegmentRecency.minBid': () => 0.01,
		'heliosSegmentRecency.maxBid': () => 4,
	},
	'Xandr,lineItem,Engagement Score,video,COST_PLUS_MARGIN,video,heliosSegmentRecency': {
		'heliosSegmentRecency.startingMinBid': () => 0.5,
		'heliosSegmentRecency.startingMaxBid': () => 2,
		'heliosSegmentRecency.minBid': () => 0.01,
		'heliosSegmentRecency.maxBid': () => 4,
	},
	'Xandr,lineItem,Engagement Score,video,COST_PLUS_MARGIN,view,heliosSegmentRecency': {
		'heliosSegmentRecency.startingMinBid': () => 0.5,
		'heliosSegmentRecency.startingMaxBid': () => 2,
		'heliosSegmentRecency.minBid': () => 0.01,
		'heliosSegmentRecency.maxBid': () => 4,
	},
	'Xandr,lineItem,Engagement Score,video,COST_PLUS_CPM,impression,heliosSegmentRecency': {
		'heliosSegmentRecency.startingMinBid': () => 0.5,
		'heliosSegmentRecency.startingMaxBid': () => 2,
		'heliosSegmentRecency.minBid': () => 0.01,
		'heliosSegmentRecency.maxBid': () => 4,
	},
	'Xandr,lineItem,Engagement Score,video,COST_PLUS_CPM,video,heliosSegmentRecency': {
		'heliosSegmentRecency.startingMinBid': () => 0.5,
		'heliosSegmentRecency.startingMaxBid': () => 2,
		'heliosSegmentRecency.minBid': () => 0.01,
		'heliosSegmentRecency.maxBid': () => 4,
	},
	'Xandr,lineItem,Engagement Score,video,COST_PLUS_CPM,view,heliosSegmentRecency': {
		'heliosSegmentRecency.startingMinBid': () => 0.5,
		'heliosSegmentRecency.startingMaxBid': () => 2,
		'heliosSegmentRecency.minBid': () => 0.01,
		'heliosSegmentRecency.maxBid': () => 4,
	},
	'Xandr,lineItem,Engagement Score,audio,COST_PLUS_MARGIN,impression,heliosSegmentRecency': {
		'heliosSegmentRecency.startingMinBid': () => 0.5,
		'heliosSegmentRecency.startingMaxBid': () => 2,
		'heliosSegmentRecency.minBid': () => 0.01,
		'heliosSegmentRecency.maxBid': () => 4,
	},
	'Xandr,lineItem,Engagement Score,audio,COST_PLUS_MARGIN,video,heliosSegmentRecency': {
		'heliosSegmentRecency.startingMinBid': () => 0.5,
		'heliosSegmentRecency.startingMaxBid': () => 2,
		'heliosSegmentRecency.minBid': () => 0.01,
		'heliosSegmentRecency.maxBid': () => 4,
	},
	'Xandr,lineItem,Engagement Score,audio,COST_PLUS_MARGIN,view,heliosSegmentRecency': {
		'heliosSegmentRecency.startingMinBid': () => 0.5,
		'heliosSegmentRecency.startingMaxBid': () => 2,
		'heliosSegmentRecency.minBid': () => 0.01,
		'heliosSegmentRecency.maxBid': () => 4,
	},
	'Xandr,lineItem,Engagement Score,audio,COST_PLUS_CPM,impression,heliosSegmentRecency': {
		'heliosSegmentRecency.startingMinBid': () => 0.5,
		'heliosSegmentRecency.startingMaxBid': () => 2,
		'heliosSegmentRecency.minBid': () => 0.01,
		'heliosSegmentRecency.maxBid': () => 4,
	},
	'Xandr,lineItem,Engagement Score,audio,COST_PLUS_CPM,video,heliosSegmentRecency': {
		'heliosSegmentRecency.startingMinBid': () => 0.5,
		'heliosSegmentRecency.startingMaxBid': () => 2,
		'heliosSegmentRecency.minBid': () => 0.01,
		'heliosSegmentRecency.maxBid': () => 4,
	},
	'Xandr,lineItem,Engagement Score,audio,COST_PLUS_CPM,view,heliosSegmentRecency': {
		'heliosSegmentRecency.startingMinBid': () => 0.5,
		'heliosSegmentRecency.startingMaxBid': () => 2,
		'heliosSegmentRecency.minBid': () => 0.01,
		'heliosSegmentRecency.maxBid': () => 4,
	},
	'Xandr,lineItem,Engagement Score,banner,CPM,click,heliosSegmentRecency': {
		'heliosSegmentRecency.startingMinBid': () => 0.75,
		'heliosSegmentRecency.startingMaxBid': () => 1.5,
		'heliosSegmentRecency.minBid': () => 0.01,
		'heliosSegmentRecency.maxBid': () => 2,
	},
	'Xandr,lineItem,Engagement Score,native,CPM,click,heliosSegmentRecency': {
		'heliosSegmentRecency.startingMinBid': () => 0.75,
		'heliosSegmentRecency.startingMaxBid': () => 1.5,
		'heliosSegmentRecency.minBid': () => 0.01,
		'heliosSegmentRecency.maxBid': () => 2,
	},
	'Xandr,lineItem,Engagement Score,video,CPM,click,heliosSegmentRecency': {
		'heliosSegmentRecency.startingMinBid': () => 0.75,
		'heliosSegmentRecency.startingMaxBid': () => 1.5,
		'heliosSegmentRecency.minBid': () => 0.01,
		'heliosSegmentRecency.maxBid': () => 2,
	},
	'Xandr,lineItem,Engagement Score,audio,CPM,click,heliosSegmentRecency': {
		'heliosSegmentRecency.startingMinBid': () => 0.75,
		'heliosSegmentRecency.startingMaxBid': () => 1.5,
		'heliosSegmentRecency.minBid': () => 0.01,
		'heliosSegmentRecency.maxBid': () => 2,
	},
	'Xandr,lineItem,Engagement Score,banner,CPM,view,heliosSegmentRecency': {
		'heliosSegmentRecency.startingMinBid': () => 0.5,
		'heliosSegmentRecency.startingMaxBid': () => 2,
		'heliosSegmentRecency.minBid': () => 0.01,
		'heliosSegmentRecency.maxBid': () => 3,
	},
	'Xandr,lineItem,Engagement Score,native,CPM,view,heliosSegmentRecency': {
		'heliosSegmentRecency.startingMinBid': () => 0.5,
		'heliosSegmentRecency.startingMaxBid': () => 2,
		'heliosSegmentRecency.minBid': () => 0.01,
		'heliosSegmentRecency.maxBid': () => 3,
	},
	'Xandr,lineItem,Engagement Score,video,CPM,view,heliosSegmentRecency': {
		'heliosSegmentRecency.startingMinBid': () => 0.5,
		'heliosSegmentRecency.startingMaxBid': () => 2,
		'heliosSegmentRecency.minBid': () => 0.01,
		'heliosSegmentRecency.maxBid': () => 3,
	},
	'Xandr,lineItem,Engagement Score,audio,CPM,view,heliosSegmentRecency': {
		'heliosSegmentRecency.startingMinBid': () => 0.5,
		'heliosSegmentRecency.startingMaxBid': () => 2,
		'heliosSegmentRecency.minBid': () => 0.01,
		'heliosSegmentRecency.maxBid': () => 3,
	},
	'Xandr,lineItem,Engagement Score,video,CPM,video,heliosSegmentRecency': {
		'heliosSegmentRecency.startingMinBid': () => 0.75,
		'heliosSegmentRecency.startingMaxBid': () => 1.5,
		'heliosSegmentRecency.minBid': () => 0.01,
		'heliosSegmentRecency.maxBid': () => 2,
	},
	'Xandr,lineItem,Engagement Score,audio,CPM,video,heliosSegmentRecency': {
		'heliosSegmentRecency.startingMinBid': () => 0.75,
		'heliosSegmentRecency.startingMaxBid': () => 1.5,
		'heliosSegmentRecency.minBid': () => 0.01,
		'heliosSegmentRecency.maxBid': () => 2,
	},
	'Xandr,lineItem,Engagement Score,banner,VCPM,impression,heliosSegmentRecency': {
		'heliosSegmentRecency.startingMinBid': () => 0.5,
		'heliosSegmentRecency.startingMaxBid': () => 2,
		'heliosSegmentRecency.minBid': () => 0.01,
		'heliosSegmentRecency.maxBid': () => 3,
	},
	'Xandr,lineItem,Engagement Score,native,VCPM,impression,heliosSegmentRecency': {
		'heliosSegmentRecency.startingMinBid': () => 0.5,
		'heliosSegmentRecency.startingMaxBid': () => 2,
		'heliosSegmentRecency.minBid': () => 0.01,
		'heliosSegmentRecency.maxBid': () => 3,
	},
	'Xandr,lineItem,Engagement Score,video,VCPM,impression,heliosSegmentRecency': {
		'heliosSegmentRecency.startingMinBid': () => 0.5,
		'heliosSegmentRecency.startingMaxBid': () => 2,
		'heliosSegmentRecency.minBid': () => 0.01,
		'heliosSegmentRecency.maxBid': () => 3,
	},
	'Xandr,lineItem,Engagement Score,audio,VCPM,impression,heliosSegmentRecency': {
		'heliosSegmentRecency.startingMinBid': () => 0.5,
		'heliosSegmentRecency.startingMaxBid': () => 2,
		'heliosSegmentRecency.minBid': () => 0.01,
		'heliosSegmentRecency.maxBid': () => 3,
	},
	'Xandr,lineItem,IVR (Measured),banner,COST_PLUS_MARGIN,impression,helios': {
		'helios.minBid': () => 0.99,
		'helios.maxBid': () => 1.01,
	},
	'Xandr,lineItem,IVR (Measured),banner,COST_PLUS_MARGIN,video,helios': {
		'helios.minBid': () => 0.99,
		'helios.maxBid': () => 1.01,
	},
	'Xandr,lineItem,IVR (Measured),banner,COST_PLUS_MARGIN,view,helios': {
		'helios.minBid': () => 0.99,
		'helios.maxBid': () => 1.01,
	},
	'Xandr,lineItem,IVR (Measured),banner,COST_PLUS_MARGIN,click,helios': {
		'helios.minBid': () => 0.99,
		'helios.maxBid': () => 1.01,
	},
	'Xandr,lineItem,IVR (Measured),banner,COST_PLUS_CPM,impression,helios': {
		'helios.minBid': () => 0.99,
		'helios.maxBid': () => 1.01,
	},
	'Xandr,lineItem,IVR (Measured),banner,COST_PLUS_CPM,video,helios': {
		'helios.minBid': () => 0.99,
		'helios.maxBid': () => 1.01,
	},
	'Xandr,lineItem,IVR (Measured),banner,COST_PLUS_CPM,view,helios': {
		'helios.minBid': () => 0.99,
		'helios.maxBid': () => 1.01,
	},
	'Xandr,lineItem,IVR (Measured),banner,COST_PLUS_CPM,click,helios': {
		'helios.minBid': () => 0.99,
		'helios.maxBid': () => 1.01,
	},
	'Xandr,lineItem,IVR (Measured),banner,VCPM,impression,helios': {
		'helios.minBid': () => 0.99,
		'helios.maxBid': () => 1.01,
	},
	'Xandr,lineItem,IVR (Measured),banner,VCPM,video,helios': {
		'helios.minBid': () => 0.99,
		'helios.maxBid': () => 1.01,
	},
	'Xandr,lineItem,IVR (Measured),banner,VCPM,view,helios': {
		'helios.minBid': () => 0.99,
		'helios.maxBid': () => 1.01,
	},
	'Xandr,lineItem,IVR (Measured),banner,VCPM,click,helios': {
		'helios.minBid': () => 0.99,
		'helios.maxBid': () => 1.01,
	},
	'Xandr,lineItem,IVR (Measured),banner,CPM,impression,helios': {
		'helios.minBid': () => 0.99,
		'helios.maxBid': () => 1.01,
	},
	'Xandr,lineItem,IVR (Measured),banner,CPM,video,helios': {
		'helios.minBid': () => 0.99,
		'helios.maxBid': () => 1.01,
	},
	'Xandr,lineItem,IVR (Measured),banner,CPM,view,helios': {
		'helios.minBid': () => 0.99,
		'helios.maxBid': () => 1.01,
	},
	'Xandr,lineItem,IVR (Measured),banner,CPM,click,helios': {
		'helios.minBid': () => 0.99,
		'helios.maxBid': () => 1.01,
	},
	'Xandr,lineItem,IVR (Measured),native,COST_PLUS_MARGIN,impression,helios': {
		'helios.minBid': () => 0.99,
		'helios.maxBid': () => 1.01,
	},
	'Xandr,lineItem,IVR (Measured),native,COST_PLUS_MARGIN,video,helios': {
		'helios.minBid': () => 0.99,
		'helios.maxBid': () => 1.01,
	},
	'Xandr,lineItem,IVR (Measured),native,COST_PLUS_MARGIN,view,helios': {
		'helios.minBid': () => 0.99,
		'helios.maxBid': () => 1.01,
	},
	'Xandr,lineItem,IVR (Measured),native,COST_PLUS_MARGIN,click,helios': {
		'helios.minBid': () => 0.99,
		'helios.maxBid': () => 1.01,
	},
	'Xandr,lineItem,IVR (Measured),native,COST_PLUS_CPM,impression,helios': {
		'helios.minBid': () => 0.99,
		'helios.maxBid': () => 1.01,
	},
	'Xandr,lineItem,IVR (Measured),native,COST_PLUS_CPM,video,helios': {
		'helios.minBid': () => 0.99,
		'helios.maxBid': () => 1.01,
	},
	'Xandr,lineItem,IVR (Measured),native,COST_PLUS_CPM,view,helios': {
		'helios.minBid': () => 0.99,
		'helios.maxBid': () => 1.01,
	},
	'Xandr,lineItem,IVR (Measured),native,COST_PLUS_CPM,click,helios': {
		'helios.minBid': () => 0.99,
		'helios.maxBid': () => 1.01,
	},
	'Xandr,lineItem,IVR (Measured),native,VCPM,impression,helios': {
		'helios.minBid': () => 0.99,
		'helios.maxBid': () => 1.01,
	},
	'Xandr,lineItem,IVR (Measured),native,VCPM,video,helios': {
		'helios.minBid': () => 0.99,
		'helios.maxBid': () => 1.01,
	},
	'Xandr,lineItem,IVR (Measured),native,VCPM,view,helios': {
		'helios.minBid': () => 0.99,
		'helios.maxBid': () => 1.01,
	},
	'Xandr,lineItem,IVR (Measured),native,VCPM,click,helios': {
		'helios.minBid': () => 0.99,
		'helios.maxBid': () => 1.01,
	},
	'Xandr,lineItem,IVR (Measured),native,CPM,impression,helios': {
		'helios.minBid': () => 0.99,
		'helios.maxBid': () => 1.01,
	},
	'Xandr,lineItem,IVR (Measured),native,CPM,video,helios': {
		'helios.minBid': () => 0.99,
		'helios.maxBid': () => 1.01,
	},
	'Xandr,lineItem,IVR (Measured),native,CPM,view,helios': {
		'helios.minBid': () => 0.99,
		'helios.maxBid': () => 1.01,
	},
	'Xandr,lineItem,IVR (Measured),native,CPM,click,helios': {
		'helios.minBid': () => 0.99,
		'helios.maxBid': () => 1.01,
	},
	'Xandr,lineItem,IVR (Measured),video,COST_PLUS_MARGIN,impression,helios': {
		'helios.minBid': () => 0.99,
		'helios.maxBid': () => 1.01,
	},
	'Xandr,lineItem,IVR (Measured),video,COST_PLUS_MARGIN,video,helios': {
		'helios.minBid': () => 0.99,
		'helios.maxBid': () => 1.01,
	},
	'Xandr,lineItem,IVR (Measured),video,COST_PLUS_MARGIN,view,helios': {
		'helios.minBid': () => 0.99,
		'helios.maxBid': () => 1.01,
	},
	'Xandr,lineItem,IVR (Measured),video,COST_PLUS_MARGIN,click,helios': {
		'helios.minBid': () => 0.99,
		'helios.maxBid': () => 1.01,
	},
	'Xandr,lineItem,IVR (Measured),video,COST_PLUS_CPM,impression,helios': {
		'helios.minBid': () => 0.99,
		'helios.maxBid': () => 1.01,
	},
	'Xandr,lineItem,IVR (Measured),video,COST_PLUS_CPM,video,helios': {
		'helios.minBid': () => 0.99,
		'helios.maxBid': () => 1.01,
	},
	'Xandr,lineItem,IVR (Measured),video,COST_PLUS_CPM,view,helios': {
		'helios.minBid': () => 0.99,
		'helios.maxBid': () => 1.01,
	},
	'Xandr,lineItem,IVR (Measured),video,COST_PLUS_CPM,click,helios': {
		'helios.minBid': () => 0.99,
		'helios.maxBid': () => 1.01,
	},
	'Xandr,lineItem,IVR (Measured),video,VCPM,impression,helios': {
		'helios.minBid': () => 0.99,
		'helios.maxBid': () => 1.01,
	},
	'Xandr,lineItem,IVR (Measured),video,VCPM,video,helios': {
		'helios.minBid': () => 0.99,
		'helios.maxBid': () => 1.01,
	},
	'Xandr,lineItem,IVR (Measured),video,VCPM,view,helios': {
		'helios.minBid': () => 0.99,
		'helios.maxBid': () => 1.01,
	},
	'Xandr,lineItem,IVR (Measured),video,VCPM,click,helios': {
		'helios.minBid': () => 0.99,
		'helios.maxBid': () => 1.01,
	},
	'Xandr,lineItem,IVR (Measured),video,CPM,impression,helios': {
		'helios.minBid': () => 0.99,
		'helios.maxBid': () => 1.01,
	},
	'Xandr,lineItem,IVR (Measured),video,CPM,video,helios': {
		'helios.minBid': () => 0.99,
		'helios.maxBid': () => 1.01,
	},
	'Xandr,lineItem,IVR (Measured),video,CPM,view,helios': {
		'helios.minBid': () => 0.99,
		'helios.maxBid': () => 1.01,
	},
	'Xandr,lineItem,IVR (Measured),video,CPM,click,helios': {
		'helios.minBid': () => 0.99,
		'helios.maxBid': () => 1.01,
	},
	'Xandr,lineItem,IVR (Measured),audio,COST_PLUS_MARGIN,impression,helios': {
		'helios.minBid': () => 0.99,
		'helios.maxBid': () => 1.01,
	},
	'Xandr,lineItem,IVR (Measured),audio,COST_PLUS_MARGIN,video,helios': {
		'helios.minBid': () => 0.99,
		'helios.maxBid': () => 1.01,
	},
	'Xandr,lineItem,IVR (Measured),audio,COST_PLUS_MARGIN,view,helios': {
		'helios.minBid': () => 0.99,
		'helios.maxBid': () => 1.01,
	},
	'Xandr,lineItem,IVR (Measured),audio,COST_PLUS_MARGIN,click,helios': {
		'helios.minBid': () => 0.99,
		'helios.maxBid': () => 1.01,
	},
	'Xandr,lineItem,IVR (Measured),audio,COST_PLUS_CPM,impression,helios': {
		'helios.minBid': () => 0.99,
		'helios.maxBid': () => 1.01,
	},
	'Xandr,lineItem,IVR (Measured),audio,COST_PLUS_CPM,video,helios': {
		'helios.minBid': () => 0.99,
		'helios.maxBid': () => 1.01,
	},
	'Xandr,lineItem,IVR (Measured),audio,COST_PLUS_CPM,view,helios': {
		'helios.minBid': () => 0.99,
		'helios.maxBid': () => 1.01,
	},
	'Xandr,lineItem,IVR (Measured),audio,COST_PLUS_CPM,click,helios': {
		'helios.minBid': () => 0.99,
		'helios.maxBid': () => 1.01,
	},
	'Xandr,lineItem,IVR (Measured),audio,VCPM,impression,helios': {
		'helios.minBid': () => 0.99,
		'helios.maxBid': () => 1.01,
	},
	'Xandr,lineItem,IVR (Measured),audio,VCPM,video,helios': {
		'helios.minBid': () => 0.99,
		'helios.maxBid': () => 1.01,
	},
	'Xandr,lineItem,IVR (Measured),audio,VCPM,view,helios': {
		'helios.minBid': () => 0.99,
		'helios.maxBid': () => 1.01,
	},
	'Xandr,lineItem,IVR (Measured),audio,VCPM,click,helios': {
		'helios.minBid': () => 0.99,
		'helios.maxBid': () => 1.01,
	},
	'Xandr,lineItem,IVR (Measured),audio,CPM,impression,helios': {
		'helios.minBid': () => 0.99,
		'helios.maxBid': () => 1.01,
	},
	'Xandr,lineItem,IVR (Measured),audio,CPM,video,helios': {
		'helios.minBid': () => 0.99,
		'helios.maxBid': () => 1.01,
	},
	'Xandr,lineItem,IVR (Measured),audio,CPM,view,helios': {
		'helios.minBid': () => 0.99,
		'helios.maxBid': () => 1.01,
	},
	'Xandr,lineItem,IVR (Measured),audio,CPM,click,helios': {
		'helios.minBid': () => 0.99,
		'helios.maxBid': () => 1.01,
	},
	'Xandr,lineItem,*,*,*,*,helios': {
		'helios.minBid': () => 0.01,
		'helios.maxBid': () => 1.1,
	},
	'Xandr,lineItem,*,*,*,*,heliosSegmentRecency': {
		'heliosSegmentRecency.startingMinBid': () => 0.5,
		'heliosSegmentRecency.startingMaxBid': () => 1.5,
		'heliosSegmentRecency.minBid': () => 0.01,
		'heliosSegmentRecency.maxBid': () => 2,
	},
	'Xandr,insertionOrder,*,,,,apnBudgetOptimization': {
		'budgetOptimization.minAllocation': () => 1,
		'budgetOptimization.maxAllocation': () => 100,
		'budgetOptimization.pacing': () => 100,
		'budgetOptimization.intelligentChildObjects': () => 'Yes',
	},
	'TTD,campaign,*,,,,ttdBudgetOptimization': {
		'budgetOptimization.minAllocation': () => 1,
		'budgetOptimization.maxAllocation': () => 100,
		'budgetOptimization.pacing': () => 100,
		'budgetOptimization.intelligentChildObjects': () => 'Yes',
	},
	'DV360,insertionOrder,*,,,,dbmBudgetOptimization': {
		'budgetOptimization.minAllocation': () => 1,
		'budgetOptimization.maxAllocation': () => 100,
		'budgetOptimization.pacing': () => 100,
		'budgetOptimization.intelligentChildObjects': () => 'Yes',
	},
	'AMZN,order,*,,,,amznBudgetOptimization': {
		'budgetOptimization.minAllocation': () => 1,
		'budgetOptimization.maxAllocation': () => 100,
		'budgetOptimization.pacing': () => 100,
		'budgetOptimization.intelligentChildObjects': () => 'Yes',
	},
};
