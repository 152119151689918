import _ from 'lodash';
import React, { Dispatch, SetStateAction } from 'react';
import DspIcon from 'components/DspIcon';
import { ChildOptions, ExpandAllOptionsObject, ParentOptions } from 'containers/StrategyWizard/ConfigurationByStrategyType/BudgetOptimization/types';
import ParentAccordion from './ParentAccordion';

type DspOptionsSectionType = {
  dspId: string | number
  sectionOptions: ParentOptions,
  selectedLineItemSession: ChildOptions
  setSelectedLineItemSession: Dispatch<SetStateAction<ChildOptions>>
  alreadyAttachedLineItemKeys: Array<string>
  expandAllObj: ExpandAllOptionsObject
};

const DspOptionsSection = ({
  dspId,
  sectionOptions,
  selectedLineItemSession,
  setSelectedLineItemSession,
  alreadyAttachedLineItemKeys,
  expandAllObj,
}: DspOptionsSectionType) => {
  const optionsForSection = _.map(sectionOptions, (childOptions: ChildOptions, parentOption: string) => (
    <ParentAccordion
      key={parentOption}
      parentOption={parentOption}
      childOptions={childOptions}
      selectedLineItemSession={selectedLineItemSession}
      setSelectedLineItemSession={setSelectedLineItemSession}
      alreadyAttachedLineItemKeys={alreadyAttachedLineItemKeys}
      expandAllObj={expandAllObj}
    />
  ));

  return (
    <>
      <DspIcon
        dspId={+dspId}
        status="budgetGroup"
      />
      {optionsForSection}
    </>
  );
};

export default DspOptionsSection;
