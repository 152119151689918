import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import {
  IntelligentChildObjectsToggleProps, FixedCostInventoryToggleProps,
} from 'containers/StrategyWizard/ConfigurationByStrategyType/BudgetOptimization/types';
import { ToggleChangeEvent, WppToggleCustomEvent } from 'utils/types';
import { StrategyConfigurationStep } from 'containers/StrategyWizard/types';
import { WppToggle } from 'buildingBlocks';

export const IntelligentChildObjectsToggle = ({ displayName }: IntelligentChildObjectsToggleProps) => {
  const { control, setValue } = useFormContext<StrategyConfigurationStep>();
  return (
    <Controller
      name="intelligentChildObjects"
      control={control}
      render={({ field: { value, onChange } }) => (
        <WppToggle
          id="intelligentChildObjects"
          className="wpp-toggle-configure-step"
          value={displayName}
          checked={value}
          labelConfig={{
            text: value ? 'ON' : 'OFF',
          }}
          onWppChange={(toggle: WppToggleCustomEvent<ToggleChangeEvent>) => {
            onChange(toggle.detail.checked);
            setValue('prevSetIntelChildObj', true);
          }}
        />
      )}
    />
  );
};

export const FixedCostInventoryToggle = ({
  displayName,
}: FixedCostInventoryToggleProps) => (
  <Controller
    name="fixedCostInventory"
    render={({ field: { value, onChange } }) => (
      <WppToggle
        id="fixedCostInventory"
        className="wpp-toggle-configure-step"
        value={displayName}
        checked={value}
        labelConfig={{
          text: value ? 'ON' : 'OFF',
        }}
        onWppChange={(toggle: WppToggleCustomEvent<ToggleChangeEvent>) => {
          onChange(toggle.detail.checked);
        }}
      />
    )}
  />
);
