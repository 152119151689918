import _ from 'lodash';
import {
  ATTACH_FLIGHTS, CONFIGURE_STRATEGY, INITIALIZE_FORM_VALUES, SELECT_GOALS, SELECT_STRATEGY_TYPE, UPDATE_BUDGET_ALLOCATION_STATE,
  CLEAR, SET_USER_BID_MOD_RMVL_CONSENT, INITIAL_WIZARD_VALUES_FOR_UI, SET_STRATEGY_NAME, RESET_GOALS_AND_STRATEGY_TYPE,
  RESET_GOALS_AND_STRATEGY_TYPE_AMZN, RESET_NEXT_BTN_CLICK, SET_NEXT_BTN_CLICK, SAVE_AWG_CONFIG, CLEAR_AWG_CONFIG, SET_BULK_STRATEGY_TYPE,
} from '../constants';
import { AWG_FIELDS_TO_EXCLUDE } from '../steps/GoalSelection/constants';
import { WizardFormValues, StrategyWizardAction } from '../types';

const INITIAL_STATE: WizardFormValues = INITIAL_WIZARD_VALUES_FOR_UI;

const strategyWizardReducer = (state: WizardFormValues = INITIAL_STATE, action: StrategyWizardAction) => {
  switch (action.type) {
    case CLEAR:
      return { ...INITIAL_STATE };
    case INITIALIZE_FORM_VALUES:
      return {
        ...state,
        ...action.payload,
      };
    case ATTACH_FLIGHTS:
      return {
        ...state,
        attachFlightsStep: {
          ...action.payload,
        },
      };
    case UPDATE_BUDGET_ALLOCATION_STATE:
      return {
        ...state,
        budgetAllocationState: action.payload,
      };
    case SELECT_GOALS:
      return {
        ...state,
        goalSelectionStep: { ...state.goalSelectionStep, ...action.payload },
      };
    case RESET_GOALS_AND_STRATEGY_TYPE:
      return {
        ...state,
        ..._.pick(INITIAL_STATE, ['goalSelectionStep', 'strategyTypeSelectionStep', 'strategyConfigurationStep']),
      };
    case RESET_GOALS_AND_STRATEGY_TYPE_AMZN:
      return {
        ...state,
        ..._.pick(INITIAL_STATE, ['goalSelectionStep', 'strategyTypeSelectionStep', 'strategyConfigurationStep']),
      };
    case CONFIGURE_STRATEGY:
      return {
        ...state,
        strategyConfigurationStep: { ...state.strategyConfigurationStep, ...action.payload }, // this accounts for any fields being sent through satellite
      };
    case SET_USER_BID_MOD_RMVL_CONSENT:
      return {
        ...state,
        strategyConfirmationStep: {
          ...state.strategyConfirmationStep,
          userBidModifierRemovalConsent: action.payload,
        },
      };
    case SELECT_STRATEGY_TYPE: {
      const shouldResetStratConfig = _.get(state.strategyTypeSelectionStep, 'strategyType.id') !== _.get(action.payload, 'strategyType.id');
      return {
        ...state,
        strategyTypeSelectionStep: {
          ...action.payload,
        },
        strategyConfigurationStep: shouldResetStratConfig ? INITIAL_STATE.strategyConfigurationStep : { ...state.strategyConfigurationStep },
      };
    }
    case SET_BULK_STRATEGY_TYPE: {
      return {
        ...state,
        strategyType: action.payload,
      };
    }
    case SET_STRATEGY_NAME:
      return {
        ...state,
        strategyConfirmationStep: {
          ...state.strategyConfirmationStep,
          name: action.payload,
        },
      };
    case RESET_NEXT_BTN_CLICK:
      return {
        ...state,
        nextBtnClick: INITIAL_STATE.nextBtnClick,
      };
    case SET_NEXT_BTN_CLICK:
      return {
        ...state,
        nextBtnClick: action.payload,
      };
    case SAVE_AWG_CONFIG:
      return {
        ...state,
        goalSelectionStep: {
          ...state.goalSelectionStep,
          customGoal: action.payload.customGoal,
          metricsConfig: action.payload.metricsConfig,
        },
      };
    case CLEAR_AWG_CONFIG:
      return {
        ...state,
        goalSelectionStep: _.omit(state.goalSelectionStep, AWG_FIELDS_TO_EXCLUDE),
      };
    default:
      return state;
  }
};

export default strategyWizardReducer;
