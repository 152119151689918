import _ from 'lodash';
import { useState } from 'react';
import { UseFormSetValue, useWatch } from 'react-hook-form';
import { Segment } from 'containers/StrategyWizard/ConfigurationByStrategyType/Helios/type';
import { loadSegments, loadSelectedLineItems } from 'containers/StrategyWizard/ConfigurationByStrategyType/Helios/hooks';
import { flattenAllSegmentIdentifiers, strategyConfigValuesUtil } from 'containers/StrategyWizard/ConfigurationByStrategyType/Helios/utils';
import { SegmentRecencyForm } from 'containers/StrategyWizard/types';
import { useAsyncEffect } from 'utils/functionHelpers';
import { useMount } from 'utils/hooks/generic/hookWrappers';
import { Member, StrategyType, Flight, LineItem, SegmentGroupsType } from 'utils/types';

export enum CurrentSegRecState {
  initial = 'initial',
  loading = 'loading',
  hasData = 'hasData',
  error = 'error',
}

type SegRecData = {
  segments?: Array<Segment>,
  attachedLIs?: Array<LineItem>,
};

export type SegRecStates =
  | { kind: CurrentSegRecState.initial }
  | { kind: CurrentSegRecState.loading }
  | { kind: CurrentSegRecState.hasData, data: SegRecData }
  | { kind: CurrentSegRecState.error, error: any };

export const useInitialSRDataFetcher = (
  segmentGroups: SegmentGroupsType,
  member: Member,
  attachedFlights: Array<Flight>,
  strategyType: StrategyType,
  strategyId: number | null,
  setValue: UseFormSetValue<SegmentRecencyForm>,
  goalType: string,
) => {
  const [state, setState] = useState<SegRecStates>({ kind: CurrentSegRecState.initial });
  let alreadyAttachedSegments = [];
  let lineItems = [];
  const minBid = useWatch({ name: 'minBid' });

  useAsyncEffect(async () => {
    const segmentIds = flattenAllSegmentIdentifiers(segmentGroups);
    setState({ kind: CurrentSegRecState.loading });
    try {
      if (segmentIds.length > 0) {
        const segmentsRes = await loadSegments(segmentGroups, member);
        alreadyAttachedSegments = segmentsRes;
      }
      if (!_.isEmpty(attachedFlights)) {
        const lineItemExtIds = _.map(attachedFlights, 'externalId');
        const lineItemsRes = await loadSelectedLineItems(lineItemExtIds);
        lineItems = lineItemsRes;
      }

      setState({
        kind: CurrentSegRecState.hasData,
        data: {
          segments: alreadyAttachedSegments,
          attachedLIs: lineItems,
        },
      });
    } catch (error) {
      setState({ kind: CurrentSegRecState.error, error });
    }
  }, []);

  useMount(() => {
    // run this for only create mode & when minBid/maxBid are not pre-populated from QS
    if (_.isNil(strategyId) && !minBid) {
      strategyConfigValuesUtil(lineItems, attachedFlights, goalType, strategyType, setValue);
    }
  });

  return state;
};
