import { CSSProperties } from 'react';
import { COPILOT_COLORS, COPILOT_LAYOUT, COPILOT_TYPOGRAPHY } from 'globalStyles';

const {
  NEW_DESIGN_SYSTEM: { NEUTRALS, BLUES }, WPP,
} = COPILOT_COLORS;

const { SPACING } = COPILOT_LAYOUT;

const { HEADING, BODY, DESCRIPTION } = COPILOT_TYPOGRAPHY;

type Props = { [key: string]: CSSProperties | { [key: string]: CSSProperties } };

export const POP_UP_FORM: Props = {
  popUpFormButton: {
    ...HEADING.H6,
    width: '222px',
    height: SPACING[32],
    borderRadius: SPACING[4],
    color: BLUES.B500_WAVE,
    backgroundColor: NEUTRALS.N0_WHITE,
    border: 'none',
    boxShadow: 'none',
    padding: '0 40px 0 0',
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    gap: SPACING[12],
  },
  allocationRangeContainer: {
    width: '65px',
  },
  showAllPopUpContainer: {
    height: '525px',
    width: '400px',
  },
  showAllPopUpList: {
    height: '480px',
    overflowY: 'auto',
  },
  individualFilterPopUpContainer: {
    width: '500px',
    height: '460px',
  },
  filterOptionsList: {
    display: 'flex',
    flexDirection: 'column',
  },
  checkBoxContainer: {
    display: 'flex',
    justifyContent: 'center',
    gap: SPACING[8],
    maxWidth: '576px',
  },
  filterTitle: {
    display: 'flex',
    alignItems: 'center',
    maxWidth: '668px',
  },
  textButton: {
    ...BODY.SMALL,
    padding: SPACING[8],
    margin: 0,
    cursor: 'pointer',
    border: 'none',
    boxShadow: 'none',
    background: 'none',
    color: BLUES.B500_WAVE,
    textTransform: 'capitalize',
  },
  lineItemText: {
    display: '-webkit-box',
    WebkitBoxOrient: 'vertical',
    overflow: 'hidden',
    WebkitLineClamp: 2,
    textOverflow: 'ellipsis',
    whiteSpace: 'normal',
  },
  noneFoundContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: SPACING[4],
    width: '100%',
    alignItems: 'center',
    paddingTop: SPACING[32],
  },
  parentOptionsContainer: {
    height: '75%',
    width: '100%',
    border: `1px solid ${NEUTRALS.N200_LINEN}`,
    borderRadius: SPACING[4],
    overflow: 'auto',
    padding: '16px 16px 0 16px',
  },
  optionsList: {
    display: 'flex',
    flexDirection: 'column',
  },
  filterBtnContainer: {
    display: 'flex',
    alignItems: 'center',
    background: 'transparent',
    boxShadow: 'none',
    border: 'none',
  },
  filterBtn: {
    background: 'transparent',
    boxShadow: 'none',
    border: 'none',
    padding: '8px 0',
  },
  filterSection: {
    display: 'flex',
    justifyContent: 'space-between',
    gap: SPACING[12],
  },
  verticalDivider: {
    height: SPACING[20],
    border: `1px solid ${NEUTRALS.N200_LINEN}`,
    backgroundColor: NEUTRALS.N200_LINEN,
    margin: '0 8px',
  },
  selectedFilterBtn: {
    background: 'transparent',
    boxShadow: 'none',
    border: 'none',
    color: NEUTRALS.N800_MATTER,
    fontWeight: 600,
    padding: '8px 0',
  },
  parentOptionText: {
    ...DESCRIPTION.BOLD,
    display: '-webkit-box',
    WebkitBoxOrient: 'vertical',
    overflow: 'hidden',
    WebkitLineClamp: 2,
    textOverflow: 'ellipsis',
    marginLeft: SPACING[4],
    color: NEUTRALS.N800_MATTER,
    whiteSpace: 'normal',
  },
  childOptionContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingBottom: SPACING[8],
    width: '100%',
  },
  parentAccordionGap: {
    gap: SPACING[8],
  },
  childOptionText: {
    ...DESCRIPTION.REGULAR,
    display: '-webkit-box',
    WebkitBoxOrient: 'vertical',
    overflow: 'hidden',
    WebkitLineClamp: 2,
    textOverflow: 'ellipsis',
    marginLeft: SPACING[4],
    color: NEUTRALS.N800_MATTER,
  },
  parentOptAccordionIcon: {
    color: NEUTRALS.N400_GRANITE,
  },
  disabledChildOptionText: {
    ...DESCRIPTION.REGULAR,
    display: '-webkit-box',
    WebkitBoxOrient: 'vertical',
    overflow: 'hidden',
    WebkitLineClamp: 2,
    textOverflow: 'ellipsis',
    marginLeft: SPACING[4],
    color: WPP.grey500,
  },
  disabledParentOptionText: {
    ...DESCRIPTION.BOLD,
    display: '-webkit-box',
    WebkitBoxOrient: 'vertical',
    overflow: 'hidden',
    WebkitLineClamp: 2,
    textOverflow: 'ellipsis',
    marginLeft: SPACING[4],
    color: WPP.grey500,
    whiteSpace: 'normal',
  },
  sideModelStyle: {
    display: 'grid',
    gap: SPACING[20],
  },
  sideModelActionStyle: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    gap: SPACING[8],
  },
  parentAccordionBodyStyle: {
    margin: '0px 0px 0px 15px',
    width: '100%',
  },
  fieldContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: SPACING[8],
  },
  subheaderTooltip: {
    display: 'flex',
    gap: SPACING[4],
  },
};
