/* eslint-disable consistent-return */
/* eslint-disable no-useless-escape */
import _ from 'lodash';
import { ALGORITHM_TYPE } from 'constantsBase';
import { COST_BASED_GOALS, RATE_BASED_GOALS } from 'containers/StrategyAnalytics/constants/metricsConstants';
import { DAG } from 'containers/StrategyWizard/ConfigurationByStrategyType/constants';
import {
  baseInitializer,
  initializePixels,
  storePixelsOnConfig,
  CreateConfigType,
  getPrimaryGoalType,
  formatGroupSettings,
  convertGroupSettingsUiToDb,
} from 'containers/StrategyWizard/ConfigurationByStrategyType/utils';
import { DSP_TO_TARGET_PLUS_ALGO_ID } from 'containers/StrategyWizard/steps/StrategyConfirmation/constants';
import { cyodIsEnabledCheck, hasRevenueTypeCheck, shouldAddTargetingPlusIds } from 'containers/StrategyWizard/steps/StrategyConfirmation/utils';
import { BudgetOptimizationForm } from 'containers/StrategyWizard/types';
import { StringMapping, MappingConfigObj, USE_NUMERIC_KEYS } from 'utils/airflow/nameTranslator';
import { toSnakeCase } from 'utils/formattingUtils';
import { numberOrUndefined } from 'utils/functionHelpers';
import { StrategyGoalsDB } from 'utils/types';
import {
  BUDGET_OPT_CONFIG,
  reduxFormFix,
  BID_MODEL_SUPPORTED_ALGO_TYPES,
  ICO_BEST_PRACTICE_CONFIG_SUPPORTED_ALGO_TYPES,
  DBM_ICO_BEST_PRACTICE_CONFIGURATIONS,
  BUDGET_OPTIMIZATION_AND_MULTI_DSP_ALGO_TYPES,
} from './constants';
import { getConfigObjByAlgoTypeId } from './utils';

const BO_COST_BASED_GOALS = [...COST_BASED_GOALS, 'engagementScore'];
const BO_RATE_BASED_GOALS = [...RATE_BASED_GOALS, 'impValuePerImp'];
const isDbmBudgetOpt = (algoTypeId: number) => ALGORITHM_TYPE.dbmBudgetOptimization.id === algoTypeId;
const isCrossPlatformAlgoType = (algoTypeId: number) => ALGORITHM_TYPE.crossPlatformOptimization.id === algoTypeId;

export type MapperT = {
  config: MappingConfigObj
  mapping: Array<StringMapping>
  createConfig: CreateConfigType<BudgetOptimizationForm>
};

export const getTargetingPlusAlgoTypeId = (algoTypeId: number): number => {
  switch (algoTypeId) {
    case ALGORITHM_TYPE.apnBudgetOptimization.id:
      return ALGORITHM_TYPE.xndrTargetingPlus.id;
    case ALGORITHM_TYPE.ttdBudgetOptimization.id:
      return ALGORITHM_TYPE.ttdTargetingPlus.id;
    case ALGORITHM_TYPE.wmtBudgetOptimization.id:
      return ALGORITHM_TYPE.wmtTargetingPlus.id;
    case ALGORITHM_TYPE.dbmBudgetOptimization.id:
      return ALGORITHM_TYPE.dbmTargetingPlus.id;
    case ALGORITHM_TYPE.crossPlatformOptimization.id:
      return ALGORITHM_TYPE.crossPlatformOptimization.id;
    default:
      return null;
  }
};

const reformatChildPriorities = (childPriorities: Array<{}>) => _.flatMap(childPriorities, (cp) => _.map(cp, (value, key) => ({ [_.last(key.split('-'))]: value })));

export const formatChildPriorities = (childPriorities: Array<{}>) => (!childPriorities ? {} : _.assign({}, ...reformatChildPriorities(childPriorities)));

export const getAdditionalConfigurations = (formValues, algoTypeId) => {
  const { strategyGoals } = formValues;
  if (isDbmBudgetOpt(algoTypeId) && _.get(formValues, 'intelligentChildObjects')) {
    const goalType = getPrimaryGoalType(strategyGoals);
    if (_.includes(BO_COST_BASED_GOALS, goalType)) {
      return { ...DBM_ICO_BEST_PRACTICE_CONFIGURATIONS.costBasedGoal.config };
    }
    if (_.includes(BO_RATE_BASED_GOALS, goalType)) {
      return { ...DBM_ICO_BEST_PRACTICE_CONFIGURATIONS.rateBasedGoal.config };
    }
  }
  return {};
};

export const baseCreateConfig = (formValues, member, attachedFlights, algoTypeId, updateAdminConfig = false, hasRevenueType = false, hasExternalCustomValue = false) => {
  const bidOptimization = _.get(formValues, 'bidOptimization');
  const groupSettings = _.get(formValues, 'groupSettings');
  const goalTypeVal = _.get(_.first(_.get(formValues, 'strategyGoals')), 'type');
  let intelligentChildObjects = _.get(formValues, 'intelligentChildObjects');
  const isCyodEnabled = cyodIsEnabledCheck(goalTypeVal, formValues) || hasExternalCustomValue;
  // config via satellite includes hasCustomRevenueType which is either Yes or No
  const hasCustomRevenueType = hasRevenueTypeCheck(goalTypeVal, formValues, hasRevenueType);
  // deliberately set intelligentChildObjects to {} for CYOD or when INT is false
  // {} makes sure we do not include the targeting plus config obj in adminConfig
  intelligentChildObjects = !intelligentChildObjects || isCyodEnabled ? {} : intelligentChildObjects;
  const isCrossPlatform = isCrossPlatformAlgoType(algoTypeId);
  let returnObj = updateAdminConfig
    ? {
      ...formValues,
      intelligentChildObjects,
    }
    : {
      budgetAllocation: formValues.budgetAllocation,
      dailyParentBudgetInflationRatio: numberOrUndefined(formValues, 'dailyParentBudgetInflationRatio'),
      intelligentChildObjects,
      bidOptimization: bidOptimization || {},
      fixedCostInventory: bidOptimization ? _.get(formValues, 'fixedCostInventory', false) : {},
      childPriorities: formatChildPriorities(formValues.childPriorities),
      ...(isCyodEnabled && { cyodEnabled: true }),
      ...(hasCustomRevenueType && { hasCustomRevenueType: true }),
      ...getAdditionalConfigurations(formValues, algoTypeId),
    };
  // at this point intelligentChildObjects is only ever true or {}
  // don't want INT enabled if revenueOutcomeType is multiple
  const formattedGroupSettings = formatGroupSettings(convertGroupSettingsUiToDb(groupSettings), toSnakeCase);

  if (shouldAddTargetingPlusIds(intelligentChildObjects, formValues)) {
    // if crossplatform & ILI - populate the targetingPlus config objects with default values
    const targetingPlusIds = _.compact(_.map(attachedFlights, (f) => DSP_TO_TARGET_PLUS_ALGO_ID[f.dsp]));
    _.each(targetingPlusIds, (tId) => {
      // check if id exists for edit flow - otherwise multiple records get created in algorithm table
      const existingTargetingId = _.get(formValues, `${tId}.id`);
      returnObj = {
        ...returnObj,
        [tId]: {
          ...(!!existingTargetingId && { id: existingTargetingId }),
          enabled: intelligentChildObjects || {},
          [DAG]: {
            ..._.get(formValues, `${tId}.${DAG}`, getConfigObjByAlgoTypeId(tId, formValues)),
            has_custom_revenue_type: hasCustomRevenueType,
            targetingPlusGroupSettings: formattedGroupSettings,
          },
        },
      };
    });
  }

  if (groupSettings) {
    returnObj = {
      ...returnObj,
      groupSettings: formattedGroupSettings,
    };
  }

  return storePixelsOnConfig(returnObj, formValues, member, isCrossPlatform);
};

export const BASE_MAPPER = (algoTypeId: number): MapperT => {
  const targetingPlusAlgoTypeId = getTargetingPlusAlgoTypeId(algoTypeId);
  const config = {
    passthrough: [
      ['cyodEnabled', `${algoTypeId}.${DAG}.cyod_enabled`],
      ['hasCustomRevenueType', `${algoTypeId}.${DAG}.has_custom_revenue_type`],
      ['childPriorities.(.*)', `${algoTypeId}.${DAG}\.child_priorities\.(.*)`, USE_NUMERIC_KEYS],
      ['cpmLever.(.*)', `${targetingPlusAlgoTypeId}.${DAG}\.cpm_lever\.(.*)`, USE_NUMERIC_KEYS],
      ['cpcvLever.(.*)', `${targetingPlusAlgoTypeId}.${DAG}\.cpcv_lever\.(.*)`, USE_NUMERIC_KEYS],
      [
        'minDeliveryFracLever.(.*)',
        `${targetingPlusAlgoTypeId}.${DAG}\.min_delivery_frac_lever\.(.*)`,
        USE_NUMERIC_KEYS,
      ],
      [
        'thresholdBoundsLever.(.*)',
        `${targetingPlusAlgoTypeId}.${DAG}\.threshold_bounds_lever\.(.*)`,
        USE_NUMERIC_KEYS,
      ],
      ['groupSettings.(.*)', `${algoTypeId}.${DAG}\.group_settings\.(.*)`, USE_NUMERIC_KEYS],
      // allow for targeting plus algo obj to be properly mapped to admin config
      ['targetingPlusGroupSettings.(.*)', `${targetingPlusAlgoTypeId}.${DAG}\.group_settings\.(.*)`, USE_NUMERIC_KEYS],
    ],
  };
  let mapping = [
    ['budgetAllocation', `${algoTypeId}.enabled`],
    [
      'dailyParentBudgetInflationRatio',
      `${algoTypeId}.${DAG}.${BUDGET_OPT_CONFIG}.daily_parent_budget_inflation_ratio`,
    ],
    ['currencyCode', `${algoTypeId}.${DAG}.currency_code`],
  ];

  // only add intelligentChildObjects when remapping DB configs if targetingPlusAlgoTypeId is not the cross-platfrom algo id
  if (!isCrossPlatformAlgoType(targetingPlusAlgoTypeId)) {
    mapping = [...mapping, ['intelligentChildObjects', `${targetingPlusAlgoTypeId}.enabled`]];
  }

  if (_.includes(BID_MODEL_SUPPORTED_ALGO_TYPES, algoTypeId)) {
    mapping = [
      ...mapping,
      ['bidOptimization', `${targetingPlusAlgoTypeId}.${DAG}.bid_grouper`],
      ['fixedCostInventory', `${targetingPlusAlgoTypeId}.${DAG}.is_fixed_cost_inventory`],
    ];
  } else {
    mapping = [...mapping, ['bidOptimization', ''], ['fixedCostInventory', '']];
  }

  if (_.includes(ICO_BEST_PRACTICE_CONFIG_SUPPORTED_ALGO_TYPES, algoTypeId)) {
    mapping = [
      ...mapping,
      ['cpmLever', `${targetingPlusAlgoTypeId}.${DAG}.cpm_lever`],
      ['cpcvLever', `${targetingPlusAlgoTypeId}.${DAG}.cpcv_lever`],
      ['minDeliveryFracLever', `${targetingPlusAlgoTypeId}.${DAG}.min_delivery_frac_lever`],
      ['thresholdBoundsLever', `${targetingPlusAlgoTypeId}.${DAG}.threshold_bounds_lever`],
    ];
  }

  if (isCrossPlatformAlgoType(algoTypeId)) {
    mapping = [
      ...mapping,
      ['budgetSettings', `${algoTypeId}.${DAG}.budget_settings`],
    ];
  } else {
    mapping = [...mapping, ['impValueFilters', `${algoTypeId}.${DAG}.imp_value_filters`]];
  }

  if (_.includes(BUDGET_OPTIMIZATION_AND_MULTI_DSP_ALGO_TYPES, algoTypeId)) {
    mapping = [
      ...mapping,
      ['groupSettings', `${algoTypeId}.${DAG}.group_settings`],
      ['targetingPlusGroupSettings', `${targetingPlusAlgoTypeId}.${DAG}.group_settings`],
    ];
  }

  return {
    // @ts-ignore
    config,
    mapping: mapping as Array<StringMapping>,
    // eslint-disable-next-line @typescript-eslint/no-shadow
    createConfig: (...args) => {
      const [formValues, , member, , , attachedflights, updateAdminConfig, hasRevenueType, hasExternalCustomValue] = args;
      return baseCreateConfig(formValues, member, attachedflights, algoTypeId, updateAdminConfig, hasRevenueType, hasExternalCustomValue);
    },
  };
};

export const baseBudgetOptInitializer = (config: { [key: string]: unknown }, strategyGoals: StrategyGoalsDB, dspId: number) => {
  const updatedConfig = baseInitializer(config);
  if (config.childPriorities) {
    const formattedChildPriorities = _.map(
      config.childPriorities as Array<{}>,
      (value: number, externalId: string) => ({ [`${reduxFormFix}-${externalId}`]: value }),
    );
    updatedConfig.childPriorities = formattedChildPriorities;
  }
  if (config.revenueType) {
    updatedConfig.hasCustomRevenueType = true;
  }
  return initializePixels(updatedConfig, strategyGoals, dspId);
};
