import _ from 'lodash';
import React from 'react';
import { useFormContext } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { GlobalState } from 'reducers';
import { WppDivider, Grid, Loader, WppGrid } from 'buildingBlocks';
import { STRATEGY_TYPE } from 'constantsBase';
import { BudgetOptimizationForm, StrategyConfigurationStep, WizardFormValues } from 'containers/StrategyWizard/types';
import { getDspByMemberOrFlights, isCrossPlatformStrategyType, useFetchDspSpecificChildren } from 'containers/StrategyWizard/utils';
import Viewability from 'containers/StrategyWizard/components/Viewability';
import { ModuleProps } from 'containers/StrategyWizard/steps/StrategyConfiguration/components/Loader';
import BudgetAllocation from 'containers/StrategyWizard/ConfigurationByStrategyType/BudgetOptimization/components/BudgetAllocation';
import { checkForChildFlightsData } from 'containers/StrategyWizard/ConfigurationByStrategyType/BudgetOptimization/components/GroupSettings/utils';
import { createSupportTicketUrl } from 'containers/StrategyWizard/constants';
import { isCYODGoalTypeValue } from 'containers/StrategyWizard/steps/GoalSelection/utils';
import { COPILOT_COLORS, COPILOT_LAYOUT } from 'globalStyles';
import { useMount } from 'utils/hooks/generic/hookWrappers';
import { StateT } from 'utils/hooks/useFetcher';
import { User } from 'utils/types';
import { DBMLineItemT, TooltipsDescriptions } from './types';
import { useChangeFormValues } from './hooks';
import { Status } from './constants';
import BAStyle from './style';

const { SPACING } = COPILOT_LAYOUT;

type Props = {
  tooltips: TooltipsDescriptions,
} & ModuleProps;

const BudgetOptimization = ({
  formValues,
  tooltips,
}: Props) => {
  const {
    attachFlightsStep: { member, attachedFlights },
    goalSelectionStep: { goal },
    strategyTypeSelectionStep: { strategyType },
    budgetAllocationState,
  } = useSelector<GlobalState>((state) => state.strategyWizard) as WizardFormValues;
  const user = useSelector<GlobalState>((rootState) => rootState.login.user) as User;

  const { setValue } = useFormContext<StrategyConfigurationStep>();
  const isCyodGoalType = isCYODGoalTypeValue(goal.type);
  const dsp = getDspByMemberOrFlights(attachedFlights, member) as number;
  const flight = _.first(attachedFlights);
  const budgetData = _.get(budgetAllocationState, 'data');
  const dspSpecificChildrenState = useFetchDspSpecificChildren(dsp, budgetAllocationState, _.get(flight, 'externalId')) as StateT<Array<DBMLineItemT>>;
  const isCrossPlatformOptimization = isCrossPlatformStrategyType(strategyType.id);
  const isAmazonStrategy = _.isEqual(strategyType.id, STRATEGY_TYPE.amznBudgetOptimization.id);
  const hasChildFlightsData = checkForChildFlightsData(budgetData);
  useChangeFormValues(dspSpecificChildrenState, dsp);

  useMount(() => {
    // intelligent line items are unavailable for Amazon and cyod goal types
    // set intelligentChildObjects to false in case it was previously true
    if (isCyodGoalType || _.isEqual(strategyType.id, STRATEGY_TYPE.amznBudgetOptimization.id)) {
      setValue('intelligentChildObjects', false);
    }
  });

  switch (budgetAllocationState.kind) {
    case Status.initial:
    case Status.loading:
      return (<Loader active size="large" style={BAStyle.noDataStyle} />);
    case Status.error:
      return (
        <Grid.Row centered style={{ ...BAStyle.noDataStyle, display: 'flex', justifyContent: 'center' }}>
          <span>
            We are unable to load the data at this time. Please contact the
            <a href={createSupportTicketUrl} style={{ color: COPILOT_COLORS.NEW_DESIGN_SYSTEM.BLUES.B500_WAVE }} target="_blank" rel="noopener noreferrer"> support team.</a>
          </span>
        </Grid.Row>
      );
    case Status.hasData:
    default:
      return (
        <WppGrid container fullWidth item all={24} style={{ gap: SPACING[12] }}>
          {(!isCrossPlatformOptimization && !isAmazonStrategy) && (
            <>
              <Viewability />
              <WppDivider style={BAStyle.divider} />
            </>
          )}
          {flight && (
            <BudgetAllocation
              displayName={strategyType.displayName}
              formValues={formValues as BudgetOptimizationForm}
              flight={flight}
              budgetAllocationState={budgetAllocationState}
              dspSpecificChildrenState={dspSpecificChildrenState}
              tooltips={tooltips}
              member={member}
              strategyType={strategyType}
              user={user}
              isCrossPlatformOptimization={isCrossPlatformOptimization}
              hasChildFlightsData={hasChildFlightsData}
            />
          )}
        </WppGrid>
      );
  }
};

export default BudgetOptimization;
