import _ from 'lodash';
import { useState } from 'react';
import { UseFormSetValue } from 'react-hook-form';
import { getDefaultDspToPixelWeights, supportsPixelSelect, loadDspPixels } from 'containers/StrategyWizard/ConfigurationByStrategyType/utils';
import { OptimizationType } from 'containers/StrategyWizard/steps/AttachFlights/constants';
import { WizardFormGoalSelection } from 'containers/StrategyWizard/types';
import { useAsyncEffect } from 'utils/functionHelpers';
import { Flight, Pixel } from 'utils/types';
import { DspToPixel, PixelLoadingState, PixelStates } from './types';

export const usePixelLoader = (
  attachedFlights: Array<Flight>,
  selectedOptType: OptimizationType,
  impValueFilters: DspToPixel,
  setValue: UseFormSetValue<WizardFormGoalSelection>,
  goalType: string,
  dsp: number,
) => {
  const [pixelState, setPixelState] = useState<PixelLoadingState>({
    kind: PixelStates.initial,
  });

  const dspId = _.get(dsp, 'id', dsp);
  // show pixel picker only if a pixel supported goal type is selected
  const displayPixelPicker = supportsPixelSelect(goalType, selectedOptType, dspId);

  useAsyncEffect(async () => {
    if (!displayPixelPicker) {
      setPixelState({ kind: PixelStates.unsupported });
      return;
    }
    setPixelState({ kind: PixelStates.loading });
    const availablePixels = await loadDspPixels(attachedFlights);
    const defaultWeights = getDefaultDspToPixelWeights(availablePixels, impValueFilters);
    if (!_.isEmpty(impValueFilters)) {
      const impValueFiltersWithNames = { ...impValueFilters };

      _.forEach(impValueFilters, (selectedDspPixels: Array<Pixel>, dspCode) => {
        const availableDspPixels = _.get(availablePixels, `[${dspCode}]`, []);
        _.forEach(selectedDspPixels, (p: { id: string }) => {
          const pixelName = _.get(_.find(availableDspPixels, ['id', p.id]), 'name');
          if (pixelName) {
            _.find(impValueFiltersWithNames[dspCode], ['id', p.id]).name = pixelName;
          }
        });
      });
      setValue('goal.impValueFilters', impValueFiltersWithNames);
    } else {
      setValue('goal.impValueFilters', {});
    }
    setPixelState({
      kind: PixelStates.success,
      pixels: availablePixels,
      defaultWeights,
    });
  }, [attachedFlights, selectedOptType, goalType, dsp]);
  return pixelState;
};
