import _ from 'lodash';
import { Chart } from 'highcharts';
import React from 'react';
import ReactHighcharts from 'react-highcharts';
import { COPILOT_COLORS } from 'globalStyles';
import { PacingProps } from './Pacing';

const { WPP } = COPILOT_COLORS;

const a = 0.11689375;
const b = 0.89155969;
const c = -0.03713518;
const d = 0.0757086;
const e = -0.03937878;
const f = 0.00598462;
const g = -0.00977476;
const h = 0.00387282;

export const getYAxis = (pacing: number) => _.map(_.range(1, 11), (x : number) => {
  const y = (a + b * pacing)
  + ((c + d * pacing + e * pacing ** 2) * x ** 2)
  + ((f + g * pacing + h * pacing ** 2) * x ** 3);
  return _.round(y * 100);
});

const textStyles = {
  fontFamily: 'Inter',
  fontSize: '12px',
  fontWeight: 400,
};

const getChartOptions = (pacing: number) => {
  const data = getYAxis(pacing);
  return {
    chart: {
      type: 'spline',
      height: 200,
      style: textStyles,
      events: {
        load: (event: { target: Chart }) => {
          if (!pacing) {
            const chart = event.target;
            const centerX = chart.plotLeft + (chart.plotWidth / 2);
            const centerY = chart.plotTop + (chart.plotHeight / 2);

            chart.renderer.text('Various', centerX, centerY)
              .css({
                color: WPP.grey500,
                fontSize: '16px',
                textAnchor: 'middle',
              })
              .attr({
                zIndex: 5,
              })
              .add();
          }
        },
      },
    },
    plotOptions: {
      spline: {
        marker: {
          enabled: false,
        },
        enableMouseTracking: false,
        states: {
          hover: {
            enabled: false,
          },
        },
      },
    },
    title: {
      text: '',
    },
    tooltip: {
      enabled: false,
    },
    xAxis: {
      title: {
        text: 'Time',
        style: textStyles,
      },
      lineWidth: 0,
      minorGridLineWidth: 0,
      labels: {
        enabled: false,
      },
      minorTickLength: 0,
      tickLength: 0,
      lineColor: WPP.grey500,
    },
    yAxis: {
      title: {
        text: 'Pacing (%)',
        style: textStyles,
      },
      min: 0,
      max: 200,
      lineColor: WPP.grey500,
      gridLineWidth: 0,
      lineWidth: 1,
      plotLines: [{
        color: WPP.grey500,
        width: 1,
        value: 0,
      }],
      offset: -1,
    },
    series: [
      {
        showInLegend: false,
        data,
        allowPointSelect: false,
        cursor: 'default',
        color: WPP.blue500,
        visible: !!pacing,
      },
    ],
    exporting: {
      enabled: false,
    },
    credits: {
      enabled: false,
    },
  };
};

const PacingGraph = ({ pacing }: PacingProps) => (
  // @ts-ignore props overload issue
  <ReactHighcharts config={getChartOptions(pacing)} />
);

export default PacingGraph;
