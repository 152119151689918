import React from 'react';
import _ from 'lodash';
import { WppTypography } from '@wppopen/components-library-react';
import strategyWizardStyles from 'containers/StrategyWizard/styles';

const CUSTOM_TREE_MODAL_MSG = 'The maximum bid in this tree is' as const;
export const CUSTOM_TREE_MODAL_HEADER = 'Check your maximum bid' as const;

type CustomTreeChickenBoxContentProps = {
  maxBid: number,
  unboundedComputeNodes: Array<string>,
};

export default ({ maxBid, unboundedComputeNodes }: CustomTreeChickenBoxContentProps) => (
  <div style={strategyWizardStyles.customTreeModelContainer}>
    <WppTypography type="s-body">{CUSTOM_TREE_MODAL_MSG}
      <WppTypography style={strategyWizardStyles.customTreeTextSuccess}>{maxBid}</WppTypography>
    </WppTypography>
    <WppTypography type="s-body">Do you wish to proceed?</WppTypography>{!_.isEmpty(unboundedComputeNodes) && (
      <WppTypography type="s-body">Detected
        <WppTypography>
          {unboundedComputeNodes.length} compute node(s) with no max bid defined.
        </WppTypography>
      </WppTypography>
    )}
  </div>
);
