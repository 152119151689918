import _ from 'lodash';
import { BOOLEAN_OPERATORS, ALGORITHM_TYPE, DSP } from 'constantsBase';
import { toCamelCase, toSnakeCase } from 'utils/formattingUtils';
import { numberOrUndefined, transformKeyOfObjWithFn } from 'utils/functionHelpers';
import { SegmentGroup, StrategyGoals, StrategyGoalsDB } from 'utils/types';
import { baseInitializer, storePixelsOnConfig, initializePixels, CreateConfigType } from 'containers/StrategyWizard/ConfigurationByStrategyType/utils';
import { getSegmentIdentifier } from 'containers/StrategyWizard/ConfigurationByStrategyType/segmentUtils';
import { HeliosForm } from 'containers/StrategyWizard/types';
import { StringMapping } from 'utils/airflow/nameTranslator';
import { SEGMENT_ACTIONS } from './constants';
import { BudgetInterval, BudgetIntervals, Segment } from './type';
import { formatBudgetIntervals } from './utils';
import { DAG } from '../constants';

export const PREFIX = `${ALGORITHM_TYPE.helios.id}.${DAG}`;
export const CO_PREFIX = `${ALGORITHM_TYPE.creativeOpt.id}.${DAG}`;

const getSegmentIncludedOrExcluded = (segment) => {
  if (segment.action) {
    return segment.action;
  }
  return _.get(segment, 'isExcluded') === false ? SEGMENT_ACTIONS.INCLUDE : SEGMENT_ACTIONS.EXCLUDE;
};

export const formatSegmentGroupsAF = (segmentGroups: Array<SegmentGroup>) => (_.map(segmentGroups, (segmentGroup: SegmentGroup) => {
  const snakeCaseSegmentGroups = {};
  _.each(segmentGroup, (v, key) => {
    let value;
    if (key === 'segments') {
      value = _.map(v as Segment[], (segment) => {
        const elt = {
          id: getSegmentIdentifier(segment),
          action: getSegmentIncludedOrExcluded(segment),
        };
        return elt;
      });
    } else if (key === 'boolOperator') {
      // @ts-ignore
      value = v.value.toLowerCase();
    } else {
      value = v;
    }
    snakeCaseSegmentGroups[toSnakeCase(key)] = value;
  });
  return snakeCaseSegmentGroups;
}));

export const formatSegmentGroups = (segmentGroups: Array<SegmentGroup>) => (_.map(segmentGroups, (segmentGroup) => {
  const camelCaseSegmentGroups = {};
  _.each(segmentGroup, (v, snakeCasedKey) => {
    const key = toCamelCase(snakeCasedKey);
    let value;
    if (key === 'segments') {
      value = _.map(v as Array<Segment>, (segment) => {
        const elt = { ...segment, isExcluded: segment.action === SEGMENT_ACTIONS.EXCLUDE };
        return elt;
      });
    } else if (key === 'boolOperator') {
      value = BOOLEAN_OPERATORS[(v as string).toUpperCase()];
    } else {
      value = v;
    }

    camelCaseSegmentGroups[key] = value;
  });
  return camelCaseSegmentGroups;
}));

export const convertBudgetIntervalAF = (budgetInterval: BudgetInterval) => (
  _.reduce(_.keys(budgetInterval), (res, key) => {
    const newKey = _.snakeCase(key);

    if (!key.startsWith('display')) {
      if (key === 'lifetimeBudget') {
        res[newKey] = numberOrUndefined(budgetInterval, key);
      } else {
        res[newKey] = budgetInterval[key];
      }
    }
    return res;
  }, {}));

export const formatBudgetIntervalsAF = (budgetIntervals: BudgetIntervals) => (
  budgetIntervals ? _.map(budgetIntervals, convertBudgetIntervalAF) : undefined
);

type MapperT = {
  mapping: Array<StringMapping>,
  createConfig: CreateConfigType<HeliosForm>,
};

export const convertBudgetIntervalsToUI = (budgetIntervals) => {
  if (!_.isEmpty(budgetIntervals)) {
    const camelCasedBIs = _.map(budgetIntervals, (bi) => transformKeyOfObjWithFn(bi, toCamelCase));
    return formatBudgetIntervals(undefined, camelCasedBIs);
  }
  return undefined;
};

export const MAPPER: MapperT = {
  // maps just the keys both directions
  mapping: [
    ['baseBid', `${PREFIX}.base_bid`],
    ['minBid', `${PREFIX}.min_bid`],
    ['maxBid', `${PREFIX}.max_bid`],
    ['viewabilityThreshold', `${PREFIX}.view_goal`],
    ['segmentGroups', `${PREFIX}.segment_groups`],
    ['impValueFilters', `${PREFIX}.imp_value_filters`],
    ['isBFO', `${PREFIX}.is_bfo`],
    ['creativeOptimization', `${CO_PREFIX}.creative_optimization`],
  ],
  // used to convert to db style before saving / and admin box. This runs first, then the mapping defined above.
  createConfig: (formValues, __, member) => {
    const customBudget: { budgetIntervals: unknown, lineItem: unknown } = {
      budgetIntervals: undefined,
      lineItem: undefined,
    };
    const config = ({
      baseBid: numberOrUndefined(formValues, 'baseBid'),
      minBid: numberOrUndefined(formValues, 'minBid'),
      maxBid: numberOrUndefined(formValues, 'maxBid'),
      viewabilityThreshold: numberOrUndefined(formValues, 'viewabilityThreshold'),
      segmentGroups: formatSegmentGroupsAF(_.get(formValues, 'segmentGroups')),
      isBFO: _.get(formValues, 'isBFO') || {},
      creativeOptimization: _.get(formValues, 'creativeOptimization') || {},
      ...customBudget,
    });
    config[`${PREFIX}.opt_mode`] = _.get(formValues, 'isBFO') ? 3 : undefined;
    return storePixelsOnConfig(config, formValues, member);
  },
};

type Config = {
  strategyGoals?: Array<StrategyGoals>,
  segmentGroups?: Array<SegmentGroup>,
  budgetIntervals?: BudgetIntervals,
};

// only used for editing existing strats, converting from DB to ui. the mapper runs first, then this.
export const initializer = (config: Config, strategyGoals: StrategyGoalsDB) => {
  const updatedConfig = baseInitializer(config);

  const segmentGroups = config.segmentGroups;
  if (segmentGroups) {
    updatedConfig.segmentGroups = formatSegmentGroups(segmentGroups);
  }

  const budgetIntervals = config.budgetIntervals;
  if (budgetIntervals) {
    const formattedBIs = _.map(budgetIntervals, (bi) => transformKeyOfObjWithFn(bi, toCamelCase));
    updatedConfig.budgetIntervals = formatBudgetIntervals(undefined, formattedBIs);
  }
  return initializePixels(updatedConfig, strategyGoals, DSP.APN.id);
};

export const TASKS = [ALGORITHM_TYPE.helios.id, ALGORITHM_TYPE.creativeOpt.id];
