/* eslint-disable no-useless-catch */
import _ from 'lodash';
import { stringifyAndFormatAsJSON } from 'utils/formattingUtils';
import { Microservices } from 'utils/copilotAPI';
import { BidListErrorArray, BidListStatus, BID_LIST_STRING, PageStatusType, VolumeControlStatus, VOLUME_CONTROL_BID_LIST_STRING } from './constants';

export const generateFourSpaceJsonString = (obj: unknown): string => stringifyAndFormatAsJSON(obj, 4);

export const generateBidLists = async (trimmedCsvInput:string, memberExtId:string, advertiserExtId:string) => {
  const body = {
    csv_text: trimmedCsvInput,
    member_external_id: memberExtId,
    advertiser_external_id: advertiserExtId,
  };

  try {
    const resp = await Microservices.runService(body, 'csv_to_bid_list');
    return resp.data;
  } catch (err) {
    throw err;
  }
};

/* eslint-disable camelcase */
type BidListResponse = { is_valid: boolean, errors: BidListErrorArray };
type ValidationResponse = { data: { bid_list: BidListResponse, volume_control_bid_list: BidListResponse } };
const handleTtdValidationResponse = ({ data: { bid_list, volume_control_bid_list } }: ValidationResponse) => ({
  pageStatus: {
    bidListStatus: bid_list.is_valid ? BidListStatus.BidListsAreValid : BidListStatus.BidListsError,
    volumeControlStatus: volume_control_bid_list.is_valid ? VolumeControlStatus.VolumeControlAreValid : BidListStatus.BidListsError,
  },
  bidListErrors: bid_list.errors,
  volumeControlBidListErrors: volume_control_bid_list.errors,
});
/* eslint-enable camelcase */

export const handleBidListValidation = async ({ payload }) => {
  try {
    const body = (
      _(payload)
        .pickBy((_v, k) => k !== BID_LIST_STRING && k !== VOLUME_CONTROL_BID_LIST_STRING)
        .mapKeys((_v, k) => _.snakeCase(k))
        .value()
    );

    const validationRes = await Microservices.runService(body, 'custom_bid_list_validation');
    const { pageStatus, bidListErrors, volumeControlBidListErrors } = handleTtdValidationResponse(validationRes);

    return {
      pageStatus,
      bidListString: payload.bidListString,
      volumeControlBidListString: payload.volumeControlBidListString,
      bidListErrors,
      volumeControlBidListErrors,
    };
  } catch (err) {
    throw err;
  }
};

export const getValidationOutput = (pageStatus: PageStatusType, validationError: string | null) => {
  const bidListStatus = _.get(pageStatus, 'bidListStatus');

  switch (bidListStatus) {
    case BidListStatus.BidListsError:
      return validationError;
    case BidListStatus.BidListsAreValid:
      return 'Bid List is Valid';
    default:
      return null;
  }
};
