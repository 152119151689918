const PREFIX = 'HELIOS';
// Actions.
export const FETCH_SEGMENTS = {
  STARTED: `${PREFIX}:FETCH_SEGMENTS`,
  COMPLETED: `${PREFIX}:FETCH_SEGMENTS_COMPLETED`,
  FAILED: `${PREFIX}:FETCH_SEGMENTS_FAILED`,
};
export const FETCH_LINE_ITEMS = {
  STARTED: `${PREFIX}:FETCH_LINE_ITEMS`,
  COMPLETED: `${PREFIX}:FETCH_LINE_ITEMS_COMPLETED`,
  FAILED: `${PREFIX}:FETCH_LINE_ITEMS_FAILED`,
};
export const FETCH_LINE_ITEM = {
  STARTED: `${PREFIX}:FETCH_LINE_ITEM`,
  COMPLETED: `${PREFIX}:FETCH_LINE_ITEM_COMPLETED`,
  FAILED: `${PREFIX}:FETCH_LINE_ITEM_FAILED`,
};

export const FETCH_STRAT_DEFAULT_WIZARD_CONFIG = {
  STARTED: `${PREFIX}:FETCH_STRAT_DEFAULT_WIZARD_CONFIG`,
  COMPLETED: `${PREFIX}:FETCH_STRAT_DEFAULT_WIZARD_CONFIG_COMPLETED`,
  FAILED: `${PREFIX}:FETCH_STRAT_DEFAULT_WIZARD_CONFIG_FAILED`,
};

export const RESET_PROPS = `${PREFIX}:RESET_PROPS`;

export const MODEL_FORM = 'helios-model-form';

export const ANALYTICS = {
  NEW_GROUP: 'New group',
  DELETE_GROUP: 'New group',
};

export const SEGMENT_ACTIONS = Object.freeze({
  INCLUDE: 'include',
  EXCLUDE: 'exclude',
});

export const INITIAL_STATE = {
  segmentsLoading: false,
  lineItemLoading: false,
  lineItemsLoading: false,
  lineItem: undefined,
  lineItems: [],
  segmentLookup: {},
  segments: [],
  stratDefaultWizardConfig: {},
};

export const OPTIMIZED_BIDS = {
  baseBid: {
    name: 'baseBid',
    title: 'Starting Bid',
  },
  minBid: {
    name: 'minBid',
    title: 'Optimized Minimum Bid',
  },
  maxBid: {
    name: 'maxBid',
    title: 'Optimized Maximum Bid',
  },
} as const;

export const OPTIMIZED_MULTIPLIERS = {
  minBid: {
    name: 'minBid',
    title: 'Optimized Minimum Multiplier',
  },
  maxBid: {
    name: 'maxBid',
    title: 'Optimized Maximum Multiplier',
  },
} as const;

export const guideLink = (
  'https://copilotsupport.freshdesk.com/support/solutions/articles/47001125020-configuration-helios'
);

export const BID_TYPES = {
  BID: 'bid',
  BID_MULTIPLIER: 'bid multiplier',
};
