/* eslint-disable jsx-a11y/no-static-element-interactions */
import _ from 'lodash';
import React, { CSSProperties, Dispatch, SetStateAction } from 'react';
import { WppAccordion, WppCheckbox, WppTypography } from 'buildingBlocks';
import NMSIcon from 'components/NMSIcon';
import { SingleChildOption, ChildOptions, ExpandAllOptionsObject } from 'containers/StrategyWizard/ConfigurationByStrategyType/BudgetOptimization/types';
import { isAlreadyAttached } from 'containers/StrategyWizard/ConfigurationByStrategyType/BudgetOptimization/components/GroupSettings/utils';
import { POP_UP_FORM } from './style';

const { parentAccordionGap, checkBoxContainer, lineItemText, disabledChildOptionText } = POP_UP_FORM;

type ItemDisplayProps = {
  text: string
  item: SingleChildOption
  onClick: (item: SingleChildOption) => void
  checked?: boolean
  style?: CSSProperties
  disabled?: boolean
};

const LineItemDisplay = ({ text, item, onClick, checked, style, disabled }: ItemDisplayProps) => {
  const handleOnClick = () => {
    if (!disabled) onClick(item);
  };
  return (
    <div onClick={handleOnClick} className="row-item item-display" style={{ ...style, ...parentAccordionGap }}>
      <div style={checkBoxContainer}>
        <WppCheckbox
          disabled={disabled}
          checked={checked}
        />
        <WppTypography
          className="text"
          type="s-body"
          tag="span"
          title={text}
          style={disabled ? disabledChildOptionText : lineItemText}
        >
          {text}
        </WppTypography>
      </div>
      <NMSIcon isProgrammaticGuaranteed={item.isProgrammaticGuaranteed} />
    </div>
  );
};

type ParentAccordionProps = {
  parentOption: string
  childOptions: ChildOptions
  selectedLineItemSession: ChildOptions
  setSelectedLineItemSession: Dispatch<SetStateAction<ChildOptions>>
  alreadyAttachedLineItemKeys: Array<string>
  expandAllObj: ExpandAllOptionsObject
  shouldShowBudgetOptimizationToggle: boolean
};

const ParentAccordion = ({
  parentOption,
  childOptions,
  selectedLineItemSession,
  setSelectedLineItemSession,
  alreadyAttachedLineItemKeys,
  expandAllObj,
  shouldShowBudgetOptimizationToggle,
}: ParentAccordionProps) => {
  const childOptionKeys = new Set(_.keys(childOptions));
  const allAttachedAndSelectedLineItemKeys = _.concat(alreadyAttachedLineItemKeys, _.keys(selectedLineItemSession));
  const allChildOptionsSelectedInSingleBudgetGroup = _.isEqual(selectedLineItemSession, childOptions) || _.size(_.pickBy(selectedLineItemSession, (_val, key: string) => childOptionKeys.has(key))) === _.size(childOptions);
  const optionsSource = shouldShowBudgetOptimizationToggle ? childOptions : _.omitBy(childOptions, 'isProgrammaticGuaranteed');
  const allChildOptionsSelected = _.size(_.omit(optionsSource, allAttachedAndSelectedLineItemKeys)) === 0;
  const allChildOptionsAlreadyAttached = isAlreadyAttached(childOptions, alreadyAttachedLineItemKeys);

  const handleParentCheckBoxClick = () => {
    if (allChildOptionsSelected) {
      setSelectedLineItemSession(_.omit(selectedLineItemSession, _.keys(childOptions)));
    } else {
      // if the user doesn't have NMS permission, we'll select all the non PG line items
      const optionsToInclude = shouldShowBudgetOptimizationToggle ? _.omit(childOptions, alreadyAttachedLineItemKeys) : _.omitBy(childOptions, 'isProgrammaticGuaranteed');
      setSelectedLineItemSession({ ...selectedLineItemSession, ...optionsToInclude });
    }
  };

  const handleChildOptionClick = (item: SingleChildOption, key: string) => {
    if (_.has(selectedLineItemSession, key)) {
      const newSelected = _.omit(selectedLineItemSession, key);
      setSelectedLineItemSession(newSelected);
    } else {
      setSelectedLineItemSession({ ...selectedLineItemSession, [key]: item });
    }
  };

  const childOptionsToDisplay = _.map(childOptions, (item: SingleChildOption, key: string) => {
    const alreadyAttached = _.includes(alreadyAttachedLineItemKeys, key);
    const alreadySelected = !!_.size(_.find(selectedLineItemSession, ['lineItemDisplayName', item.lineItemDisplayName]));
    return (
      <LineItemDisplay
        key={`${parentOption} - ${item.extId}`}
        text={item.lineItemDisplayName}
        item={item}
        onClick={(i) => {
          if (!alreadyAttached) {
            handleChildOptionClick(i, key);
          }
        }}
        checked={alreadySelected || alreadyAttached}
        style={POP_UP_FORM.childOptionContainer}
        disabled={(!shouldShowBudgetOptimizationToggle && item.isProgrammaticGuaranteed) || alreadyAttached}
      />
    );
  });

  const isChecked = allChildOptionsSelectedInSingleBudgetGroup || allChildOptionsAlreadyAttached;
  const isIndeterminate = allChildOptionsSelected && !allChildOptionsSelectedInSingleBudgetGroup && !allChildOptionsAlreadyAttached;

  return (
    <WppAccordion expanded={expandAllObj[parentOption]} withDivider={false} key={parentOption}>
      <div slot="header" style={{ ...POP_UP_FORM.filterTitle, ...parentAccordionGap }}>
        <WppCheckbox
          disabled={allChildOptionsAlreadyAttached}
          checked={isChecked}
          indeterminate={isIndeterminate}
          onWppChange={handleParentCheckBoxClick}
        />
        <WppTypography
          type="s-body"
          tag="span"
          onClick={handleParentCheckBoxClick}
          style={allChildOptionsAlreadyAttached ? POP_UP_FORM.disabledParentOptionText : POP_UP_FORM.parentOptionText}
          title={parentOption}
        >
          {parentOption}
        </WppTypography>
      </div>
      <div style={POP_UP_FORM.parentAccordionBodyStyle}>
        {childOptionsToDisplay}
      </div>
    </WppAccordion>
  );
};

export default ParentAccordion;
