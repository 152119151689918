import { COPILOT_COLORS, COPILOT_LAYOUT, COPILOT_TYPOGRAPHY } from 'globalStyles';
import BAStyle from 'containers/StrategyWizard/ConfigurationByStrategyType/BudgetOptimization/style';

const {
  NEW_DESIGN_SYSTEM: { NEUTRALS },
  WPP,
} = COPILOT_COLORS;

const { SPACING } = COPILOT_LAYOUT;
const { HEADING, BODY } = COPILOT_TYPOGRAPHY;
const { toolTipStyle } = BAStyle;

export const BUDGET_MANAGEMENT_STYLES: any = {
  budgetAllocation: {
    intelligenceContainer: {
      display: 'flex',
      flexDirection: 'column',
      gap: SPACING[16],
    },
    intelligenceSection: {
      display: 'flex',
      justifyContent: 'space-between',
      color: NEUTRALS.N600_ABBEY,
    },
    budgetManagementHeaderContainer: {
      display: 'flex',
      justifyContent: 'flex-start',
      color: COPILOT_COLORS.NEW_DESIGN_SYSTEM.NEUTRALS.N600_ABBEY,
    },
    sectionHeader: {
      ...HEADING.H5,
      margin: 0,
      color: NEUTRALS.N600_ABBEY,
    },
    budgetManagementText: {
      ...BODY.SMALL,
      marginBottom: '18px',
      color: NEUTRALS.N600_ABBEY,
    },
    intelligenceSectionText: {
      ...BODY.SMALL,
      color: NEUTRALS.N600_ABBEY,
    },
    divider: {
      width: '100%',
      marginTop: SPACING[24],
      marginBottom: SPACING[24],
    },
    budgetAllocationtGroup: {
      display: 'flex',
      flexDirection: 'column',
      gap: SPACING[4],
      width: '100%',
    },
    budgetAllocationHeader: {
      display: 'flex',
      gap: SPACING[4],
    },
  },
  budgetParent: {
    outerContainer: {
      display: 'flex',
      width: '100%',
      backgroundColor: 'var(--wpp-grey-color-100)',
      borderRadius: 'var(--wpp-border-radius-m)',
      padding: '12px 16px',
      gap: SPACING[32],
      marginBottom: SPACING[16],
    },
    textContainer: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      width: '100%',
    },
    textSection: {
      display: 'flex',
      justifyContent: 'center',
      flexDirection: 'column',
      width: '42%',
    },
    strategyNameHeader: {
      ...HEADING.H5,
      margin: 0,
      color: NEUTRALS.N800_MATTER,
    },
    headerText: {
      ...HEADING.H6,
      color: NEUTRALS.N600_ABBEY,
    },
    subHeaderText: {
      ...BODY.SMALL,
      color: NEUTRALS.N600_ABBEY,
      display: '-webkit-box',
      WebkitBoxOrient: 'vertical',
      overflow: 'hidden',
      WebkitLineClamp: 3,
      textOverflow: 'ellipsis',
      wordBreak: 'break-all',
    },
    stratName: {
      display: 'flex',
      justifyContent: 'center',
      flexDirection: 'column',
      width: '55%',
      overflowWrap: 'break-word',
    },
    budgetParentDateRangeStyle: {
      width: '37%',
    },
    toolTip: {
      ...toolTipStyle,
      fontSize: SPACING[20],
      margin: 0,
      color: NEUTRALS.N400_GRANITE,
      marginLeft: SPACING[8],
    },
    budgetParenttextTooltip: {
      display: 'flex',
      gap: SPACING[4],
    },
  },
  budgetGroup: {
    budgetGroupContainer: {
      display: 'grid',
      gridTemplateColumns: '1fr 1fr',
      gap: SPACING[20],
    },
    bannerMessage: {
      width: '100%',
    },
    container: {
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      gap: SPACING[20],
    },
    popUpFormContainer: {
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
      margin: '-40px 0px 20px 0px',
    },
    budgetGroupCardContainer: {
      width: '100%',
      maxHeight: '357px',
    },
    budgetGroupCard: {
      display: 'flex',
      flexDirection: 'column',
      gap: SPACING[20],
    },
    cardInfoSection: {
      display: 'flex',
      flexDirection: 'column',
      gap: SPACING[8],
    },
    deliverySection: {
      display: 'flex',
      height: SPACING[48],
      width: '100%',
    },
    individualDeliveryColumn: {
      display: 'flex',
      flexDirection: 'column',
      gap: SPACING[4],
      width: '100%',
    },
    deleteModalFooterContainer: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
      gap: SPACING[8],
    },
    objectsAttachedContainer: {
      display: 'flex',
      height: '52px',
      justifyContent: 'space-between',
      alignItems: 'center',
      padding: SPACING[12],
      backgroundColor: WPP.grey100,
      borderRadius: SPACING[8],
    },
    objectsAttachedText: {
      display: 'flex',
      gap: SPACING[8],
    },
    lineItemPopOverContainer: {
      display: 'flex',
      flexDirection: 'column',
      width: '350px',
      maxHeight: '324px',
    },
    lineItemsContainer: {
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
      padding: '12px 16px 0px 16px',
      gap: SPACING[8],
      maxHeight: '264px',
      overflowY: 'auto',
    },
    singleLineItemContainer: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      width: '100%',
      maxHeight: '54px',
      minHeight: '34px',
    },
    singleLineItemText: {
      margin: 0,
      maxWidth: '256px',
    },
    popOverFooter: {
      display: 'flex',
      justifyContent: 'flex-end',
      padding: SPACING[8],
    },
    budgetOptToggleContainer: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      height: '22px',
    },
    budgetOptHeader: {
      display: 'flex',
      gap: SPACING[4],
      margin: '20px 0px',
    },
    budgetGroupInfoFirstHalf: {
      display: 'flex',
      width: '55%',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
    budgetGroupInfoSecondHalf: {
      display: 'flex',
      width: '42%',
      justifyContent: 'center',
      gap: SPACING[16],
    },
    headerText: {
      ...HEADING.H6,
      color: NEUTRALS.N600_ABBEY,
      margin: '0 8px 0 0',
    },
    subHeaderText: {
      ...BODY.SMALL,
      color: NEUTRALS.N600_ABBEY,
      display: '-webkit-box',
      WebkitBoxOrient: 'vertical',
      overflow: 'hidden',
      WebkitLineClamp: 3,
      textOverflow: 'ellipsis',
      width: '100%',
      paddingTop: SPACING[12],
    },
    allocationRangeContainer: {
      display: 'flex',
      width: '100%',
      alignItems: 'start',
      minHeight: SPACING[32],
      justifyContent: 'space-between',
    },
    allocationRangeDivider: {
      padding: '10px 4px',
      display: 'flex',
      justifyContent: 'center',
    },
    dailyAllocationRangeStyle: {
      minWidth: '160px',
    },
    minLabelWidth: {
      width: '80px',
      padding: '5px 10px 5px 12px',
    },
    maxLabelWidth: {
      width: '80px',
      padding: '5px 10px 5px 10px',
    },
    displayBudgetGroupName: {
      display: 'flex',
      width: '100%',
    },
    bugdetGroupGridStyle: {
      gap: SPACING[16],
    },
    budgetGroupNameIcon: {
      fontSize: SPACING[16],
      color: NEUTRALS.N400_GRANITE,
      marginTop: SPACING[2],
      marginLeft: SPACING[2],
    },
    accordionBudgetouterContainer: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      border: '1px solid var(--wpp-grey-color-500)',
      borderRadius: SPACING[4],
      margin: '0px 10px 0px 10px',
    },
    budgetGroupDisplayInfoStyle: {
      display: 'flex',
      width: '100%',
      justifyContent: 'flex-start',
      alignItems: 'center',
      margin: '8px 0px 0px 0px',
    },
    noObjectInfoSectionStyle: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      gap: SPACING[4],
    },
    expandableCard: {
      width: '100%',
      marginBottom: SPACING[12],
    },
    budgetGroupVerticleDivider: {
      height: '236px',
      width: '1px',
      transform: 'rotate(180deg)',
      borderLeft: '1px solid',
      color: 'var(--wpp-grey-color-300)',
    },
    errorMessage: {
      display: 'flex',
      alignItems: 'center',
      width: '100%',
    },
  },
  budgetBanner: {
    container: {
      display: 'flex',
      justifyContent: 'flex-start',
      alignItems: 'center',
      border: `1px solid ${NEUTRALS.N200_LINEN}`,
      background: NEUTRALS.N0_WHITE,
      padding: SPACING[16],
      height: SPACING[56],
      borderRadius: SPACING[4],
    },
    text: {
      ...BODY.SMALL,
      color: NEUTRALS.N600_ABBEY,
    },
    infoCircle: {
      boxShadow: `0 0 0 1px ${NEUTRALS.N600_ABBEY} inset`,
      color: NEUTRALS.N600_ABBEY,
    },
  },
};
