/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import _ from 'lodash';
import React, { useEffect, useState, useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import {
  WppIconSearch, WppInput, WppActionButton, WppSideModal, WppButton, WppSegmentedControl,
  WppSegmentedControlItem, WppTypography, WppInlineMessage, WppToggle, WppSelect, WppListItem,
} from 'buildingBlocks';
import { StrategyConfigurationStep } from 'containers/StrategyWizard/types';
import {
  BudgetGroupOptions, ChildOptions, ExpandAllOptionsObject, FilterButtonState, BudgetGroupLineItemMapping, ParentOptions,
} from 'containers/StrategyWizard/ConfigurationByStrategyType/BudgetOptimization/types';
import {
  BudgetOptions, DEFAULT_MAX_ALLOCATION, DEFAULT_MIN_ALLOCATION, LineItemTypes, TOOLTIP_DESCRIPTIONS,
} from 'containers/StrategyWizard/ConfigurationByStrategyType/BudgetOptimization/constants';
import { AllocationRangeInput } from 'containers/StrategyWizard/ConfigurationByStrategyType/BudgetOptimization/components/GroupSettings/BudgetGroup/components/AllocationRangeInput';
import Tooltip from 'containers/Tooltip';
import useSideModalHideFreshdesk from 'utils/hooks/useSideModalHideFreshdesk';
import { ToggleChangeEvent, WppToggleCustomEvent } from 'utils/types';
import { usePrevious } from 'utils/hooks/usePrevious';
import { POP_UP_FORM } from './style';
import FormOptions from './FormOptions';

const {
  sideModelStyle, fieldContainer, subheaderTooltip, container,
  filterSection, filterBtnContainer, sideModelActionStyle,
} = POP_UP_FORM;

type PopUpFormProps = {
  groupedOptions: BudgetGroupOptions
  selectedLineItems: ChildOptions
  budgetGroupToLineItems: BudgetGroupLineItemMapping
  budgetGroupKey: string
  allActiveChildOptions: ChildOptions
  hasObjectsAttached: React.MutableRefObject<boolean>
  saveBudgetGroupToGroupSettings: Function
  isModalOpen: boolean
  handleCloseModal: () => void
  shouldShowBudgetOptimizationToggle: boolean
};

const PopUpForm = ({
  groupedOptions,
  selectedLineItems,
  budgetGroupToLineItems,
  budgetGroupKey,
  allActiveChildOptions,
  hasObjectsAttached,
  saveBudgetGroupToGroupSettings,
  isModalOpen,
  handleCloseModal,
  shouldShowBudgetOptimizationToggle,
}: PopUpFormProps) => {
  // source for accordion logic. Should only display collapseAll when all parentAccordions are open
  const expandAllOptionsObj = useMemo<ExpandAllOptionsObject>(() => _.reduce(groupedOptions, (acc: ExpandAllOptionsObject, options: ParentOptions) => {
    _.forEach(options, (_childOptions, parentKey: string) => {
      acc[parentKey] = true;
    });
    return acc;
  }, {}), [groupedOptions]);

  const { clearErrors, getValues } = useFormContext<StrategyConfigurationStep>();
  const [expandAllObj, setExpandAllObj] = useState<ExpandAllOptionsObject>(expandAllOptionsObj);
  const [budgetGroupName, setBudgetGroupName] = useState<string>('');
  const [minValue, setMinValue] = useState<number | string>(0);
  const [maxValue, setMaxValue] = useState<number | string>(1);
  const [budgetOption, setBudgetOption] = useState<string | null>(null);
  const [isBudgetOptimizationEnabled, setIsBudgetOptimizationEnabled] = useState<boolean>(true);
  const [search, setSearch] = useState<string>('');
  const [lineItemTypeFilter, setLineItemTypeFilter] = useState<LineItemTypes>(LineItemTypes.all);
  const [expandAll, setExpandAll] = useState<boolean>(true);
  const [selectedLineItemSession, setSelectedLineItemSession] = useState<ChildOptions>(selectedLineItems);
  const [filterBtnState, setFilterBtnState] = useState<FilterButtonState>(FilterButtonState.all);

  const alreadyAttachedLineItems = _.size(budgetGroupToLineItems) && _.reject(budgetGroupToLineItems, (_lI, key: string) => key === budgetGroupKey);
  const alreadyAttachedLineItemKeys = _.chain(alreadyAttachedLineItems).map((lI) => _.keys(lI)).flatten().value();
  const hasPGLineItem = _.some(selectedLineItemSession, 'isProgrammaticGuaranteed');
  const prevHasPGLines = usePrevious(hasPGLineItem);
  // hide freshdesk button when side modal is open
  useSideModalHideFreshdesk(isModalOpen);

  useEffect(() => {
    if (isModalOpen) {
      // sync modal values with form values
      setBudgetGroupName(getValues(`groupSettings[${budgetGroupKey}].groupName` as keyof StrategyConfigurationStep));
      setMinValue(getValues(`groupSettings[${budgetGroupKey}].min` as keyof StrategyConfigurationStep));
      setMaxValue(getValues(`groupSettings[${budgetGroupKey}].max` as keyof StrategyConfigurationStep));
      setIsBudgetOptimizationEnabled(getValues(`groupSettings[${budgetGroupKey}].budgetOptimization` as keyof StrategyConfigurationStep));
      setBudgetOption(getValues(`groupSettings[${budgetGroupKey}].allocationStrategy` as keyof StrategyConfigurationStep));
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isModalOpen]);

  useEffect(() => {
    setSelectedLineItemSession(selectedLineItems);
  }, [selectedLineItems]);

  useEffect(() => {
    setExpandAll(_.every(expandAllObj));
  }, [expandAllObj]);

  useEffect(() => {
    if (shouldShowBudgetOptimizationToggle && (hasPGLineItem || !isBudgetOptimizationEnabled)) {
      setIsBudgetOptimizationEnabled(false);
      setBudgetOption(BudgetOptions.ignore.value);
      setMinValue(DEFAULT_MIN_ALLOCATION);
      setMaxValue(DEFAULT_MAX_ALLOCATION);
    }
    // only set budgetOptimization to true if we previously had PG line selected and deselected it now
    if (shouldShowBudgetOptimizationToggle && prevHasPGLines && !hasPGLineItem) {
      setIsBudgetOptimizationEnabled(true);
      setBudgetOption(null);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedLineItemSession, shouldShowBudgetOptimizationToggle, hasPGLineItem, isBudgetOptimizationEnabled]);

  const handleExpandAllClick = () => {
    // need to explicitly set to true/false since each values can be offset via individual accordion click
    if (expandAll) {
      setExpandAllObj(_.mapValues(expandAllObj, () => false));
    } else {
      setExpandAllObj(_.mapValues(expandAllObj, () => true));
    }
    setExpandAll(!expandAll);
  };

  const handleSaveLineItems = () => {
    // hack to sync error so line item error does not display when trying to add objects initally
    clearErrors((`groupSettings[${budgetGroupKey}].childExtIds` as keyof StrategyConfigurationStep));
    saveBudgetGroupToGroupSettings(selectedLineItemSession, budgetGroupName, isBudgetOptimizationEnabled, minValue, maxValue, budgetOption);
    handleCloseModal();
    setSearch('');
    // eslint-disable-next-line no-param-reassign
    hasObjectsAttached.current = true;
  };

  const handleClearFilters = () => {
    setSearch('');
    setLineItemTypeFilter(LineItemTypes.all);
    setFilterBtnState(FilterButtonState.all);
    setExpandAllObj(expandAllOptionsObj);
  };

  const hasBudgetGroupError = _.isEmpty(budgetGroupName);
  const budgetOptionText = _.isEqual(budgetOption, BudgetOptions.ignore.value)
    ? 'Copilot will ignore the budgets of child objects in this group and remove them from budget optimization calculations.'
    : 'Copilot will exclude child items in this group from optimization, reallocating any unused budget to other lines with Optimization enabled.';

  return (
    <WppSideModal
      open={isModalOpen}
      onWppSideModalClose={handleCloseModal}
      size="l"
    >
      <h3 slot="header">Edit Budget Management Group</h3>
      <div slot="body" style={sideModelStyle}>
        <div style={fieldContainer}>
          <WppTypography type="s-strong">Group Name</WppTypography>
          <div>
            <WppInput
              value={budgetGroupName}
              onWppChange={(event) => {
                setBudgetGroupName(event.target.value);
              }}
              className={hasBudgetGroupError && 'validated-input-error'}
            />
            {hasBudgetGroupError && (
              <WppInlineMessage
                type="error"
                size="s"
                message="You must enter a group name."
                className="validated-error-msg"
              />
            )}
          </div>
        </div>
        {shouldShowBudgetOptimizationToggle && (
          <div style={fieldContainer}>
            <div style={subheaderTooltip}>
              <WppTypography type="s-strong">Optimization</WppTypography>
              <Tooltip content={_.get(TOOLTIP_DESCRIPTIONS, 'budgetOptimization')} theme="dark">
                <WppInlineMessage size="s" type="information" />
              </Tooltip>
            </div>
            <WppToggle
              checked={isBudgetOptimizationEnabled}
              disabled={hasPGLineItem}
              onWppChange={(toggle: WppToggleCustomEvent<ToggleChangeEvent>) => {
                if (toggle.detail.checked) {
                  setBudgetOption(null);
                }
                setIsBudgetOptimizationEnabled(toggle.detail.checked);
              }}
            />
            {!isBudgetOptimizationEnabled && (
            <WppInlineMessage
              type="information"
              size="m"
              message="Optimization turned off. Intelligent Line Items and Allocation Range are disabled."
            />
            )}
          </div>
        )}
        {!isBudgetOptimizationEnabled && (
        <div style={fieldContainer}>
          <WppTypography type="s-strong">Budget Option</WppTypography>
          <WppSelect
            placeholder={BudgetOptions.ignore.displayName}
            value={budgetOption}
            displayValue={_.get(BudgetOptions[budgetOption], 'displayName') ?? BudgetOptions.ignore.displayName}
            onWppChange={(event) => setBudgetOption(event.target.value)}
          >
            {_.map(BudgetOptions, (option) => (
              <WppListItem key={option.value} value={option.value}>
                <p slot="label">{option.displayName}</p>
              </WppListItem>
            ))}
          </WppSelect>
          <WppTypography type="xs-midi">{budgetOptionText}</WppTypography>
        </div>
        )}
        <div style={fieldContainer}>
          <WppTypography type="s-strong">Allocation Range</WppTypography>
          <AllocationRangeInput
            budgetGroupKey={budgetGroupKey}
            minValue={minValue}
            maxValue={maxValue}
            setMinValue={setMinValue}
            setMaxValue={setMaxValue}
            disabled={!isBudgetOptimizationEnabled}
            isSideModalField
          />
        </div>
        <div style={container}>
          <WppTypography type="s-strong">Objects</WppTypography>
          {(hasPGLineItem && shouldShowBudgetOptimizationToggle) && (
            <WppInlineMessage
              type="information"
              size="m"
              message="Selecting a Programmatic Guaranteed object has disabled Optimization. Deselect it to enable Optimization."
            />
          )}
          <WppInput
            className="pop-up-form-search"
            value={search}
            onWppChange={(e) => setSearch(e.target.value)}
          >
            <WppIconSearch slot="icon-start" aria-label="Search icon" />
          </WppInput>
          <div style={filterSection}>
            <div style={filterBtnContainer}>
              <WppSegmentedControl size="s" hugContentOff value={filterBtnState}>
                <WppSegmentedControlItem value="All" onClick={() => setFilterBtnState(FilterButtonState.all)}>
                  All
                </WppSegmentedControlItem>
                <WppSegmentedControlItem value="Selected" onClick={() => setFilterBtnState(FilterButtonState.selected)}>
                  {`Selected (${_.size(selectedLineItemSession)})`}
                </WppSegmentedControlItem>
              </WppSegmentedControl>
            </div>
            <WppSelect
              placeholder={LineItemTypes.all}
              value={lineItemTypeFilter}
              onWppChange={(event) => setLineItemTypeFilter(event.target.value)}
            >
              {_.map(LineItemTypes, (type) => (
                <WppListItem key={type} value={type}>
                  <p slot="label">{type}</p>
                </WppListItem>
              ))}
            </WppSelect>
            <div style={{ display: 'flex' }}>
              <WppActionButton onClick={handleExpandAllClick} variant="secondary">
                {!expandAll ? 'Expand All' : 'Collapse All'}
              </WppActionButton>
              <WppActionButton onClick={() => setSelectedLineItemSession(_.omit(allActiveChildOptions, alreadyAttachedLineItemKeys))} variant="secondary">
                Select All
              </WppActionButton>
              <WppActionButton onClick={() => setSelectedLineItemSession({})} variant="secondary">
                Deselect All
              </WppActionButton>
            </div>
          </div>
          <FormOptions
            groupedOptions={groupedOptions}
            search={search}
            setSearch={setSearch}
            selectedLineItemSession={selectedLineItemSession}
            setSelectedLineItemSession={setSelectedLineItemSession}
            alreadyAttachedLineItemKeys={alreadyAttachedLineItemKeys}
            filterBtnState={filterBtnState}
            setFilterBtnState={setFilterBtnState}
            expandAllObj={expandAllObj}
            setExpandAllObj={setExpandAllObj}
            lineItemTypeFilter={lineItemTypeFilter}
            handleClearFilters={handleClearFilters}
            shouldShowBudgetOptimizationToggle={shouldShowBudgetOptimizationToggle}
          />
        </div>
      </div>
      <div slot="actions" style={sideModelActionStyle}>
        <WppButton variant="secondary" size="s" onClick={handleCloseModal}>Cancel</WppButton>
        <WppButton size="s" onClick={handleSaveLineItems} disabled={_.isEmpty(budgetGroupName)}>Save</WppButton>
      </div>
    </WppSideModal>
  );
};

export default PopUpForm;
