import React from 'react';
import { DraggableProvided, Droppable } from '@hello-pangea/dnd';
import { Icon } from 'buildingBlocks';
import { Item, GroupItem } from '../SegmentPickerTypes';
import { segmentGroupStyles } from '../style';

type ReorderGroupListContentProps = {
  dragProvided: DraggableProvided,
  group: GroupItem,
  items: Array<Item>,
  groupKeyId: string,
};
const ReorderGroupListContent = ({
  dragProvided,
  group,
  groupKeyId,
  items,
}: ReorderGroupListContentProps) => (
  <div id={`segment-group-${groupKeyId}`} style={{ marginTop: '20px' }}>
    <Droppable droppableId={groupKeyId}>
      {(dropProvided, dropSnapshot) => (
        <div
          ref={dropProvided.innerRef}
          {...dropProvided.droppableProps}
        >
          <div
            ref={dragProvided.innerRef}
            {...dragProvided.draggableProps}
            {...dragProvided.dragHandleProps}
          >
            <div
              style={{
                ...segmentGroupStyles.segmentGroup,
                backgroundColor: dropSnapshot.isDraggingOver ? '#d8d8d8' : '#F0F1F0',
              }}
              key={groupKeyId}
              title={`${group.name} ${group.boolOperator.text} ${items.length} segments`}
            >
              <span style={segmentGroupStyles.sgLeftSide}>
                <span style={segmentGroupStyles.sgName}>
                  <Icon
                    name="sidebar"
                    size="large"
                    style={{ marginRight: '20px', color: 'rgba(74, 74, 74, 0.5)' }}
                  />
                  {group.name}
                </span>
              </span>
              <span style={{ color: '#00b4e3', marginLeft: '10px', marginRight: '10px' }}>
                {group.boolOperator.text}
              </span>
              <span style={segmentGroupStyles.sgRightSide}>
                {items.length} segments
              </span>
            </div>
          </div>
          {dropProvided.placeholder}
        </div>
      )}
    </Droppable>
  </div>
);

export default ReorderGroupListContent;
