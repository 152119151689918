import _ from 'lodash';
import React, { CSSProperties, MouseEventHandler } from 'react';
import { Control, Controller } from 'react-hook-form';
import Dropzone, { DropFilesEventHandler } from 'react-dropzone';
import FormSectionHeader from 'components/FormSectionHeader';
import { Form, WppTypography, WppButton, WppIconFile, WppInlineMessage } from 'buildingBlocks';
import { StrategyType } from 'utils/types';
import CustomTextArea from 'components/CustomTextArea';
import strategyWizardStyles from 'containers/StrategyWizard/styles';

type Props = {
  disabled: boolean
  control: Control
  csvValidationMsg: string
  strategyType: StrategyType
  isCustomTreeGenerating: boolean
  isCustomTreeValidating: boolean
  csvInputValue: boolean
  csvInputLength: number
  dropzoneStyle: { [key: string]: CSSProperties }
  onGenerateTreeText: MouseEventHandler<HTMLWppButtonElement>
  onDrop: DropFilesEventHandler
};

const FileUpload = ({
  disabled, control, csvValidationMsg, strategyType,
  isCustomTreeGenerating, isCustomTreeValidating, onGenerateTreeText,
  csvInputValue, csvInputLength, dropzoneStyle, onDrop,
}: Props) => (
  <>
    <FormSectionHeader
      title="Tree File"
      help="Upload a CSV file specifying the desired feature and bid combinations."
      group={strategyType.displayName}
      bottom={16}
      top={24}
    />
    <div style={{ position: 'relative' }}>
      <Form.Field disabled={disabled}>
        <Controller
          name="treeFileInput"
          control={control}
          render={({ field }) => (
            <CustomTextArea
              name="treeFileInput"
              disabled={disabled}
              onChange={field.onChange}
              csvInputValue={csvInputValue}
              height={500}
              mode="python"
              {..._.omit(field, 'ref')}
            />
          )}
        />
        <Dropzone
          onDrop={onDrop}
          multiple={false}
          style={csvInputLength > 0
            ? { ...dropzoneStyle.container, border: 0, opacity: 0 }
            : { ...dropzoneStyle.container, opacity: 1 }}
        >
          <div style={dropzoneStyle.dropZoneUpload}>
            <WppIconFile slot="icon-start" />
            <WppTypography tag="p" type="xs-body">
              <WppTypography tag="span"><a style={dropzoneStyle.browseText}>Choose a file</a></WppTypography> to upload or drag it here
            </WppTypography>
            <WppTypography type="xs-body">
              Alternatively, type or paste into this field
            </WppTypography>
          </div>
        </Dropzone>
      </Form.Field>
    </div>
    <div style={strategyWizardStyles.fileUploadCustom}>
      <Controller
        name="generateCustomTree"
        control={control}
        render={({ field }) => (
          <WppButton
            size="m"
            variant="secondary"
            className="custom-configure-tree-btn"
            loading={isCustomTreeGenerating}
            onClick={onGenerateTreeText}
            disabled={isCustomTreeValidating}
            style={{ ...strategyWizardStyles.customTreeBtn, ...(isCustomTreeValidating && { pointerEvents: 'none' }) }}
            {...field}
          >
            Generate
          </WppButton>
        )}
      />
    </div>
    {csvValidationMsg !== null
      && (
      <WppInlineMessage
        size="s"
        message={csvValidationMsg}
        type="error"
      />
      )}
  </>
);

export default FileUpload;
