import React from 'react';
import { WppButton } from 'buildingBlocks';
import { STRATEGY_WIZARD_BUTTON_TAG } from 'cssTagConstants';
import strategyWizardStyles from '../styles';

type Props = {
  onNextClick: () => void
  onBackClick: () => void
  showBackButton?: boolean
  backButtonDisabled?: boolean
  showNextButton?: boolean
  nextButtonDisabled?: boolean
  strategyId?: number
  nextButtonText?: string
  backButtonText?: string
  style?: {}
};

const NavFooter = ({
  onNextClick,
  onBackClick,
  showBackButton,
  backButtonDisabled,
  showNextButton = true,
  nextButtonDisabled,
  strategyId,
  nextButtonText = 'Next',
  backButtonText = 'Back',
  style = {},
}: Props) => (
  <div style={{ ...strategyWizardStyles.navButtonContainerStyle, ...style }}>
    {showBackButton && (
      <WppButton
        size="m"
        id={strategyId ? STRATEGY_WIZARD_BUTTON_TAG.backButtonForEditStrategy : STRATEGY_WIZARD_BUTTON_TAG.backButtonForNewStrategy}
        disabled={backButtonDisabled}
        variant="secondary"
        onClick={onBackClick}
      >
        {backButtonText}
      </WppButton>
    )}
    {showNextButton && (
      <WppButton
        size="m"
        id={strategyId ? STRATEGY_WIZARD_BUTTON_TAG.nextButtonForEditStrategy : STRATEGY_WIZARD_BUTTON_TAG.nextButtonForNewStrategy}
        disabled={nextButtonDisabled}
        onClick={onNextClick}
        style={nextButtonDisabled ? { pointerEvents: 'none' } : {}}
      >
        {nextButtonText}
      </WppButton>
    )}
  </div>
);

export default NavFooter;
