import _ from 'lodash';
import React from 'react';
import { Droppable, Draggable } from '@hello-pangea/dnd';
import ReorderGroupListContent from './ReorderGroupListContent';
import { dragAndDropStyles } from '../style';
import { Item, GroupItem } from '../SegmentPickerTypes';

type DraggableGroupListProps = {
  itemsMap: { [key: string]: Array<Item> },
  groups: { [key: string]: GroupItem },
  groupOrder: Array<string>,
  enableGroupReordering: boolean,
};

const DraggableGroupList = ({
  itemsMap,
  groups,
  groupOrder,
  enableGroupReordering,
}: DraggableGroupListProps) => (
  <div style={{ marginBottom: '100px' }}>
    <Droppable droppableId="segment-groups" type="group">
      {(dropProvided) => (
        <div
          ref={dropProvided.innerRef}
          style={{
            ...dragAndDropStyles.segmentGroup,
          }}
          {...dropProvided.droppableProps}
        >
          {
            _.map(groupOrder, (groupKeyId, index: number) => {
              const group = groups[groupKeyId];
              const items = group.dragIds.map(
                (id) => _.find(itemsMap[groupKeyId], ['dragId', id]),
              );
              return (
                <Draggable
                  draggableId={groupKeyId}
                  index={index}
                  key={groupKeyId}
                  isDragDisabled={!enableGroupReordering}
                >
                  {(dragProvided) => (
                    <ReorderGroupListContent
                      dragProvided={dragProvided}
                      groupKeyId={groupKeyId}
                      items={items}
                      group={group}
                    />
                  )}
                </Draggable>
              );
            })
}
          {dropProvided.placeholder}
        </div>
      )}
    </Droppable>
  </div>
);

export default DraggableGroupList;
