import _ from 'lodash';
import { useMemo } from 'react';
import moment, { Moment } from 'moment';
import { useFormState } from 'react-hook-form';
import { createIsoDate } from 'charts/utils';
import { DSP, MetricsFormattingConstants } from 'constantsBase';
import { ILI_DISPLAY_NAME_SUFFIX, MomentTimeUnits } from 'containers/StrategyWizard/ConfigurationByStrategyType/BudgetOptimization/constants';
import { BudgetAllocationData, BudgetHierarchy, ChildExtIdToSettings, ChildOptions, GroupSettings, SingleChildOption } from 'containers/StrategyWizard/ConfigurationByStrategyType/BudgetOptimization/types';
import { AttachFlightsForm, BudgetSetting, WizardFormGoalSelection } from 'containers/StrategyWizard/types';
import { displayEstimatedMinMax, getInterval, getParentMetrics } from 'containers/StrategyWizard/ConfigurationByStrategyType/BudgetOptimization/utils';
import { roundNum } from 'containers/StrategyWizard/utils';
import { ChildSettings } from 'charts/BudgetOptimizationViz/types';
import { Flight } from 'utils/types';

export const shouldCreateChildKeys = (
  origToClone: Array<string>,
  childKey: string,
  childDataMapping: ChildExtIdToSettings,
): boolean => !_.includes(_.values(origToClone), childKey) && _.has(childDataMapping, childKey);

/**
 * Custom hook that creates a memoized object of options for each budget group
 * @param budgetAllocationData the value of 'data' from budgetAllocationState
 * @param validFlights an array of valid flight external ids
 * @returns object of options of type BudgetGroupOptions
 */
export const useBudgetGroupOptionsFetcher = (
  budgetAllocationData: { [flightExtId: string]: BudgetAllocationData },
  validFlights: Array<Flight>,
  flightStates: { validFlights: Array<Flight>, invalidFlights: Array<Flight> },
) => useMemo(() => {
  let groupedOptions = {};
  let allActiveChildOptions = {};
  _.forEach(validFlights, (parentFlight: Flight) => {
    const origToClone = _.values(_.get(budgetAllocationData, `${parentFlight.externalId}.origToClone`, {}) as { [key: string]: string }) as Array<string>;
    const { parentSettings, childExtIdToSettings, invalidData } = _.get(budgetAllocationData, `${parentFlight.externalId}.hierarchy`, {}) as BudgetHierarchy;
    const childData = { ...childExtIdToSettings, ...invalidData };
    // handle case where something went wrong with fetching budgetAllocationData and ends up returning string 'There was a problem.'
    if (_.size(parentSettings) && _.size(childData) && _.size(childExtIdToSettings)) {
      const parentKey = `${parentSettings?.name} (${parentFlight.externalId})`;
      const childKeyMapping = _.mapValues(childData, 'name');
      const activeChildKeys = {};
      const inactiveChildKeys = {};
      _.forEach(childKeyMapping, (val: string, key: string) => {
        // populate activeChildKeys with only original line items
        if (shouldCreateChildKeys(origToClone, key, childExtIdToSettings)) {
          activeChildKeys[`${val} (${key})`] = {
            lineItemDisplayName: `${val} (${key})`,
            extId: key,
            cloneDisplayName: `${val}${ILI_DISPLAY_NAME_SUFFIX}`,
            parentId: parentFlight.externalId,
            isProgrammaticGuaranteed: childExtIdToSettings[key]?.isProgrammaticGuaranteed,
          };
        }
        if (shouldCreateChildKeys(origToClone, key, invalidData)) {
          inactiveChildKeys[`${val} (${key})`] = {
            lineItemDisplayName: `${val} (${key})`,
            extId: key,
            cloneDisplayName: `${val}${ILI_DISPLAY_NAME_SUFFIX}`,
            parentId: parentFlight.externalId,
            isProgrammaticGuaranteed: childExtIdToSettings[key]?.isProgrammaticGuaranteed,
          };
        }
      });
      allActiveChildOptions = { ...allActiveChildOptions, ...activeChildKeys };
      groupedOptions = {
        ...groupedOptions,
        [parentFlight.dsp]: {
          ...groupedOptions[parentFlight.dsp],
          [parentKey]: { ...activeChildKeys },
        },
      };
    }
  });
  return {
    groupedOptions,
    allActiveChildOptions,
  };
  // eslint-disable-next-line react-hooks/exhaustive-deps
}, [validFlights, budgetAllocationData, flightStates]);

/**
 * Returns an array of two objects split at n
 * @param lineItems collection of line item names
 * @param num number
 * @returns an array of two objects split at n
 */
export const truncateLineItems = (lineItems: ChildOptions, num: number): Array<ChildOptions> => [_.pick(lineItems, _.slice(_.keys(lineItems), 0, num)), _.pick(lineItems, _.slice(_.keys(lineItems), num))];

// eslint-disable-next-line @typescript-eslint/no-shadow
export const getAttachedLineItems = (lineItemIds: Array<string>, childOptions: ChildOptions) => _.reduce(lineItemIds, (res, extId) => {
  const lineItem = _.find(childOptions, ['extId', extId]);
  if (lineItem) {
    res[lineItem.lineItemDisplayName] = lineItem;
  }
  return res;
}, {});

export const getMemberByParentExtId = (attachedFlights: Array<Flight>, parentExtId: string): string => {
  const parentFlight = _.find(attachedFlights, (flight) => flight.externalId === parentExtId);
  return parentFlight?.memExtId;
};

export const isAlreadyAttached = (childOptions: ChildOptions, attachedLineItemKeys: Array<string>) => {
  const childOptionKeys = _.keys(childOptions);
  const difference = _.difference(childOptionKeys, attachedLineItemKeys);
  return _.isEmpty(difference);
};

export const getCrossPlatformInterval = (budgetSettings: Array<BudgetSetting>, today: Moment = moment()) => {
  const sortedIntervals = _.sortBy(budgetSettings, 'endDate');
  // find current interval
  const currentInterval = _.find(sortedIntervals, ({ startDate, endDate }) => today.isSameOrAfter(startDate, MomentTimeUnits.days) && today.isSameOrBefore(endDate, MomentTimeUnits.days));
  // if current interval doesn't exist, find next interval
  if (_.isNil(currentInterval)) {
    const nextInterval = _.find(sortedIntervals, ({ endDate }) => today.isBefore(endDate));
    // if next interval doesn't exist, find most recent previous interval
    if (_.isNil(nextInterval)) {
      const prevIntervals = _.map(budgetSettings, ({ endDate }) => moment(endDate));
      // @ts-ignore - issue with types due to depreciated moment package
      const mostRecentIntervalDate = moment.max(prevIntervals);
      return _.find(budgetSettings, ({ endDate }) => mostRecentIntervalDate.isSame(endDate, 'day'));
    }
    return nextInterval;
  }
  return currentInterval;
};

export const getBudgetParentData = (
  isCrossPlatformOptimization: boolean,
  goalSelectionStep: WizardFormGoalSelection,
  budgetData: { [flightExtId: string]: BudgetAllocationData },
  attachedFlights: Array<Flight>,
) => {
  const revTypeConfig = _.get(goalSelectionStep, 'budget');
  if (isCrossPlatformOptimization) {
    const budgetSettings = _.get(goalSelectionStep, 'budgetSettings');
    const interval = getCrossPlatformInterval(budgetSettings);
    // remove time and timezone so that only the date is used to calculate remainingSpendDays
    const endDate = createIsoDate(interval.endDate);
    const today = createIsoDate(moment());
    const remainingSpendDays = moment(endDate).diff(moment(today), MomentTimeUnits.days) + 1;
    // for cross platform strategies, we only want to display budget in terms of revenue
    const { lifeTimeBudget, requiredDailyValue } = getParentMetrics(interval, remainingSpendDays, revTypeConfig, budgetData);
    return {
      lifeTimeBudget,
      requiredDailyValue,
      interval,
      remainingSpendDays,
      flightExtType: null,
      flightTimezone: null,
    };
  }
  // have to do this instead of deconstructing parentFlightBudgetData since data may be undefined on initial load
  const parentFlightBudgetData = _.head(_.values(budgetData));
  const remainingSpendDays = _.get(parentFlightBudgetData, 'remainingSpendDays');
  const parentSettings = _.get(parentFlightBudgetData, 'hierarchy.parentSettings');
  const interval = getInterval(parentSettings);
  const { lifeTimeBudget, requiredDailyValue } = getParentMetrics(interval, remainingSpendDays, revTypeConfig, budgetData);
  const flightExtType = _.get(_.head(attachedFlights), 'externalType');
  const flightTimezone = _.get(_.head(attachedFlights), 'timezone');
  return {
    lifeTimeBudget,
    requiredDailyValue,
    interval,
    remainingSpendDays,
    flightExtType,
    flightTimezone,
  };
};

export const checkForChildFlightsData = (
  budgetAllocationData: { [flightExtId: string]: BudgetAllocationData },
) => {
  if (_.size(budgetAllocationData)) {
    const hasAllInactiveChildFlights = _.every(budgetAllocationData, (budgetData: BudgetAllocationData) => {
      const childSettings = _.get(budgetData, 'hierarchy.childExtIdToSettings', {}) as ChildSettings;
      return _.every(childSettings, (settings) => !settings.active);
    });
    return !hasAllInactiveChildFlights;
  }
  return false;
};

export const getFieldErrors = (budgetGroupKey: string, fieldName: string) => {
  const field = `groupSettings[${budgetGroupKey}][${fieldName}]`;
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { errors } = useFormState({ name: field });
  return _.get(errors, field);
};

export const displayDailyDelivery = (requiredDailyValue: number, minMultiplier: number, maxMultiplier: number, budgetMetric: string) => {
  const estimatedMinValue = minMultiplier * requiredDailyValue;
  const estimatedMaxValue = maxMultiplier * requiredDailyValue;
  return `${displayEstimatedMinMax(estimatedMinValue)} \u2014 ${displayEstimatedMinMax(estimatedMaxValue)} ${budgetMetric}`;
};

export const displayLifetimeDelivery = (requiredDailyValue: number, minMultiplier: number, maxMultiplier: number, budgetMetric: string, remainingSpendDays: number, aggDelivery: number) => {
  const minDailyDelivery = minMultiplier * requiredDailyValue;
  const maxDailyDelivery = maxMultiplier * requiredDailyValue;
  const minLifeTimeDelivery = aggDelivery + (remainingSpendDays * minDailyDelivery);
  const maxLifeTimeDelivery = aggDelivery + (remainingSpendDays * maxDailyDelivery);
  return `${displayEstimatedMinMax(minLifeTimeDelivery)} \u2014 ${displayEstimatedMinMax(maxLifeTimeDelivery)} ${budgetMetric}`;
};

export const getMetricFormatForAllocationRange = (num: number) => (!_.isInteger(num) ? MetricsFormattingConstants.ROUNDED_TWO_DECIMALS : MetricsFormattingConstants.NO_DECIMALS);

export const displayAllocationRange = (minVal: number, maxVal: number) => {
  const min = minVal * 100;
  const max = maxVal * 100;
  const minMetricFormat = getMetricFormatForAllocationRange(min);
  const maxMetricFormat = getMetricFormatForAllocationRange(max);
  return `${roundNum(min, minMetricFormat)} \u2014 ${roundNum(max, maxMetricFormat)}%`;
};

// don't want groupSettings to contain the defaultGroup
export const getAllNonAttachedLineItems = (filteredGroupSettings: GroupSettings, allActiveChildOptions: ChildOptions) => {
  const allAttachedLineItems = _.flatMap(filteredGroupSettings, 'childExtIds');
  const allNonPg = _.flatMap(_.filter(allActiveChildOptions, ['isProgrammaticGuaranteed', false]), 'extId');
  const allPg = _.flatMap(_.filter(allActiveChildOptions, 'isProgrammaticGuaranteed'), 'extId');
  return {
    nonAttachedReg: _.difference(allNonPg, allAttachedLineItems),
    nonAttachedPG: _.difference(allPg, allAttachedLineItems),
  };
};

export const getFlightStates = (attachFlightsStep: AttachFlightsForm) => {
  const attachedFlights = _.get(attachFlightsStep, 'attachedFlights', []);
  const toBeDetached = _.get(attachFlightsStep, 'toBeDetached', []);
  const toBeDeactivated = _.get(attachFlightsStep, 'toBeDeactivated', []);
  const deactivatedFlights = _.get(attachFlightsStep, 'deactivatedFlights', []);

  const flightsToOmit = _.concat(toBeDetached, toBeDeactivated, deactivatedFlights) as Array<Flight>;
  const validFlights = _.differenceWith(attachedFlights, flightsToOmit, _.isEqual) as Array<Flight>;
  return { validFlights, invalidFlights: flightsToOmit };
};

export const filterForValidFlights = (attachedLineItems: Array<string>, invalidFlights: Array<Flight>, childOptions: ChildOptions) => {
  const filtered = _.filter(attachedLineItems, (lineItem: string) => {
    const childOption = _.find(childOptions, ['extId', lineItem]);
    return !_.isNil(childOption) && !_.includes(_.map(invalidFlights, 'externalId'), childOption?.parentId);
  });
  return filtered;
};

export const isAMZNFlight = (flights: Array<Flight>, childOption: SingleChildOption) => _.chain(flights)
  .find(['externalId', childOption.parentId])
  .get('dsp')
  .isEqual(DSP.AMZN.id)
  .value();

// function to include cloned line items when intelligent child objects is enabled
export const getAllBudgetGroupLineItems = (attachedFlights: Array<Flight>, attachedLineItems: ChildOptions, budgetData: { [flightExtId: string]: BudgetAllocationData }) => {
  const returnObj = { ...attachedLineItems };
  _.forEach(returnObj, (childOption) => {
    const isAMZN = isAMZNFlight(attachedFlights, childOption);
    if (!isAMZN && !childOption.isProgrammaticGuaranteed) {
      const cloneExtId = _.get(budgetData, `[${childOption.parentId}].origToClone[${childOption.extId}]`) as unknown as string;
      returnObj[childOption.cloneDisplayName] = {
        lineItemDisplayName: childOption.cloneDisplayName,
        origExtId: childOption.extId,
        parentId: childOption.parentId,
        isProgrammaticGuaranteed: false,
        ...(cloneExtId && { extId: cloneExtId }),
      };
    }
  });
  return returnObj;
};
