export enum PageStatus {
  InitialPageStatus = 'InitialPageStatus',
  CsvHasError = 'CsvHasError',
  CustomTreeGenerating = 'CustomTreeGenerating',
}

export enum CustomTreeStatus {
  NotYetValidated = 'NotYetValidated',
  CustomTreeValidating = 'CustomTreeValidating',
  CustomTreeIsValid = 'CustomTreeIsValid',
  CustomTreeError = 'CustomTreeError',
}

export const modelTypeEnum = {
  bid: { text: 'Bid', value: 'bid' },
  bidModifier: { text: 'Bid Modifier', value: 'bidModifier' },
};

export const INITIAL_VALUES = {
  treeFileInput: '',
  customTreeInput: '',
  modelType: modelTypeEnum.bid,
  pageValidator: CustomTreeStatus.NotYetValidated,
};

export const TREE_FILE_CSV_INPUT = 'treeFileInput' as const;
export const CUSTOM_TREE_INPUT = 'customTreeInput' as const;
export const MAX_SET_SIZE = 10;

type ValidResponse = {
  pageStatus: PageStatusType,
  maxBid?: number,
  unboundedComputeNodes?: Array<string>,
};

type InvalidResponse = {
  pageStatus: PageStatusType,
  errorType?: string,
  errorMessage?: string,
};

export const ACCEPTED_TYPES = new Set(['text/csv', 'application/vnd.ms-excel', 'application/octet-stream']);

export type PageStatusType = PageStatus | { customTreeStatus: CustomTreeStatus };
export type ValidationData = ValidResponse | InvalidResponse;
export type AddOrRemoveLeafNamesAction = (customTreeInput: string) => void;
export type TreeStatus = {
  pageStatus: PageStatusType,
  validationError: string,
};
export type KoalaBox = {
  maxBid: number | null,
  unboundedComputeNodes: Array<string> | null,
};

export const SUCCESSFULLY_GET_TREE_DATA = 'SUCCESSFULLY_GET_TREE_DATA';
export const HANDLE_VALIDATION_ERROR = 'HANDLE_VALIDATION_ERROR';
export const VALIDATE_CUSTOM_TREE = 'TRIGGER_VALIDATE_DAG';
export const HANDLE_VALIDATION_RESPONSE = 'HANDLE_VALIDATION_RESPONSE';
export const VALIDATED_INITIAL_TREE_STATUS = 'VALIDATED_INITIAL_TREE_STATUS';
export const UPDATE_CUSTOM_TREE_VALIDATION_STATUS = 'UPDATE_CUSTOM_TREE_VALIDATION_STATUS';
export const RESET_PROPS = 'RESET_PROPS';
export const UPLOAD_TREE = 'UPLOAD_TREE_DAG';
export const GENERATE_TREE_TEXT = 'GENERATE_TREE_TEXT';
export const SUCCESSFULLY_PARSED_CSV_DATA = 'SUCCESSFULLY_PARSED_CSV_DATA';
export const PARSE_CSV_DATA_FAILED = 'PARSE_CSV_DATA_FAILED';
export const RESET_CSV_VALIDATION = 'RESET_CSV_VALIDATION';
export const ADD_LEAF_NAMES = 'ADD_LEAF_NAMES';
export const REMOVE_LEAF_NAMES = 'REMOVE_LEAF_NAMES';
export const TOGGLE_LEAF_NAMES_SUCCEEDED = 'TOGGLE_LEAF_NAMES_SUCCEEDED';
export const TOGGLE_LEAF_NAMES_FAILED = 'TOGGLE_LEAF_NAMES_FAILED';
export const RESET_CUSTOM_TREE_VALIDATION = 'RESET_CUSTOM_TREE_VALIDATION';
