import { CSSProperties } from 'react';
import { COPILOT_LAYOUT } from 'globalStyles';

const { SPACING } = COPILOT_LAYOUT;

export const dragAndDropStyles = {
  color: {
    copilotGreen: '#37b784',
    copilotRed: '#ff6e31',
  },
  sourceList: {
    headerBar: {
      fontWeight: 500,
      marginBottom: 0,
      borderBottom: 0,
      borderBottomLeftRadius: 0,
      borderBottomRightRadius: 0,
    },
  },
  dragDropItem: {
    display: 'flex',
    alignItems: 'center',
    backgroundColor: 'white',
    padding: '0px 10px',
    margin: '4px 0',
    boxSizing: 'border-box',
    fontWeight: 500,
    width: '100%',
    fontSize: '12px',
    minHeight: '30px',
  } as CSSProperties,
  scrollContainer: {
    overflowY: 'scroll',
    flex: 1,
    marginBottom: '10px',
    paddingLeft: '1px',
    border: '1px solid rgba(34,36,38,.15)',
    borderTop: 0,
  } as CSSProperties,
  scrollSGContainer: {
    overflowY: 'scroll',
    height: '548px',
    marginBottom: '10px',
  } as CSSProperties,
  tableCell: {
    segmentName: {
      verticalAlign: 'top',
      wordBreak: 'break-word',
    } as CSSProperties,
  },
  configureDefaultsBar: {
    flex: { display: 'flex', alignItems: 'flex-end' },
    button: { textTransform: 'uppercase', position: 'relative', bottom: '1px', fontSize: SPACING[12], padding: SPACING[12] - SPACING[2] },
  },
  groupName: {
    fontSize: '12px',
    fontWeight: 500,
    color: 'grey',
    wordBreak: 'break-word',
  } as CSSProperties,
  segmentName: {
    display: 'inline-block',
    verticalAlign: 'middle',
    wordBreak: 'break-word',
  } as CSSProperties,
  segmentGroup: {
    minHeight: '35px',
    width: '100%',
    outline: 'none',

    btnContainer: {
      width: '30px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      // alignSelf: 'flex-start', */ align to top
      position: 'relative',
      right: '-4px',
    } as CSSProperties,
    btnIcon: {
      background: 'transparent',
      boxShadow: 'none',
      border: 0,
    },
    nonEditModeList: {
      color: 'grey',
    },
  },
};

export const SGTopBarStyles = {
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: '41px',
    fontWeight: 500,
  },
  button: {
    fontWeight: 500,
    color: '#3d62a1',
  },
};

export const segmentGroupStyles = {
  segmentGroup: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
    height: '50px',
    color: 'black',
    backgroundColor: '#F0F1F0',
  },
  sgLeftSide: {
    marginLeft: '20px',
    fontWeight: 500,
    width: '70%',
  },
  sgName: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    width: '100%',
    display: 'inline-block',
    verticalAlign: 'middle',
    textOverflow: 'ellipsis',
  } as CSSProperties,
  sgRightSide: {
    marginRight: '20px',
    color: '#4a4a4a',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: 'inline-block',
  } as CSSProperties,
};
