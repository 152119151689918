import { ALGORITHM_TYPE } from 'constantsBase';
import { BASE_MAPPER, baseBudgetOptInitializer } from '../BudgetOptimization/mapper';

const algoTypeId = ALGORITHM_TYPE.crossPlatformOptimization.id;

export const TASKS = [
  algoTypeId,
  ALGORITHM_TYPE.xndrTargetingPlus.id,
  ALGORITHM_TYPE.dbmTargetingPlus.id,
  ALGORITHM_TYPE.ttdTargetingPlus.id,
  ALGORITHM_TYPE.wmtTargetingPlus.id,
];

export const MAPPER = BASE_MAPPER(algoTypeId);

export const initializer = baseBudgetOptInitializer;

export const EXCLUDED_FIELDS = ['intelligentChildObjects'];
