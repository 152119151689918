import React from 'react';
import { Link } from 'react-router-dom';
import { Controller, useFormContext } from 'react-hook-form';
import { ObjectMultiDropdown, Form, WppGrid, WppActionButton, WppInlineMessage, WppTypography, WppDivider } from 'buildingBlocks';
import { COPILOT_LAYOUT, COPILOT_COLORS } from 'globalStyles';
import strategyWizardStyles from 'containers/StrategyWizard/styles';
import { WidgetWrapper } from 'utils/widget';
import { useUsersDataFetcher } from 'utils/hooks/useUserFetcher';
import { User } from 'utils/types';
import { developerApiLink } from './constants';

const { SPACING } = COPILOT_LAYOUT;

const InfoBox = () => (
  <WppGrid item all={18}>
    <WppInlineMessage
      size="m"
      showTooltipFrom={1000}
      message={`Use this strategy to track Insertion Orders that the Marketing Science or Data Science teams are optimizing using the 
      ${
        <Link rel="noopener noreferrer" target="_blank" to={developerApiLink}>
          <WppActionButton>
            Copilot APIs.
          </WppActionButton>
        </Link>
        }`}
      type="information"
    />
  </WppGrid>
);

const UploadSDFSection = () => (
  <WppGrid item all={14}>
    <WppTypography type="m-strong">Upload SDF (Coming soon)</WppTypography>
    <WppTypography tag="span" type="m-body">
      Use this strategy to direct Marketing Science / Data Science teams to the Insertion Order to be used.<br />
      If you would like to make changes using SDF, please reach out with a
      <Link to="#">
        <WppActionButton onClick={() => WidgetWrapper('open')}>
          support ticket.
        </WppActionButton>
      </Link>
    </WppTypography>
  </WppGrid>
);

type EmailComponentProps = {
  users: Array<User>,
  usersFetchError: string,
};

const EmailComponent = ({ users, usersFetchError }: EmailComponentProps) => {
  const { control } = useFormContext();
  return (
    <WppGrid item all={14} style={{ gap: SPACING[2] }}>
      <WppTypography type="m-strong">Share with Team Members</WppTypography>
      <WppTypography type="s-body" style={{ marginBottom: SPACING[12] }}>
        The following team members will be notified with strategy details to allow them to
        update the Custom SDF strategy via Copilot API.
        Please ensure that team members have existing Copilot access.
      </WppTypography>
      <br />
      <Form.Field style={{ width: '50%' }}>
        <Controller
          name="selectedUsersForEmail"
          control={control}
          render={({ field }) => (
            // @ts-ignore find a way to get the props to work with rhf controller
            <ObjectMultiDropdown
              name="selectedUsersForEmail"
              options={users}
              keyFn={(obj: { email: string; }) => obj.email}
              placeholder="Choose user(s)"
              selection
              search={{ searchType: 'local' }}
              value={field.value}
              onChange={field.onChange}
              {...field}
            />
          )}
        />
        {usersFetchError && <span style={{ color: COPILOT_COLORS.WPP.red0 }}>Failed to fetch users</span>}
      </Form.Field>
    </WppGrid>
  );
};

const CustomSDF = () => {
  const [users, usersFetchError] = useUsersDataFetcher();

  return (
    <WppGrid container fullWidth>
      <InfoBox />
      <EmailComponent
        users={users}
        usersFetchError={usersFetchError}
      />
      <WppDivider style={strategyWizardStyles.dividerStyle} />
      <UploadSDFSection />
    </WppGrid>
  );
};

export default CustomSDF;
