import _ from 'lodash';
import React from 'react';
import { Button } from 'buildingBlocks';

type TargetGroupBarProps = {
  groupKeyId: string,
  booleanOptions: { [key: string]: { text: string, value: string } },
  selectedValue: string,
  onClick: Function,
};

const TargetGroupBar = ({
  booleanOptions, selectedValue, onClick, groupKeyId,
}: TargetGroupBarProps) => (
  <div style={{ marginTop: '10px', marginBottom: '10px' }}>
    Target
    <span style={{ padding: '0 10px' }}>
      <Button.Group size="mini">
        {
          _.map(booleanOptions, (option) => (
            <Button
              key={option.value}
              primary={selectedValue === option.value}
              content={option.text}
              onClick={() => { onClick(groupKeyId, option); }}
              type="button"
            />
          ))
        }
      </Button.Group>
    </span>
    between segments
  </div>
);

export default TargetGroupBar;
