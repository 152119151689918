import _ from 'lodash';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Controller, useFormContext, useWatch } from 'react-hook-form';
import { GlobalState } from 'reducers';
import { RHFPercentageInput, WppTypography, WppInlineMessage, WppToggle } from 'buildingBlocks';
import { COPILOT_LAYOUT } from 'globalStyles';
import { ToggleChangeEvent, WppToggleCustomEvent } from 'utils/types';
import { StrategyConfigurationStep } from 'containers/StrategyWizard/types';
import Tooltip from 'containers/Tooltip';
import { validatedWppFieldWrapper } from 'utils/wrapperComponentsReactHookForms';
import strategyWizardStyles from '../styles';

const { SPACING } = COPILOT_LAYOUT;

const ValidatedPctInput = validatedWppFieldWrapper(RHFPercentageInput);

export const ViewabilityToggle = () => {
  const { control, setValue } = useFormContext<StrategyConfigurationStep>();
  const viewability = useSelector<GlobalState>((rootState) => rootState.strategyWizard.strategyConfigurationStep?.viewability);
  const viewabilityTarget = _.get(viewability, 'target');

  return (
    <Controller
      name="viewability.enabled"
      control={control}
      render={({ field: { value, onChange } }) => (
        <WppToggle
          className="wpp-toggle-configure-step"
          checked={value}
          labelConfig={{
            text: value ? 'ON' : 'OFF',
          }}
          onWppChange={(event: WppToggleCustomEvent<ToggleChangeEvent>) => {
            onChange(event.detail.checked);
            if (!event.detail.checked) {
              setValue('viewability', { enabled: event.detail.checked, target: viewabilityTarget });
            }
          }}
        />
      )}
    />
  );
};

const Viewability = () => {
  const viewabilityEnabled = useWatch<StrategyConfigurationStep>({ name: 'viewability.enabled' });
  const viewabilityTarget = useWatch<StrategyConfigurationStep>({ name: 'viewability.target' });
  const { control, setError, clearErrors } = useFormContext();
  const toolTipContent = 'Copilot will automatically adjust which inventory is bought to meet the viewability target.';

  useEffect(() => {
    if (viewabilityEnabled) {
      if (!viewabilityTarget) {
        setError('viewability.target', { message: 'Viewability is required' });
      }
    } else {
      clearErrors('viewability.target');
    }
  }, [viewabilityEnabled, viewabilityTarget, setError, clearErrors]);

  return (
    <div style={strategyWizardStyles.viewabilityContainer}>
      <div style={strategyWizardStyles.viewabilityHeader}>
        <WppTypography type="m-strong">Viewability</WppTypography>
        <Tooltip
          content={toolTipContent}
          tooltipstyling={{ marginTop: '2px' }}
        >
          <WppInlineMessage
            size="s"
            type="information"
          />
        </Tooltip>
      </div>
      <div style={strategyWizardStyles.viewabilityContainer}>
        <WppTypography style={{ marginBottom: SPACING[2] }} tag="span" type="s-body">Set the viewability goal for Copilot to achieve</WppTypography>
        <ViewabilityToggle />
      </div>
      {viewabilityEnabled && (
        <div style={{ marginTop: SPACING[16] }}>
          <Controller
            name="viewability.target"
            control={control}
            render={({ field, fieldState }) => (
              <ValidatedPctInput
                type="number"
                onChange={field.onChange}
                style={{ width: 120 }}
                size="m"
                field={field}
                fieldState={fieldState}
              />
            )}
          />
        </div>
      )}
    </div>
  );
};

export default Viewability;
