import _ from 'lodash';
import uuid from 'uuid';
import { SmallItem, Item, SegmentPickerState } from './SegmentPickerTypes';

export const getIndexByDragId = (arr: Array<Item>, index: number) => _.findIndex(arr, ['dragId', index]);

export const sortByObjectKey = (arr: Array<Item>, columnName: string) => {
  _.sortBy(arr, (val) => _.lowerCase(val[columnName]));
};

export const createDraggableItems = (items: Array<SmallItem>): Array<Item> => _.map(items, (item: SmallItem) => ({
  dragId: uuid(),
  id: item.id,
  name: item.shortName,
  text: `${item.id} ${item.shortName}`,
  isExcluded: item.isExcluded || false,
  isCloned: false,
  isDeleted: item.isDeleted || false,
  shortName: item.shortName,
}));

export const copyItem = (
  source: Array<any>,
  destination: Array<any>,
  droppableSource: { index: number },
  droppableDestination: { index: number },
  dragId: string | number,
) => {
  const sourceClone = _.clone(source);
  const destClone = _.clone(destination);
  const item = sourceClone[droppableSource.index];

  destClone.splice(droppableDestination.index, 0, { ...item, dragId });
  return destClone;
};

/** this finds index from source list of id and toggles isClonedVal to true or false */
export const setClonedSource = (sourceList: Array<Item>, itemId: number, isClonedVal: boolean) => {
  const index = _.findIndex(sourceList, ['id', itemId]);
  _.set(sourceList[index], 'isCloned', isClonedVal);
};

/** this will return an array of objects that match itemId */
export const arrayOfClonedItems = (itemsMap: { [key: string]: Array<Item> }, id: number) => _(itemsMap)
  .flatMap((x) => _.filter(x, { id }))
  .slice(1)
  .value();

/** this compares two arrays and returns a new array that sets isCloned value to false */
export const unsetCloned = (sourceList: Array<Item>, itemsMap: { [key: string]: Array<Item> }, groupKey: string) => {
  const idsFromGroups = _(itemsMap).omit(groupKey).flatMap().identity();
  const arr1 = _.uniq(_.map(idsFromGroups, 'id'));
  const arr2 = _.map(itemsMap[groupKey], 'id');
  const result = _.filter(arr2, (v) => !_.includes(arr1, v));
  _.map(result, (value) => setClonedSource(sourceList, value, false));
};

export const getIdsFromGroups = (itemsMap: { [key: string]: Array<Item> }) => _(itemsMap).flatMap()
  .map('id')
  .value();

export const hasDuplicates = (arr) => !_.isEqual(_.uniq(arr).length, arr.length);

export const idExistsInGroupCheck = (group: Array <Item>, itemId: number, preventCloning: boolean) => {
  if (preventCloning) {
    return _.isUndefined(_.find(group, {
      id: itemId,
    }));
  }
  return true;
};

export const setSegmentGroupFormValues = (segment: SegmentPickerState) => _.map(segment.groupOrder, (groupKeyId) => {
  const group = segment.groups[groupKeyId];
  const segments = group.dragIds.map(
    (id) => _.find(segment.itemsMap[groupKeyId], ['dragId', id]),
  );
  const segmentGroupObject = {
    name: segment.groups[groupKeyId].name,
    uuid: segment.groups[groupKeyId].uuid,
    segments,
    boolOperator: segment.groups[groupKeyId].boolOperator,
    bids: segment.groups[groupKeyId].bids,
    maxDays: segment.groups[groupKeyId].maxDays,
    startingMaxBid: segment.groups[groupKeyId].startingMaxBid,
    startingMinBid: segment.groups[groupKeyId].startingMinBid,
  };
  return segmentGroupObject;
});
