import _ from 'lodash';
import React, { Dispatch, SetStateAction } from 'react';
import { WppSegmentedControl, WppSegmentedControlItem, WppTypography } from 'buildingBlocks';
import { PixelCategories } from '../types';
import { PIXEL_PICKER_STYLES } from '../styles';

const { pixelSegment } = PIXEL_PICKER_STYLES;

type CategorySwitcherProps = {
  pixelCategory: PixelCategories
  setPixelCategory: Dispatch<SetStateAction<PixelCategories>>
  allCount: number
  selectedCount: number
};

const CategorySwitcher = (props: CategorySwitcherProps) => {
  const { pixelCategory, setPixelCategory, allCount, selectedCount } = props;

  return (
    <div style={pixelSegment}>
      <WppSegmentedControl
        value={pixelCategory}
        size="s"
      >
        <WppSegmentedControlItem
          onClick={() => setPixelCategory(PixelCategories.all)}
          value={PixelCategories.all}
        >
          <WppTypography className="wppTypographySegmentColor" type="s-midi" tag="p">{_.capitalize(PixelCategories.all)} · {allCount}</WppTypography>
        </WppSegmentedControlItem>
        <WppSegmentedControlItem
          onClick={() => setPixelCategory(PixelCategories.selected)}
          value={PixelCategories.selected}
        >
          <WppTypography className="wppTypographySegmentColor" type="s-midi" tag="p"> {_.capitalize(PixelCategories.selected)} · {selectedCount}</WppTypography>
        </WppSegmentedControlItem>
      </WppSegmentedControl>
    </div>
  );
};

export default CategorySwitcher;
