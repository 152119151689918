import React from 'react';
import { Grid, Header, Icon, Popup } from 'buildingBlocks';
import strategyWizardStyles from 'containers/StrategyWizard/styles';
import { COPILOT_COLORS, COPILOT_TYPOGRAPHY } from 'globalStyles';
import { Permission } from 'utils/featureFlags';
import BFOComponent from '../containers/BFOComponent';

const { column } = strategyWizardStyles;
const { NEW_DESIGN_SYSTEM: { NEUTRALS } } = COPILOT_COLORS;

const OptionalSettings = () => (
  Permission.heliosBFO
    && (
      <Grid.Column style={{ ...column, color: NEUTRALS.N600_ABBEY }} width={12}>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <div style={{ display: 'flex' }}>
            <Header
              as="h5"
              style={{ ...COPILOT_TYPOGRAPHY.HEADING.H5, marginBottom: 0 }}
              content="Bid Frequency Optimization (Optional)"
            />
            <Popup
              content="As Copilot identifies pockets of low performing inventory, it will gradually decrease the volume of auctions that can be entered. Should be used on direct inventory with fixed costs."
              trigger={<Icon name="question circle outline" style={{ marginLeft: '8px' }} />}
            />
          </div>
          <BFOComponent />
        </div>
        <div style={{ marginBottom: '15px', fontSize: '0.8571rem' }}>
          <span>Throttle the volume of auctions that can be entered on low performing inventory.</span>
        </div>
      </Grid.Column>
    ));

export default OptionalSettings;
