import { CSSProperties } from 'react';
import { Flight, User, Member, StrategyType } from 'utils/types';
import { ParentDatum } from 'charts/BudgetOptimizationViz/types';
import { BudgetOptimizationForm, StrategyConfigurationStep, WizardFormValues } from 'containers/StrategyWizard/types';
import { StateT } from 'utils/hooks/useFetcher';
import { BulkCreateWizardFormValues } from 'containers/BulkCreateStrategyWizard/types';
import { Status } from './constants';

export type SupportedExternalFlightTypes = 11 | 12 | 13 | 30 | 27;

export type TooltipsDescriptions = {
  budget: string
  daily: string
  lifetimeBudget: string
  estimatedDaily: string
  estimatedLifetime: string
};

export type ChildSettingsData = {
  endDate: Date
  startDate: Date
  name: string
  active: boolean
  extType: number
  isProgrammaticGuaranteed: boolean
};

export type BudgetInterval = {
  budgetAmount: number
  budgetImps: number
  dailyBudgetAmount: number
  dailyBudgetImps: number
  endDate: Date
  startDate: Date
  externalId: string
  pastIntervals: Array<BudgetInterval>
  futureIntervals: Array<BudgetInterval>
  name: string
  budgetType: string
  currencyCode: string
};

export type ChildExtIdToSettings = { [key: string]: ChildSettingsData };

export type BudgetHierarchy = {
  childExtIdToSettings: ChildExtIdToSettings
  parentSettings: BudgetInterval
  parentExtId: string
  invalidData: ChildExtIdToSettings
};

export type BudgetAllocationData = {
  parentData: {
    cumData: Array<ParentDatum>
  }
  hierarchy: BudgetHierarchy
  childData: {
    [key: string]: { cumData: Array<ParentDatum> }
  }
  remainingSpendDays: number
  origToClone: { [key: string]: string }
};

export type BudgetAllocationResponse =
  | { kind: Status.initial }
  | { kind: Status.loading }
  | { kind: Status.error; error: string }
  | { kind: Status.hasData; data: { [flightExtId: string]: BudgetAllocationData } };

export type BudgetAllocationProps = {
  displayName: string
  formValues: BudgetOptimizationForm
  flight: Flight
  budgetAllocationState: BudgetAllocationResponse
  tooltips: TooltipsDescriptions
  member: Member
  strategyType: StrategyType
  user: User
  hasChildFlightsData: boolean
  strategyWizardFormValues: WizardFormValues | BulkCreateWizardFormValues
  strategyId?: number
  descriptionText?: string
  dspSpecificChildrenState?: StateT<Array<DBMLineItemT>>
  isCrossPlatformOptimization?: boolean
};

export type IntelligentChildObjectsToggleProps = {
  displayName: string
};

export type FixedCostInventoryToggleProps = {
  displayName: string
};

export type DBMLineItemT = {
  name: string
  id: number
  insertionOrder: number
  lineItemType: string
  externalId: string
};

export type StaticDisplayProps = {
  index: number
  minValue: number
  maxValue: number
};

export type InputDisplayProps = {
  minFieldStr: string
  maxFieldStr: string
  externalId: string
};

export type MinMaxType = { [key: string]: { min: number | string; max: number | string } };

export type ParentTableWithChildrenPropsWrapperProps = {
  budgetAllocationState: BudgetAllocationResponse
  externalTypeId: number
  color: string
  config: StrategyConfigurationStep
};

export type AttachPageChildGroupsWrapperProps = {
  budgetAllocationState: BudgetAllocationResponse
  externalTypeId: number
};

export type BudgetAllocationTableWrapperNewProps = {
  budgetAllocationState: BudgetAllocationResponse
  flight: Flight
  tooltips?: TooltipsDescriptions
  expanded: boolean
  setExpanded: (isExpanded) => void
  formValues: BudgetOptimizationForm
};

export type EstimatedSpendMetrics = {
  estimatedDailyMinValue: number
  estimatedDailyMaxValue: number
  estimatedLifetimeMinValue: number
  estimatedLifetimeMaxValue: number
};

export type ParentMetrics = {
  lifeTimeBudget: number
  requiredDailyValue: number
};

export type DisplayChildNamesWithClonesProps = {
  name: string
  cloneName: string
  showNameTooLongWarning: boolean
};
export type DisplayChildNamesProps = { name: string };

export type DisplayEstimatedDailyValuesProps = {
  estimatedDailyMinValue: number
  estimatedDailyMaxValue: number
  requiredDailyValue?: number
  budgetMetric?: string
};

export type DisplayEstimatedLifetimeValuesProps = {
  estimatedLifetimeMinValue: number
  estimatedLifetimeMaxValue: number
  style?: CSSProperties
  budgetMetric?: string
};

export type DisplayDatesProps = {
  data: ChildSettingsData
  displayIntelligentChildObjects: boolean
  displayDates: boolean
};

export type DisplayDateProps = {
  date: Date
};

export type GroupSettings = {
  [groupId: string]: BudgetGroup
};

export type BudgetGroup = {
  groupName: string
  min: number
  max: number
  childExtIds: Array<string>
  allocationStrategy?: string | null
  budgetOptimization?: boolean
  deactivateOptimization?: number
  aggDelivery?: number
};

export type SingleChildOption = {
  lineItemDisplayName: string
  parentId: string
  isProgrammaticGuaranteed: boolean
  extId?: string
  cloneDisplayName?: string
  origExtId?: string
};

export type ChildOptions = {
  [childObjName: string]: SingleChildOption
};

export type ParentOptions = {
  [parentObjName: string]: ChildOptions
};

export type BudgetGroupOptions = {
  [dspKey: string]: ParentOptions
};

export type BudgetGroupLineItemMapping = {
  [budgetGroupId: string]: ChildOptions
};

export enum FilterButtonState {
  all = 'All',
  selected = 'Selected',
}

export type ExpandAllOptionsObject = {
  [key: string]: boolean
};
