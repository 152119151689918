import _ from 'lodash';
import { OPTIMIZED_BIDS as BASE_OPTIIMZED_BIDS } from 'containers/StrategyWizard/ConfigurationByStrategyType/Helios/constants';

export const MODEL_FORM = 'helios-segrec-model-form';

export const OPTIMIZED_BIDS = _.pick(BASE_OPTIIMZED_BIDS, ['minBid', 'maxBid']);

export const guideLink = 'https://xaxistech.atlassian.net/wiki/spaces/CP/pages/504070398/Segment+Recency+NEW+Resources';

export const MAX_DAYS = 180;
