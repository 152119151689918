import React from 'react';
import PageLoading from 'components/PageLoading/PageLoading';
import { PAGE_NAME } from '../StrategyWizard';

type ErrorProps = {
  id: number | string
  error: string
};

const StrategyError = ({ id, error }: ErrorProps) => (
  <PageLoading loading={false} pageName={PAGE_NAME}>
    <div style={{ color: 'red' }}>
      {`Error while loading strategy (${id})`}
      <br /><br />{error}
      <br /><br />Please submit a support ticket.
    </div>
  </PageLoading>
);

export default StrategyError;
