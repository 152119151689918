import _ from 'lodash';
import React from 'react';
import {
  TOOLTIP_DESCRIPTIONS, BID_MODEL_SUPPORTED_STRATEGY_TYPES,
  WIKI_LINK,
  TTD_FIXED_COST_INVENTORY_WIKI, LINK_FOR_BUDGET_MANAGEMENT_TOOLTIP,
} from 'containers/StrategyWizard/ConfigurationByStrategyType/BudgetOptimization/constants';
import { Member, StrategyType, User } from 'utils/types';
import { featureEnabled, MEMBER, Permission } from 'utils/featureFlags';
import { DSP, STRATEGY_TYPE } from 'constantsBase';

const REQUIRE_INTELLIGENT_CHILD_OBJ_PERMISSIONS = {
  [STRATEGY_TYPE.apnBudgetOptimization.id]: Permission.xndrIntelligentChildObjects,
  [STRATEGY_TYPE.crossPlatformOptimization.id]: Permission.crossPlatformIntelligentChildObjects,
};

export const tooltipContent = (dsp: number, key: string) => {
  const link = (dsp === DSP.TTD.id && key === 'fixedCostInventory') ? TTD_FIXED_COST_INVENTORY_WIKI : WIKI_LINK;
  return (
    <span>
      {TOOLTIP_DESCRIPTIONS[dsp][key]} <a href={link} target="_blank" rel="noopener noreferrer">Read more.</a>
    </span>
  );
};

export const tooltipContentForBudget = (content: string) => (
  <span>
    {content} <a href={LINK_FOR_BUDGET_MANAGEMENT_TOOLTIP} target="_blank" rel="noopener noreferrer">Learn more</a>.
  </span>
);

export const shouldShowIntelligentChildObjectsToggle = (
  user: User,
  member: Member,
  strategyType: StrategyType,
  hasExternalCustomValue: boolean,
) => {
  if (hasExternalCustomValue) {
    return false;
  }
  if (strategyType) {
    if (_.has(REQUIRE_INTELLIGENT_CHILD_OBJ_PERMISSIONS, strategyType.id)) {
      const mem = _.isNil(member) ? MEMBER.ANY : member;
      return featureEnabled(user, REQUIRE_INTELLIGENT_CHILD_OBJ_PERMISSIONS[strategyType.id], mem);
    }
    if (strategyType.id === STRATEGY_TYPE.amznBudgetOptimization.id) {
      return false;
    }
    return true;
  }
  return false;
};

export const shouldShowBidModelToggles = (user: User, member: Member, strategyType: StrategyType) => {
  if (strategyType) {
    return (
      _.includes(BID_MODEL_SUPPORTED_STRATEGY_TYPES, strategyType.id)
      && featureEnabled(user, Permission.ttdBudgetOptBidModel, member)
    );
  }
  return false;
};
