/* eslint-disable react-hooks/exhaustive-deps */
import _ from 'lodash';
import React, { Dispatch, SetStateAction, useCallback, useEffect, useRef, useState } from 'react';
import { WppActionButton, WppTypography } from 'buildingBlocks';
import {
  BudgetGroupOptions,
  ChildOptions,
  FilterButtonState,
  ExpandAllOptionsObject,
  ParentOptions,
  SingleChildOption,
} from 'containers/StrategyWizard/ConfigurationByStrategyType/BudgetOptimization/types';
import { LineItemTypes } from 'containers/StrategyWizard/ConfigurationByStrategyType/BudgetOptimization/constants';
import { filterByLineItemType } from 'containers/StrategyWizard/ConfigurationByStrategyType/BudgetOptimization/utils';
import DspOptionsSection from './DspOptionsSection';
import { POP_UP_FORM } from './style';

const { optionsList, noneFoundContainer } = POP_UP_FORM;

type FormOptionsProps = {
  groupedOptions: BudgetGroupOptions
  search: string
  setSearch: Dispatch<SetStateAction<string>>
  selectedLineItemSession: ChildOptions
  setSelectedLineItemSession: Dispatch<SetStateAction<ChildOptions>>
  alreadyAttachedLineItemKeys: Array<string>
  filterBtnState: FilterButtonState
  setFilterBtnState: Dispatch<SetStateAction<FilterButtonState>>
  expandAllObj: ExpandAllOptionsObject
  setExpandAllObj: Dispatch<SetStateAction<ExpandAllOptionsObject>>
  lineItemTypeFilter: LineItemTypes
  handleClearFilters: () => void
  shouldShowBudgetOptimizationToggle: boolean
};

const FormOptions = ({
  groupedOptions,
  search,
  setSearch,
  selectedLineItemSession,
  setSelectedLineItemSession,
  alreadyAttachedLineItemKeys,
  filterBtnState,
  setFilterBtnState,
  expandAllObj,
  setExpandAllObj,
  lineItemTypeFilter,
  handleClearFilters,
  shouldShowBudgetOptimizationToggle,
}: FormOptionsProps) => {
  const [filteredOptions, setFilteredOptions] = useState<BudgetGroupOptions>(groupedOptions);

  // using optionsToSearch as source for search
  const optionsToSearch = useRef<BudgetGroupOptions>(groupedOptions);

  const updateExpandAllObj = (groupedOptionsObj: BudgetGroupOptions) => {
    setExpandAllObj(_.reduce(groupedOptionsObj, (acc: ExpandAllOptionsObject, options: ParentOptions) => {
      _.forEach(options, (_childOpt, parentOpt: string) => {
        acc[parentOpt] = true;
      });
      return acc;
    }, {}));
  };

  const getSelectedOptions = (lineItemType: LineItemTypes) => {
    const selectedChildOptions = {};
    const selectedLineItemsKeys = _.concat(_.keys(selectedLineItemSession));
    _.forEach(groupedOptions, (parentToChildObj: ParentOptions, dspId: string) => {
      _.forEach(parentToChildObj, (childOptions: ChildOptions, parentObjName: string) => {
        const matches = _.pickBy(childOptions, (_childOptionObj, childOptionName: string) => _.includes(selectedLineItemsKeys, childOptionName));
        if (_.size(matches)) {
          selectedChildOptions[dspId] = { ...selectedChildOptions[dspId], [parentObjName]: matches };
        }
      });
    });
    updateExpandAllObj(selectedChildOptions);
    setFilteredOptions(filterByLineItemType(selectedChildOptions, lineItemType));
    optionsToSearch.current = selectedChildOptions;
  };

  const getAllChildOptions = (lineItemType: LineItemTypes) => {
    updateExpandAllObj(groupedOptions);
    setFilteredOptions(filterByLineItemType(groupedOptions, lineItemType));
  };

  const filterOptions = (lineItemType: LineItemTypes) => {
    if (search === '') {
      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
      filterBtnState === FilterButtonState.all
        ? getAllChildOptions(lineItemType)
        : getSelectedOptions(lineItemType);
      return;
    }
    const result = {};
    _.forEach(optionsToSearch.current, (options: ParentOptions, dspKey: string) => {
      _.forEach(options, (childOptions: ChildOptions, parentOption: string) => {
        const lineItemMatches = _.pickBy(childOptions, (option: SingleChildOption) => _.includes(option.lineItemDisplayName.toLowerCase(), search.toLowerCase()));
        if (_.size(lineItemMatches)) {
          result[dspKey] = { ...result[dspKey], [parentOption]: lineItemMatches };
        }
      });
    });
    updateExpandAllObj(result);
    setFilteredOptions(filterByLineItemType(result, lineItemType));
  };

  const filterOptionsWrapper = useCallback(_.debounce(filterOptions, 400), [search, selectedLineItemSession, lineItemTypeFilter]);

  useEffect(() => {
    if (filterBtnState === FilterButtonState.selected) {
      getSelectedOptions(lineItemTypeFilter);
    } else {
      getAllChildOptions(lineItemTypeFilter);
      optionsToSearch.current = groupedOptions;
    }
    // need to trigger filter function when toggling filterBtnState
    filterOptions(lineItemTypeFilter);
  }, [filterBtnState, selectedLineItemSession, lineItemTypeFilter]);

  useEffect(() => {
    filterOptionsWrapper(lineItemTypeFilter);
  }, [search, lineItemTypeFilter]);

  const allOptions = _.map(filteredOptions, (options: ParentOptions, dspKey: string) => (
    <DspOptionsSection
      key={dspKey}
      dspId={dspKey}
      sectionOptions={options}
      selectedLineItemSession={selectedLineItemSession}
      setSelectedLineItemSession={setSelectedLineItemSession}
      alreadyAttachedLineItemKeys={alreadyAttachedLineItemKeys}
      expandAllObj={expandAllObj}
      shouldShowBudgetOptimizationToggle={shouldShowBudgetOptimizationToggle}
    />
  ));

  const onlySearchBarUsed = search && _.isEqual(lineItemTypeFilter, LineItemTypes.all) && _.isEqual(filterBtnState, FilterButtonState.all);
  const onlySelectedFilter = _.isEmpty(search) && _.isEqual(lineItemTypeFilter, LineItemTypes.all) && _.isEqual(filterBtnState, FilterButtonState.selected);

  const handleClearFiltersClick = () => {
    if (onlySearchBarUsed) {
      setSearch('');
    } else if (onlySelectedFilter) {
      setFilterBtnState(FilterButtonState.all);
    } else {
      handleClearFilters();
    }
  };

  const getActionButtonText = () => {
    if (onlySearchBarUsed) {
      return 'Clear Search Bar';
    }
    return onlySelectedFilter ? 'Select Object' : 'Clear Filters';
  };

  const noneFoundText = onlySelectedFilter
    ? 'No objects are selected. Please select some objects.'
    : 'No objects found. Please try again.';

  return (
    <div style={optionsList}>
      {_.size(allOptions)
        ? allOptions
        : (
          <div style={noneFoundContainer}>
            <WppTypography type="s-body">{noneFoundText}</WppTypography>
            <WppActionButton onClick={handleClearFiltersClick}>{getActionButtonText()}</WppActionButton>
          </div>
        )}
    </div>
  );
};

export default FormOptions;
