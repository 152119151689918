/* eslint-disable camelcase */
import _ from 'lodash';
import { toCamelCase, pluralizer } from 'utils/formattingUtils';
import { DSP } from 'constantsBase';
import { AppNexus } from 'utils/copilotAPI';

type HeaderObjType = {
  name: string
  parseFn: Function
  checkFn: Function
  required: boolean
  isUnique: boolean
};

export type FiltersType = {
  pixelId: Array<number>
  deviceType?: Array<string>
};

type HeaderConfigType = Array<HeaderObjType>;

type ConvRevImpValueFiltersType = Array<{ filters: {}, impression_value: Array<string> }>;

type EngScoreImpValueFiltersType = Array<{ filters: FiltersType, impression_value: number }>;

export type ImpValueFiltersType = ConvRevImpValueFiltersType | EngScoreImpValueFiltersType;

type SegmentLeafJsonType = {
  segmentID: string
  leafName: string
};
type RoasJsonType = {
  pixelId: string
  score: string
  deviceType?: string
};

type InputJsonType = SegmentLeafJsonType | RoasJsonType;

type FnObjtype =
  | { segmentID: Function, leafName: Function }
  | { pixelId: Function, score: Function, deviceType?: Function };

export type CsvResultType = {
  errors?: Array<string>
  impValueFilters?: EngScoreImpValueFiltersType
  segmentToLeafName?: { number: string }
  successInfo?: Array<string>
};

const CONVERSION_REVENUE_IMP_VALUE = [{
  filters: {},
  impression_value: ['post_view_revenue', 'post_click_revenue'],
}];

const CONVERSION_REVENUE_IMP_VALUE_TTD = [{
  filters: {},
  impression_value: ['monetary_value'],
}];

const CONVERSION_REVENUE_IMP_VALUE_WMT = [{
  filters: {},
  impression_value: ['monetary_value'],
}];

const CONVERSION_REVENUE_IMP_VALUE_AMZN = [{
  filters: {},
  impression_value: ['sales14d'],
}];

export const CONVERSION_REVENUE_IMP_VALUE_BY_DSP = {
  [DSP.APN.id]: CONVERSION_REVENUE_IMP_VALUE,
  [DSP.TTD.id]: CONVERSION_REVENUE_IMP_VALUE_TTD,
  [DSP.AMZN.id]: CONVERSION_REVENUE_IMP_VALUE_AMZN,
  [DSP.WALMART.id]: CONVERSION_REVENUE_IMP_VALUE_WMT,
};

export const colsPluralizer = pluralizer('column', 'columns');
export const rowsPluralizer = pluralizer('row', 'rows');

export const requiredCSVCols = (roasCols: HeaderConfigType) => _(roasCols)
  .filter('required')
  .map('name')
  .value();

const getDuplicates = (vals: Array<string>) => _.keys(_.pickBy(_.countBy(vals), (x) => x > 1));

const checkForInvalidHeaders = (
  headers: Array<string>,
  headerConfig: HeaderConfigType,
) => {
  const configColsByName = _.map(headerConfig, 'name');
  return _.difference(headers, configColsByName);
};

export const validateCSVHeaders = (
  headers: Array<string>,
  headerConfig: HeaderConfigType,
) => {
  const requiredCols = requiredCSVCols(headerConfig);

  const missingRequiredCols = _.difference(requiredCols, headers);
  if (!_.isEmpty(missingRequiredCols)) {
    return [`Missing required ${colsPluralizer(missingRequiredCols.length)} [${missingRequiredCols.join(', ')}]`];
  }
  const duplicateCols = getDuplicates(headers);
  if (!_.isEmpty(duplicateCols)) {
    return [`Duplicate ${colsPluralizer(duplicateCols.length)} [${duplicateCols.join(',')}]`];
  }
  const inValidCols = checkForInvalidHeaders(headers, headerConfig);
  if (!_.isEmpty(inValidCols)) {
    return [`Invalid ${colsPluralizer(inValidCols.length)} [${inValidCols.join(',')}]`];
  }
  return [];
};

export const getDataAsTextArray = (data: any) => {
  const csvText = typeof data === 'string'
    ? data : String.fromCharCode.apply(null, new Uint16Array(data));

  // support if break line is \n (windows/mac) or \r (unix/mac) or both (windows)
  return csvText.trim().split(/\r\n|\r|\n/);
};

// @ts-ignore - return value `FnObjtype` may or may not have certain properties
const makeFnObj = (field: string, headerConfig: HeaderConfigType): FnObjtype => (
  _.fromPairs(_.map(headerConfig, (obj) => [obj.name, obj[field]]))
);

const applyObjOfFns = (fnObj: FnObjtype, obj: InputJsonType) => (
  _.fromPairs(_.map(fnObj, (fn: Function, k: string) => [k, fn(obj[k])]))
);

const applyCheckFnOnObj = (obj: InputJsonType, headerConfig: HeaderConfigType) => {
  const checkFnObj = makeFnObj('checkFn', headerConfig);
  const resObj = applyObjOfFns(checkFnObj, obj);
  const errObj = _.pickBy(resObj, (v) => !v);
  return _.keys(errObj);
};

const applyParseFnOnObj = (obj: InputJsonType, headerConfig: HeaderConfigType) => {
  const parseFnObj = makeFnObj('parseFn', headerConfig);
  return applyObjOfFns(parseFnObj, obj);
};

const getHeadersWithUniqueVals = (roasCols: HeaderConfigType) => _(roasCols)
  .filter('isUnique')
  .map('name')
  .value();

const getHeaderConfigToUse = (jsonObjList: Array<InputJsonType>, headerConfig: HeaderConfigType) => {
  const inputHeaders = _.keys(_.assign({}, ...jsonObjList));
  return _.filter(headerConfig, (obj) => _.includes(inputHeaders, obj.name));
};

const checkForDuplicates = (jsonObjList: Array<InputJsonType>, headerConfig: HeaderConfigType) => {
  const inputHeaders = _.keys(_.assign({}, ...jsonObjList));
  const headersWithUnique = getHeadersWithUniqueVals(headerConfig);
  const colsToCheckForDups = _.intersection(inputHeaders, headersWithUnique);
  const errors = [];
  _.forEach(colsToCheckForDups, (col: string) => {
    const vals = _.map(jsonObjList, (obj) => obj[col]);
    const dups = getDuplicates(vals);
    if (!_.isEmpty(dups)) {
      errors.push(`Duplicate values: [${dups.join(',')}] in ${col} column `);
    }
  });
  return errors;
};

const checkForInvalidVals = (jsonObjList: Array<InputJsonType>, headerConfig: HeaderConfigType) => {
  const errors = [];
  const headerConfigToUse = getHeaderConfigToUse(jsonObjList, headerConfig);
  _.forEach(jsonObjList, (obj, i: number) => {
    const invalidCells = applyCheckFnOnObj(obj, headerConfigToUse);
    if (!_.isEmpty(invalidCells)) {
      errors.push(`Invalid or missing value for [${invalidCells.join(',')}] in Row:${i + 2} `);
    }
  });
  return errors;
};

const getParsedJsonObjList = (jsonObjList: Array<InputJsonType>, headerConfig: HeaderConfigType) => {
  const parsedJsonObjList = [];
  const headerConfigToUse = getHeaderConfigToUse(jsonObjList, headerConfig);
  _.forEach(jsonObjList, (obj) => {
    const parsedJsonObj = applyParseFnOnObj(obj, headerConfigToUse);
    parsedJsonObjList.push(parsedJsonObj);
  });
  return parsedJsonObjList;
};

const checkForInvalidPixels = async (jsonObjList: Array<RoasJsonType>, memberId: number) => {
  const pixelIds = _.map(jsonObjList, (obj) => parseInt(obj.pixelId, 10));
  const res = await AppNexus.pixelsById(memberId, pixelIds);
  const validPixels = _.map(res.data.response, (obj) => obj.id);
  const invalidPixels = _.difference(pixelIds, validPixels);
  return !_.isEmpty(invalidPixels) ? `Invalid Conversion Pixel IDs: [${invalidPixels}]` : '';
};

const validateRows = async (
  jsonObjList: Array<InputJsonType>,
  headerConfig: HeaderConfigType,
  memberId?: number,
  dsp?: number,
) => {
  const errors = [];
  // check for duplicate values
  const duplicateValErrors = checkForDuplicates(jsonObjList, headerConfig);
  errors.push(...duplicateValErrors);

  // check for Invalid Values
  const inValidValErrors = checkForInvalidVals(jsonObjList, headerConfig);
  errors.push(...inValidValErrors);

  // validate pixelIds against Xandr
  if (memberId && dsp === DSP.APN.id && _.isEmpty(inValidValErrors)) {
    const invalidPixels = await checkForInvalidPixels(jsonObjList as Array<RoasJsonType>, memberId);
    if (invalidPixels) {
      errors.push(invalidPixels);
    }
  }

  return errors;
};

/*
 * Parse rows based on headerConfig
 * if any value is missing, concatenate the error to the error list
 * otherwise build up the resultant object
 * return
 *   - result object
 *   - errors: list of all errors
 */
export const parseCSVRows = async (
  rows: Array<string>,
  headers: Array<string>,
  headerConfig: HeaderConfigType,
  createResultFn: Function,
  memberId?: number,
  dsp?: number,
): Promise<CsvResultType> => {
  if (_.isEmpty(rows)) {
    return { errors: ['file is empty'] };
  }

  const values = _.map(rows, (row) => row.split(','));

  const jsonObjList = _.map(values, (x) => _.zipObject(headers, x)) as Array<InputJsonType>;
  const errors = await validateRows(jsonObjList, headerConfig, memberId, dsp);

  if (_.isEmpty(errors)) {
    const parsedJsonObjList = getParsedJsonObjList(jsonObjList, headerConfig);
    return { ...createResultFn(parsedJsonObjList) };
  }

  return { errors };
};

/*
 * Parse csv based on headerConfig
 * if any value is missing, concatenate the error to the error list
 * otherwise build up the resultant object
 * return
 *   - result object
 *   - errors: list of all errors
 */
export const parseCSV = async (
  data: Array<string>,
  headerConfig: HeaderConfigType,
  createResultFn: Function,
  memberId?: number,
  dsp?: number,
) => {
  const headerCols = data.shift().split(',');
  const headers = _.map(headerCols, (v) => toCamelCase(v));
  const invalidHeaders = validateCSVHeaders(headers, headerConfig);

  if (!_.isEmpty(invalidHeaders)) {
    return { errors: invalidHeaders };
  }

  const res = await parseCSVRows(data, headers, headerConfig, createResultFn, memberId, dsp);
  return res;
};
