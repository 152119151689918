import _ from 'lodash';
import moment from 'moment-timezone';
import { UseFormSetValue } from 'react-hook-form';
import { BOOLEAN_OPERATORS, FLIGHT_EXTERNAL_TYPE, STRATEGY_TYPE, APN_CONSOLE_REVENUE_TYPE } from 'constantsBase';
import { getSegmentIdentifier, segmentFormatOption, APNSegment } from 'containers/StrategyWizard/ConfigurationByStrategyType/segmentUtils';
import { getBoolOperatorForSegments, getSegmentGroupTargetsData } from 'containers/StrategyWizard/steps/StrategyConfiguration/utils';
import { HeliosForm, SegmentRecencyForm, WizardFormValuesForInitialization } from 'containers/StrategyWizard/types';
import { roundToNearest } from 'utils/functionHelpers';
import { DATE_TIME_FORMAT } from 'utils/dateTime';
import { generateUUID } from 'utils/formattingUtils';
import { SegmentGroupsType, SegmentGroup, Flight, StrategyType, Currency, LineItem, StrategyGoal } from 'utils/types';
import { SEGMENT_ACTIONS, OPTIMIZED_MULTIPLIERS, OPTIMIZED_BIDS, BID_TYPES } from './constants';
import { BidTypeValue, BudgetIntervals } from './type';
import { getMinConfiguration, getStrategyConfigsForFlights } from '../StrategyRecommendation/strategySetup';

export const flattenAllSegments = (segmentGroups: SegmentGroupsType) => (
  _.flattenDeep(_.map(segmentGroups, 'segments'))
);

export const flattenAllSegmentIdentifiers = (segmentGroups: SegmentGroupsType) => (
  _.map(flattenAllSegments(segmentGroups), getSegmentIdentifier)
);

export const formatSegmentResponse = (segments: Array<APNSegment>) => (
  _.map(segments, (segment) => ({
    ...segment,
    ...segmentFormatOption(segment),
    action: SEGMENT_ACTIONS.INCLUDE,
  }))
);

export const customRound = (n: number) => {
  if (n < 1) {
    return roundToNearest(n, 0.05);
  }
  if (n < 100) {
    return roundToNearest(n, 1);
  } if (n < 1000) {
    return roundToNearest(n, 5);
  } if (n < 10000) {
    return roundToNearest(n, 50);
  }

  return roundToNearest(n, 500);
};

export const formatBudgetIntervals = (oldBudgetIntervals: BudgetIntervals | null, budgetIntervals: BudgetIntervals) => (
  _.map(
    budgetIntervals,
    ({
      startDate, endDate, lifetimeBudget, timezone,
    }) => {
      const momentStartDate = moment.utc(startDate).tz(timezone);
      const momentEndDate = moment.utc(endDate).tz(timezone);
      const dateRange = {
        startDate: momentStartDate.toISOString(),
        endDate: momentEndDate.toISOString(),
      };

      return {
        ...dateRange,
        // Should NOT use dateTimeFormatter because dateTimeFormatter will convert it to local timezone
        displayStartDate: momentStartDate.format(DATE_TIME_FORMAT),
        displayEndDate: momentEndDate.format(DATE_TIME_FORMAT),
        timezone,
        lifetimeBudget: _.get(_.find(oldBudgetIntervals, dateRange), 'lifetimeBudget', lifetimeBudget),
      };
    },
  ));

export const DEFAULT_SEGMENT_GROUP = {
  uuid: generateUUID(),
  name: 'New Group 0',
  boolOperator: BOOLEAN_OPERATORS.OR,
  segments: [],
};

const DEFAULT_MAX_BID = 25;

export const getInitialMaxBid = (
  stratDefaultWizardConfig: {},
  wizardForm: WizardFormValuesForInitialization,
  currencyExchange: {},
  defaultMaxBid: number = DEFAULT_MAX_BID,
) => {
  const currencyCode = _.get(wizardForm, 'advertiser.defaultCurrency.code');
  // for UK we use the same bid as USD
  const rate = currencyCode !== 'GBP' ? _.get(currencyExchange, `${currencyCode}.ratePerUsd`, 1) : 1;
  return {
    maxBid: _.get(stratDefaultWizardConfig, 'maxBid', customRound(defaultMaxBid * rate)),
    baseBid: customRound(0.5 * rate),
  };
};

export const processHeliosSegmentGroups = (segmentGroupTargets: Array<SegmentGroup>) => (
  _.map(segmentGroupTargets, (sgt, idx) => {
    const uuid = generateUUID();
    const boolOperator = getBoolOperatorForSegments(_.get(sgt, 'boolean_operator'));
    const name = `New Group ${idx}`;
    return ({ ...sgt, uuid, boolOperator, name });
  })
);

const getHeliosSegmentGroups = async (wizardForm: WizardFormValuesForInitialization, populateSegments?: boolean) => {
  const segmentGroupTargetsData = await getSegmentGroupTargetsData(wizardForm, populateSegments);
  return _.isEmpty(segmentGroupTargetsData)
    ? [DEFAULT_SEGMENT_GROUP]
    : processHeliosSegmentGroups(segmentGroupTargetsData[0]);
};

export const getBidType = (attachedFlights: Array<Flight>) => (
  !_.isEmpty(attachedFlights) && attachedFlights[0].externalType === FLIGHT_EXTERNAL_TYPE.apnCampaign.id ? BID_TYPES.BID : BID_TYPES.BID_MULTIPLIER
);

export const getBidsAndBidType = (attachedFlights: Array<Flight>, stratTypeId: StrategyType['id']) => {
  const bidType = getBidType(attachedFlights) as BidTypeValue;
  const optimizedBids = stratTypeId === STRATEGY_TYPE.helios.id ? OPTIMIZED_BIDS : _.pick(OPTIMIZED_BIDS, ['minBid', 'maxBid']);
  const bids = bidType === BID_TYPES.BID ? _.values(optimizedBids) : _.values(OPTIMIZED_MULTIPLIERS);
  return { bids, bidType };
};

const getHeliosInitialBids = (stratDefaultWizardConfig: {}, wizardForm: WizardFormValuesForInitialization, currencyExchange: {}) => {
  const bidType = getBidType(wizardForm.attachedFlights);
  if (bidType === BID_TYPES.BID) {
    return { minBid: 0.1, ...getInitialMaxBid(stratDefaultWizardConfig, wizardForm, currencyExchange, DEFAULT_MAX_BID) };
  }
  return { baseBid: 1, minBid: 0.01, maxBid: 1 };
};

export const getInitialValues = async (stratDefaultWizardConfig: {}, wizardForm: WizardFormValuesForInitialization, currencyExchange: {}, populateSegments?: boolean) => {
  const segmentGroups = await getHeliosSegmentGroups(wizardForm, populateSegments);
  const initialBids = getHeliosInitialBids(stratDefaultWizardConfig, wizardForm, currencyExchange);
  return {
    segmentGroups,
    useCustomBudget: false,
    creativeOptimization: false,
    isBFO: false,
    viewability: { enabled: false },
    ...initialBids,
  };
};

export const getEstimatedBids = (currency: Currency, li: LineItem, minBidMultiplier: number, maxBidMultiplier: number) => {
  switch (li.consoleRevenueType) {
    case APN_CONSOLE_REVENUE_TYPE.cpm.id:
      return { minBid: `${_.round(minBidMultiplier * li.revenueValue, 2)} ${currency.code}`,
        minBidFormula: 'Optimized Minimum Multiplier * Revenue Value',
        maxBid: `${_.round(maxBidMultiplier * li.revenueValue, 2)} ${currency.code}`,
        maxBidFormula: 'Optimized Maximum Multiplier * Revenue Value' };
    case APN_CONSOLE_REVENUE_TYPE.costPlus.id:
      return { minBid: `${0} ${currency.code}`,
        minBidFormula: 'Optimized Minimum Multiplier * Xandr Valuation',
        maxBid: `${maxBidMultiplier} * Xandr Valuation ${currency.code}`,
        maxBidFormula: 'Optimized Maximum Multiplier * Xandr Valuation' };
    case APN_CONSOLE_REVENUE_TYPE.dcpm.id:
      return { minBid: `${_.round(minBidMultiplier * _.get(li, 'minAvgCpm', 0), 2)} ${currency.code}`,
        minBidFormula: 'Optimized Minimum Multiplier * Min Avg CPM',
        maxBid: `${_.round(maxBidMultiplier * _.get(li, 'maxAvgCpm', 0), 2)} ${currency.code}`,
        maxBidFormula: 'Optimized Maximum Multiplier * Max Avg CPM' };
    case APN_CONSOLE_REVENUE_TYPE.vcpm.id:
      return { minBid: `${0} ${currency.code}`,
        minBidFormula: 'Optimized Minimum Multiplier * Revenue Value * Xandr Viewability Probability',
        maxBid: `${_.round(maxBidMultiplier * li.revenueValue, 2)} ${currency.code}`,
        maxBidFormula: 'Optimized Maximum Multiplier * Revenue Value * Xandr Viewability Probability' };
    case APN_CONSOLE_REVENUE_TYPE.cpc.id:
      return { minBid: `${0} ${currency.code}`,
        minBidFormula: 'Optimized Minimum Multiplier * Revenue Value * Xandr Click Probability * 1000',
        maxBid: `${_.round(maxBidMultiplier * li.revenueValue * 1000, 2)} ${currency.code}`,
        maxBidFormula: 'Optimized Maximum Multiplier * Revenue Value * Xandr Click Probability * 1000' };
    case APN_CONSOLE_REVENUE_TYPE.cpcv.id:
      return { minBid: `${0} ${currency.code}`,
        minBidFormula: 'Optimized Minimum Multiplier * Revenue Value * Xandr Completion Probability * 1000',
        maxBid: `${_.round(maxBidMultiplier * li.revenueValue * 1000, 2)} ${currency.code}`,
        maxBidFormula: 'Optimized Maximum Multiplier * Revenue Value * Xandr Completion Probability * 1000' };
    default:
      return { minBid: `${0} ${currency.code}`, minBidFormula: '', maxBid: `${1} ${currency.code}`, maxBidFormula: '' };
  }
};

type GetStrategyConfigValuesType = (
  strategyGoals: Array<StrategyGoal>,
  strategyType: StrategyType,
  attachedFlights: Array<LineItem>,
  setValue: UseFormSetValue<SegmentRecencyForm | HeliosForm>,
) => void;

const getStrategyConfigValues: GetStrategyConfigValuesType = (goalType, strategyType, attachedFlights, setValue) => {
  // @ts-ignore
  const configs = getStrategyConfigsForFlights(strategyType, goalType, attachedFlights);
  const minConfiguration = getMinConfiguration(configs);
  _.forEach(minConfiguration, (value, key) => setValue(key as 'minBid' | 'maxBid', value));
};

export const strategyConfigValuesUtil = (lineItems, attachedFlights, goalType, strategyType, setValue) => {
  const lineItemsByExtId = _.keyBy(lineItems, 'externalId');
  const attachedLIs = !_.isEmpty(lineItemsByExtId) ? _.map(attachedFlights, (f) => lineItemsByExtId[f.externalId]) : [];
  getStrategyConfigValues(goalType, strategyType, _.compact(attachedLIs), setValue);
};
