import _ from 'lodash';
import React from 'react';
import { Router } from 'utils/withRouter';
import { WppStepper, WppStep } from 'buildingBlocks';
import strategyWizardStyles from 'containers/StrategyWizard/styles';
import { Strategy } from 'utils/types';
import { WIZARD_STEPS, StepItem, WizardSteps } from '../constants';
import { WizardFormValues } from '../types';

type Props = {
  step: number
  wizardFormValues: WizardFormValues | Strategy
  router: Router
};

type StepDescriptionProps = {
  formField: {}
  additionalFieldsForDisplay: Array<string>
  wizardFormValues: WizardFormValues | Strategy
  description: string
  dynamicDescription: (formField: {}, ...args: Array<string>) => string
  currentStep: number
  stepId: number
};

type SubStepsProps = {
  subSteps: {
    [key: string]: {
      id: number
      title: string
    }
  }
};

const StepDescription = ({
  formField,
  additionalFieldsForDisplay,
  wizardFormValues,
  description,
  dynamicDescription,
  currentStep,
  stepId,
}: StepDescriptionProps) => {
  if (stepId >= currentStep || currentStep <= _.toNumber(WIZARD_STEPS[WizardSteps.attachFlightsStep].subSteps.objects.id)) {
    return null;
  }
  let additionalFieldValues = [];
  if (formField) {
    if (additionalFieldsForDisplay) {
      additionalFieldValues = _.map(additionalFieldsForDisplay, (field) => _.get(wizardFormValues, field));
    }
    if (!_.isEmpty(formField)) {
      return <span slot="description" style={strategyWizardStyles.stepperDescription}>{dynamicDescription(formField, ...additionalFieldValues)}</span>;
    }
    return null;
  }
  return <span slot="description" style={strategyWizardStyles.stepperDescription}>{description}</span>;
};

const SubSteps = ({ subSteps }: SubStepsProps) => {
  // Convert object values to an array for mapping
  const subStepsArray = Object.values(subSteps);

  if (subStepsArray.length) {
    return (
      <>
        {_.map(subStepsArray, ({ id, title }) => (
          <WppStep key={id} substep>
            <span slot="label" style={strategyWizardStyles.stepperLableStyle}>
              {title}
            </span>
          </WppStep>
        ))}
      </>
    );
  }
  return null;
};

const StrategyWizardProgress = ({
  step,
  wizardFormValues,
  router,
}: Props) => (
  <WppStepper activeStep={_.toNumber(router.params.step)} useDecimalSubSteps stepperWidth="100%">
    {
      _.map(WIZARD_STEPS, (stepItem: StepItem) => {
        const { id, title, description, subSteps } = stepItem;
        const dynamicDescription = _.get(stepItem, 'dynamicDescription');
        const additionalFieldsForDisplay = _.get(stepItem, 'additionalFieldsForDisplay');
        const formField = _.get(wizardFormValues, stepItem.field);
        const stepsObject = _.isObject(subSteps) && !_.isNil(subSteps) ? subSteps : {};

        return (
          <WppStep
            key={id}
            className="strategy-wizard-stepper-step"
          >
            <p slot="label" style={{ marginBottom: '0px' }}>
              {title}
            </p>
            <StepDescription
              formField={formField}
              additionalFieldsForDisplay={additionalFieldsForDisplay || []}
              wizardFormValues={wizardFormValues}
              description={description}
              dynamicDescription={dynamicDescription}
              currentStep={step}
              stepId={id}
            />
            <SubSteps key={id} subSteps={stepsObject} />
          </WppStep>
        );
      })
    }
  </WppStepper>
);

export default StrategyWizardProgress;
