import _ from 'lodash';
import moment from 'moment-timezone';
import { checkDupes, checkSegments } from 'containers/StrategyWizard/steps/StrategyConfiguration/validate';
import { checkDuplicate, coerceBoolStringToBool } from 'utils/functionHelpers';
import {
  applyAllValidators,
  isNumber,
  required,
  isLess,
  isGreater,
  crossValidate,
  mapValidator,
  conditionalValidate,
  percentageValidation,
} from 'utils/reactHookFormValidators';
import { SegmentGroup } from 'utils/types';
import { viewabilityValidation } from '../BudgetOptimization/validate';

const checkBugdetIntervals = (formValues: { useCustomBudget: boolean, lineItem: Object }, fieldName: string) => {
  const useCustomBudget = coerceBoolStringToBool(formValues.useCustomBudget);
  const today = moment.utc();
  if (useCustomBudget) {
    const lineItem = formValues.lineItem;
    if (_.isNil(lineItem) || lineItem === '') {
      return {
        lineItem: 'Required.',
      };
    }

    const budgetIntervals = formValues[fieldName];
    let atLeastOneBudgetIsEligible = false;
    const errors = _.map(budgetIntervals, (budgetInterval) => {
      const biEndDate = moment.tz(budgetInterval.endDate, budgetInterval.timezone);
      if (biEndDate.diff(today) > 0) {
        atLeastOneBudgetIsEligible = true;
        const lifetimeBudget = budgetInterval.lifetimeBudget;
        if (_.isNil(lifetimeBudget) || lifetimeBudget === '') {
          return { lifetimeBudget: 'Required.' };
        } if (lifetimeBudget < 0) {
          return { lifetimeBudget: 'Should be positive.' };
        }
      }
      return null;
    });
    if (_.some(errors)) {
      return { budgetIntervals: errors };
    } if (!atLeastOneBudgetIsEligible) {
      return {
        budgetIntervals: {
          _error: 'At least one budget interval must be eligible. Please select another Line Item.',
        },
      };
    }
  }
  return {};
};

export const validators = {
  baseBid: [
    isGreater(0),
    isNumber,
    required,
  ],
  maxBid: [isGreater(0), crossValidate(isGreater, 'minBid'), required],
  minBid: [isGreater(0), crossValidate(isLess, 'maxBid'), required],
  viewabilityThreshold: conditionalValidate(
    (f) => !(_.isNil(f) || f === ''),
    { viewabilityThreshold: [isNumber, percentageValidation] },
  ),
  // Will validate line item too
  budgetIntervals: [checkBugdetIntervals],
  'viewability.target': [viewabilityValidation],
};

export default (values: { segmentGroups: Array<SegmentGroup> }) => {
  const allNames = _.map(values.segmentGroups, 'name');
  const dupesNames = checkDuplicate(allNames);

  const segmentGroupValidators = {
    name: [required, checkDupes(dupesNames)],
    boolOperator: [required],
    segments: [checkSegments],
  };

  const segmentGroups = conditionalValidate(
    (f) => !(_.isNil(f) || _.isEmpty(f) || f === ''),
    { segmentGroups: [mapValidator(segmentGroupValidators)] },
  );

  return {
    values,
    errors: applyAllValidators(values, { ...validators, segmentGroups }),
  };
};
