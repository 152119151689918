import _ from 'lodash';
import React, { ChangeEventHandler, SyntheticEvent } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { Input, Label } from 'buildingBlocks';
import { validatedFieldWrapper } from 'utils/wrapperComponentsReactHookForms';

export const InputWrapper = validatedFieldWrapper(Input);

type RHFInputWrapperProps = {
  name: string
  component: typeof React.Component
  label?: string
  title?: string
  placeholder?: string
  type?: string
  required?: boolean
  onChange?: Function
  disabled?: boolean
};

export const RHFInputWrapper = ({ name, component: Component, onChange, required, ...rest }: RHFInputWrapperProps) => {
  const { control } = useFormContext();
  return (
    <Controller
      name={name}
      control={control}
      rules={required ? { required: true } : {}}
      render={({ field, fieldState }) => {
        const customOnChange = (e: SyntheticEvent, v: { value: string | number }) => {
          if (onChange) {
            onChange(e);
          }
          field.onChange(v.value);
        };
        return (
          <Component
            fluid
            field={field}
            fieldState={fieldState}
            onChange={customOnChange}
            {...rest}
          />
        );
      }}
    />
  );
};

/* eslint-disable react/default-props-match-prop-types */
RHFInputWrapper.defaultProps = {
  component: InputWrapper,
};

export const NumberInput = (props) => (
  <RHFInputWrapper
    type="number"
    step="any"
    component={InputWrapper}
    {...props}
  />
);

export const CurrencyInput = (props) => (
  <RHFInputWrapper
    type="number"
    component={InputWrapper}
    labelPosition="right"
    label={_.get(props, 'currency.code')}
    {...props}
  />
);

export const DualLabelInput = (props: { value: number, onChange: ChangeEventHandler }) => (
  <div>
    <Input fluid labelPosition="right" type="text">
      <Label>Last</Label>
      <input
        type="number"
        onChange={props.onChange}
        value={props.value}
      />
      <Label>Days</Label>
    </Input>
  </div>
);
