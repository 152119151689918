import _ from 'lodash';
import React, { MutableRefObject, useRef } from 'react';
import { WppActionButton, WppDivider, WppIconChevron, WppIconPlus, WppInlineMessage, WppPopover, WppTypography } from 'buildingBlocks';
import { ChildOptions, SingleChildOption } from 'containers/StrategyWizard/ConfigurationByStrategyType/BudgetOptimization/types';
import NMSIcon from 'components/NMSIcon';
import { BUDGET_MANAGEMENT_STYLES } from 'containers/StrategyWizard/ConfigurationByStrategyType/BudgetOptimization/components/GroupSettings/style';

const {
  budgetGroup: {
    cardInfoSection, objectsAttachedContainer, objectsAttachedText, errorMessage,
    lineItemPopOverContainer, lineItemsContainer, singleLineItemContainer, singleLineItemText, popOverFooter,
  },
} = BUDGET_MANAGEMENT_STYLES;

type ObjectsAttachedCardProps = {
  lineItemsToDisplay: ChildOptions
  handleOpenModal: () => void
  hasObjectsAttached: MutableRefObject<boolean>
  lineItemFieldErrors: any
};

const ObjectsAttachedCard = ({ lineItemsToDisplay, handleOpenModal, hasObjectsAttached, lineItemFieldErrors }: ObjectsAttachedCardProps) => {
  const popoverRef = useRef<HTMLWppPopoverElement>(null);
  const closePopover = () => {
    popoverRef?.current?.closePopover();
  };

  return (
    <div style={cardInfoSection}>
      <div style={objectsAttachedContainer}>
        <div style={objectsAttachedText}>
          <WppTypography type="s-strong">{_.size(lineItemsToDisplay) ? 'Objects Added' : 'No Objects Added'}</WppTypography>
          <WppTypography type="s-body">{!!_.size(lineItemsToDisplay) && _.size(lineItemsToDisplay)}</WppTypography>
        </div>
        {_.size(lineItemsToDisplay)
          ? (
            <WppPopover ref={popoverRef} config={{ appendTo: () => document.querySelector('#app')! }}>
              <WppActionButton slot="trigger-element" variant="secondary" onMouseEnter={() => popoverRef?.current?.openPopover()}>
                View
                <WppIconChevron direction="right" slot="icon-end" />
              </WppActionButton>
              <div style={lineItemPopOverContainer} onMouseLeave={closePopover}>
                <div style={lineItemsContainer}>
                  {_.map(lineItemsToDisplay, (lineItem: SingleChildOption) => {
                    const lineItemName = lineItem.extId
                      ? `[${lineItem.extId}] - ${_.replace(lineItem.lineItemDisplayName, ` (${lineItem.extId})`, '')}`
                      : lineItem.lineItemDisplayName;
                    return (
                      <div key={lineItem.lineItemDisplayName} style={singleLineItemContainer}>
                        <WppTypography type="s-body" tag="p" style={singleLineItemText} title={lineItemName}>
                          {lineItemName}
                        </WppTypography>
                        <NMSIcon isProgrammaticGuaranteed={lineItem.isProgrammaticGuaranteed} />
                      </div>
                    );
                  })}
                </div>
                <WppDivider alignment="horizontal" />
                <div style={popOverFooter}>
                  <WppActionButton variant="secondary" onClick={() => { handleOpenModal(); closePopover(); }}>Edit</WppActionButton>
                  <WppActionButton onClick={closePopover}>Done</WppActionButton>
                </div>
              </div>
            </WppPopover>
          )
          : (
            <WppActionButton variant="secondary" onClick={handleOpenModal}>
              <WppIconPlus slot="icon-start" />
              Add Objects
            </WppActionButton>
          )}
      </div>
      {(lineItemFieldErrors && hasObjectsAttached.current) && (
      <WppInlineMessage
        size="s"
        message={lineItemFieldErrors.message as string}
        type="error"
        style={errorMessage}
      />
      )}
    </div>
  );
};

export default ObjectsAttachedCard;
