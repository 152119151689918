import _ from 'lodash';
import { APNSegment } from 'utils/copilotAPI';
import {
  DataProviderPaginatedArgs,
  DataProviderInterface,
  DataProviderResponse,
} from 'buildingBlocks/TwoListMultiSelect/TwoListMultiSelect';
import { cancelablePromise, isNumeric } from 'utils/functionHelpers';

type SearchBy = { externalId?: {}, shortName?: {} };
type QueryAllArgs = { search: string, limit: number, skip: number };

class APNSegmentDataProvider implements DataProviderInterface {
  memberId: string | number;

  advertiserId: string | number;

  excludedIds: Array<string | number> | null;

  augmentedResponse: Function | null;

  currentRequest: { promise: {}, cancel: Function, isRunning: boolean } | null;

  includeNetworkSegments: boolean;

  constructor(
    memberId: string | number,
    advertiserId: string | number,
    includeNetworkSegments: boolean,
    augmentedResponse?: Function,
    excludedIds?: Array<string | number>,
  ) {
    this.memberId = memberId;
    this.advertiserId = advertiserId;
    this.excludedIds = excludedIds;
    this.augmentedResponse = augmentedResponse;
    this.currentRequest = null;
    this.includeNetworkSegments = includeNetworkSegments;
  }

  async queryWithPagination({ search, limit = 10, skip }: DataProviderPaginatedArgs): Promise<DataProviderResponse> {
    // passing limit and skip to query all, will append it to the query automatically through rest
    return this.queryAll({ limit, skip, search });
  }

  // setting the limit very high because if including network segments the number of segments can be 6 figures.
  // if 'select all' is pressed, and the list is long, it'll blow up. maybe we want to handle this better in the future.
  async queryAll({ search, limit = 999999, skip }: QueryAllArgs): Promise<DataProviderResponse> {
    if (this.currentRequest && this.currentRequest.isRunning) {
      this.currentRequest.cancel();
    }
    try {
      this.currentRequest = cancelablePromise(new Promise((resolve) => {
        const advertiserOr = [{ extAdvertiserId: this.advertiserId }];
        if (this.includeNetworkSegments) {
          advertiserOr.push({ extAdvertiserId: null });
        }

        const searchBy: SearchBy = {};
        if (!_.isEmpty(search)) {
          if (isNumeric(search)) {
            searchBy.externalId = { startsWith: search };
          } else {
            searchBy.shortName = { contains: search };
          }
        }

        const where = {
          or: advertiserOr,
          member: this.memberId,
          isDeleted: false,
          ...searchBy,
        };

        if (this.excludedIds) {
          where.externalId = {
            ...(where.externalId ? where.externalId : {}),
            '!': this.excludedIds,
          };
        }

        const req = {
          where,
          limit,
          skip,
        };

        const res = [APNSegment.get(req), APNSegment.count(req)];
        Promise.all(res)
          .then(([{ data: segments }, { data: { count } }]) => {
            const elements = this.augmentedResponse ? this.augmentedResponse(segments) : segments;
            resolve({
              elements,
              count,
            });
          });
      }));
      return this.currentRequest.promise as DataProviderResponse;
    } catch (e) {
      return {
        elements: [],
        count: 0,
      };
    }
  }
}

export default APNSegmentDataProvider;
