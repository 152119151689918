import React from 'react';
import { Header, Button } from 'buildingBlocks';
import { BidTypeValue } from 'containers/StrategyWizard/ConfigurationByStrategyType/Helios/type';
import { ButtonClickHandler, Currency } from 'utils/types';
import { SGTopBarStyles } from '../style';
import ConfigureDefaultsBar from './ConfigureDefaultsBar';

type Props = {
  enableGroupReordering: boolean,
  isReorderGroupsMode: boolean,
  toggleReorderGroupsMode: Function,
  currency: Currency,
  overrideBidValues: ButtonClickHandler,
  showConfigureDefaultsBar: boolean,
  bidType: BidTypeValue,
};

const SGTopBar = ({ enableGroupReordering, isReorderGroupsMode, toggleReorderGroupsMode, currency, overrideBidValues,
  showConfigureDefaultsBar, bidType }: Props) => (
    <>
      <Header block style={SGTopBarStyles.header} attached="top">
        {enableGroupReordering ? 'Edit & Reorder Groups' : 'Edit Groups'}
        {enableGroupReordering && (
        <Button
          size="mini"
          style={SGTopBarStyles.button}
          onClick={() => toggleReorderGroupsMode()}
          type="button"
        >
          {isReorderGroupsMode ? 'Edit Groups' : 'Reorder Groups'}
        </Button>
        )}
      </Header>
      {showConfigureDefaultsBar && <ConfigureDefaultsBar currency={currency} overrideBidValues={overrideBidValues} bidType={bidType} />}
    </>
);

export default SGTopBar;
