/* eslint-disable no-param-reassign */
import _ from 'lodash';
import React, { Dispatch, SetStateAction, useState, useEffect } from 'react';
import { WppCheckbox, WppGrid, WppTable, WppTableBodyCell, WppTableBodyRow, WppTableHeader, WppTableHeaderCell, WppTableHeaderRow, WppTypography } from 'buildingBlocks';
import { Pixel } from 'utils/types';
import DspPixelOptions from './DspPixelOptions';
import { PIXEL_PICKER_STYLES } from '../styles';
import { DspToPixel, DspToPixelToWeightsType, PixelCategories } from '../types';
import { getMatchingPixels } from '../utils';

const { pixelCheckbox, pixelTableGridStyle, pixelsHeaderCell, pixelDsp, pixelDspText, pixelWeights, pixelCheckboxHeader } = PIXEL_PICKER_STYLES;

type PixelTableProps = {
  isEngScore: boolean
  pixelCategory: PixelCategories
  searchStr: string
  pixels: DspToPixel
  selectedModalPixels: DspToPixel
  setSelectedModalPixels: Dispatch<SetStateAction<DspToPixel>>
  defaultDspToPixelToWeights: DspToPixelToWeightsType
};

const PixelTable = (props: PixelTableProps) => {
  const {
    isEngScore,
    pixelCategory,
    searchStr,
    pixels,
    selectedModalPixels,
    setSelectedModalPixels,
    defaultDspToPixelToWeights,
  } = props;
  // component will be re-rendered if pixelCategory changes, setting the visible pixels to the correct DSPToPixel object
  const pixelOptions = pixelCategory === PixelCategories.all ? pixels : selectedModalPixels;
  const [visiblePixels, setVisiblePixels] = useState<DspToPixel>(pixelOptions);

  const [indeterminate, setIndeterminate] = useState<boolean>(false);

  useEffect(() => {
    // Calculate indeterminate state whenever selectedModalPixels or visiblePixels change
    // Check if any checkbox is checked
    const isAnyCheckboxChecked = _.some(visiblePixels, (pixelArr, dsp) => {
      const selectedPixels = _.get(selectedModalPixels, dsp, []);
      // Check if any pixel in the current pixelArr matches an id in selectedModalPixels[dsp]
      return _.some(pixelArr, (pixel) => _.some(selectedPixels, (selectpix) => selectpix.id === pixel.id));
    });

    // Check if all checkboxes are checked
    const isAllCheckboxesChecked = _.every(visiblePixels, (pixelArr, dsp) => {
      // Safely retrieve the array of selected pixels for the current dsp, default to an empty array if undefined
      const selectedPixels = _.get(selectedModalPixels, dsp, []);
      // Handle case where selectedPixels might be undefined
      return pixelArr.length > 0 && _.isEqual(
        _.sortBy(pixelArr, 'id'),
        _.sortBy(selectedPixels, 'id'),
      );
    });

    setIndeterminate(isAnyCheckboxChecked && !isAllCheckboxesChecked);
  }, [visiblePixels, selectedModalPixels]);

  const selectAllChecked = _.every(visiblePixels, (pixelArr, dsp) => {
    if (selectedModalPixels[dsp]) {
      // if all visible dsp pixels are within dsp array of selectedModalPixels, difference should be empty array
      return !_.size(_.differenceBy(pixelArr, selectedModalPixels[dsp], 'id'));
    }
    return false;
  });

  const onSelectAllClick = () => {
    // include weights if goal type is engagement score
    const pixelsToUse = isEngScore
      ? (
        _.reduce(visiblePixels, (result, pixelArr, dsp) => {
          _.forEach(pixelArr, (p) => {
            p.weight = defaultDspToPixelToWeights[dsp][p.id];
          });
          return result;
        }, { ...visiblePixels })
      ) : visiblePixels;
    const newSelectedModalPixels = _.reduce(pixelsToUse, (result, pixelArr, dsp) => {
      if (result[dsp]) {
        // remove common pixels if selectAll is check otherwise join dsp pixel arrays and remove duplicates
        result[dsp] = selectAllChecked
          ? _.differenceBy(result[dsp], pixelArr, 'id')
          : _.uniqBy([...pixelArr, ...result[dsp]], 'id');
      }
      // set the dsp pixels if prev selectedModalPixels did not include any from dsp
      if ((!result[dsp] || _.isEmpty(result[dsp])) && !selectAllChecked) {
        result[dsp] = pixelArr;
      }
      return result;
    }, { ...selectedModalPixels });
    setSelectedModalPixels(newSelectedModalPixels);
  };

  useEffect(() => {
    if (_.isEmpty(searchStr)) {
      setVisiblePixels(pixelOptions);
    } else {
      const pixelCopy = { ...pixelOptions };
      const filteredPixels = _.reduce(pixelCopy, (result, pixelArr, dsp) => {
        const matchingPixels = getMatchingPixels(pixelArr, searchStr);
        if (!_.isEmpty(matchingPixels)) {
          result[dsp] = matchingPixels;
        }
        return result;
      }, {});
      setVisiblePixels(filteredPixels);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchStr, pixelCategory, selectedModalPixels]);

  return (
    <WppGrid container fullWidth>
      <WppGrid direction="row" style={pixelTableGridStyle}>
        <WppTable>
          <WppTableHeader>
            <WppTableHeaderRow className="wpp-table-header-row">
              <WppTableHeaderCell style={pixelCheckboxHeader}>
                <WppCheckbox
                  checked={selectAllChecked}
                  disabled={_.isEmpty(_.flatten(_.values(visiblePixels)))}
                  onClick={onSelectAllClick}
                  style={pixelCheckbox}
                  indeterminate={indeterminate}
                />
              </WppTableHeaderCell>
              <WppTableHeaderCell style={pixelsHeaderCell}>
                <WppTypography type="s-strong" tag="p">Pixels</WppTypography>
              </WppTableHeaderCell>
              <WppTableHeaderCell style={pixelDsp}>
                <WppTypography type="s-strong" tag="p" style={pixelDspText}>DSP</WppTypography>
              </WppTableHeaderCell>
              {isEngScore && (
                <WppTableHeaderCell style={pixelWeights} colSpan={1}>
                  <WppTypography type="s-strong" tag="p">Weights</WppTypography>
                </WppTableHeaderCell>
              )}
            </WppTableHeaderRow>
          </WppTableHeader>
          {!_.isEmpty(visiblePixels) && (
            <>
              {_.map(visiblePixels, (pixArr: Array<Pixel>, dsp: string) => ((_.isEqual(pixelCategory, PixelCategories.all) || !_.isEmpty(pixArr)) && (
                <DspPixelOptions
                  key={dsp}
                  dsp={_.toNumber(dsp)}
                  pixels={pixArr}
                  selectedModalPixels={selectedModalPixels}
                  setSelectedModalPixels={setSelectedModalPixels}
                  defaultDspToPixelToWeights={defaultDspToPixelToWeights}
                  isEngScore={isEngScore}
                />
              )))}
            </>
          )}
          {_.isEmpty(visiblePixels) && !_.isEmpty(searchStr) && (
            <WppTableBodyRow className="wpp-table-body-row outcomeValueTableError">
              <WppTableBodyCell className="wpp-table-body-cell" colSpan={5}>
                <WppGrid>
                  <WppTypography type="s-body" tag="p">No results found for “{searchStr}”. Try a new search.</WppTypography>
                </WppGrid>
              </WppTableBodyCell>
            </WppTableBodyRow>
          )}
        </WppTable>
      </WppGrid>
    </WppGrid>
  );
};

export default PixelTable;
