import { applyAllValidators, required } from 'utils/reactHookFormValidators';
import { CustomTreeStatus } from './constants';

const checkValidTree = (formValues: any, fieldName: string) => {
  const { pageValidator } = formValues;
  if (pageValidator !== CustomTreeStatus.CustomTreeIsValid) {
    return { [fieldName]: { message: 'The tree needs to be validated to continue' } };
  }
  return {};
};

export const validate = (values: {}) => {
  const validators = {
    customTreeInput: [required],
    treeFileInput: [required],
    pageValidator: [checkValidTree],
  };
  return { values, errors: applyAllValidators(values, validators) };
};

export default validate;
