import { CSSProperties } from 'react';

export const INITIAL_VALUES = {
  customSDFEnabled: true,
  selectedUsersForEmail: [],
};

export const textStyles: { [key: string]: CSSProperties } = {
  header: { fontWeight: 'normal', color: 'rgba(0,0,0,.87)' },
  content: { fontSize: 'small', color: 'rgba(74,74,74,0.8)' },
  infoBox: { fontSize: '14px', color: 'black' },
};

export const developerApiLink = 'https://copilotsupport.freshdesk.com/support/solutions/articles/47001125123-developer-api';
