import _ from 'lodash';
import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { WppInlineMessage, WppToggle, WppTooltip, WppTypography } from 'buildingBlocks';
import Tooltip from 'containers/Tooltip';
import { TOOLTIP_DESCRIPTIONS } from 'containers/StrategyWizard/ConfigurationByStrategyType/BudgetOptimization/constants';
import { BudgetGroup, ChildOptions } from 'containers/StrategyWizard/ConfigurationByStrategyType/BudgetOptimization/types';
import { ToggleChangeEvent, WppToggleCustomEvent } from 'utils/types';
import { BUDGET_MANAGEMENT_STYLES } from '../style';

const { budgetGroup: { budgetOptToggleContainer, budgetOptHeader } } = BUDGET_MANAGEMENT_STYLES;

type BudgetOptimizationToggleProps = {
  selectedLineItems: ChildOptions
  budgetGroup: BudgetGroup
  budgetGroupKey: string
};

const BudgetOptimizationToggle = ({ selectedLineItems, budgetGroup, budgetGroupKey }: BudgetOptimizationToggleProps) => {
  const { control } = useFormContext();

  const handleToggleChange = (onChange: (value: boolean) => void) => (toggle: WppToggleCustomEvent<ToggleChangeEvent>) => {
    onChange(toggle.detail.checked);
  };

  return (
    <div style={budgetOptToggleContainer}>
      <div style={budgetOptHeader}>
        <WppTypography type="s-strong">Budget Optimization</WppTypography>
        <Tooltip content={_.get(TOOLTIP_DESCRIPTIONS, 'budgetOptimization')} theme="dark">
          <WppInlineMessage size="s" type="information" />
        </Tooltip>
      </div>
      <Controller
        name={`groupSettings[${budgetGroupKey}].budgetOptimization` as const}
        control={control}
        render={({ field: { onChange } }) => {
          const hasPGLineItem = _.some(selectedLineItems, 'isProgrammaticGuaranteed');
          if (hasPGLineItem) {
            return (
              <WppTooltip text={_.get(TOOLTIP_DESCRIPTIONS, 'pgWarning')} theme="dark">
                <WppToggle
                  checked={budgetGroup.budgetOptimization}
                  onWppChange={handleToggleChange(onChange)}
                  disabled={hasPGLineItem}
                />
              </WppTooltip>
            );
          }
          return (
            <WppToggle
              checked={budgetGroup.budgetOptimization}
              onWppChange={handleToggleChange(onChange)}
            />
          );
        }}
      />
    </div>
  );
};

export default BudgetOptimizationToggle;
