import _ from 'lodash';
import { GroupItem } from 'containers/StrategyWizard/components/SegmentPicker/SegmentPickerTypes';
import { createDraggableItems } from 'containers/StrategyWizard/components/SegmentPicker/utils';
import APNSegmentDataProvider from 'containers/StrategyWizard/ConfigurationByStrategyType/dataProvider/APNSegmentDataProvider';
import { BID_TYPES } from 'containers/StrategyWizard/ConfigurationByStrategyType/Helios/constants';
import {
  getInitialMaxBid,
  customRound,
  processHeliosSegmentGroups,
  DEFAULT_SEGMENT_GROUP,
  getBidType,
} from 'containers/StrategyWizard/ConfigurationByStrategyType/Helios/utils';
import { getSegmentGroupTargetsData } from 'containers/StrategyWizard/steps/StrategyConfiguration/utils';
import { WizardFormValuesForInitialization } from 'containers/StrategyWizard/types';
import { SegmentGroup } from 'utils/types';

type Bid = {
  time: number,
  value: number,
};

export const removeDuplicateBidByTime = (bids: Array<Bid>) => _.sortedUniqBy(bids, (bid) => bid.time);

export const boundariesValid = (minBid: number, maxBid: number, maxDays: number) => (
  minBid > 0 && maxBid > 0 && maxDays > 0 && minBid < maxBid
);

export const bidClamper = (bid: Bid, minBid: number, maxBid: number, maxDays: number) => ({
  time: parseInt(_.clamp(bid.time, 0, maxDays * 24 * 60) as unknown as string, 10),
  value: _.clamp(bid.value, minBid, maxBid),
});

export const createDefaultBidCurve = (minBid: number, maxBid: number, maxDays: number, buckets: number = 5) => {
  const xMax = (24 * 60 * maxDays);
  const bucketMinutes = xMax / buckets;
  const n = Math.floor(buckets / 3);

  const createBid = (index) => {
    if (index === 0) {
      return { time: 1, value: maxBid };
    }
    return {
      time: index * bucketMinutes,
      value: _.clamp(Math.exp(-index / n) * maxBid, minBid, maxBid),
    };
  };

  return _.map<number, Bid>(_.range(0, buckets + 1), createBid);
};

const DEFAULT_MAX_BID = 50;

const getGlobalDefaultBidValues = (stratDefaultWizardConfig: {}, wizardForm: WizardFormValuesForInitialization, currencyExchange: {}, maxBid: number) => {
  const currencyCode = _.get(wizardForm, 'advertiser.defaultCurrency.code');
  const rate = _.get(currencyExchange, `${currencyCode}.ratePerUsd`, 1);
  const startingMinBid = _.get(stratDefaultWizardConfig, 'startingMinBid', Math.max(customRound(1 * rate), 1));
  return {
    startingMinBid,
    startingMaxBid: Math.min(_.get(stratDefaultWizardConfig, 'startingMaxBid', customRound(4 * rate)), maxBid),
    maxDays: 30,
  };
};

export const processSegRecSegmentGroups = (
  segmentGroupTargets: Array<SegmentGroup>,
  globalDefaultBidValues: { startingMinBid: number, startingMaxBid: number, maxDays: number },
) => {
  const segmentGroups = processHeliosSegmentGroups(segmentGroupTargets);
  return _.map(segmentGroups, (sg) => (
    {
      ...sg,
      ...globalDefaultBidValues,
    }
  ));
};

export const getHeliosSegRecSegmentGroups = async (stratDefaultWizardConfig: {}, wizardForm: WizardFormValuesForInitialization, currencyExchange: {}, maxBid: number, populateSegments?: boolean) => {
  const segmentGroupTargetsData = await getSegmentGroupTargetsData(wizardForm, populateSegments);
  const globalDefaultBidValues = getGlobalDefaultBidValues(stratDefaultWizardConfig, wizardForm, currencyExchange, maxBid);
  return _.isEmpty(segmentGroupTargetsData)
    ? [{ ...globalDefaultBidValues, ...DEFAULT_SEGMENT_GROUP }]
    : processSegRecSegmentGroups(segmentGroupTargetsData[0], globalDefaultBidValues);
};

const getInitialMinMaxBids = (stratDefaultWizardConfig: {}, wizardForm: WizardFormValuesForInitialization, currencyExchange: {}) => {
  const bidType = getBidType(wizardForm.attachedFlights);
  if (bidType === BID_TYPES.BID) {
    return { minBid: 0.5, maxBid: getInitialMaxBid(stratDefaultWizardConfig, wizardForm, currencyExchange, DEFAULT_MAX_BID).maxBid };
  }
  return { minBid: 0.01, maxBid: 4 };
};

/** Initial values for the form. */
export const getInitialValues = async (stratDefaultWizardConfig: {}, wizardForm: WizardFormValuesForInitialization, currencyExchange: {}, populateSegments?: boolean) => {
  const { minBid, maxBid } = getInitialMinMaxBids(stratDefaultWizardConfig, wizardForm, currencyExchange);
  const segmentGroups = await getHeliosSegRecSegmentGroups(stratDefaultWizardConfig, wizardForm, currencyExchange, maxBid, populateSegments);
  const globalDefaultBidValues = getGlobalDefaultBidValues(stratDefaultWizardConfig, wizardForm, currencyExchange, maxBid);
  return {
    segmentGroups,
    minBid,
    maxBid,
    ...globalDefaultBidValues,
  };
};

export const overrideSGDefaultBidValues = (
  groups: { [key: string]: GroupItem },
  bidValues: { startingMinBid: number, startingMaxBid: number, maxDays: number },
) => {
  const newObj = _.mapValues(groups, (o) => ({
    ...o,
    maxDays: _.get(bidValues, 'maxDays'),
    startingMinBid: _.get(bidValues, 'startingMinBid'),
    startingMaxBid: _.get(bidValues, 'startingMaxBid'),
  }));
  return newObj;
};

export const getSegments = (memberId, advertiserId, filterExcludedIds) => new APNSegmentDataProvider(
  memberId,
  advertiserId,
  filterExcludedIds,
  (segments) => createDraggableItems(_.map(segments, (segment) => ({
    id: _.toNumber(segment.externalId),
    shortName: segment.shortName,
  }))),
);
