import React from 'react';
import { WppGrid, WppTypography } from 'buildingBlocks';
import BAStyle from 'containers/StrategyWizard/ConfigurationByStrategyType/BudgetOptimization/style';
import PacingSlider from './PacingSlider';
import PacingGraph from './PacingGraph';

const pacingMainCopy = 'Set pacing speed to spend budget more quickly or slowly.';

const { pacingTextStyle, pacingStyle, pacingGridStyle } = BAStyle;

export type PacingProps = {
  pacing: number
};

const Pacing = ({ pacing }: PacingProps) => (
  <WppGrid container fullWidth item all={24}>
    <div style={pacingGridStyle}>
      <WppTypography style={pacingTextStyle} type="m-strong">Pacing</WppTypography>
      <WppTypography style={pacingStyle} tag="p" type="s-body">{pacingMainCopy}</WppTypography>
      <div style={pacingStyle}>
        <WppGrid all={6}>
          <PacingGraph pacing={pacing} />
          <PacingSlider />
        </WppGrid>
      </div>
    </div>
  </WppGrid>
);

export default Pacing;
