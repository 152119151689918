import { ALGORITHM_TYPE } from 'constantsBase';
import { BASE_MAPPER, baseBudgetOptInitializer } from '../mapper';

const algoTypeId = ALGORITHM_TYPE.wmtBudgetOptimization.id;

export const TASKS = [algoTypeId, ALGORITHM_TYPE.wmtTargetingPlus.id];

export const MAPPER = BASE_MAPPER(algoTypeId);

export const initializer = baseBudgetOptInitializer;
