import _ from 'lodash';
import { ALGORITHM_TYPE, BOOLEAN_OPERATORS, DSP } from 'constantsBase';
import { toCamelCase, toSnakeCase } from 'utils/formattingUtils';
import { DAG } from 'containers/StrategyWizard/ConfigurationByStrategyType/constants';
import { baseInitializer, storePixelsOnConfig, CreateConfigType, initializePixels } from 'containers/StrategyWizard/ConfigurationByStrategyType/utils';
import { numberOrUndefined } from 'utils/functionHelpers';
import { SegmentGroup, StrategyGoalsDB } from 'utils/types';
import { getSegmentIdentifier } from 'containers/StrategyWizard/ConfigurationByStrategyType/segmentUtils';
import { SegmentRecencyForm } from 'containers/StrategyWizard/types';
import { StringMapping } from 'utils/airflow/nameTranslator';
import { boundariesValid, createDefaultBidCurve, removeDuplicateBidByTime, bidClamper } from './utils';

const PREFIX = `${ALGORITHM_TYPE.heliosSegmentRecency.id}.${DAG}`;

const NUMBER_FIELDS = ['startingMinBid', 'startingMaxBid', 'maxDays'];

const generateBids = ({ startingMinBid, startingMaxBid, maxDays: days }) => {
  const minBid = Number(startingMinBid);
  const maxBid = Number(startingMaxBid);
  const maxDays = Number(days);

  if (boundariesValid(minBid, maxBid, maxDays)) {
    const currentBids = createDefaultBidCurve(minBid, maxBid, maxDays);
    const newBids = removeDuplicateBidByTime(_.map(currentBids, (bid) => bidClamper(bid, minBid, maxBid, maxDays)));
    return newBids;
  }

  return null;
};

export const formatSegmentGroupsAF = (segmentGroups: Array<SegmentGroup>) => (_.map(segmentGroups, (segmentGroup) => {
  const snakeCaseSegmentGroups = {};
  _.each(segmentGroup, (v, k) => {
    let value;
    const key = ((k === 'segments') ? 'segmentExtIds' : k);
    if (key === 'segmentExtIds') {
      value = _.map(v as Array<string>, getSegmentIdentifier);
    } else if (key === 'boolOperator') {
      // @ts-ignore
      value = v.value.toLowerCase();
    } else if (NUMBER_FIELDS.includes(key)) {
      value = Number(v);
    } else {
      value = v;
    }

    snakeCaseSegmentGroups[toSnakeCase(key)] = value;
  });

  if (!(snakeCaseSegmentGroups as { bids: unknown }).bids) {
    const newBids = generateBids(segmentGroup);
    if (newBids) {
      (snakeCaseSegmentGroups as { bids: unknown }).bids = newBids;
    }
  }

  return snakeCaseSegmentGroups;
}));

export const formatSegmentGroups = (segmentGroups: Array<SegmentGroup>) => (_.map(segmentGroups, (segmentGroup) => {
  const camelCaseSegmentGroups = {};
  _.each(segmentGroup, (v, snakeCasedKey) => {
    const k = toCamelCase(snakeCasedKey);
    const key = ((k === 'segmentExtIds') ? 'segments' : k);
    camelCaseSegmentGroups[key] = v;
  });
  (camelCaseSegmentGroups as { boolOperator: unknown }).boolOperator = BOOLEAN_OPERATORS.OR;

  return camelCaseSegmentGroups;
}));

type MapperT = {
  mapping: Array<StringMapping>,
  createConfig: CreateConfigType<SegmentRecencyForm>,
};

export const MAPPER: MapperT = {
  mapping: [
    ['minBid', `${PREFIX}.min_bid`],
    ['maxBid', `${PREFIX}.max_bid`],
    ['segmentGroups', `${PREFIX}.models`],
    ['startingMinBid', `${PREFIX}.initial_min_bid`],
    ['startingMaxBid', `${PREFIX}.initial_max_bid`],
    ['maxDays', `${PREFIX}.initial_max_days`],
    ['impValueFilters', `${PREFIX}.imp_value_filters`],
  ],
  createConfig: (formValues, __, member) => storePixelsOnConfig({
    minBid: numberOrUndefined(formValues, 'minBid'),
    maxBid: numberOrUndefined(formValues, 'maxBid'),
    segmentGroups: formatSegmentGroupsAF(_.get(formValues, 'segmentGroups')),
    startingMinBid: numberOrUndefined(formValues, 'startingMinBid'),
    startingMaxBid: numberOrUndefined(formValues, 'startingMaxBid'),
    maxDays: numberOrUndefined(formValues, 'maxDays'),
  }, formValues, member),
};

type Config = {
  segmentGroups?: Array<SegmentGroup>,
};

export const initializer = (config: Config, strategyGoals: StrategyGoalsDB) => {
  const updatedConfig = baseInitializer(config);

  const segmentGroups = config.segmentGroups;
  if (segmentGroups) {
    updatedConfig.segmentGroups = formatSegmentGroups(segmentGroups);
  }
  return initializePixels(updatedConfig, strategyGoals, DSP.APN.id);
};

export const EXCLUDED_FIELDS = ['runAnalyze', 'modelOutput', 'customModelStructure'];

export const TASKS = [ALGORITHM_TYPE.heliosSegmentRecency.id];
