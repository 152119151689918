import _ from 'lodash';
import React, { CSSProperties } from 'react';
import { Droppable, Draggable } from '@hello-pangea/dnd';
import { Header, Input, Grid, Dropdown } from 'buildingBlocks';
import PaginationControl from 'components/PaginationControl';
import { dragAndDropStyles } from '../style';
import { filteredSegmentOptions } from '../constants';

const boldClonedItem = (t: boolean): CSSProperties => (t ? { fontWeight: 500 } : { fontWeight: 'normal' });

type DraggableSourceListProps<T> = {
  name: string
  items: Array<T>
  numberOfItems: number
  startingPage: number
  onSearchChange: (...args: any[]) => void
  onDataRangeChange: (...args: any[]) => void
  onFilterExcludedIds: Function
};

const DraggableSourceList = (props: DraggableSourceListProps<any>) => {
  const {
    name,
    items,
    numberOfItems,
    startingPage,
    onSearchChange,
    onDataRangeChange,
    onFilterExcludedIds,
  } = props;

  return (
    <Grid.Column id="sourceList" style={{ display: 'flex', flexDirection: 'column', padding: 0, paddingRight: 12 }}>
      <Header block style={dragAndDropStyles.sourceList.headerBar}>
        Drag & Drop Segment(s) into Groups
      </Header>
      <Input
        className="source-picker-search-input"
        fluid
        attached="bottom"
        icon="search"
        iconPosition="left"
        label={(
          <Dropdown
            search
            selection
            placeholder="Filter Segments"
            defaultValue="all"
            onChange={(_e, t) => onFilterExcludedIds(t.value === 'all')}
            options={filteredSegmentOptions}
          />
        )}
        labelPosition="right"
        placeholder="Search by ID or name"
        onChange={_.debounce(onSearchChange, 1000)}
      />
      <div style={{ ...dragAndDropStyles.scrollContainer, paddingLeft: '1px' }}>
        <div
          className="src-list-table"
          style={{ borderTop: 0, borderLeft: 0, borderRight: 0, borderRadius: 0 }}
        >
          <div className="src-list-table-heading">
            <div className="src-list-table-cell">
              Id
            </div>
            <div className="src-list-table-cell">
              Name
            </div>
          </div>
          <Droppable droppableId={name} isDropDisabled>
            {(dropProvided, dropSnapshot) => (
              <div
                ref={dropProvided.innerRef}
                className="src-list-table-body"
                style={{
                  backgroundColor: dropSnapshot.isDraggingOver ? '#d8d8d8' : 'white',
                  minHeight: '35px',
                  width: '100%',
                  marginTop: '20px',
                  fontSize: '12px',
                }}
                {...dropProvided.droppableProps}
              >
                {
                  _.map(items, (item, index: number) => (
                    <Draggable draggableId={item.dragId} index={index} key={item.dragId}>
                      {(dragProvided, dragSnapshot) => (
                        <>
                          <div
                            ref={dragProvided.innerRef}
                            className={`src-list-table-row ${dragSnapshot.isDragging ? 'drag-table-row-item' : ''}`}
                            {...dragProvided.draggableProps}
                            {...dragProvided.dragHandleProps}
                          >
                            <div
                              title={_.toString(item.id)}
                              className="src-list-table-cell"
                              style={{
                                ...dragAndDropStyles.tableCell.segmentName,
                                ...boldClonedItem(item.isCloned),
                                width: '100px',
                              }}
                            >
                              {item.id}
                            </div>
                            <div
                              title={item.name}
                              className="src-list-table-cell"
                              style={{
                                ...dragAndDropStyles.tableCell.segmentName,
                                ...boldClonedItem(item.isCloned),
                              }}
                            >
                              {item.name}
                            </div>
                          </div>
                          {dragSnapshot.isDragging && (
                            <div className="src-list-table-row clone">
                              <div
                                title={_.toString(item.id)}
                                className="src-list-table-cell"
                                style={{ verticalAlign: 'top', ...boldClonedItem(item.isCloned) }}
                              >{item.id}
                              </div>
                              <div
                                title={item.name}
                                className="src-list-table-cell"
                                style={{
                                  ...dragAndDropStyles.tableCell.segmentName,
                                  ...boldClonedItem(item.isCloned),
                                }}
                              >
                                {item.name}
                              </div>
                            </div>
                          )}
                        </>
                      )}
                    </Draggable>
                  ))
                }
                {dropProvided.placeholder}
              </div>
            )}
          </Droppable>
        </div>
      </div>
      <PaginationControl
        numberOfElements={numberOfItems}
        onDataRangeChange={onDataRangeChange}
        startingElementsPerPage={10}
        startingPage={startingPage}
        elementsPerPageOptions={[10]}
      />
    </Grid.Column>
  );
};

export default DraggableSourceList;
