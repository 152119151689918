import _ from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import { DragDropContext } from '@hello-pangea/dnd';
import { Button, Grid, Header, Icon } from 'buildingBlocks';
import { getSegmentIdentifier } from 'containers/StrategyWizard/ConfigurationByStrategyType/segmentUtils';
import { DEFAULT_SEGMENT_GROUP } from 'containers/StrategyWizard/ConfigurationByStrategyType/Helios/utils';
import { overrideSGDefaultBidValues } from 'containers/StrategyWizard/ConfigurationByStrategyType/SegmentRecency/utils';
import { generateUUID } from 'utils/formattingUtils';
import { usePrevious } from 'utils/hooks/usePrevious';
import { useMount } from 'utils/hooks/generic/hookWrappers';
import { COPILOT_LAYOUT, COPILOT_TYPOGRAPHY } from 'globalStyles';
import { bidFormKeys } from './constants';
import {
  getIndexByDragId,
  copyItem,
  setClonedSource,
  arrayOfClonedItems,
  unsetCloned,
  setSegmentGroupFormValues,
  createDraggableItems,
  getIdsFromGroups,
  hasDuplicates,
  idExistsInGroupCheck,
} from './utils';
import {
  SegmentPickerProps, SegmentPickerState, DragSource, DragDestination, Item, GroupItem,
} from './SegmentPickerTypes';
import DraggableSourceList from './components/DraggableSourceList';
import SegPickerRightView from './components/SegPickerRightView';

const descriptionText = 'Select the segment groups that Copilot should consider for optimization.';

const SegmentPicker = (props: SegmentPickerProps) => {
  const {
    cannotDeleteAllSegmentGroups = false,
    dataProvider,
    field,
    fieldState,
    preventCloning,
    onFilterExcludedIds,
    globalDefaultBidValues,
    enableGroupReordering,
    showIncludeExcludeSelector,
    enableItemReordering,
    currency,
    bidType,
    strategyId,
    filterAllSegments,
    segmentLookup,
    renderSegmentAutoFill,
    count,
    setCount,
  } = props;

  const groupContainerEnd = useRef<Element | null>(null);

  const itemsMap = {};
  const groups = {};

  const [segPickerState, setSegPickerState] = useState<SegmentPickerState>({
    sourceList: [],
    itemsMap,
    groupOrder: _.keys(itemsMap),
    groups,
    search: '',
    numberOfItems: 0,
    startingPage: 1,
    loadedInitial: false,
    isReorderGroupMode: false,
  });

  const prevSegPickerState = usePrevious(segPickerState);
  const prevfilterAllSegments = usePrevious<boolean>(filterAllSegments);

  // eslint-disable-next-line react/sort-comp
  const scrollToEnd = _.debounce(() => {
    if (groupContainerEnd) {
      (groupContainerEnd.current as unknown as Element).scrollIntoView({ behavior: 'smooth' });
    }
  }, 100);

  const getData = ({
    search = '',
    limit = 10,
    skip = 0,
    ...rest
  }: { search?: string, limit?: number, skip?: number }) => {
    dataProvider.queryWithPagination({
      search,
      skip,
      limit,
      ...rest,
    })
      .then((res) => {
        setSegPickerState((prevState) => {
          const sourceList = _.clone(res.elements) as Array<Item>;
          const itemsMapClone = _.clone(prevState.itemsMap);
          const ids = _.uniq(getIdsFromGroups(itemsMapClone));
          _.map(ids, (id) => { setClonedSource(sourceList, id, true); });
          return ({
            ...prevState,
            sourceList,
            numberOfItems: res.count,
            startingPage: (skip / limit) + 1,
          });
        });
      });
  };

  const createState = (newValueProps: Array<GroupItem>, segLookup) => {
    const itemsMapClone = {};
    const groupsClone = {};
    _.forEach(newValueProps, (v, i) => {
      const segments = createDraggableItems(_.map(v.segments, (s) => {
        const identifier = getSegmentIdentifier(s);
        const segment = segLookup[`${identifier}`];
        return {
          id: identifier,
          shortName: _.get(segment, 'shortName', _.get(s, 'name', 'Segment Not Found')),
          isExcluded: _.get(s, 'isExcluded', false),
          isDeleted: _.get(segment, 'isDeleted', false),
        };
      }));
      itemsMapClone[i] = segments;
      groupsClone[i] = {
        ..._.pick(v, _.concat(['uuid', 'name', 'boolOperator', 'bids'], bidFormKeys)),
        dragIds: _.map(segments, 'dragId'),
      };
    });
    const groupOrder = _.keys(itemsMapClone);
    setSegPickerState({
      ...segPickerState,
      itemsMap: itemsMapClone,
      groups: groupsClone,
      groupOrder,
      loadedInitial: true,
    });
  };

  useMount(() => {
    const getInitData = async () => getData({});
    getInitData();
    const newValueProps = field.value;
    createState(newValueProps, segmentLookup);
  });

  useEffect(() => {
    if (segPickerState.loadedInitial && !_.isEqual(segPickerState, prevSegPickerState)) {
      field.onChange(setSegmentGroupFormValues(segPickerState)); // changes form values for segment group - function creates the array
    }
    if (!_.isEqual(filterAllSegments, prevfilterAllSegments)) {
      getData({ search: segPickerState.search });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [segPickerState, filterAllSegments]);

  const setGroupFormChange = (groupIdentifier: string, newValue: {}) => { // changes the individual fields of each segment group and changes segPickerState
    setSegPickerState((prevState) => ({
      ...prevState,
      groups: { ...prevState.groups, [groupIdentifier]: { ...prevState.groups[groupIdentifier], ...newValue } },
    }));
  };

  const rearrangeGroups = (source: DragSource, destination: DragDestination) => {
    const newGroupsOrder = _.clone(segPickerState.groupOrder);
    const items = newGroupsOrder.splice(source.index, 1);
    newGroupsOrder.splice(destination.index, 0, items[0]);
    const newState = { ...segPickerState, groupOrder: newGroupsOrder };
    setSegPickerState(newState);
  };

  const sourceListOnDragEnd = (
    source: DragSource,
    destination: DragDestination,
    groupsObj: { [key: string]: GroupItem },
    draggableId: string,
  ) => {
    const dragId = generateUUID();
    const finish = groupsObj[destination.droppableId];
    const finishDragIds = _.clone(finish.dragIds);
    finishDragIds.splice(destination.index, 0, dragId);
    const newFinish = { ...finish, dragIds: finishDragIds };
    const itemId = _.get(_.find(segPickerState.sourceList, ['dragId', draggableId]), 'id');

    /** Prevent same segment ids from being dragged from sourceList to group that already has that segment id */
    if (idExistsInGroupCheck(
      segPickerState.itemsMap[destination.droppableId],
      itemId,
      preventCloning,
    )) {
      setClonedSource(segPickerState.sourceList, itemId, true);
      setSegPickerState((prevState) => ({
        ...prevState,
        itemsMap: {
          ...prevState.itemsMap,
          [destination.droppableId]: copyItem(
            prevState.sourceList,
            prevState.itemsMap[destination.droppableId],
            source,
            destination,
            dragId,
          ),
        },
        groups: { ...prevState.groups, [destination.droppableId]: newFinish },
      }));
    }
  };

  const rearrangeItems = (
    source: DragSource,
    destination: DragDestination,
    start: GroupItem,
    draggableId: string,
  ) => {
    const newdragIds = _.clone(start.dragIds);
    newdragIds.splice(source.index, 1);
    newdragIds.splice(destination.index, 0, draggableId);
    const group = { ...start, dragIds: newdragIds };
    const newState = {
      ...segPickerState,
      groups: { ...segPickerState.groups, [destination.droppableId]: group },
    };
    setSegPickerState(newState);
  };

  const moveItemBetweenGroup = (
    source: DragSource,
    destination: DragDestination,
    start: GroupItem,
    finish: GroupItem,
    itemsMapObj: { [key: string]: Array<Item> },
    draggableId: string,
  ) => {
    const startDragIds = _.clone(start.dragIds);
    startDragIds.splice(source.index, 1);

    const newStart = { ...start, dragIds: startDragIds };
    const finishDragIds = _.clone(finish.dragIds);

    finishDragIds.splice(destination.index, 0, draggableId);
    const newFinish = { ...finish, dragIds: finishDragIds };

    const newState = {
      ...segPickerState,
      groups: {
        ...segPickerState.groups,
        [source.droppableId]: newStart,
        [destination.droppableId]: newFinish,
      },
    };
    if (!hasDuplicates(getIdsFromGroups(itemsMapObj))) {
      setSegPickerState(newState);
    }
  };

  const onDragEnd = (result: {
    source: DragSource,
    destination: DragDestination | null,
    type: string,
    draggableId: string
  }) => {
    if (!result.destination) {
      return;
    }
    const {
      source, destination, type, draggableId,
    } = result;
    const groupsClone = _.clone(segPickerState.groups);

    /** This rearranges the order of segment groups */
    if (type === 'group') {
      if (destination && destination.droppableId !== 'sourceList') {
        rearrangeGroups(source, destination);
      }
    }

    /** This only applies when dragging items from the source list on left side to a group on right side.
    Handles cloning items to groups. */
    if (destination && source.droppableId === 'sourceList') {
      sourceListOnDragEnd(source, destination, groupsClone, draggableId);
      return;
    }

    /** Everything below applies for segment groups only */
    if (source.droppableId !== 'segment-groups') {
      if (destination && destination.droppableId !== 'sourceList') {
        const start = groupsClone[source.droppableId];
        const finish = groupsClone[destination.droppableId];
        const itemsMapClone = _.clone(segPickerState.itemsMap);
        const items = itemsMapClone[source.droppableId].splice(source.index, 1);
        itemsMapClone[destination.droppableId].splice(destination.index, 0, items[0]);

        /** This rearranges the order of the dragIds (segment items) within a group */
        if (start === finish) {
          // rearrangeItems(source, destination, start, itemsMap, draggableId);
          rearrangeItems(source, destination, start, draggableId);
          return;
        }
        /** This moves segment items from one group to another group */
        moveItemBetweenGroup(source, destination, start, finish, itemsMapClone, draggableId);
      }
    }
  };

  const onSearchChange = (_e: Event, data: { value: string }) => {
    const search = data.value;
    setSegPickerState({ ...segPickerState, search });
    getData({ search });
  };

  const overrideBidValues = () => {
    setSegPickerState((prevState) => {
      const newGroups = overrideSGDefaultBidValues(prevState.groups, globalDefaultBidValues);
      return { ...prevState, groups: newGroups };
    });
  };

  const addNewGroup = () => {
    const itemsMapClone = _.clone(segPickerState.itemsMap);
    const newId = `${count + 1}`;
    itemsMapClone[newId] = [];

    setSegPickerState((prevState) => ({
      ...prevState,
      itemsMap: itemsMapClone,
      groups: {
        ...prevState.groups,
        [newId]: {
          ...DEFAULT_SEGMENT_GROUP,
          ...globalDefaultBidValues,
          uuid: generateUUID(),
          name: `New Group ${newId}`,
          dragIds: [],
        },
      },
      groupOrder: [...prevState.groupOrder, newId],
    }));
    setCount(+newId);
    scrollToEnd();
  };

  const removeFromGroup = (groupKey: string, dragId: number, id: number) => {
    const itemsFromGroup = _.clone(segPickerState.itemsMap[groupKey]);
    itemsFromGroup.splice(getIndexByDragId(itemsFromGroup, dragId), 1);
    const clonedItems = arrayOfClonedItems(segPickerState.itemsMap, id);
    if (clonedItems.length === 0) {
      setClonedSource(segPickerState.sourceList, id, false);
    }
    setSegPickerState((prevState) => ({
      ...prevState,
      itemsMap: { ...prevState.itemsMap, [groupKey]: itemsFromGroup },
      groups: {
        ...prevState.groups,
        [groupKey]: { ...prevState.groups[groupKey], dragIds: _.map(itemsFromGroup, 'dragId') },
      },
    }));
  };

  const removeGroup = (groupKey: string) => {
    const groupOrder = _.clone(segPickerState.groupOrder);
    groupOrder.splice(groupOrder.indexOf(groupKey), 1);
    unsetCloned(segPickerState.sourceList, segPickerState.itemsMap, groupKey);
    const newRemoveGroupState = {
      ...segPickerState,
      itemsMap: { ..._.omit(segPickerState.itemsMap, groupKey) },
      groupOrder,
      groups: { ..._.omit(segPickerState.groups, groupKey) },
    };
    setSegPickerState(newRemoveGroupState);
  };

  const includeExcludeToggle = (groupName: string, itemId: number, excluded: boolean) => {
    setSegPickerState((prevState) => {
      const itemsMapClone = _.clone(prevState.itemsMap);
      const target = _.clone(itemsMapClone[groupName]);
      const id = getIndexByDragId(target, itemId);
      const segment = _.clone(target[id]);
      segment.isExcluded = excluded;

      target[id] = segment;
      itemsMapClone[groupName] = target;

      return ({ ...prevState, itemsMap: itemsMapClone });
    });
  };

  const setTargetGroup = (groupKey: string, targetValue: { text: string, value: string }) => {
    setSegPickerState((prevState) => ({
      ...prevState,
      groups: {
        ...prevState.groups,
        [groupKey]: { ...prevState.groups[groupKey], boolOperator: targetValue },
      },
    }));
  };

  const toggleReorderGroupsMode = () => {
    setSegPickerState((prevState) => ({
      ...prevState,
      isReorderGroupMode: !prevState.isReorderGroupMode,
    }));
  };

  return (
    <Grid id="segment-picker" style={{ margin: 0 }}>
      <Grid.Row style={{ padding: 0 }}>
        <Grid.Column style={{ padding: 0 }}>
          <Header
            as="h5"
            style={{ ...COPILOT_TYPOGRAPHY.HEADING.H5, margin: 0 }}
            content="Segment Groups"
          />
          <p style={{ ...COPILOT_TYPOGRAPHY.BODY.SMALL, marginBottom: COPILOT_LAYOUT.SPACING[16] }}>
            {descriptionText}
          </p>
          {_.isFunction(renderSegmentAutoFill) && renderSegmentAutoFill()}
          <DragDropContext onDragEnd={onDragEnd}>
            <Grid columns="equal" style={{ margin: 0 }}>
              <DraggableSourceList
                name="sourceList"
                key="sourceList"
                items={segPickerState.sourceList}
                onSearchChange={(e, v) => onSearchChange(e, v)}
                numberOfItems={segPickerState.numberOfItems}
                startingPage={segPickerState.startingPage}
                onFilterExcludedIds={onFilterExcludedIds}
                onDataRangeChange={({ start }) => getData({
                  search: segPickerState.search,
                  limit: 10,
                  skip: start,
                })}
              />
              <Grid.Column id="seg-picker-right-view" style={{ padding: 0, paddingLeft: 12 }}>
                <SegPickerRightView
                  itemsMap={segPickerState.itemsMap}
                  // @ts-ignore ref?
                  groupContainerEndRef={(el) => {
                    groupContainerEnd.current = el;
                  }}
                  groups={segPickerState.groups}
                  groupOrder={segPickerState.groupOrder}
                  removeFromGroup={removeFromGroup}
                  removeGroup={removeGroup}
                  includeExcludeToggle={includeExcludeToggle}
                  setGroupFormChange={setGroupFormChange}
                  enableGroupReordering={enableGroupReordering}
                  isReorderGroupsMode={segPickerState.isReorderGroupMode}
                  toggleReorderGroupsMode={toggleReorderGroupsMode}
                  showIncludeExcludeSelector={showIncludeExcludeSelector}
                  enableItemReordering={enableItemReordering}
                  errors={fieldState.error}
                  currency={currency}
                  onTargetBetweenSegmentsClick={setTargetGroup}
                  cannotDeleteAllSegmentGroups={cannotDeleteAllSegmentGroups}
                  overrideBidValues={overrideBidValues}
                  bidType={bidType}
                  showConfigureDefaultsBar={!_.isNil(globalDefaultBidValues) && _.isUndefined(strategyId)}
                />
                <Button fluid onClick={addNewGroup} type="button">
                  <Icon name="plus" />
                  Segment Group
                </Button>
              </Grid.Column>
            </Grid>
          </DragDropContext>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
};

export default SegmentPicker;
