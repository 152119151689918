import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import PageLoading from 'components/PageLoading/PageLoading';
import { STRATEGY_TYPE } from 'constantsBase';
import Strategy403 from 'containers/403/components/Strategy403';
import { GlobalState } from 'reducers';
import { checkPermissions, Permission } from 'utils/featureFlags';
import { useAsyncEffect, withQueryString } from 'utils/functionHelpers';
import { PossibleStates } from 'utils/hooks/useFetcher';
import { User } from 'utils/types';
import withRouter, { RouterProps } from 'utils/withRouter';
import StrategyError from './components/StrategyError';
import StrategyWizardRoutes from './components/StrategyWizardRoutes';
import { INITIALIZE_FORM_VALUES, StrategyWizardStates } from './constants';
import { useStrategyWizardContext } from './contexts/StrategyWizardProvider';
import useStrategyFetcher from './hooks';
import { attachFlightsInfoInitialState } from './steps/AttachFlights/constants';
import { AttachFlightsInfoType } from './types';
import { getInitialWizardFormFieldsUIValues, QSParams } from './utils';

export const PAGE_NAME = 'Strategy Wizard';

const getLoadingMessage = (strategyId?: string) => (
  strategyId ? `Loading data for strategy [${strategyId}]` : 'Loading'
);

type Props = {
  qsParams: QSParams
} & RouterProps;

const StrategyWizard = (props: Props) => {
  const { qsParams, router } = props;
  const { wizardFormValues, dispatch } = useStrategyWizardContext();
  const {
    attachFlightsStep: { member: contextValuesMember, brand: contextValuesBrand },
    goalSelectionStep,
    budgetAllocationState,
  } = wizardFormValues;
  const { params } = router;
  const strategyId = params.id;
  const step = _.toNumber(params.step);
  const customGoal = _.get(goalSelectionStep, 'customGoal');
  const metricsConfig = _.get(goalSelectionStep, 'metricsConfig');
  const [attachFlightsInfo, setAttachFlightsInfo] = useState<AttachFlightsInfoType>({ ...attachFlightsInfoInitialState });
  const user = useSelector<GlobalState>((state) => state.login.user) as User;
  const canAccessCrossPlatform = checkPermissions(user, Permission.stratCrossPlatformOptimization);
  const strategyState = useStrategyFetcher(strategyId, qsParams, attachFlightsInfo, setAttachFlightsInfo, _.get(budgetAllocationState, 'data'), customGoal, metricsConfig, dispatch);
  const strategyTypeId = _.get(strategyState, 'data.strategyType.id');

  useAsyncEffect(async () => {
    if (strategyState.kind === PossibleStates.hasData || strategyState.kind === StrategyWizardStates.initFromQs) {
      const initialWizardFormFieldsUIValues = getInitialWizardFormFieldsUIValues(strategyState);
      const editingCrossPlatformStrat = (strategyId && _.get(initialWizardFormFieldsUIValues, 'strategyTypeSelectionStep.strategyType.id') === STRATEGY_TYPE.crossPlatformOptimization.id);
      await dispatch({
        type: INITIALIZE_FORM_VALUES,
        payload: {
          ...initialWizardFormFieldsUIValues,
          attachFlightsStep: {
            ...initialWizardFormFieldsUIValues.attachFlightsStep,
            flightsStatus: attachFlightsInfo.flightsStatus,
            eligibleFlights: attachFlightsInfo.eligibleFlights,
            ineligibleFlights: attachFlightsInfo.ineligibleFlights,
            attachedToThisStrategy: attachFlightsInfo.attachedToThisStrategy,
            ...(editingCrossPlatformStrat && {
              deactivatedFlights: attachFlightsInfo.deactivatedFlights,
              reactivatedFlights: [],
              toBeDeactivated: [],
            }),
          },
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [strategyState]);

  useEffect(() => {
    if (_.isNil(contextValuesMember) && _.isNil(contextValuesBrand)) {
      setAttachFlightsInfo({ ...attachFlightsInfoInitialState });
    }
  }, [contextValuesMember, contextValuesBrand]);

  const commonProps = {
    step,
    wizardFormValues,
    qsParams,
    strategyState,
    attachFlightsInfo,
    setAttachFlightsInfo,
    canAccessCrossPlatform,
    router,
    strategyTypeId,
  };
  switch (strategyState.kind) {
    case PossibleStates.hasData:
      return (
        <StrategyWizardRoutes
          strategyConfig={strategyState.data.config}
          strategyId={strategyState.data.id}
          {...commonProps}
        />
      );
    case StrategyWizardStates.initFromQs:
      return (
        <StrategyWizardRoutes
          {...commonProps}
        />
      );
    case StrategyWizardStates.newStrategy:
      return (
        <StrategyWizardRoutes
          {...commonProps}
        />
      );
    case StrategyWizardStates.unauthorized:
      return (
        <Strategy403
          strategyId={strategyId}
          user={user}
          loadingComponent={<PageLoading pageName={PAGE_NAME}>{getLoadingMessage(strategyId)}</PageLoading>}
        />
      );
    case PossibleStates.error:
      return <StrategyError id={strategyId} error={_.toString(_.get(strategyState, 'errorObject'))} />;
    case PossibleStates.initial:
    case PossibleStates.loading:
    default:
      return <PageLoading pageName={PAGE_NAME}>{getLoadingMessage(strategyId)}</PageLoading>;
  }
};

export default withRouter(withQueryString(StrategyWizard));
