/* This component is common to both bulk create strategies and regular strategy creation, and it contains code related to bulk creation as well. */
import _ from 'lodash';
import React, { useEffect } from 'react';
import { Controller, useFormContext, useWatch } from 'react-hook-form';
import { WppDivider, WppTypography, WppInlineMessage, WppTag } from 'buildingBlocks';
import PermissionWrapper from 'components/PermissionWrapper';
import { DSP } from 'constantsBase';
import { getChildDisplayName } from 'containers/StrategyWizard/ConfigurationByStrategyType/BudgetOptimization/utils';
import { BUDGET_MANAGEMENT_STYLES } from 'containers/StrategyWizard/ConfigurationByStrategyType/BudgetOptimization/components/GroupSettings/style';
import { configuringCampaignStratCheck } from 'containers/StrategyWizard/steps/AttachFlights/utils';
import { StrategyConfigurationStep } from 'containers/StrategyWizard/types';
import { hasExternalCustomValueMetric, isBulkCreateMode, isHigherOrderExternalType } from 'containers/StrategyWizard/utils';
import Tooltip from 'containers/Tooltip';
import { checkPermissions, Permission } from 'utils/featureFlags';
import { useMount } from 'utils/hooks/generic/hookWrappers';
import { COPILOT_LAYOUT } from 'globalStyles';
import { RevenueTypeOutcomeOptions } from 'containers/StrategyWizard/constants';
import GroupSettings from './GroupSettings/GroupSettings';
import Pacing from './Pacing';
import { IntelligentChildObjectsToggle, FixedCostInventoryToggle } from './Toggles/Toggles';
import { shouldShowIntelligentChildObjectsToggle, tooltipContent, tooltipContentForBudget } from './Toggles/utils';
import { BudgetAllocationProps, SupportedExternalFlightTypes } from '../types';

const { divider, intelligenceContainer, budgetAllocationGroup, budgetAllocationHeader } = BUDGET_MANAGEMENT_STYLES.budgetAllocation;
const { SPACING } = COPILOT_LAYOUT;

const BETA_TAG_COLOR = 2;

const BudgetAllocation = ({
  displayName,
  flight,
  budgetAllocationState,
  tooltips,
  member,
  strategyType,
  user,
  isCrossPlatformOptimization,
  hasChildFlightsData,
  strategyWizardFormValues,
}: BudgetAllocationProps) => {
  // @ts-ignore BulkCreateAttachForm doenst have selectedOptType
  const { attachFlightsStep: { attachedFlights, selectedOptType }, goalSelectionStep, strategyConfigurationStep } = strategyWizardFormValues;
  const goalType = _.get(goalSelectionStep, 'goal.type');
  const hasExternalCustomValue = hasExternalCustomValueMetric(goalType, goalSelectionStep);
  const revenueOutcomeType = _.get(goalSelectionStep, 'revenueOutcomeType');
  const contextIntelChildObj = _.get(strategyConfigurationStep, 'intelligentChildObjects');
  const intelligentChildObjectsEnabled = useWatch({ name: 'intelligentChildObjects' });
  const { setValue, getValues } = useFormContext<StrategyConfigurationStep>();
  const hasOnlyAMZNFlights = isCrossPlatformOptimization && _.every(attachedFlights, ['dsp', DSP.AMZN.id]);
  const hasSomeAMZNFlight = isCrossPlatformOptimization && _.some(attachedFlights, ['dsp', DSP.AMZN.id]);
  const dailyParentBudgetInflationRatio = useWatch({ name: 'dailyParentBudgetInflationRatio' });

  const dsp = _.get(flight, 'dsp');
  const icoTooltipContent = tooltipContent(dsp, 'intelligentChildObjects');
  const bmTooltipContent = tooltipContentForBudget(_.get(tooltips, 'budgetManagement'));
  const fixedCostInventoryTooltipContent = tooltipContent(dsp, 'fixedCostInventory');
  const hasMultipleOutcomesOrValues = _.isEqual(revenueOutcomeType, RevenueTypeOutcomeOptions.multiple);
  const showIntelligentChildObjectsToggle = shouldShowIntelligentChildObjectsToggle(user, member, strategyType, hasExternalCustomValue) && !hasOnlyAMZNFlights && !hasMultipleOutcomesOrValues;
  const hasSingleParentLevelObject = isHigherOrderExternalType(_.head(attachedFlights).externalType) && _.size(attachedFlights) === 1;
  const isHigherOrderOrCrossPlatform = isCrossPlatformOptimization || hasSingleParentLevelObject;
  const prevSetIntelChildObj = getValues('prevSetIntelChildObj');
  const isBulkCreate = isBulkCreateMode(location?.pathname ?? '');

  useMount(() => {
    if (!hasOnlyAMZNFlights && prevSetIntelChildObj && !_.isNil(contextIntelChildObj)) {
      setValue('intelligentChildObjects', contextIntelChildObj);
    }
    if (contextIntelChildObj && hasExternalCustomValue) {
      setValue('intelligentChildObjects', false);
    }
  });

  useEffect(() => {
    setValue('bidOptimization', !!intelligentChildObjectsEnabled);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [intelligentChildObjectsEnabled]);

  const showFixedCostInventoryToggle = (member?.dsp === DSP.TTD.id && !isBulkCreate) && configuringCampaignStratCheck(selectedOptType);
  const intelligentChildObjectDisplayName = isCrossPlatformOptimization ? 'Line Items' : getChildDisplayName(flight.externalType as SupportedExternalFlightTypes);
  const baseIntelligenceText = `Activate Intelligent ${intelligentChildObjectDisplayName} to improve goal performance.`;
  const intelligenceSectionText = hasSomeAMZNFlight ? `${baseIntelligenceText} Not available on Amazon DSP.` : baseIntelligenceText;

  const hasNMSPermission = checkPermissions(user, Permission.nmsConsolidatedIo);

  return (
    <>
      <Pacing pacing={dailyParentBudgetInflationRatio} />
      <WppDivider style={divider} />
      {showIntelligentChildObjectsToggle && (
        <>
          <div style={intelligenceContainer}>
            <div style={budgetAllocationGroup}>
              <div style={budgetAllocationHeader}>
                <WppTypography type="m-strong">{`Intelligent ${intelligentChildObjectDisplayName}`}</WppTypography>
                <Tooltip
                  content={icoTooltipContent}
                >
                  <WppInlineMessage
                    size="s"
                    type="information"
                  />
                </Tooltip>
              </div>
              <WppTypography tag="span" type="s-body">{intelligenceSectionText}</WppTypography>
              <IntelligentChildObjectsToggle
                displayName={displayName}
              />
            </div>
            {showFixedCostInventoryToggle && intelligentChildObjectsEnabled && (
              <div style={budgetAllocationGroup}>
                <div style={budgetAllocationHeader}>
                  <WppTypography type="m-strong">Fixed Cost Inventory</WppTypography>
                  <Tooltip
                    content={fixedCostInventoryTooltipContent}
                  >
                    <WppInlineMessage
                      size="s"
                      type="information"
                    />
                  </Tooltip>
                </div>
                <FixedCostInventoryToggle
                  displayName={displayName}
                />
              </div>
            )}
          </div>
          <WppDivider style={divider} />
        </>
      )}
      {isHigherOrderOrCrossPlatform && !isBulkCreate && (
        <PermissionWrapper
          permissions={Permission.budgetAllocationGroups}
        >
          <div style={budgetAllocationGroup}>
            <div style={budgetAllocationHeader}>
              <WppTypography type="m-strong">Budget Management</WppTypography>
              <Tooltip content={bmTooltipContent}>
                <WppInlineMessage
                  size="s"
                  type="information"
                />
              </Tooltip>
              {hasNMSPermission && <WppTag label="BETA" style={{ marginLeft: SPACING[4] }} categoricalColorIndex={BETA_TAG_COLOR} />}
            </div>
            <WppTypography style={{ marginBottom: SPACING[16] }} type="s-body">Control how budget is allocated across strategy objects</WppTypography>
            <Controller
              name="groupSettings"
              render={() => (
                <GroupSettings
                  budgetAllocationState={budgetAllocationState}
                  tooltips={tooltips}
                  hasChildFlightsData={hasChildFlightsData}
                />
              )}
            />
          </div>
        </PermissionWrapper>
      )}
    </>
  );
};

export default BudgetAllocation;
