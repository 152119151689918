import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { Button, Segment } from 'buildingBlocks';
import { DualLabelInputWrapper, BidTypeInput } from 'containers/StrategyWizard/ConfigurationByStrategyType/SegmentRecency/components/SegmentGroupBidFormComponent';
import { BidTypeValue } from 'containers/StrategyWizard/ConfigurationByStrategyType/Helios/type';
import { BID_TYPES } from 'containers/StrategyWizard/ConfigurationByStrategyType/Helios/constants';
import { ButtonClickHandler, Currency } from 'utils/types';
import { dragAndDropStyles } from '../style';

type Props = {
  currency: Currency
  overrideBidValues: ButtonClickHandler
  bidType: BidTypeValue
};

const ConfigureDefaultsBar = ({ currency, overrideBidValues, bidType }: Props) => {
  const bidOrMultiplier = bidType === BID_TYPES.BID ? 'Bid' : 'Multiplier';
  const { control } = useFormContext();
  return (
    <Segment attached style={{ backgroundColor: '#F0F1F0', padding: '10px' }}>
      <div className="configure-defaults-bar" style={dragAndDropStyles.configureDefaultsBar.flex}>
        <div style={{ flex: 1 }}>
          <div className="ui form">
            <div id="configure-default-bid-values-bar" style={dragAndDropStyles.configureDefaultsBar.flex}>
              <BidTypeInput
                bidType={bidType}
                currency={currency}
                name="startingMinBid"
                title={`Starting Min. ${bidOrMultiplier}`}
                required={false}
              />
              <BidTypeInput
                bidType={bidType}
                currency={currency}
                name="startingMaxBid"
                title={`Starting Max. ${bidOrMultiplier}`}
                required={false}
              />
              <Controller
                name="maxDays"
                control={control}
                rules={{ min: { value: 1, message: 'Cannot be below 1.' } }}
                render={({ field, fieldState }) => (
                  <DualLabelInputWrapper
                    type="number"
                    field={field}
                    fieldState={fieldState}
                    onChange={field.onChange}
                    value={field.value}
                    skipIsDirtyCheck
                  />
                )}
              />
            </div>
          </div>
        </div>
        <div style={dragAndDropStyles.configureDefaultsBar.flex}>
          <Button
            primary
            style={dragAndDropStyles.configureDefaultsBar.flex}
            onClick={overrideBidValues}
            type="button"
          >
            Apply All
          </Button>
        </div>
      </div>
    </Segment>
  );
};

export default ConfigureDefaultsBar;
