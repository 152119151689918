import _ from 'lodash';
import { Dispatch } from 'react';
import { Microservices } from 'utils/copilotAPI';
import MaxOrderedMap from 'utils/maxOrderedMap';
import { ADD_LEAF_NAMES, KoalaBox, CustomTreeStatus, CUSTOM_TREE_INPUT, HANDLE_VALIDATION_ERROR, HANDLE_VALIDATION_RESPONSE, MAX_SET_SIZE, PageStatus, PageStatusType, PARSE_CSV_DATA_FAILED, RESET_CSV_VALIDATION, RESET_CUSTOM_TREE_VALIDATION, SUCCESSFULLY_PARSED_CSV_DATA, TOGGLE_LEAF_NAMES_FAILED, TOGGLE_LEAF_NAMES_SUCCEEDED, TreeStatus, UPDATE_CUSTOM_TREE_VALIDATION_STATUS, VALIDATED_INITIAL_TREE_STATUS, VALIDATE_CUSTOM_TREE } from './constants';
import { getTreeDetails, getTreeValidationError, handleXndrValidationResponse } from './utils';

export type State = {
  validationError: string | null
  cachedCustomTrees: MaxOrderedMap<string, TreeStatus>
  cachedTreeFileInputs: MaxOrderedMap<string, string>
  csvValidationMsg: string | null
  chickenBox: KoalaBox
  pageStatus: PageStatusType
  isExistingValues: boolean
};

export const INITIAL_STATE: State = {
  validationError: null,
  cachedCustomTrees: new MaxOrderedMap(MAX_SET_SIZE),
  cachedTreeFileInputs: new MaxOrderedMap(MAX_SET_SIZE),
  csvValidationMsg: null,
  chickenBox: {
    maxBid: null,
    unboundedComputeNodes: null,
  },
  pageStatus: PageStatus.InitialPageStatus,
  isExistingValues: false,
};

export const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case VALIDATED_INITIAL_TREE_STATUS: {
      const { trimmedCustomTreeInput, trimmedTreeFileInput } = action.payload;
      const pageStatus = { customTreeStatus: CustomTreeStatus.CustomTreeIsValid };
      return {
        ...state,
        pageStatus,
        cachedCustomTrees: state.cachedCustomTrees.set(trimmedCustomTreeInput, { pageStatus, validationError: null }),
        cachedTreeFileInputs: trimmedTreeFileInput
          ? state.cachedTreeFileInputs.set(trimmedTreeFileInput, trimmedCustomTreeInput)
          : state.cachedTreeFileInputs,
        isExistingValues: true,
      };
    }
    case TOGGLE_LEAF_NAMES_SUCCEEDED: {
      const { trimmedCustomTreeInput } = action.payload;
      const { pageStatus, validationError } = getTreeDetails(state, trimmedCustomTreeInput);
      return {
        ...state,
        cachedCustomTrees: state.cachedCustomTrees.set(
          trimmedCustomTreeInput,
          { validationError: null, pageStatus: { customTreeStatus: CustomTreeStatus.NotYetValidated } },
        ),
        validationError,
        pageStatus,
      };
    }
    case TOGGLE_LEAF_NAMES_FAILED: {
      return {
        ...state,
        validationError: action.payload.msg,
        pageStatus: { customTreeStatus: CustomTreeStatus.CustomTreeError },
      };
    }
    case HANDLE_VALIDATION_RESPONSE: {
      const { validationData, trimmedCustomTreeInput } = action.payload;
      const validationError = getTreeValidationError(validationData);

      return {
        ...state,
        pageStatus: validationData.pageStatus,
        validationError,
        cachedCustomTrees: state.cachedCustomTrees.set(
          trimmedCustomTreeInput,
          { validationError, pageStatus: validationData.pageStatus },
        ),
      };
    }
    case HANDLE_VALIDATION_ERROR: {
      return {
        ...state,
        validationError: 'Something went wrong while getting the validation result',
        pageStatus: { customTreeStatus: CustomTreeStatus.CustomTreeError },
      };
    }
    case VALIDATE_CUSTOM_TREE: {
      return {
        ...state,
        pageStatus: { customTreeStatus: CustomTreeStatus.CustomTreeValidating },
        validationError: null,
      };
    }
    case UPDATE_CUSTOM_TREE_VALIDATION_STATUS: {
      const { pageStatus, validationError } = getTreeDetails(state, action.payload.trimmedCustomTreeInput);
      return {
        ...state,
        pageStatus: action.payload.shouldRevalidate ? { customTreeStatus: CustomTreeStatus.NotYetValidated } : pageStatus,
        validationError,
        csvValidationMsg: null,
      };
    }
    case SUCCESSFULLY_PARSED_CSV_DATA: {
      const { trimmedTreeFileInput, trimmedCustomTreeInput } = action.payload;
      return {
        ...state,
        cachedTreeFileCsvInputs: state.cachedTreeFileInputs.set(trimmedTreeFileInput, trimmedCustomTreeInput),
        pageStatus: { customTreeStatus: CustomTreeStatus.NotYetValidated },
        csvValidationMsg: null,
      };
    }
    case PARSE_CSV_DATA_FAILED: {
      return {
        ...state,
        csvValidationMsg: action.payload.error,
        pageStatus: PageStatus.CsvHasError,
      };
    }
    case RESET_CSV_VALIDATION: {
      return {
        ...state,
        csvValidationMsg: null,
        pageStatus: PageStatus.InitialPageStatus,
      };
    }
    case RESET_CUSTOM_TREE_VALIDATION: {
      return {
        ...state,
        pageStatus: PageStatus.InitialPageStatus,
        validationError: null,
      };
    }
    default:
      return INITIAL_STATE;
  }
};

export const toggleLeafNames = async (
  type,
  treeText,
  setValue,
  dispatch: Dispatch<any>,
) => {
  const service = type === ADD_LEAF_NAMES ? 'add' : 'remove';
  try {
    const res = await Microservices.runService({ tree_text: treeText }, `${service}_leaf_names`);
    const trimmedCustomTreeInput = _.trim(res.data.tree_text);
    await dispatch({ type: TOGGLE_LEAF_NAMES_SUCCEEDED, payload: { trimmedCustomTreeInput } });
    setValue(CUSTOM_TREE_INPUT, trimmedCustomTreeInput);
  } catch (error) {
    console.log({ error });
    dispatch({ type: TOGGLE_LEAF_NAMES_FAILED, payload: { msg: `Error: Couldn't ${service} leaf names.` } });
  }
};

export const handleTreeValidation = async (
  member,
  treeText,
  setKoalaBoxValues,
  dispatch: Dispatch<any>,
) => {
  dispatch({ type: VALIDATE_CUSTOM_TREE });
  const payload = { memberExtId: member.externalId, modelText: treeText };
  try {
    const body = _.mapKeys(payload, (_v, k) => _.snakeCase(k));
    const validationRes = await Microservices.runService(body, 'custom_tree_validation');
    const formattedValidationData = handleXndrValidationResponse(validationRes);
    await dispatch({
      type: HANDLE_VALIDATION_RESPONSE,
      payload: { validationData: formattedValidationData, trimmedCustomTreeInput: _.trim(body.model_text) },
    });
    const shouldUpdateKoalaValues = _.get(formattedValidationData.pageStatus, 'customTreeStatus') === CustomTreeStatus.CustomTreeIsValid;
    if (shouldUpdateKoalaValues) {
      const koalaValues = {
        maxBid: _.get(formattedValidationData, 'maxBid'),
        unboundedComputeNodes: _.get(formattedValidationData, 'unboundedComputeNodes'),
      };
      setKoalaBoxValues(koalaValues);
    }
  } catch (error) {
    console.log({ error });
    dispatch({ type: HANDLE_VALIDATION_ERROR });
  }
};

export const generateTreeText = async (treeText, setValue, dispatch) => {
  try {
    const validationCSVData = await Microservices.runService({ csv_text: treeText }, 'csv_to_bonsai');
    const trimmedCustomTreeInput = _.trim(_.get(validationCSVData, 'data.model_text'));
    await dispatch({ type: SUCCESSFULLY_PARSED_CSV_DATA, payload: { trimmedTreeFileInput: treeText, trimmedCustomTreeInput } });
    setValue(CUSTOM_TREE_INPUT, trimmedCustomTreeInput);
  } catch (error) {
    console.log({ error });
    const errorText = _.get(error, 'response.data.error', 'Error generating bonsai tree');
    dispatch({ type: PARSE_CSV_DATA_FAILED, payload: { error: errorText } });
  }
};
