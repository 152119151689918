import _ from 'lodash';
import React from 'react';
import { Form } from 'buildingBlocks';
import { NumberInput } from 'containers/StrategyWizard/ConfigurationByStrategyType/Wrappers';

export type OptimizedMultiplier = {
  name: string,
  title: string,
};

export type OptimizedMultipliersProps = {
  optimizedMultipliers: Array<OptimizedMultiplier>,
};

const OptimizedMultipliers = ({ optimizedMultipliers }: OptimizedMultipliersProps) => (
  <div className="ui small form">
    <Form.Group widths="equal">
      {
        _.map(optimizedMultipliers, ({ name, title }) => (
          <NumberInput
            key={name}
            name={name}
            title={title}
          />
        ))
      }
    </Form.Group>
  </div>
);

export default OptimizedMultipliers;
