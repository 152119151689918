import _ from 'lodash';
import React from 'react';
import { Form } from 'buildingBlocks';
import { CurrencyInput } from 'containers/StrategyWizard/ConfigurationByStrategyType/Wrappers';
import { Currency } from 'utils/types';

import { OptimizedBid } from '../type';

export type OptimizedBidsProps = {
  currency: Currency,
  optimizedBids: Array<OptimizedBid>,
};

const OptimizedBidsComponent = ({ currency, optimizedBids }: OptimizedBidsProps) => (
  <div className="ui small form">
    <Form.Group widths="equal">
      {
        _.map(optimizedBids, ({ name, title }) => (
          <CurrencyInput
            key={name}
            name={name}
            title={title}
            min={0}
            currency={currency}
            required
          />
        ))
      }
    </Form.Group>
  </div>
);

export default OptimizedBidsComponent;
