import React from 'react';
import { useLocation } from 'react-router-dom';
import { WppTypography, Menu } from 'buildingBlocks';
import strategyWizardStyles from 'containers/StrategyWizard/styles';
import { isBulkCreateMode } from 'containers/StrategyWizard/utils';

type Props = {
  isNewStrategy: boolean
  strategyName?: string
};

const StrategyWizardHeader = ({ isNewStrategy, strategyName = '' }: Props) => {
  const location = useLocation();
  const getStratTitleText = () => {
    if (isBulkCreateMode(location?.pathname ?? '')) return 'Create Bulk Strategy';
    return isNewStrategy ? 'Create Strategy' : `Edit Strategy | ${strategyName}`;
  };

  return (
    <Menu.Item fitted style={strategyWizardStyles.wizardHeaderContainer}>
      <WppTypography tag="p" type="3xl-heading">
        {getStratTitleText()}
      </WppTypography>
    </Menu.Item>
  );
};

export default StrategyWizardHeader;
