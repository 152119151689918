import React from 'react';
import { DSP } from 'constantsBase';
import { ModuleProps } from 'containers/StrategyWizard/steps/StrategyConfiguration/components/Loader';
import BudgetOptimization from '../BudgetOptimization';
import { TOOLTIP_DESCRIPTIONS } from '../constants';

const TTDBudgetOptimization = (props: ModuleProps) => {
  const {
    formValues,
    initialValues,
    strategyId,
  } = props;

  return (
    <BudgetOptimization
      formValues={formValues}
      initialValues={initialValues}
      strategyId={strategyId}
      tooltips={TOOLTIP_DESCRIPTIONS[DSP.TTD.id]}
    />
  );
};

export default TTDBudgetOptimization;
