import _ from 'lodash';
import { apiEnums, FLIGHT_EXTERNAL_TYPE, GOAL_TYPES, IMG_ICONS_16_BASE_PATH, STRATEGY_TYPE } from 'constantsBase';
import { Status } from 'containers/StrategyWizard/ConfigurationByStrategyType/BudgetOptimization/constants';
import { hasMultipleExternalTypesAttached } from 'containers/StrategyWizard/utils';
import { Flight } from 'utils/types';
import { formatGoal, pluralizer } from 'utils/formattingUtils';
import { OptimizationDirection, WizardFormValues } from './types';

export const strategyPopulateCols = [
  apiEnums.member,
  apiEnums.advertiser,
  apiEnums.brand,
  apiEnums.strategyType,
  apiEnums.strategyGoals,
];

export enum BidModifierRemovalConsent {
  NOT_NEEDED = 'BID_MODIFIER_REMOVAL_CONSENT_NOT_NEEDED',
}

export const formatFlightDisplayName = (
  flights: Array<Flight>,
  showCount: boolean = true,
) => {
  if (_.isEmpty(flights)) {
    return '';
  }
  const flightsByExternalType = _.groupBy(flights, 'externalType');
  const externalTypesById = _.keyBy(FLIGHT_EXTERNAL_TYPE, 'id');
  const flightNames = _.map(flightsByExternalType, (f, extType) => {
    const displayName = _.get(externalTypesById[extType], 'wizardDisplayName');
    const flightPluralizer = pluralizer(displayName, `${displayName}s`);
    return showCount
      ? `${f.length} ${flightPluralizer(f.length)}`
      : flightPluralizer(f.length);
  });
  return _.join(flightNames, ' & ');
};

export const formatCrossPlatformFlightDisplayName = (flights: Array<Flight>) => {
  if (_.isEmpty(flights)) {
    return '';
  }
  const flightsLength = _.size(flights);
  return `${flightsLength} ${pluralizer('object', 'objects')(flightsLength)}`;
};

export const WIZARD_BASE = '/strategies/wizard/';

export enum WizardSteps {
  attachFlightsStep = 'attachFlightsStep',
  goalSelectionStep = 'goalSelectionStep',
  strategyTypeSelectionStep = 'strategyTypeSelectionStep',
  strategyConfigurationStep = 'strategyConfigurationStep',
  strategyConfirmationStep = 'strategyConfirmationStep',
}

export enum RevenueTypeOutcomeOptions {
  single = 'single',
  multiple = 'multiple',
}

export const WIZARD_STEPS = {
  [WizardSteps.attachFlightsStep]: {
    id: 1,
    title: 'Attach',
    field: 'attachFlightsStep.attachedFlights',
    subSteps: {
      optimizationType: {
        id: 1.1,
        title: 'Optimization Type',
        field: 'attachFlightsStep.attachedFlights',
      },
      objects: {
        id: 1.2,
        title: 'Objects',
        field: 'attachFlightsStep.attachedFlights',
      },
    },
    dynamicDescription: (attachedFlights: Array<Flight>) => `${hasMultipleExternalTypesAttached(attachedFlights) ? formatCrossPlatformFlightDisplayName(attachedFlights) : formatFlightDisplayName([...attachedFlights])} Attached`,
  },
  [WizardSteps.goalSelectionStep]: {
    id: 2,
    title: 'Goal',
    field: 'goalSelectionStep.goal',
    additionalFieldsForDisplay: ['attachFlightsStep.defaultCurrency.code', 'goalSelectionStep.customGoal.value', 'goalSelectionStep.revenueOutcomeType'],
    dynamicDescription: (
      goal,
      currencyCode: string,
      customGoal: string,
      revenueOutcomeType: RevenueTypeOutcomeOptions,
    ) => {
      if (!_.isEmpty(goal.type)) {
        const { type, target } = goal;
        const hasMultiOutcomeOrValues = _.isEqual(revenueOutcomeType, RevenueTypeOutcomeOptions.multiple);
        return formatGoal(GOAL_TYPES[type], _.round(target, 4), currencyCode, customGoal, hasMultiOutcomeOrValues);
      }
      return '';
    },
  },
  [WizardSteps.strategyTypeSelectionStep]: {
    id: 3,
    title: 'Strategy',
    field: 'strategyTypeSelectionStep.strategyType',
    dynamicDescription: (strategyType) => strategyType.displayName,
  },
  [WizardSteps.strategyConfigurationStep]: {
    id: 4,
    title: 'Configure',
    field: 'config',
  },
  [WizardSteps.strategyConfirmationStep]: {
    id: 5,
    title: 'Confirm',
    field: 'confirm',
  },
} as const;

export const ATTACH_FLIGHTS_LINK = `${WIZARD_BASE}${WIZARD_STEPS[WizardSteps.attachFlightsStep].id}`;

export type StepItem = {
  readonly id: number
  readonly title: string
  readonly description: string
  readonly subSteps?: Array<string>
  readonly field?: string
  readonly dynamicDescription?: (...args: Array<unknown>) => string
  readonly additionalFieldsForDisplay?: Array<string>
};

export const FLIGHT_DETACH_STATUS = {
  SUCCESS: 'SUCCESS',
  FAILED: 'FAILED',
};

export const WIZARD_FORM_NAME = 'wizard';
export const FLIGHT_CONFIG_PREFIX = 'flightConfig';
export const STRAT_TYPES_PER_ROW = 3;

export const INITIAL_WIZARD_VALUES_FOR_UI: WizardFormValues = {
  attachFlightsStep: {
    member: null,
    advertiser: null,
    brand: null,
    optimizationLevel: null,
    flightsStatus: {},
    attachedFlights: [],
    eligibleFlights: [],
    ineligibleFlights: [],
    attachedToThisStrategy: [],
    toBeDetached: [],
    defaultCurrency: null,
  },
  goalSelectionStep: {
    goal: {
      type: null,
      target: null,
    },
    budget: null,
    revenueOutcomeType: null,
  },
  strategyTypeSelectionStep: {
    strategyType: null,
  },
  strategyConfigurationStep: null,
  strategyConfirmationStep: {
    name: '',
  },
  budgetAllocationState: {
    kind: Status.initial,
  },
  nextBtnClick: false,
};

export enum DisabledStrategyTypeReasons {
  unsupportedAdTypes = 'unsupportedAdTypes',
  unsupportedFlightTypes = 'unsupportedFlightTypes',
  noFlightSupported = 'noFlightSupported',
  multipleFlightTypesSupported = 'multipleFlightTypesSupported',
  multipleFlightsSupported = 'multipleFlightsSupported',
}

export const DISABLED_STRATEGY_TYPE_MESSAGES = {
  [DisabledStrategyTypeReasons.unsupportedAdTypes]: `The flight(s) you have selected contain ad types which are
  unsupported by this strategy type`,
  [DisabledStrategyTypeReasons.unsupportedFlightTypes]: `The flight(s) you have selected contain flight types which
  are unsupported by this strategy type`,
  [DisabledStrategyTypeReasons.noFlightSupported]:
    'This strategy type requires that you choose at least one flight',
  [DisabledStrategyTypeReasons.multipleFlightTypesSupported]: `This strategy type does not support collections of
  flights with different flight types`,
};

export const BUDGET_OPTIMIZATION_MICRO_SERVICE = 'budget_opt_wizard';

export const stratTypesWithReportingOnlyTxt = [
  STRATEGY_TYPE.helios.id,
  STRATEGY_TYPE.heliosSegmentRecency.id,
  STRATEGY_TYPE.predictorALI.id,
  STRATEGY_TYPE.customTree.id,
  STRATEGY_TYPE.heliosBidList.id,
  STRATEGY_TYPE.apnBudgetOptimization.id,
  STRATEGY_TYPE.ttdBudgetOptimization.id,
  STRATEGY_TYPE.dbmBudgetOptimization.id,
  STRATEGY_TYPE.customBidList.id,
  STRATEGY_TYPE.dbmCustomSDF.id,
];

export const STRATEGY_TYPE_WITH_NO_CONFIRM_FOOTER = [
  STRATEGY_TYPE.dbmCustomSDF.id,
];

export const SINGLE_CAMPAIGN_LEVEL_OPT_STRATEGIES = [
  STRATEGY_TYPE.apnBudgetOptimization.id,
  STRATEGY_TYPE.ttdBudgetOptimization.id,
  STRATEGY_TYPE.dbmBudgetOptimization.id,
  STRATEGY_TYPE.amznBudgetOptimization.id,
  STRATEGY_TYPE.wmtBudgetOptimization.id,
];

const ACTIONS_PREFIX = 'STRATEGY_WIZARD:';

export const INITIALIZE_FORM_VALUES = `${ACTIONS_PREFIX}INITIALIZE_FORM_VALUES`;
export const ATTACH_FLIGHTS = `${ACTIONS_PREFIX}ATTACH_FLIGHTS`;
export const SELECT_GOALS = `${ACTIONS_PREFIX}SELECT_GOALS`;
export const UPDATE_BUDGET_ALLOCATION_STATE = `${ACTIONS_PREFIX}UPDATE_BUDGET_ALLOCATION_STATE`;
export const SET_USER_BID_MOD_RMVL_CONSENT = `${ACTIONS_PREFIX}SET_USER_BID_MOD_RMVL_CONSENT`;
export const CLEAR = `${ACTIONS_PREFIX}CLEAR`;
export const SELECT_STRATEGY_TYPE = `${ACTIONS_PREFIX}SELECT_STRATEGY_TYPE`;
export const CONFIGURE_STRATEGY = `${ACTIONS_PREFIX}CONFIGURE_STRATEGY`;
export const SET_STRATEGY_NAME = `${ACTIONS_PREFIX}SET_STRATEGY_NAME`;
export const SET_BULK_STRATEGY_TYPE = `${ACTIONS_PREFIX}SET_BULK_STRATEGY_TYPE`;
export const RESET_GOALS_AND_STRATEGY_TYPE = `${ACTIONS_PREFIX}RESET_GOALS_AND_STRATEGY_TYPE`;
export const RESET_GOALS_AND_STRATEGY_TYPE_AMZN = `${ACTIONS_PREFIX}RESET_GOALS_AND_STRATEGY_TYPE_AMZN`;

export const YOUTUBE_SPECIFIC_GOALS = [GOAL_TYPES.cpvYoutube.value, GOAL_TYPES.vcrYoutube.value];

export const TRUE_VIEW = 'TrueView' as const;
export const TRUE_VIEWS = 'TrueViews' as const;
export const NON_SKIPPABLE = 'Non Skippable' as const;

export const SET_NEXT_BTN_CLICK = `${ACTIONS_PREFIX}SET_NEXT_BTN_CLICK`;
export const RESET_NEXT_BTN_CLICK = `${ACTIONS_PREFIX}RESET_NEXT_BTN_CLICK`;
export const SAVE_AWG_CONFIG = `${ACTIONS_PREFIX}SAVE_AWG_CONFIG`;
export const CLEAR_AWG_CONFIG = `${ACTIONS_PREFIX}CLEAR_AWG_CONFIG`;

export const createSupportTicketUrl = 'https://copilotsupport.freshdesk.com/support/tickets/new';

export const IMPACT_OUTCOME_FORMULA = {
  name: 'Impact',
  value: 'impactOutcome',
  formula: ['(', 'revenue', '/', 'impressions', ')', '*', '1000'],
  metrics: ['revenue', 'impressions'],
  optimizationDirection: OptimizationDirection.down,
};

export const OPERATOR_SVGS = {
  '+': `${IMG_ICONS_16_BASE_PATH}/add-operator.svg`,
  '*': `${IMG_ICONS_16_BASE_PATH}/asterisk.svg`,
  '/': `${IMG_ICONS_16_BASE_PATH}/divide-operator.svg`,
  '-': `${IMG_ICONS_16_BASE_PATH}/subtract-operator.svg`,
};

export const ALL_OPERATORS = ['(', ')', '*', '/', '+', '-'];
export const ALL_METRICS = [
  'clicks',
  'cost',
  'conversionRevenue',
  'conversions',
  'impressions',
  'measuredImpressions',
  'revenue',
  'videoViewableCompletes',
  'videoStarts',
  'viewableImpressions',
  'trueviews',
  'videoCompletes',
  'externalCustomValue',
];

export const METRIC_SEARCH_OBJS = [
  { value: 'clicks', text: 'Clicks' },
  { value: 'cost', text: 'Cost' },
  { value: 'conversionRevenue', text: 'Conversion Revenue' },
  { value: 'conversions', text: 'Conversions' },
  { value: 'impressions', text: 'Impressions' },
  { value: 'measuredImpressions', text: 'Measured Impressions' },
  { value: 'revenue', text: 'Revenue' },
  { value: 'videoViewableCompletes', text: 'Video Viewable Completes' },
  { value: 'videoStarts', text: 'Video Starts' },
  { value: 'viewableImpressions', text: 'Viewable Impressions' },
  { value: 'trueviews', text: 'TrueViews' },
  { value: 'videoCompletes', text: 'Video Completes' },
  { value: 'externalCustomValue', text: 'External Measurement' },
];

export const EDIT_IMG_PATH = `${IMG_ICONS_16_BASE_PATH}/edit-notepad.svg`;
export const DELETE_IMG_PATH = `${IMG_ICONS_16_BASE_PATH}/trash.svg`;
export const WARNING_IMG_PATH = `${IMG_ICONS_16_BASE_PATH}/warning.svg`;
export const CONVERSION_METRICS = ['conversions', 'conversionRevenue'];
export const CUSTOM_GOAL_FIELDS = ['id', 'name', 'displayName', 'equation', 'direction'];

export const NCS_GOALS = [GOAL_TYPES.ncsNbCpa.value, GOAL_TYPES.ncsNbCvr.value, GOAL_TYPES.ncsNbEngagementScore.value, GOAL_TYPES.ncsNbRoas.value];

export enum WizardMode {
  newStrategy = 'newStrategy',
  existingStrategy = 'existingStrategy',
  formPreviouslyFilled = 'formPreviouslyFilled',
}

export const NO_REDIRECT = 'NO_REDIRECT';

export const SYNC_BY_ADVERTISER_MICROSERVICE = 'sync_by_advertiser';

export enum StrategyWizardStates {
  newStrategy = 'newStrategy',
  unauthorized = 'unauthorized',
  initFromQs = 'initFromQs',
}
