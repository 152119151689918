import _ from 'lodash';
import { useState } from 'react';
import { UseFormSetValue, useWatch } from 'react-hook-form';
import { APNSegment, AppNexus, APNLineItem } from 'utils/copilotAPI';
import { RESULTS_LIMIT } from 'constantsBase';
import { Member, Advertiser, Flight, StrategyType, LineItem, SegmentGroupsType } from 'utils/types';
import { HeliosForm } from 'containers/StrategyWizard/types';
import { transformKeyOfObjWithFn, useAsyncEffect } from 'utils/functionHelpers';
import { toCamelCase } from 'utils/formattingUtils';
import { useMount } from 'utils/hooks/generic/hookWrappers';
import { flattenAllSegmentIdentifiers, strategyConfigValuesUtil } from './utils';
import { Segment } from './type';

// Load the segments that are currently attached to this strategy.
export const loadSegments = async (segmentGroups: SegmentGroupsType, member: Member) => {
  const segmentIds = flattenAllSegmentIdentifiers(segmentGroups);
  if (segmentIds.length > 0) {
    const params = {
      where: {
        externalId: segmentIds,
        member: member.id,
      },
      limit: segmentIds.length,
    };
    const segmentResp = await APNSegment.get(params);
    return segmentResp.data;
  }
  return [];
};

const loadLineItems = async (advertiserId: number) => {
  const where = { limit: RESULTS_LIMIT, advertiser: advertiserId };
  const sources = await APNLineItem.get(where);
  return sources.data;
};

export const loadSelectedLineItems = async (externalIds: Array<string>) => {
  const sources = await APNLineItem.get({ where: { externalId: externalIds }, limit: externalIds.length });
  return sources.data;
};

// Get the most up-to-date lineItem (and budget interval) from apn for the lineItem selected.
const loadSelectedItemDetail = async (memberId: number, lineItem: string, id?: string) => {
  const selectedLineItemId = id || lineItem;

  if (!selectedLineItemId) {
    return { selectedLineItem: null, e: null };
  }

  const { data: { response: { lineItem: li } } } = await AppNexus.lineItem(memberId, selectedLineItemId);
  if (li.length !== 1) {
    return {
      selectedLineItem: null,
      e: `No Line Item with id (${selectedLineItemId || '(unknown ID)'}) found from Xandr`,
    };
  }

  const formattedLineItem = transformKeyOfObjWithFn(li[0], toCamelCase);
  // Sorting budget intervals to be more user friendly
  formattedLineItem.budgetIntervals = _.sortBy(formattedLineItem.budgetIntervals, 'endDate');
  return { selectedLineItem: formattedLineItem, e: null };
};

enum HeliosState {
  INITIALIZING = 'INITIALIZING',
  HAS_DATA = 'HAS_DATA',
  SELECTED_LINE_ITEM_LOADING = 'SELECTED_LINE_ITEM_LOADING',
  SELECTED_LINE_ITEM_NOT_FOUND_ERROR = 'SELECTED_LINE_ITEM_NOT_FOUND_ERROR',
}

const INITIAL_HELIOS_STATE = {
  segments: [],
  selectedLineItem: null,
  lineItems: [],
  lineItemError: null,
  overallState: HeliosState.INITIALIZING,
};

type HeliosStateType = {
  segments: Array<Segment>,
  selectedLineItem: LineItem | null,
  lineItems: Array<LineItem>,
  lineItemError: string | null,
  overallState: HeliosState,
};

export const useHeliosDataFetcher = (
  config: HeliosForm,
  member: Member,
  advertiser: Advertiser,
  attachedFlights: Array<Flight>,
  strategyType: StrategyType,
  strategyId: number | null,
  setValue: UseFormSetValue<HeliosForm>,
) => {
  const [state, setState] = useState<HeliosStateType>(INITIAL_HELIOS_STATE);
  const { segments, lineItems, selectedLineItem, lineItemError, overallState } = state;
  const { lineItem, segmentGroups } = config;
  const loadSelectedLineItem = async (id?: string) => {
    setState({ ...state, overallState: HeliosState.SELECTED_LINE_ITEM_LOADING });
    // eslint-disable-next-line @typescript-eslint/no-shadow
    const { selectedLineItem, e: lineItemError } = await loadSelectedItemDetail(member.id, lineItem, id);
    setState({ ...state, selectedLineItem, lineItemError, overallState: HeliosState.HAS_DATA });
  };

  const minBid = useWatch({ name: 'minBid' });

  useAsyncEffect(async () => {
    // eslint-disable-next-line @typescript-eslint/no-shadow
    const [segments, lineItems, { selectedLineItem, e: lineItemError }] = await Promise.all(
      [
        loadSegments(segmentGroups, member),
        loadLineItems(advertiser.id),
        loadSelectedItemDetail(member.id, lineItem),
      ],
    );
    setState({
      ...state,
      segments,
      lineItems,
      selectedLineItem,
      lineItemError,
      overallState: HeliosState.HAS_DATA,
    });
  }, []);

  useMount(() => {
    // run this for only create mode & when minBid/maxBid are not pre-populated from QS
    if (_.isNil(strategyId) && !minBid) {
      strategyConfigValuesUtil(lineItems, attachedFlights, config, strategyType, setValue);
    }
  });

  return {
    segments,
    selectedLineItem,
    loadSelectedLineItem,
    lineItems,
    lineItemError,
    loading: overallState === HeliosState.INITIALIZING,
    selectedLineItemLoading: overallState === HeliosState.SELECTED_LINE_ITEM_LOADING,
  };
};
