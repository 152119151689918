/* eslint-disable no-param-reassign */
import _ from 'lodash';
import { DSP, GOAL_TYPES } from 'constantsBase';
import { Pixel } from 'utils/types';
import { DspToPixel, DspCodeToPixel } from './types';

export const getMatchingPixels = (pixelsArr: Array<Pixel>, searchStr: string) => {
  const lowerCasedSearch = _.toLower(searchStr);
  return _.filter(
    pixelsArr,
    (p: Pixel) => (_.includes(_.toLower(_.toString(p.id)), lowerCasedSearch) || (p.name && _.includes(_.toLower(p.name), lowerCasedSearch))),
  );
};

export const getTotalMatchingPixelCount = (dspToPixel: DspToPixel, searchStr: string) => {
  const flatPixelsArr = _.flatten(_.values(dspToPixel));
  return _.size(getMatchingPixels(flatPixelsArr, searchStr));
};

export const getHeaderText = (goalType: string) => (
  `${_.get(_.keyBy(GOAL_TYPES, 'value'), `${goalType}.strategyWizardLongText`, 'Pixel')} Configuration`
);

const dspToCode = {
  [DSP.APN.id]: DSP.APN.code,
  [DSP.TTD.id]: DSP.TTD.code,
  [DSP.DBM.id]: DSP.DBM.code,
  [DSP.AMZN.id]: DSP.AMZN.code,
  [DSP.WALMART.id]: DSP.WALMART.code,
};

export const convertPixelsForQS = (pixels: DspToPixel): DspCodeToPixel => (_.reduce(
  pixels,
  (result, pixArr: Array<Pixel>, dsp: string) => {
    result[_.get(dspToCode, dsp)] = pixArr;
    return result;
  },
  {},
));

export const convertQSPixelsToForm = (pixels: DspCodeToPixel): DspToPixel => (_.reduce(
  pixels,
  (result, pixArr: Array<Pixel>, dspCode: string) => {
    const dspId = _.findKey(dspToCode, (code: string) => _.isEqual(code, dspCode));
    result[dspId] = _.map(pixArr, (pix: Pixel) => ({ ...pix, ...(pix.weight && { weight: _.toNumber(pix.weight) }) }));
    return result;
  },
  {},
));
