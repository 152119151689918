import _ from 'lodash';
import { CustomTreeStatus, modelTypeEnum, PageStatusType, ValidationData } from './constants';
import { State } from './context';

export const modelTypeEnumOptions = _.values(modelTypeEnum);

export const checkIfCanValidate = (errors: { [key: string]: string }) => _.isEmpty(errors);

export const getValidationOutput = (pageStatus: PageStatusType, validationError: string | null) => {
  const customTreeStatus = _.get(pageStatus, 'customTreeStatus');

  switch (customTreeStatus) {
    case CustomTreeStatus.CustomTreeError:
      return validationError;
    case CustomTreeStatus.CustomTreeIsValid:
      return 'Tree is Valid';
    default:
      return null;
  }
};

export const getTreeDetails = (state: State, customTree: string) => {
  const customTreeDetails = state.cachedCustomTrees.get(customTree);
  if (customTreeDetails) {
    return { pageStatus: customTreeDetails.pageStatus, validationError: customTreeDetails.validationError };
  }
  return { pageStatus: { customTreeStatus: CustomTreeStatus.NotYetValidated }, validationError: null };
};

/* eslint-disable camelcase */
type XndrValidationResponse = {
  data: {
    max_bid?: number,
    unbounded_compute_nodes?: Array<string>,
    valid?: boolean,
    error_type?: string,
    error_message?: string,
  },
};
export const handleXndrValidationResponse = ({ data }: XndrValidationResponse): ValidationData => {
  if (data.valid) {
    const { unbounded_compute_nodes: unboundedComputeNodes, max_bid: maxBid } = data;
    return { pageStatus: { customTreeStatus: CustomTreeStatus.CustomTreeIsValid }, unboundedComputeNodes, maxBid };
  }
  return {
    pageStatus: { customTreeStatus: CustomTreeStatus.CustomTreeError },
    errorType: data.error_type,
    errorMessage: data.error_message,
  };
};

export const getTreeValidationError = (treeValidationData: ValidationData): string | null => (
  _.get(treeValidationData.pageStatus, 'customTreeStatus') === CustomTreeStatus.CustomTreeIsValid
    ? null
    : `Validation error: ${_.get(treeValidationData, 'errorType')} - ${_.get(treeValidationData, 'errorMessage')}`
);
