import _ from 'lodash';
import React, { Dispatch, SetStateAction } from 'react';
import { WppGrid, WppTableBody, WppTableBodyCell, WppTableBodyRow, WppTypography } from 'buildingBlocks';
import { DSP } from 'constantsBase';
import { DEFAULT_WEIGHT } from 'containers/StrategyWizard/ConfigurationByStrategyType/constants';
import { Pixel } from 'utils/types';
import PixelOption from './PixelOption';
import { PIXEL_PICKER_STYLES } from '../styles';
import { DspToPixel, DspToPixelToWeightsType } from '../types';

const { pixelRowError } = PIXEL_PICKER_STYLES;

const removePixelFromArr = (pixArr: Array<Pixel>, pixel: Pixel) => _.filter(pixArr, (pix: Pixel) => !_.isEqual(pix.id, pixel.id));

type DspPixelOptionsProps = {
  dsp: number
  pixels: Array<Pixel>
  selectedModalPixels: DspToPixel
  setSelectedModalPixels: Dispatch<SetStateAction<DspToPixel>>
  defaultDspToPixelToWeights: DspToPixelToWeightsType
  isEngScore: boolean
};

const DspPixelOptions = (props: DspPixelOptionsProps) => {
  const { dsp, pixels, selectedModalPixels, setSelectedModalPixels, defaultDspToPixelToWeights, isEngScore } = props;

  const pixelFetchError = _.isString(pixels);
  const noPixelsForDsp = _.isEmpty(pixels);
  const currSelectedDspPixels = _.get(selectedModalPixels, dsp, []);
  const selectedModalDspPixelIds = _.map(currSelectedDspPixels, 'id');
  const dspInfo = DSP.getById(dsp);
  const dspCode = _.get(dspInfo, 'code');
  const noPixelsMessage = pixelFetchError
    ? `Error fetching ${dspCode} pixels. Please try again later.`
    : `No pixels found for ${dspCode}. Please remove the attached ${dspCode} flights.`;

  const onCheckboxClick = (pixel: Pixel) => {
    const isChecked = _.includes(selectedModalDspPixelIds, pixel.id);
    const newDspPixelArray = isChecked
      ? removePixelFromArr(currSelectedDspPixels, pixel)
      : _.sortBy([...currSelectedDspPixels, pixel], 'id');
    const newSelectedModalPixels = { ...selectedModalPixels, [dsp]: newDspPixelArray };
    setSelectedModalPixels(newSelectedModalPixels);
  };

  const onWeightChange = (pixel: Pixel) => {
    const pixelsCopy = removePixelFromArr(currSelectedDspPixels, pixel);
    // set default pixel weight to what user configured
    defaultDspToPixelToWeights[dsp][pixel.id] = pixel.weight;
    setSelectedModalPixels({ ...selectedModalPixels, [dsp]: _.sortBy([...pixelsCopy, pixel], 'id') });
  };

  return (
    <WppTableBody className="wpp-tbody">
      {(pixelFetchError || noPixelsForDsp) && (
        <WppTableBodyRow className="wpp-table-body-row outcomeValueTableError">
          <WppTableBodyCell className="wpp-table-body-cell" colSpan={5}>
            <WppGrid direction="row" style={pixelRowError}>
              <WppGrid direction="column">
                <WppTypography type="s-body" tag="p">{noPixelsMessage}</WppTypography>
              </WppGrid>
            </WppGrid>
          </WppTableBodyCell>
        </WppTableBodyRow>
      )}
      {!pixelFetchError && (
        <>
          {_.map(pixels, (p: Pixel) => {
            const alreadySelectedPixel = _.find(currSelectedDspPixels, (pix: Pixel) => _.isEqual(pix.id, p.id));
            const fallbackDefaultWeight = _.get(defaultDspToPixelToWeights, `[${dsp}][${p.id}]`, DEFAULT_WEIGHT);
            return (
              <PixelOption
                key={`${dsp} - ${p.id}`}
                dsp={dsp}
                isEngScore={isEngScore}
                pixel={p}
                defaultWeight={_.get(alreadySelectedPixel, 'weight') ?? fallbackDefaultWeight}
                checked={_.includes(selectedModalDspPixelIds, p.id)}
                onCheckboxClick={onCheckboxClick}
                onWeightChange={onWeightChange}
              />
            );
          })}
        </>
      )}
    </WppTableBody>
  );
};

export default DspPixelOptions;
