import React from 'react';
import { Controller } from 'react-hook-form';
import { Form } from 'buildingBlocks';
import { CurrencyInput, DualLabelInput, NumberInput } from 'containers/StrategyWizard/ConfigurationByStrategyType/Wrappers';
import { BidTypeValue } from 'containers/StrategyWizard/ConfigurationByStrategyType/Helios/type';
import { BID_TYPES } from 'containers/StrategyWizard/ConfigurationByStrategyType/Helios/constants';
import { Currency } from 'utils/types';
import { validatedFieldWrapper } from 'utils/wrapperComponentsReactHookForms';

export const DualLabelInputWrapper = validatedFieldWrapper(DualLabelInput);

type Props = {
  currency: Currency
  createFieldName?: Function
  groupIndex?: number
  onChange?: Function
  bidType: BidTypeValue
};

type BidTypeInputProps = Props & { name: string, title: string, required: boolean };

export const BidTypeInput = ({ bidType, name, title, createFieldName, currency, groupIndex, required, onChange }: BidTypeInputProps) => {
  const fieldName = createFieldName ? createFieldName(name, groupIndex) : name;
  if (bidType === BID_TYPES.BID) {
    return (
      <CurrencyInput
        name={fieldName}
        title={title}
        currency={currency}
        required={required}
        onChange={onChange && onChange(name)}
        skipIsDirtyCheck
      />
    );
  }
  return (
    <NumberInput
      name={fieldName}
      title={title}
      required={required}
      onChange={onChange && onChange(name)}
      skipIsDirtyCheck
    />
  );
};

const SegmentGroupBidFormComponent = ({
  currency,
  groupIndex,
  createFieldName,
  onChange,
  bidType,
} :Props) => {
  const bidOrMultiplier = bidType === BID_TYPES.BID ? 'Bid' : 'Multiplier';
  return (
    <Form.Group className="bid-form" widths="equal" unstackable={false}>
      <BidTypeInput
        bidType={bidType}
        currency={currency}
        createFieldName={createFieldName}
        groupIndex={groupIndex}
        required
        onChange={onChange}
        name="startingMinBid"
        title={`Starting Min. ${bidOrMultiplier}`}
      />
      <BidTypeInput
        bidType={bidType}
        currency={currency}
        createFieldName={createFieldName}
        groupIndex={groupIndex}
        required
        onChange={onChange}
        name="startingMaxBid"
        title={`Starting Max. ${bidOrMultiplier}`}
      />
      <Controller
        name={createFieldName('maxDays', groupIndex)}
        render={({ field, fieldState }) => (
          <DualLabelInputWrapper
            label="Attribution Window"
            type="number"
            onChange={(v) => {
              field.onChange(v);
              onChange('maxDays')(v);
            }}
            field={field}
            fieldState={fieldState}
            skipIsDirtyCheck
          />
        )}
      />
    </Form.Group>
  );
};

export default SegmentGroupBidFormComponent;
