import React from 'react';
import numeral from 'numeral';
import { WppGrid, WppInlineMessage } from 'buildingBlocks';
import { DEFAULT_MAX_ALLOCATION, DEFAULT_MIN_ALLOCATION } from 'containers/StrategyWizard/ConfigurationByStrategyType/BudgetOptimization/constants';
import { MetricsFormattingConstants } from 'constantsBase';
import { pluralizer } from 'utils/formattingUtils';
import { BUDGET_MANAGEMENT_STYLES } from '../style';

const { budgetGroup: { bannerMessage } } = BUDGET_MANAGEMENT_STYLES;

type DefaultGroupBannerProps = {
  nonAttachedLineItemsCount: number
  isPGBanner?: boolean
};

const DefaultGroupBanner = ({ nonAttachedLineItemsCount, isPGBanner = false }: DefaultGroupBannerProps) => {
  const objectPluralizer = pluralizer('object', 'objects')(nonAttachedLineItemsCount);
  const bannerText = isPGBanner
    ? `The remaining ${nonAttachedLineItemsCount} programmatic guaranteed ${objectPluralizer} will have optimization disabled and Copilot will ignore their unspent budget. Alternatively, you can add them to new or existing groups to edit these settings.`
    : `The remaining ${nonAttachedLineItemsCount} ${objectPluralizer} will have a default daily allocation range (${DEFAULT_MIN_ALLOCATION * 100} \u2014 ${numeral(DEFAULT_MAX_ALLOCATION).format(MetricsFormattingConstants.PERCENTAGE_NO_DECIMALS)}) with optimization enabled. Alternatively, you can add them to new or existing groups to edit these settings.`;
  return (
    <WppGrid container item all={24} fullWidth>
      <WppInlineMessage
        style={bannerMessage}
        size="m"
        message={bannerText}
        type="information"
        showTooltipFrom={250}
      />
    </WppGrid>
  );
};

export default DefaultGroupBanner;
