import _ from 'lodash';
import { ALGORITHM_TYPE, DSP } from 'constantsBase';
import { MappingConfigObj, StringMapping } from 'utils/airflow/nameTranslator';
import { toCamelCase, toSnakeCase } from 'utils/formattingUtils';
import { StrategyGoalsDB } from 'utils/types';
import { CustomTreeStatus, modelTypeEnum } from './constants';
import { DAG } from '../constants';
import { baseInitializer, initializePixels } from '../utils';

const PREFIX = `${ALGORITHM_TYPE.customTree.id}.${DAG}`;

export const TASKS = [ALGORITHM_TYPE.customTree.id];

type CreateConfigResult = {
  treeFileInput: string,
  customTreeInput: string,
  modelType: string,
};

type MapperT = {
  config: MappingConfigObj,
  mapping: Array<StringMapping>,
  validateMapping: Array<StringMapping>,
  createConfig: (x: any) => CreateConfigResult,
};

export const MAPPER: MapperT = {
  config: {
    passthrough: [
      ['pageValidator', 'pageValidator'],
    ],
  },
  mapping: [
    ['treeFileInput', `${PREFIX}.csv_text`],
    ['customTreeInput', `${PREFIX}.model_text`],
    ['modelType', `${PREFIX}.model_output`],
    ['impValueFilters', `${PREFIX}.imp_value_filters`],
  ],
  validateMapping: [
    ['memberExternalId', `${PREFIX}.member_ext_id`],
    ['advertiserExternalId', `${PREFIX}.advertiser_ext_id`],
    ['customTreeInput', `${PREFIX}.model_text`],
    ['modelType', `${PREFIX}.model_output`],
  ],
  createConfig: (formValues: any) => ({
    treeFileInput: _.get(formValues, 'treeFileInput'),
    customTreeInput: _.get(formValues, 'customTreeInput'),
    modelType: toSnakeCase(_.get(formValues, 'modelType.value')),
  }),
};

type Config = {
  modelType: string
  [key: string]: unknown
};

export const initializer = (config: Config, strategyGoals: StrategyGoalsDB) => {
  const updatedConfig = baseInitializer(config);

  if (config.modelType) {
    updatedConfig.modelType = _.get(modelTypeEnum, toCamelCase(config.modelType));
    updatedConfig.pageValidator = CustomTreeStatus.NotYetValidated;
  }

  return initializePixels(updatedConfig, strategyGoals, DSP.APN.id);
};

export const ANALYZE_REQUIRED_FIELDS = [
  'modelType',
  'customTreeInput',
  'pageValidator',
];

export const EXCLUDED_FIELDS = ['dagStatus', 'pageValidator'];
