import React from 'react';
import { WppActionButton, WppTypography } from 'buildingBlocks';
import strategyWizardStyles from 'containers/StrategyWizard/styles';

type Props = {
  onAdd: React.MouseEventHandler<HTMLWppActionButtonElement>
  onRemove: React.MouseEventHandler<HTMLWppActionButtonElement>
};

const AddRemoveLeafNames = ({ onAdd, onRemove }: Props) => (
  <div id="leaf-name-toggle">
    <WppTypography type="s-body">Leaf Names: </WppTypography>
    <WppActionButton style={strategyWizardStyles.leafNameStyle} onClick={onAdd} variant="secondary">Fill All</WppActionButton>
    <WppActionButton style={strategyWizardStyles.leafNameStyle} onClick={onRemove} variant="secondary">Remove</WppActionButton>
  </div>
);

export default AddRemoveLeafNames;
