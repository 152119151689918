import _ from 'lodash';
import { ALGORITHM_TYPE } from 'constantsBase';
import { StringMapping, MappingConfigObj } from 'utils/airflow/nameTranslator';
import { DAG } from '../constants';
import { baseInitializer } from '../utils';

const algoTypeId = ALGORITHM_TYPE.dbmCustomSDF.id;
const PREFIX = `${algoTypeId}.${DAG}`;

export const TASKS = [ALGORITHM_TYPE.dbmCustomSDF.id];

type CreateConfigResult = {
  customSDFEnabled: boolean,
};

export type MapperT = {
  config: MappingConfigObj,
  mapping: Array<StringMapping>,
  createConfig: (x: FormData) => CreateConfigResult,
};

const BASE_MAPPER = (): MapperT => {
  const config = {};
  const mapping = [
    ['customSDFEnabled', `${PREFIX}.enabled`],
    ['selectedUsersForEmail', `${algoTypeId}.selectedUsersForEmail`],
    ['impValueFilters', `${PREFIX}.imp_value_filters`],
  ];
  const createConfig = (formValues) => ({
    customSDFEnabled: _.get(formValues, 'customSDFEnabled'),
    selectedUsersForEmail: _.get(formValues, 'selectedUsersForEmail'),
  });

  return ({
    // @ts-ignore
    config,
    mapping: mapping as Array<StringMapping>,
    createConfig,
  });
};

export const MAPPER = BASE_MAPPER();
export const initializer = (config: { [key: string]: unknown }) => baseInitializer(config);
