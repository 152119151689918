import React from 'react';
import _ from 'lodash';
import { Grid, Table, Label, Popup, Icon, Header } from 'buildingBlocks';
import { APN_CONSOLE_REVENUE_TYPE } from 'constantsBase';
import { COPILOT_TYPOGRAPHY, COPILOT_COLORS, COPILOT_LAYOUT } from 'globalStyles';
import { Currency, LineItem } from 'utils/types';
import { getEstimatedBids } from '../utils';

type BidMultiplierProps = {
  currency: Currency,
  minBidMultiplier: number,
  maxBidMultiplier: number,
};

type BidEstimationProps = BidMultiplierProps & { attachedLIs: Array<LineItem> };

type BidsRowProps = BidMultiplierProps & { lineItem: LineItem };

const bidEstimationText = `Xandr’s final bid amount will be influenced by 
other non-Copilot factors, for example: settings for margin and fees, the ALI’s Goal Priority, and Xandr’s risk premium.
The bids are calculated using extreme scenarios that rarely occur: the minimum bid is calculated with a 0% probability
of a click/conversion/video view, and the maximum bid is calculated with a 100% probability of a click/conversion/video view.`;

const headers = ['Line Item(s) and Campaign(s)', 'Revenue Type', 'Minimum Copilot Bid', 'Maximum Copilot Bid'];

const getLabel = (bid: string, formula: string) => (
  <Label basic style={{ border: 0, fontSize: '1rem' }}>
    {`${bid} `}
    <Popup content={formula} trigger={<Icon name="question circle outline" disabled />} />
  </Label>
);

const BidsEstimateRow = ({ currency, lineItem, minBidMultiplier, maxBidMultiplier }: BidsRowProps) => {
  const { minBid, minBidFormula, maxBid, maxBidFormula } = getEstimatedBids(currency, lineItem, minBidMultiplier, maxBidMultiplier);
  return (
    <Table.Row>
      <Table.Cell width={5}>{lineItem.name}</Table.Cell>
      <Table.Cell width={2}>{_.get(APN_CONSOLE_REVENUE_TYPE.getById(lineItem.consoleRevenueType), 'displayName')}</Table.Cell>
      <Table.Cell width={2}>{getLabel(minBid, minBidFormula)}</Table.Cell>
      <Table.Cell width={3}>{getLabel(maxBid, maxBidFormula)}</Table.Cell>
    </Table.Row>
  );
};

const BidsEstimationComponent = ({ currency, attachedLIs, minBidMultiplier, maxBidMultiplier }: BidEstimationProps) => (
  <Grid.Column width={12} style={{ padding: '0 1rem 24px 1.5rem', boxShadow: 'none' }}>
    <Header
      as="h5"
      style={{ ...COPILOT_TYPOGRAPHY.HEADING.H5, marginBottom: 0 }}
      content="Bid Value Estimation"
    />
    <div style={{ marginBottom: COPILOT_LAYOUT.SPACING[8] }}>
      <span style={{ ...COPILOT_TYPOGRAPHY.BODY.SMALL, color: COPILOT_COLORS.NEW_DESIGN_SYSTEM.NEUTRALS.N600_ABBEY }}>{bidEstimationText}</span>
    </div>
    <Table>
      <Table.Header>
        <Table.Row>
          {headers.map((header) => <Table.HeaderCell key={header}>{header}</Table.HeaderCell>)}
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {attachedLIs.map((li) => <BidsEstimateRow key={li.id} currency={currency} lineItem={li} minBidMultiplier={minBidMultiplier} maxBidMultiplier={maxBidMultiplier} />)}
      </Table.Body>
    </Table>
  </Grid.Column>
);

export default BidsEstimationComponent;
