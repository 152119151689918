import React from 'react';
import { WppProgressIndicator } from 'buildingBlocks';
import { PIXEL_PICKER_STYLES } from './styles';

const { loadinginCard } = PIXEL_PICKER_STYLES;

const PixelsLoading = () => (
  <WppProgressIndicator variant="circle" width={25} style={loadinginCard} />
);

export default PixelsLoading;
