import _ from 'lodash';
import React from 'react';
import Confirm from 'containers/StrategyWizard/steps/StrategyConfirmation/Confirm';
import { Router } from 'utils/withRouter';
import { WizardSteps, WIZARD_STEPS } from '../constants';
import AttachFlightsStep from '../steps/AttachFlights/AttachFlightsStep';
import StrategyConfigurationStep from '../steps/StrategyConfiguration/StrategyConfigurationStep';
import GoalSelection from '../steps/GoalSelection';
import StrategyTypeSelection from '../steps/StrategyTypeSelection';
import { AttachFlightsInfoType, StrategyWizardResponse } from '../types';
import RevenueTypeProvider from '../steps/GoalSelection/contexts/GoalSectionProvider';
import { QSParams } from '../utils';

type Props = {
  qsParams: QSParams
  attachFlightsInfo: AttachFlightsInfoType
  setAttachFlightsInfo: (x: any) => void
  strategyState: StrategyWizardResponse
  canAccessCrossPlatform: boolean
  router: Router
};

// eslint-disable-next-line consistent-return
const CreateNewStrategyRoutes = (props: Props) => {
  const {
    qsParams,
    attachFlightsInfo,
    setAttachFlightsInfo,
    strategyState,
    canAccessCrossPlatform,
    router,
  } = props;

  const { location: { pathname } } = router;
  const isAttachFlightsStepOrSubstep = (step: string, pathParam: string) => _.includes([`/strategies/wizard/${WIZARD_STEPS[step].id}`, `/strategies/wizard/${WIZARD_STEPS[step].subSteps.optimizationType.id}`, `/strategies/wizard/${WIZARD_STEPS[step].subSteps.objects.id}`], pathParam);

  if (isAttachFlightsStepOrSubstep(WizardSteps.attachFlightsStep, pathname)) {
    return (
      <AttachFlightsStep
        qsParams={qsParams}
        attachFlightsInfo={attachFlightsInfo}
        setAttachFlightsInfo={setAttachFlightsInfo}
        canAccessCrossPlatform={canAccessCrossPlatform}
        router={router}
      />
    );
  }
  if (_.isEqual(pathname, `/strategies/wizard/${WIZARD_STEPS[WizardSteps.goalSelectionStep].id}`)) {
    return (
      <RevenueTypeProvider>
        <GoalSelection qsParams={qsParams} />
      </RevenueTypeProvider>
    );
  }
  if (_.isEqual(pathname, `/strategies/wizard/${WIZARD_STEPS[WizardSteps.strategyTypeSelectionStep].id}`)) {
    return (<StrategyTypeSelection qsParams={qsParams} />);
  }
  if (_.isEqual(pathname, `/strategies/wizard/${WIZARD_STEPS[WizardSteps.strategyConfigurationStep].id}`)) {
    return (
      <StrategyConfigurationStep
        qsParams={qsParams}
        strategyConfig={undefined}
      />
    );
  }
  if (_.isEqual(pathname, `/strategies/wizard/${WIZARD_STEPS[WizardSteps.strategyConfirmationStep].id}`)) {
    return (
      <Confirm
        strategyState={strategyState}
      />
    );
  }
};

export default CreateNewStrategyRoutes;
