import React from 'react';
import _ from 'lodash';
import { Grid, Header } from 'buildingBlocks';
import { Currency, LineItem } from 'utils/types';
import strategyWizardStyles from 'containers/StrategyWizard/styles';
import OptimizedBidsComponent from 'containers/StrategyWizard/ConfigurationByStrategyType/Helios/containers/OptimizedBidsComponent';
import OptimizedMultipliers from 'containers/StrategyWizard/ConfigurationByStrategyType/Helios/components/OptimizedMultipliers';
import { BID_TYPES } from 'containers/StrategyWizard/ConfigurationByStrategyType/Helios/constants';
import { BidTypeValue, OptimizedBid } from 'containers/StrategyWizard/ConfigurationByStrategyType/Helios/type';
import { COPILOT_TYPOGRAPHY, COPILOT_COLORS, COPILOT_LAYOUT } from 'globalStyles';
import BidsEstimationComponent from './BidsEstimationComponent';

const { row } = strategyWizardStyles;

type BidSettingsProps = {
  currency: Currency,
  bidType: BidTypeValue,
  bids: Array<OptimizedBid>
  attachedLIs?: Array<LineItem>,
  minBidMultiplier?: number,
  maxBidMultiplier?: number,
};

const getBidsComponent = (currency: Currency, optimizedBids: Array<OptimizedBid>, bidType: BidTypeValue) => (
  bidType === BID_TYPES.BID ? <OptimizedBidsComponent currency={currency} optimizedBids={optimizedBids} />
    : <OptimizedMultipliers optimizedMultipliers={optimizedBids} />
);

const getBidSettingsText = (bidType: BidTypeValue) => (
  `As Copilot identifies pockets of high performing inventory,
  it will gradually increase the ${bidType} on that inventory, up to the optimized maximum ${bidType}.
  Copilot will gradually decrease the ${bidType} on pockets of poorly performing inventory,
  down to the optimized minimum ${bidType}.`
);

const BidSettingsComponent = (props: BidSettingsProps) => (
  <Grid.Row style={row}>
    <Grid.Column width={12} style={{ padding: '24px 1.5rem 16px' }}>
      <Header
        as="h5"
        style={{ ...COPILOT_TYPOGRAPHY.HEADING.H5, marginBottom: 0 }}
        content="Bid Settings"
      />
      <div style={{ marginBottom: COPILOT_LAYOUT.SPACING[8] }}>
        <span style={{ ...COPILOT_TYPOGRAPHY.BODY.SMALL, color: COPILOT_COLORS.NEW_DESIGN_SYSTEM.NEUTRALS.N600_ABBEY }}>{getBidSettingsText(props.bidType)}</span>
      </div>
      {getBidsComponent(props.currency, props.bids, props.bidType)}
    </Grid.Column>
    {
      props.bidType === BID_TYPES.BID_MULTIPLIER && !_.isEmpty(props.attachedLIs)
      && (
        <BidsEstimationComponent
          currency={props.currency}
          attachedLIs={props.attachedLIs}
          minBidMultiplier={props.minBidMultiplier}
          maxBidMultiplier={props.maxBidMultiplier}
        />
      )
    }
  </Grid.Row>
);

export default BidSettingsComponent;
