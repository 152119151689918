import { ALGORITHM_TYPE, DSP } from 'constantsBase';
import { StrategyConfigurationStep } from 'containers/StrategyWizard/types';
import { MappingConfigObj, StringMapping } from 'utils/airflow/nameTranslator';
import { safeJSONParse } from 'utils/safeJSONParse';
import { StrategyGoalsDB } from 'utils/types';
import { BidListStatus, BID_LIST_STRING, VOLUME_CONTROL_BID_LIST_STRING, BID_LIST_CSV_INPUT } from './constants';
import { generateFourSpaceJsonString } from './utils';
import { DAG } from '../constants';
import { baseInitializer, initializePixels } from '../utils';

const PREFIX = `${ALGORITHM_TYPE.customBidList.id}.${DAG}`;

export const TASKS = [ALGORITHM_TYPE.customBidList.id];

type CreateConfigResult = {
  [BID_LIST_CSV_INPUT]: string,
  [BID_LIST_STRING]: string,
  [VOLUME_CONTROL_BID_LIST_STRING]: string,
};

type MapperT = {
  config: MappingConfigObj,
  mapping: Array<StringMapping>,
  validateMapping: Array<StringMapping>,
  createConfig: (x: any) => CreateConfigResult,
};

export const MAPPER: MapperT = {
  config: {
    passthrough: [
      ['pageValidator', 'pageValidator'],
    ],
  },
  mapping: [
    [BID_LIST_CSV_INPUT, `${PREFIX}.csv_text`],
    [BID_LIST_STRING, `${PREFIX}.bid_list`],
    [VOLUME_CONTROL_BID_LIST_STRING, `${PREFIX}.vc_bid_list`],
  ],
  validateMapping: [
    [BID_LIST_STRING, `${PREFIX}.bid_list`],
    [VOLUME_CONTROL_BID_LIST_STRING, `${PREFIX}.vc_bid_list`],
  ],
  createConfig: (formValues: StrategyConfigurationStep) => ({
    [BID_LIST_CSV_INPUT]: formValues[BID_LIST_CSV_INPUT],
    [BID_LIST_STRING]: safeJSONParse(formValues[BID_LIST_STRING]) || '',
    [VOLUME_CONTROL_BID_LIST_STRING]: safeJSONParse(formValues[VOLUME_CONTROL_BID_LIST_STRING]) || '',
  }),
};

export const initializer = (config: { [key: string]: unknown }, strategyGoals: StrategyGoalsDB) => {
  const updatedConfig = baseInitializer(config);

  if (updatedConfig.bidListString) {
    updatedConfig.bidListString = generateFourSpaceJsonString(updatedConfig.bidListString);
  }

  if (updatedConfig.volumeControlBidListString) {
    updatedConfig.volumeControlBidListString = generateFourSpaceJsonString(updatedConfig.volumeControlBidListString);
  }

  updatedConfig.pageValidator = BidListStatus.NotYetValidated;
  return initializePixels(updatedConfig, strategyGoals, DSP.TTD.id);
};
