import _ from 'lodash';
import { useFormContext } from 'react-hook-form';
import { useEffect } from 'react';
import moment from 'moment';
import { DSP } from 'constantsBase';
import { PossibleStates, StateT } from 'utils/hooks/useFetcher';
import { StrategyConfigurationStep } from 'containers/StrategyWizard/types';
import { TRUE_VIEW } from 'containers/StrategyWizard/constants';
import { DBMLineItemT } from './types';

const hasAtleastOneTrueViewLI = (data) => {
  const currentDate = moment.utc();
  const active = _.filter(data, (d) => d.isEnabled && !moment(d.endDate).isBefore(currentDate));
  const flightTypes = _.map(active, 'lineItemType');
  return _.some(flightTypes, (f) => f === TRUE_VIEW);
};

// update 'hidden' form values. 'lifetimeEventBudget' needs to be displayed on the confirm page, and
// revenueType / clientEventRevenueValue need to be stored in the dag config.
export const useChangeFormValues = (dspSpecificChildrenState: StateT<Array<DBMLineItemT>>, dsp: number) => {
  const { setValue } = useFormContext<StrategyConfigurationStep>();
  return useEffect(() => {
    if (dspSpecificChildrenState.kind === PossibleStates.hasData) {
      if (dsp === DSP.DBM.id) {
        if (hasAtleastOneTrueViewLI(dspSpecificChildrenState.data)) {
          setValue('isTrueView', true);
        } else {
          setValue('isTrueView', false);
        }
      }
    }
  }, [dspSpecificChildrenState, dsp, setValue]);
};
