import _ from 'lodash';
import React, { useState } from 'react';
import { WppCheckbox, WppTableBodyRow, WppTableBodyCell, WppInput, WppTypography } from 'buildingBlocks';
import dspIconStyles from 'components/DspIcon/style';
import { DSP } from 'constantsBase';
import { InputChangeEventDetail, Pixel, WppInputCustomEvent } from 'utils/types';
import { PIXEL_PICKER_STYLES } from '../styles';

const { pixelCheckbox, pixeldspNameId, pixelOptionDisplay, pixelOptionDspName, dspHeader } = PIXEL_PICKER_STYLES;

type PixelOptionProps = {
  isEngScore: boolean
  pixel: Pixel
  defaultWeight: number
  checked: boolean
  onCheckboxClick: Function
  onWeightChange: Function
  dsp: number
};

export const PixelOption = (props: PixelOptionProps) => {
  const { isEngScore, pixel, defaultWeight, checked, onCheckboxClick, onWeightChange, dsp } = props;
  const [weight, setWeight] = useState<number | ''>(defaultWeight);
  const pixelWeightError = _.isString(weight) || (_.isNumber(weight) && weight < 0);
  const dspInfo = DSP.getById(dsp);
  const dspName = _.get(dspInfo, 'code');
  const dspHeaderStyle = { ...dspIconStyles[_.get(dspInfo, 'code')], ...dspHeader };

  const onWeightInputChange = (value: string) => {
    const numberedWeight = _.isEmpty(value) ? '' : _.toNumber(value);
    setWeight(numberedWeight);
    onWeightChange({ ...pixel, weight: numberedWeight });
  };

  return (
    <WppTableBodyRow className="wpp-table-body-row">
      <WppTableBodyCell className="wpp-table-body-cell">
        <WppCheckbox
          checked={checked}
          style={pixelCheckbox}
          onWppChange={() => onCheckboxClick({ ...pixel, ...(isEngScore && { weight }) })}
          className={checked ? 'visible-checkbox' : 'wpp-checkbox'}
        />
      </WppTableBodyCell>
      <WppTableBodyCell className="wpp-table-body-cell" style={pixelOptionDisplay}>
        <WppTypography type="s-body" style={pixeldspNameId} tag="p">{pixel.id} | {pixel.name}</WppTypography>
      </WppTableBodyCell>
      <WppTableBodyCell className="wpp-table-body-cell" style={pixelOptionDspName}>
        <WppTypography type="s-body" tag="p" style={dspHeaderStyle}>{dspName}</WppTypography>
      </WppTableBodyCell>
      <WppTableBodyCell className="wpp-table-body-cell">
        <WppInput
          className={`pixel-weight-input${(pixelWeightError && checked) ? ' pixelWeightError' : ''}`}
          type="number"
          size="s"
          disabled={!checked}
          value={_.toString(weight)}
          onWppChange={(event: WppInputCustomEvent<InputChangeEventDetail>) => onWeightInputChange(event.detail.value)}
        />
      </WppTableBodyCell>
    </WppTableBodyRow>
  );
};

export default PixelOption;
